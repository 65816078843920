import { Delete, Edit, Favorite } from "@material-ui/icons";
import {
  Box,
  Button,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Divider,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteComment,
  editCommentRequest,
  likeComment,
  unlikeComment,
} from "../../../../apps/Reviews/actions/actions";
import CustomModal from "../../Modals/CustomModal";
import { stringAvatar } from "../../../utility";
import HoverTaggedUser from "./HoverTaggedUser";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  color: "black",
  textTransform: "none",
  fontSize: 12,
  padding: "6px 12px",
  lineHeight: 1.5,
  "&:hover": {
    boxShadow: "none",
    // border: "1px solid #EAEBEC",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {},
});

const primary = {
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#181A1B",
};

const secondary3 = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#484D51",
};

const Comment = ({ comment, review_id, hotel_code, source_code,taggedData=[] }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteModal, setDeleteModal] = useState(false);
  const [time, setTime] = useState();
  const deleting = useSelector(
    (state) => state.review.reviews.deleting_comment || false
  );
  const user = useSelector((state) => state.auth.user || {});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [taggerUser, setTaggerUser] = useState({
    filter: [],
    details: {}
  });

  useEffect(() => {
    const comment_date = new Date(comment.created_at);
    const curr_date = new Date();
    const d1 = comment_date.toLocaleString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const d2 = curr_date.toLocaleString(undefined, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let c_time;
    if (d1 !== d2)
      c_time = comment_date.toLocaleString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    else
      c_time = comment_date.toLocaleString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });
    setTime(c_time);
  }, [comment]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = () => {
    setDeleteModal((prev) => !prev);
    dispatch(editCommentRequest({}));
  };

  const handleDelete = () => {
    const params = {
      c_id: comment.id,
      r_id: review_id,
      hotel: hotel_code,
      source: source_code,
    };
    dispatch(deleteComment(params));
    handleClose();
  };

  const handleEditComment = () => {
    const data = { text: comment.text, id: comment.id, review_id };
    dispatch(editCommentRequest(data));
    handleClose();
  };

  const likeDislike = () => {
    if (comment.user_liked) dispatch(unlikeComment(comment.id, review_id));
    else dispatch(likeComment(comment.id, review_id));
  };



  const replaceWithStringSpan = (sentence, data) => {
    let users = data.filter
    let replacedSentence = [];
    let wordArray = sentence.split(" ");

    for (let i = 0; i < wordArray.length; i++) {
      let currentWord = wordArray[i];
      let nextWord = wordArray[i + 1];
      let combinedWords = `${currentWord} ${nextWord}`;

      if (users.includes(currentWord)) {
        replacedSentence.push(
          <HoverTaggedUser word={currentWord + " "} userData={data.details[currentWord]} />
        );
      } else if (nextWord && users.includes(combinedWords)) {
        replacedSentence.push(
          <HoverTaggedUser word={combinedWords + " "} userData={data.details[combinedWords]} />
        );
        i++; // Skip next word since it's already handled
      } else {
        replacedSentence.push(<span key={i}>{currentWord + " "}</span>);
      }
    }

    return <>{replacedSentence}</>;
  }

  // const ReplaceWithSpanComponent = ({ sentence, users }) => {
  //   // const handleTagHover = (id) => {
  //   //   // Your hover handling logic
  //   // };

  //   let replacedSentence = [];
  //   let wordArray = sentence.split(" ");

  //   for (let i = 0; i < wordArray.length; i++) {
  //     let currentWord = wordArray[i];
  //     let nextWord = wordArray[i + 1];
  //     let combinedWords = `${currentWord} ${nextWord}`;
  // console.log(combinedWords,users.includes(currentWord),users)
  //     if (users.includes(currentWord)) {
  //       replacedSentence.push(
  //         <span
  //           key={i}
  //           style={{ color: 'blue', cursor: 'pointer' }}
  //           onMouseEnter={() => handleTagHover("id")}
  //           onMouseLeave={() => handleTagHover(null)}
  //         >
  //           {currentWord}
  //         </span>
  //       );
  //     } else if (nextWord && users.includes(combinedWords)) {
  //       replacedSentence.push(
  //         <span
  //           key={i}
  //           style={{ color: 'blue', cursor: 'pointer' }}
  //           onMouseEnter={() => handleTagHover("id")}
  //           onMouseLeave={() => handleTagHover(null)}
  //         >
  //           {combinedWords}
  //         </span>
  //       );
  //       i++; // Skip next word since it's already handled
  //     } else {
  //       replacedSentence.push(<span key={i}>{currentWord}</span>);
  //     }
  //   }

  //   return <>{replacedSentence}</>;
  // };






  useEffect(() => {
    let map = {}
    let data = taggedData.map((e) => {
      let key = `@${e.user?.first_name ? e.user?.first_name : ""}${e.user?.first_name && e.user?.last_name && " "}${e.user?.last_name ? e.user?.last_name : ""}`.trim()
      if (!map[key])
        map[key] = e
      return key
    })
    setTaggerUser((p) => ({
      filter: data,
      details: map
    }))

  }, [comment])



  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        marginTop: 2,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <Avatar
          src={comment.user?.profile?.photo}
          {...stringAvatar(
            comment.user.first_name
              ? `${comment.user.first_name} ${comment.user.last_name}`
              : comment.user.last_name,
            { width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Box>
            <Typography component="span" sx={primary}>
              {comment.user?.first_name} {" "}
            </Typography>
            <Typography id={comment.id} component="span" sx={secondary3} >
              {replaceWithStringSpan(comment.text, taggerUser)}
              {/* <ReplaceWithSpanComponent sentence={comment.text} users={taggerUser} /> */}
            </Typography>


          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Typography fontSize={12}>{time}</Typography>
            {/* {isMobile ? (
              <IconButton sx={{ padding: { xs: "4px", sm: "8px" } }}>
                <SubdirectoryArrowRightIcon fontSize="small" />
              </IconButton>
            ) : (
              <BootstrapButton
                size="small"
                startIcon={<SubdirectoryArrowRightIcon />}
              >
                Comment
              </BootstrapButton>
            )} */}

            {isMobile ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={likeDislike}
                  sx={{ padding: { xs: "4px", sm: "8px" } }}
                >
                  {comment.user_liked ? (
                    <Favorite fontSize="small" htmlColor="red" />
                  ) : (
                    <FavoriteBorderIcon fontSize="small" />
                  )}
                </IconButton>
                {comment.like_users?.length > 0 && (
                  <Typography
                    style={{
                      borderRadius: "50%",
                      padding: "0 5px",
                      backgroundColor: "#EAEBEC",
                      fontSize: "12px",
                    }}
                  >
                    {comment.like_users.length}
                  </Typography>
                )}
              </Box>
            ) : (
              <BootstrapButton
                size="small"
                onClick={likeDislike}
                startIcon={
                  comment.user_liked ? (
                    <Favorite htmlColor="red" />
                  ) : (
                    <FavoriteBorderIcon />
                  )
                }
              >
                Like{" "}
                {comment.like_users?.length > 0 && (
                  <span
                    style={{
                      borderRadius: "50%",
                      padding: "0 5px",
                      backgroundColor: "#EAEBEC",
                      marginLeft: "5px",
                    }}
                  >
                    {comment.like_users.length}
                  </span>
                )}
              </BootstrapButton>
            )}

            {/* <BootstrapButton size="small" startIcon={<IosShareIcon />}>
              Share
            </BootstrapButton> */}
          </Box>
        </Box>
      </Box>
      {comment.user?.id == user?.id && (
        <Button
          id="commwnt-menu-button"
          aria-controls={open ? "commwnt-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ padding: 0 }}
          style={{ minWidth: "40px", borderRadius: "15px" }}
        >
          <MoreHorizIcon fontSize="small" htmlColor="black" />
        </Button>
      )}
      <Menu
        id="commwnt-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "commwnt-menu-button",
        }}
        sx={{ width: 320, maxWidth: "100%" }}
      >
        <MenuItem
          onClick={handleDeleteModal}
          sx={{ padding: "5px 10px", minHeight: "20px" }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleEditComment}
          sx={{ padding: "5px 10px", minHeight: "20px" }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Menu>
      <CustomModal
        show={deleteModal}
        handleClose={handleDeleteModal}
        handleClick={handleDelete}
        loading={deleting}
        title={"Please confirm before you delete the comment"}
      />
    </Box>
  );
};

export default Comment;
