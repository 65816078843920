import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  styled,
  MenuItem,
  Menu,
  Modal,
  Box,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddUrlForm from "./AddUrlForm";
import { useDispatch } from "react-redux";
import { deleteScrapUrl } from "../actions/settingsActions";
import Tooltip from '@mui/material/Tooltip';
import { SwiperSlide } from "swiper/react";
import SwipeImageForTable from "./SwipeImageForTable";

const StyledTableCell = styled(TableCell)(({ theme, fontWeight }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E4E6E7",
    color: "#6D7378",
    padding: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontWeight: fontWeight ? fontWeight : 500,
    padding: "8px 4px",
  },
}));
const CommonSingleRow = ({menuItems, payload={},showMenu,id,deleteAction,refreshOption=false,refreshAction,firstRowCap=true,menuItem=null,fullData=null,imageList=false,commonCss={ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '270px', textTransform: firstRowCap?'capitalize':"none"}}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch= useDispatch()

    
  
    const toggleNewTaskModal = () => {
      setShow((prev) => !prev);
    };

    
  const handleClick = (event) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    event.stopPropagation();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleDelete = (e) => {

    dispatch(deleteAction({
     id: id,
    }))
   };

   const handleRefresh = (e) => {

    dispatch(refreshAction({
     id: id,
    }))
   };
  
    return (
      <>
        <TableRow>
     {
        Object.keys(payload).length > 0 ?(
      
        Object.keys(payload).map((e,i)=>(
            <StyledTableCell >
              {/* <Tooltip title={payload[e]} > */}
            <Typography
              sx={{
                fontWeight: i === 0 ? "600" : "normal",
                fontSize: "14px",
                paddingLeft:'20px',
                ...commonCss
              }}
            >
              {payload[e]}
            </Typography>
            {/* </Tooltip> */}
          </StyledTableCell>
        ))
      
        ):(
            <StyledTableCell >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                paddingLeft:'20px'
              }}
            >
              No data
            </Typography>
          </StyledTableCell>
        )
     }
   
{
  imageList && Array.isArray(imageList) &&    <StyledTableCell sx={{
      maxWidth:"200px"
  }} >
 <SwipeImageForTable list={
imageList.map((obj, index) =>
<SwiperSlide>
<img width={120} height={120} src={obj.image} alt="" />
</SwiperSlide>)
} />
</StyledTableCell>
}

     {
        showMenu  && (
            <StyledTableCell>
            <Typography
                sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    paddingLeft:'20px',
                    ...commonCss
                  }}
            >
  
              {/* {<EditIcon onClick={toggleNewTaskModal} />} */}
              <MoreHorizIcon fontSize="small"  aria-controls="menu"
              aria-haspopup="true"
              onClick={(e)=>{handleClick(e) }} 
              />
            </Typography>
          </StyledTableCell>
          
        )
        
     }
            <Menu
          id="menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
        >
            {/* <MenuItem  onClick={toggleNewTaskModal}>Edit</MenuItem> */}
     
          {menuItems ? menuItems.map((item, index) => (
            <MenuItem key={index} onClick={(e)=>{
              e.stopPropagation()
              item.onClick(fullData)
            }}>
              {item.label}
            </MenuItem>
          )):[
            refreshOption && <MenuItem key="refresh" onClick={handleRefresh}>Refresh</MenuItem>,
            <MenuItem key="delete" onClick={handleDelete}>Delete</MenuItem>
          ]}
        </Menu>
        </TableRow>
        <Modal
        open={show}
        onClose={toggleNewTaskModal}
        aria-labelledby="add-new-task-modal"
        aria-describedby="add-new-task-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "10px",
            width: { xs: "400px", sm: "700px" },
            border: "1px solid #c4c4c4",
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <AddUrlForm
            closeModal={toggleNewTaskModal}
            isEdit={true}
            data={payload}
          />
        </Box>
      </Modal>
      </>
    );
  };
  

export default CommonSingleRow
