import * as actionTypes from "./actionTypes";
import axiosClient from "../../../httpClient";
import {
  check_auth_error,
  convert_dates_to_query_params,
  convert_filter_obj_to_query_param2,
} from "../../../components/Utility/Utility";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import { pushGAEvent } from "../../../commons/utility";


export const fetchReviewsRequest = () => {
  return {
    type: actionTypes.FETCH_REVIEWS_REQUEST,
  };
};

export const fetchReviewsSuccess = (reviews, replace) => {
  return {
    type: actionTypes.FETCH_REVIEWS_SUCCESS,
    payload: { reviews: reviews, replace: replace },
  };
};

export const fetchReviewsError = (error) => {
  return {
    type: actionTypes.FETCH_REVIEWS_ERROR,
    payload: error,
  };
};

export const clearReviews = () => {
  return {
    type: actionTypes.CLEAR_REVIEWS,
  };
};

export const clearAReply = (ai_generated_reply_key) => {
  return {
    type: actionTypes.CLEAR_A_REPLY,
    payload: ai_generated_reply_key,
  };
};

export function fetchReviews(searchText = null, page_no = 1, replace = true, filterKey="/reviews") {
  return function (dispatch, getState) {
    dispatch(fetchReviewsRequest());
    const selectedHotel = getState().filter.selectedHotel || {};
    const selectedCurrDate = getState().filter.selectedCurrDate || {};
    const selectedCompDate = getState().filter.selectedCompDate || {};
    const selectedFilters = getState().filter.selectedFilters[filterKey] || [];
    const selectedFilterTopics = getState().filter.selectedFilterTopics || [];
    const review_id = getState().filter.selectedReviewId;

    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate
    )}&comp-date=${convert_dates_to_query_params(
      selectedCompDate.option,
      selectedCompDate.startDate,
      selectedCompDate.endDate
    )}`;
    const filter_params = convert_filter_obj_to_query_param2(selectedFilters);
    const filter_topic_params =
      convert_filter_obj_to_query_param2(selectedFilterTopics);
    if (!navigator.onLine) {
      dispatch(fetchReviewsError("Offline"));
      return;
    }
    axiosClient
      .get(
        `/analytics/reviews/?hotel=${selectedHotel.hotel_code}&${date_params}${filter_params ? `&${filter_params}` : ""
        }&page=${page_no}${searchText ? "&search-text=" + searchText : ""}${filter_topic_params ? "&" + filter_topic_params : ""
        }${review_id ? `&review_id=${review_id}` : ""}`
      )
      .then((res) => {
        if (res.status != 204) {
          dispatch(fetchReviewsSuccess(res.data, replace));
        } else {
          dispatch(setNoMoreReview());
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        console.log(`Error: ${JSON.stringify(error)}`);
        dispatch(fetchReviewsError(error.response?.msg));
      });
  };
}

export const generateReplyRequest = () => {
  return {
    type: actionTypes.GENERATE_REPLY_REQUEST,
  };
};

export const cacheGeneratedReviewReply = (key, reply) => {
  return {
    type: actionTypes.CACHE_GENERATED_REPLY,
    payload: { key: key, reply: reply },
  };
};

export const fetchReviewReplyLangSuccess = (languages) => {
  return {
    type: actionTypes.FETCH_LANGUAGE_OPTION_SUCCESS,
    payload: languages,
  };
};

export const generateReply = (
  review_txt,
  guest_name,
  hotel_name,
  hotel_code,
  review_id,
  review_rating_score,
  rating_out_of,
  source_name,
  lang_code,
  lang_name,
  setLoading,
  setError,
  loading
) => {
  return (dispatch, getState) => {
    // const loading = getState().review.replies.loading;
    const {
      filter: { selectedHotel },
    } = getState();
    if (loading) {
      console.log(`Please wait previous request still in process.`);
      return;
    }
    // dispatch(generateReplyRequest());

    if (!navigator.onLine) {
      if (setLoading)
        setLoading("");
      if (setError)
        setError("Offline");
      return;
    }
    if (setLoading)
      setLoading("generating_reply");
    console.log(`generating reply`);
    axiosClient
      .post(`/reviews/reply/generate-reply/?hotel=${selectedHotel.hotel_code}`, {
        review_txt: review_txt.substr(0, 2000),
        hotel_name: hotel_name,
        guest_name: guest_name,
        target_lang: lang_name,
        rating: review_rating_score,
        out_of: rating_out_of,
        website: source_name,
      })
      .then((response) => {
        const key = `${hotel_code}_${review_id}_${lang_code}`;
        if (setError)
          setError(null);
        dispatch(cacheGeneratedReviewReply(key, response.data.reply_txt));
      })
      .catch((error) => {
        const errorMsg = error.message;
        if (setLoading)
          setLoading("");
        //check_auth_error(error);
        if (setError)
          setError(errorMsg || "Something went wrong, please try again!");
      });
  };
};

export const convertReply = (
  reply_txt,
  hotel_code,
  review_id,
  lang_code,
  lang_name,
  setLoading,
  setError,
  loading
) => {
  return (dispatch, getState) => {
    // const loading = getState().review.replies.loading;
    if (loading) {
      console.log(`Please wait previous request still in process.`);
      return;
    }
    // dispatch(generateReplyRequest());

    if (!navigator.onLine) {
      if (setLoading)
        setLoading("");
      if (setError)
        setError("Offline");
      return;
    }
    if (setLoading)
      setLoading("generating_reply");
    console.log(`converting reply`);
    axiosClient
      .post(`/reviews/reply/convert-reply-lang/`, {
        text: reply_txt,
        target_langs: [lang_name],
      })
      .then((response) => {
        const key = `${hotel_code}_${review_id}_${lang_code}`;
        dispatch(cacheGeneratedReviewReply(key, response.data[lang_name]));
        if (setError)
          setError(null);
      })
      .catch((error) => {
        const errorMsg = error.message;
        if (setLoading)
          setLoading("");
        if (setError)
          setError(errorMsg || "Something went wrong, please try again!");
      });
  };
};

export const fetchLanguageOptions = () => {
  return (dispatch, getState) => {
    axiosClient
      .get(`/survey/languageOptions/`)
      .then((response) => {
        dispatch(fetchReviewReplyLangSuccess(response.data || []));
      })
      .catch((error) => {
        const errorMsg = error.message;
        // dispatch({type: chatActiontypes.FETCH_USER_MESSAGES_FAILURE, payload: errorMsg})
      });
  };
};

export const setNoMoreReview = (payload) => {
  return {
    type: actionTypes.SET_NO_MORE_REVIEWS,
    payload,
  };
};

export const update_dont_ask_popup_flag = () => {
  return {
    type: actionTypes.UPDATE_DONT_ASK_POPUP_FLAG,
  };
};

// reply to a review
export const sendReviewReplySuccess = (review_id) => {
  return {
    type: actionTypes.SEND_REVIEW_REPLY_SUCCESS,
    payload: review_id,
  };
};

export const sendReviewReply = (setLoading, data, scrollY, history, setNotConnectedPopUp, setPopUpMessage) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    setLoading("sending_reply");
    axiosClient
      .post(`/reviews/reply/${data.code}/${data.review_id}/?hotel=${selectedHotel.hotel_code}`, {
        reply: data.reply,
        ...(data.review_reply_post_dont_ask_again?{review_reply_post_dont_ask_again:true}:{})
      })
      .then((response) => {
        if (response?.status === 200) {
          let event_data = {
            event:"BTN_REVIEW_POST",
            // reviewEventType:"reply_review_button_click",
            source_name:data.source_name,
            }
            
            pushGAEvent(event_data)
          dispatch(fetchReviews())
          dispatch(sendReviewReplySuccess(data.review_id));
          if(data?.review_reply_post_dont_ask_again){
            dispatch(update_dont_ask_popup_flag())
          }
        } else {
          dispatch(setGlobalMessage({
            mess: "Something went wrong",
            type: 'error'
          }))
        }
        setLoading("");
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response.status === 404) {
          setPopUpMessage(error.response?.data || "")
          setNotConnectedPopUp(true)
          if (setLoading)
            setLoading("");
          //  if(history)
          //  history.push('/settings', { scrollY,next:'/reviews' });
        }
        else {
          setLoading("");
          dispatch(setGlobalMessage({
            mess: error?.response?.data || "Something went wrong",
            type: 'error'
          }))
        }
      });
  };
};

//review comments
export const fetchCommentsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COMMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchComments = (url, setLoading, setError, review_id) => {
  return (dispatch, getState) => {
    const {
      review:{reviews},
    } = getState();
    setLoading(true);
    axiosClient
      .get(url)
      .then((res) => {
        let comments = reviews?.comments[review_id]?.data || []
        const data = {
          review_id: review_id,
          comments: res.data.previous? [...(comments || []),...res.data.results]:res.data.results,
          next: res.data.next,
          total_comments: res.data.count,
        };
        setError("");
        dispatch(fetchCommentsSuccess(data));
      })
      .catch((error) => {
        console.log("error in getting comments", error);
        setLoading(false);
        setError(error.message || "Something went wrong, try again!");
      });
  };
};

export const sendCommentSuccess = (data) => {
  return {
    type: actionTypes.SEND_COMMENT_SUCCESS,
    payload: data,
  };
};

export const postComment = (data, setError, setLoading,setTaggerUserRecord) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    console.log(data)
    data.tag_to_obj_lst = []

    for (let e of data.removedDup) {
      let name = e.split("||")[0]
      let id = e.split("||")[1]
      // console.log(name, data.text.includes(name))
      if (data.text.includes(name))
        data.tag_to_obj_lst.push({
          type: "USER", id
        })
    }
    delete data.removedDup
    // console.log(data)  
    setLoading(true);
    axiosClient
      .post(`/reviews/comments/?hotel=${selectedHotel.hotel_code}`, data)
      .then((res) => {
        const new_comment = { review_id: data.review_id, comment: res.data };
        dispatch(sendCommentSuccess(new_comment));
        setError("");
        setLoading(false);
        if(setTaggerUserRecord)
        setTaggerUserRecord([])
      })
      .catch((error) => {
        console.log("post comment error: ", error);
        setLoading(false);
        setError(error.message || "Something went wrong, try again!");
        dispatch(setGlobalMessage({
          mess: error.data?error.data[0]:"Something went wrong, try again!",
          type: 'error'
        }))
      });
  };
};

export const updateLike = (data) => {
  return {
    type: actionTypes.SET_COMMENT_LIKE,
    payload: data,
  };
};

//like a comment
export const likeComment = (id, review_id) => {
  return (dispatch, getState) => {
    const {
      auth: { user },
    } = getState();
    axiosClient
      .post(`/reviews/comments/${id}/likes/`)
      .then((res) => {
        if (res.data.result === "success") {
          const data = { comment_id: id, review_id, user };
          dispatch(updateLike(data));
        }
      })
      .catch((error) => {
        console.log("error in like: ", error);
      });
  };
};

//unlike a comment
export const unlikeComment = (id, review_id) => {
  return (dispatch, getState) => {
    const {
      auth: { user },
    } = getState();
    axiosClient
      .delete(`/reviews/comments/${id}/likes/`)
      .then((res) => {
        const data = { comment_id: id, review_id, user };
        dispatch(updateLike(data));
      })
      .catch((error) => {
        console.log("error in like: ", error);
      });
  };
};

// Delete a comment
export const deleteCommentRequest = () => {
  return {
    type: actionTypes.DELETE_COMMENT_REQUEST,
    payload: true,
  };
};

export const deleteCommentError = (e) => {
  return {
    type: actionTypes.DELETE_COMMENT_ERROR,
    payload: e,
  };
};

export const commentDeleted = (data) => {
  return {
    type: actionTypes.DELETE_COMMENT_SUCCESS,
    payload: data,
  };
};

export const deleteComment = (params) => {
  return (dispatch, getstate) => {
    dispatch(deleteCommentRequest());
    axiosClient
      .delete(
        `/reviews/comments/${params.c_id}/?hotel=${params.hotel}&review_id=${params.r_id}&source=${params.source}`
      )
      .then((res) => {
        const data = { comment_id: params.c_id, review_id: params.r_id };
        dispatch(commentDeleted(data));
      })
      .catch((error) => {
        const msg =
          error.detail || error.message || "Something went wrong, try again!";
        dispatch(deleteCommentError(msg));
        console.log("error in deleting comment", msg);
      });
  };
};

// edit a comment
export const editCommentRequest = (comment) => {
  return {
    type: actionTypes.EDIT_COMMENT_REQUEST,
    payload: comment,
  };
};

export const editCommentSuccess = (edited_comment) => {
  return {
    type: actionTypes.EDIT_COMMENT_SUCCESS,
    payload: edited_comment,
  };
};

export const editCommentError = (error) => {
  return {
    type: actionTypes.EDIT_COMMENT_ERROR,
    payload: error,
  };
};
export const setAggregateScore = (data) => {
  return {
    type: actionTypes.SET_AGGREGATE_SCORE,
    payload: data,
  };
};

export const editComment = (params, setError, setLoading,setTaggerUserRecord) => {
  return (dispatch, getstate) => {
    console.log(params)
    params.tag_to_obj_lst = []

    for (let e of params.removedDup) {
      let name = e.split("||")[0]
      let id = e.split("||")[1]
      // console.log(name, params.text.includes(name))
      if (params.text.includes(name))
        params.tag_to_obj_lst.push({
          type: "USER", id
        })
    }
    delete params.removedDup
    setLoading(true);
    axiosClient
      .patch(
        `/reviews/comments/${params.comment_id}/?hotel=${params.hotel_code}&review_id=${params.review_id}&source=${params.source_code}`,
        {
          text: params.text,
          tag_to_obj_lst: params.tag_to_obj_lst
        }
      )
      .then((res) => {
        const data = {
          comment_id: params.comment_id,
          edited_comment: res.data,
          review_id: params.review_id,
        };
        dispatch(editCommentSuccess(data));
        setLoading(false);
        setError("");
        if(setTaggerUserRecord)
        setTaggerUserRecord([])
      })
      .catch((error) => {
        const msg =
          error.detail || error.message || "Something went wrong, try again!";
        dispatch(editCommentError());
        setLoading(false);
        setError(
          error.detail || error.message || "Something went wrong, try again!"
        );
        console.log("error in deleting comment", msg);
      });
  };
};

export function fetchAggregateScore(filterkey="") {
  return function (dispatch, getState) {
    const selectedHotel = getState().filter.selectedHotel || {};
    const selectedCurrDate = getState().filter.selectedCurrDate || {};
    const selectedCompDate = getState().filter.selectedCompDate || {};
    const selectedFilters = getState().filter.selectedFilters[filterkey] || [];
    const selectedFilterTopics = getState().filter.selectedFilterTopics || [];

    const date_params = `date=${convert_dates_to_query_params(
      selectedCurrDate.option,
      selectedCurrDate.startDate,
      selectedCurrDate.endDate
    )}&compare-date=${convert_dates_to_query_params(
      selectedCompDate.option,
      selectedCompDate.startDate,
      selectedCompDate.endDate
    )}`;
    const filter_params = convert_filter_obj_to_query_param2(selectedFilters);
    const filter_topic_params =
      convert_filter_obj_to_query_param2(selectedFilterTopics);
    if (!navigator.onLine) {
      dispatch(fetchReviewsError("Offline"));
      return;
    }
    if (!selectedHotel?.hotel_code) {
      return;
    }
    // axiosClient
    //   .get(
    //     `/analytics/aggregate-score/?hotel=${selectedHotel.hotel_code}&${date_params}${
    //       filter_params ? `&${filter_params}` : ""
    //     }${
    //       filter_topic_params ? "&" + filter_topic_params : ""
    //     }`
    //   )
    axiosClient
      .get(
        `/analytics/aggregate-score/?hotel=${selectedHotel.hotel_code}&date=year to date&compare-date=same period last year`
      )
      .then((res) => {
        dispatch(setAggregateScore(res.status === 200 ? res.data : {}))
      })
      .catch((error) => {
        //check_auth_error(error);
        console.log(`Error: ${JSON.stringify(error)}`, error.response);
        dispatch(setAggregateScore({}))
        // dispatch(setGlobalMessage({
        //   mess: error?.response?.data?.detail||"Something went wrong",
        //   type:'error'
        //  }))
      });
  };
}

export const MarkReview = (body,handleCopy,setMarkPopup) => {
  return (dispatch, getState) => {
    const selectedHotel = getState().filter.selectedHotel || {};
    let reviews = getState().review?.reviews?.data || []
  
    axiosClient
      .post(`/reviews/v2/reply/${body.code}/${body.review_id}/?hotel=${selectedHotel.hotel_code}`,{
        reply:body.reply
      })
      .then((response) => {
        // let event_data = {
        //   event:"review_events",
        //   reviewEventType:"mark_review_button_click",
        //   review_id:body.review_id
        //   }
          
        //   pushGAEvent(event_data)

        
       let data = response.data
      reviews = reviews.map((e)=>e.review_id == data.review_id?{...e,...data}:e)
      console.log(reviews,data)
      dispatch(fetchReviewsSuccess(reviews, true));
      // handleCopy("Review is successfully marked and copied to clipboard")
      dispatch(setGlobalMessage({
        mess: "Review is marked successfully",
        type:'success'
       }))
      })
      .catch((error) => {
        const errorMsg = error.message;
           dispatch(setGlobalMessage({
          mess: error?.response?.data?.details||"Something went wrong",
          type:'error'
         }))
      }).finally(()=>{
        if(setMarkPopup) setMarkPopup(null)
      })
  };
};


export const UnMarkReview = (body,setClose) => {
  return (dispatch, getState) => {
    const selectedHotel = getState().filter.selectedHotel || {};
    let reviews = getState().review?.reviews?.data || []
  
    axiosClient
      .delete(`/reviews/v2/reply/${body.code}/${body.review_id}/?hotel=${selectedHotel.hotel_code}`)
      .then((response) => {
       let data = response.data
      reviews = reviews.map((e)=>e.review_id == data.review_id?{...e,...data}:e)
      dispatch(fetchReviewsSuccess(reviews, true));
      dispatch(setGlobalMessage({
        mess: "Review is unmarked successfully",
        type:'success'
       }))
      })
      .catch((error) => {
        const errorMsg = error.message;
           dispatch(setGlobalMessage({
          mess: error?.response?.data?.detail||"Something went wrong",
          type:'error'
         }))
      }).finally(()=>{
        if(setClose) setClose()
      })
  };
};
