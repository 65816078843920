import React, { useState } from "react";
import "./FileUpload.css";
import FilePreview from "./subComponents/FilePreview";
import { Box, Modal } from "@mui/material";
import CropImages from "./subComponents/CropImages";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setGlobalMessage } from "../../../actions/dashboardActions";

const FileUpload = ({ closeUploadModal, inputHandler,copiedImage,setCopiedImage,forWhichComp=null,showCaption=true,operationData = null,imageDeleteHandler = null }) => {
  const [selectedfile, setSelectedFile] = useState([]);
  const [rawImages, setRawImages] = useState([]);
  const [cropImage, setCropImage] = useState(null);
  const channel = useSelector((state) => state.chat.selected_channel || "");
  const dispatch = useDispatch()

  const isFileSizeOverLimit = (bytes) => {
    if (bytes === 0) return false;
    const k = 1000000;
    const i = Math.floor(bytes / k);
    return i > 5;
  };

  // useEffect(() => {
  //   if (operationData && operationData.images && operationData.images.length > 0) {
  //     const fetchImages = async () => {
  //       try {
  //         const files = await Promise.all(operationData.images.map(async (url, index) => {
  //           const img = new Image();
  //           img.crossOrigin = "Anonymous";
  //           img.src = url;
            
  //           await new Promise((resolve, reject) => {
  //             img.onload = resolve;
  //             img.onerror = reject;
  //           });
            
  //           const canvas = document.createElement("canvas");
  //           canvas.width = img.width;
  //           canvas.height = img.height;
  //           const ctx = canvas.getContext("2d");
  //           ctx.drawImage(img, 0, 0);
  //           const blob = await new Promise((resolve) => canvas.toBlob(resolve));
  //           const file = new File([blob], `image-${index}.jpg`, { type: blob.type });
  //           return file;
  //         }));
  //         const syntheticEvent = {
  //           target: {
  //             files: files,
  //           },
  //         };
  //         InputChange(syntheticEvent);
  //         setCopiedImage(null);
  //       } catch (error) {
  //         console.error(error);
  //         alert(`Error fetching images: ${error.message}`);
  //       }
  //     };
  //     fetchImages();
  //   }
  // }, [operationData]);

    useEffect(() => {
    if (operationData && operationData.images && operationData.images.length > 0) {
      let data  =  operationData.images.map((e)=>(
        {
          id:e.id,
          filename: e?.image ?  e.image.split("/")[e.image.split("/").length -1]:"file"+Math.random(),
          filetype: e?.image ?  e?.image.split(".")[e.image.split(".").length -1]:".jpg",
          fileimage: e?.image,
          caption: "",
          isUploaded:true,
        }
      ))
      setSelectedFile((preValue) => {
        return [
          ...preValue,
     ...data
        ];
      });
       
    }
  }, [operationData]);

  useEffect(() => {
    if (copiedImage && copiedImage.length > 0) {
      const syntheticEvent = {
        target: {
          files: copiedImage,
        },
      };
      InputChange(syntheticEvent);
      setCopiedImage(null)
    }
  }, [copiedImage]);

  const InputChange = (e) => {
    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      reader.onloadend = () => {
        if (isFileSizeOverLimit(images[i].size)) {
          alert("File size should be less than or equal to 5mb");
          return;
        }
        setSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: images[i].name,
              filename: images[i].name,
              filetype: images[i].type,
              fileimage: reader.result,
              caption: "",
            },
          ];
        });
      };
      if (images[i]) {
        reader.readAsDataURL(file);
      }
    }
    setRawImages(images)
  };

  const deleteSelectedFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id != id);
      setSelectedFile(result);
     if(imageDeleteHandler)
      imageDeleteHandler(id,operationData)
    }
  };

  const FileUploadSubmit = async (e) => {
    e.preventDefault();

    // form reset on submit
    e.target.reset();
    if (selectedfile.length > 0) {
if(forWhichComp && forWhichComp === "Trip Planner"){
  if(rawImages.length === 0){
    return  dispatch(setGlobalMessage({
      mess: "Please add image.",
      type: 'warning'
    }));
   }
  inputHandler(rawImages)
}else{
  selectedfile.forEach((file) => {
    let messsage = {
      msg: file.caption,
      msg_type: "IMAGE",
      photo: file.fileimage,
    };
    inputHandler(messsage, channel);
  });
}
      setSelectedFile([]);
      if(closeUploadModal)
      closeUploadModal();
    } else {
      alert("Please select file");
    }
  };

  const closeCropModal = () => {
    setCropImage(null);
  };

  return (
    <>
      <div className="fileupload-view">
        <div className="row justify-content-center m-0">
          <div className="col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title">
                      <h6>Multiple File Upload With Preview</h6>
                    </div>
                  </div>
                  <form onSubmit={FileUploadSubmit}>
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={InputChange}
                          multiple
                          accept="image/*"
                        />
                        <span>
                          Drag and drop or{" "}
                          <span className="file-link">Choose your files</span>
                        </span>
                      </div>
                    </div>
                    <div className="kb-attach-box mb-3"
                    style={{
                      display:"flex",
                      flexDirection:"row",
                      overflowX:"auto"
                    }}
                    >
                      {selectedfile.map((data, index) => {
                        return (
                          <FilePreview
                            data={data}
                            deleteSelectedFile={deleteSelectedFile}
                            setSelectedFile={setSelectedFile}
                            setCropImage={setCropImage}
                            showCaption={showCaption}
                          />
                        );
                      })}
                    </div>
                    <div
                      className="kb-buttons-box"
                      style={{ marginTop: "10px" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary form-submit"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={!cropImage ? false : true}
        onClose={closeCropModal}
        aria-labelledby="modal-crop-image"
        aria-describedby="modal-crop-image"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "600px", lg: "1000px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <CropImages
            setSelectedFile={setSelectedFile}
            image_to_be_cropped={cropImage}
            closeCropModal={closeCropModal}
          />
        </Box>
      </Modal>
    </>
  );
};

export default FileUpload;
