import React from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles,createTheme } from '@material-ui/core/styles';
import review_management from "./assets/review-management.svg";

const useStyles = makeStyles(theme => ({
  icon:{
    filter: 'invert(0)',
    height: '1.3rem'
  },
  selected_icon:{
    filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(97%) contrast(102%)',
    height: '1.3rem'
  },
  iconCont:{
    width: '1.5rem',
    display: 'flex',
    justifyContent: 'center'
  }
}))
const NavItem = (props) => {
  const classes = useStyles()
    return (
        <div className={props.contClass} >
            <NavLink
              key = {props.navItem.id}
              to= {props.navItem.frontend_route}
              style={{ textDecoration: "none", color: "#fff", margin: 0, display: 'flex'}}
              activeClassName="active"
              activeStyle = {{color: '#283953'}}>
              <div className={classes.iconCont} >
                <img className={props.selectedMenu == props.navItem.name ? classes.selected_icon : classes.icon } src={props.imgSrc || review_management} />
              </div>
              <li className="nav-item" id="dashboard_li">
                <p className="nav-link" id="dashboard_a" style={{margin: 0, paddingLeft: '15px'}}>
                  {props.navItem.name}
                </p>
              </li>
              
            </NavLink>
          </div>
    );
};

export default NavItem;