import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { Box, useMediaQuery } from "@mui/material";

function CommonDateFilter({ selectedDates, onDateChange,obj }) {
  const match = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [dates, setDates] = useState([null, null]);

  useEffect(() => {
    if (selectedDates) {
      setDates([
        new Date(selectedDates.startDate),
        new Date(selectedDates.endDate),
      ]);
    }
  }, [selectedDates]);

  const handleChange = (selectedDates) => {
    if (Array.isArray(selectedDates)) {
      for (let d of selectedDates) {
        if (d === "Invalid Date") return;
      }
    }

    const startDate = selectedDates ? new Date(selectedDates[0]) : null;
    const endDate = selectedDates ? new Date(selectedDates[1]) : null;

    setDates([startDate, endDate]);
    if (startDate && endDate) {
      onDateChange({ startDate, endDate });
    }
    else
    onDateChange({ startDate:null, endDate:null });
  };

  const Ranges = [
    {
      placement: "left",
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "yesterday",
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1)),
      ],
    },
    {
      placement: "left",
      label: "Last 7 days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "This week",
      value: [startOfWeek(new Date()), new Date()],
    },
    {
      placement: "left",
      label: "Last week",
      value: [
        startOfWeek(subDays(startOfWeek(new Date()), 1)),
        subDays(startOfWeek(new Date()), 1),
      ],
    },
    {
      placement: "left",
      label: "This month",
      value: [startOfMonth(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "Last month",
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
    },
    {
      placement: "left",
      label: "This quarter",
      value: [startOfQuarter(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "Last quarter",
      value: [
        startOfQuarter(subMonths(startOfQuarter(new Date()), 1)),
        endOfQuarter(subMonths(startOfQuarter(new Date()), 1)),
      ],
    },
    {
      placement: "left",
      label: "This year",
      value: [
       startOfYear(new Date()),
       endOfDay(new Date()),
      ],
    },
  ];

  return (
    <Box>
      <label
        style={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#181A1B",
        }}
        htmlFor="date-range"
      >
        {obj.name}
      </label>
      <DateRangePicker
        id="date-range"
        block={true}
        showOneCalendar={match ? true : false}
        placeholder="Select a range"
        format="yyyy-MM-dd"
        placement="autoVerticalEnd"
        value={dates[0] && dates[1] && dates}
        ranges={Ranges}
        character=" - "
        onChange={handleChange}
        shouldDisableDate={(date) => date > new Date()}
        style={{ paddingTop: "0px" }}
      />
    </Box>
  );
}

export default CommonDateFilter;
