import React, { useState,useEffect, Fragment } from 'react';
import reload from '../assets/reload.svg';
import loading from '../assets/Loadinggif.gif';
import axios from 'axios';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { check_auth_error, add_auth_header } from "../../Utility/Utility";
import Spinner from '../../Spinner/Spinner';
import datanotfound from '../assets/datanotfound.png';

const BaseAPICompnenetWithLoader = props => {
    const {add_auth_header} = props;
    const [api_error, setAPIError] = useState(false);
    const [http_resp_code, setHttpRespCode] = useState(null);
    const [api_loading, setAPILoading] = useState(true);
    let api_dependency = props.api_dependency != null ? props.api_dependency : [];

    const load_data = () => {
        if(props.debug) console.log(`loading again...`)

        setAPILoading(true);  
        setAPIError(false);
        setHttpRespCode(null);
        if(props.setAPIRespCode) props.setAPIRespCode(null);

        axios
        .get(
            props.data_url,
            {
              headers: add_auth_header({}),
            }
        )
        .then((res) => {
          if (res.status !== 204){
            props.setAPIData(res.data);
          } else if(props.noData) props.setAPIData(props.noData)
            
            setHttpRespCode(res.status);
            if(props.setAPIRespCode){
              props.setAPIRespCode(res.status)
            }
            if(props.debug) console.log('onSuccess setting api loading to false');
            setAPILoading(false);
            setAPIError(false);
        })
        .catch((error) => {
            setAPIError(true);
            if(props.debug) console.log('onError api loading to false');
            setAPILoading(false);
            console.log(error);
            check_auth_error(error);
        });
    }

    const not_null = (v) => {
      return v instanceof Array ? v.length>0 : v instanceof Object ? Object.keys(v).length>0: v != null;
    }

    useEffect(() => {
      if (props.debug) console.log(JSON.stringify(api_dependency));
        if (api_dependency.length > 0 && api_dependency.every(not_null)){
            load_data();
        }
    }, api_dependency);

    return (
        <Fragment>
            {api_error ? (
              <div className="review_reload">
                <img
                  src={reload}
                  alt=""
                  className="loading_image cursor"
                  onClick={load_data}
                />
                <p className="loading_text ">Reload</p>
              </div>)
            : api_loading ? (
              <div className="review_loading">
                <Spinner/>
                <p className="loading_text">Loading, please wait</p>
              </div>
            )
            : (http_resp_code == 204 && !props.objectName === "Employee") ? (<div className="review_loading">

                  {!props.hide_not_found_img && (<Fragment> {props.not_found_component} </Fragment> || <img src={datanotfound} alt="Data Not Found"/>)}
                  { props.not_found_msg 
                   ? <p className="loading_text">{props.not_found_msg}</p>
                   : (
                    <Fragment>
                      <p className="loading_text">Data Not found.</p>
                      <p className="loading_text">Please try changing the hotels/filters settings.</p>
                    </Fragment>
                   )}
                </div>) 
          : http_resp_code != null ? (
              <Fragment> 
                {props.debug && console.log(`responseCode: ${http_resp_code}. APILoading: ${api_loading}`)}
                {props.component}
              </Fragment>
            )
          : ("")}
        </Fragment>
    )
}



const mapDispatchToProps = dispatch => {
  return{
      // saveTokenHandler: value => dispatch({type: actionTypes.AUTH_TOKEN, value: value}),
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
};


export default connect(null,mapDispatchToProps)(BaseAPICompnenetWithLoader);
    