import React from "react";

const Logout = (props) => {
  return (
    <div>
      <a
        href="#"
        onClick={props.logout}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "38%",
          color: "#fff",
          marginTop: "2rem",
          marginLeft: "2rem",
          textDecoration: "none",
        }}
      >
        <img src={props.imgSrc} />
        <h5 style={{ fontFamily: "Nunito", margin: 0 }}>Logout</h5>
      </a>
    </div>
  );
};

export default Logout;
