import { Typography } from "@mui/material";
import React from "react";

const Tooltip = ({ className = "", styles = {} }, ref) => {
  return (
    <Typography
      className={className}
      sx={{
        display: "none",
        position: "absolute",
        padding: "5px 10px",
        borderRadius: "15px",
        zIndex: 1,
        transition: "all 0.1s",
        backgroundColor: "#F2DBD9",
        ...styles,
      }}
      ref={ref}
    ></Typography>
  );
};

const ToolTip = React.forwardRef(Tooltip);

export default ToolTip;
