import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  Box,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../UI/CommonButton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { ChangePassword } from "../actions/actions";
import { Close } from "@material-ui/icons";
import CustomPasswordField from "../../Settings/components/inputCommponets/CustomPasswordField";


const ChangePasswordForm = ({ show,closeModal,isEdit=false,data={},passwordResetRequired,handleConfirmLogout }) => {
    useEffect(()=>{
        if(!show)
        setbodyData(initialState)
    },[show])
        const root = {
            boxShadow: "none",
            border: "none",
            display: "flex",
            gap: "32px",
            flexDirection: "column",
            height: "100%",
            padding: "20px 0",
            flexGrow: 1,
          };
    const initialState = {
        old_password:data?.old_password || "",
        new_password1:data?.new_password1 || "",
        new_password2:data?.new_password2 || ""

      };
      const [bodyData, setbodyData] = useState(initialState);
      const [loading, setLoading] = useState(false);
      const [formError, setFormError] = useState({
        old_password:"",
        new_password1: "",
        new_password2:""
      });
      const dispatch = useDispatch();
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (formError[name] && `${value}`.trim()){
            if (name.includes("new_password1")) {
                setFormError((prev) => ({ ...prev, ["new_password1"]: "" }));
                setFormError((prev) => ({ ...prev, ["new_password2"]: "" }));
              } else setFormError((prev) => ({ ...prev, [name]: "" }));
            }
        setbodyData({ ...bodyData, [name]: value });
      };
    
      const isValid = () => {
        let valid = true;
        Object.keys(bodyData).forEach((key) => {
          if (bodyData[key].length == 0) {
            valid = false;
            setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
          }
        });
        if (bodyData.new_password1 != bodyData.new_password2) {
            valid = false;
            setFormError((prev) => ({
              ...prev,
              ["new_password1"]: "password should match",
            }));
            setFormError((prev) => ({
              ...prev,
              ["new_password2"]: "password should match",
            }));
          }
        return valid;
      };
    
      const handleSubmit = () => {
     
        if (isValid()) {
          setLoading(true);
            console.log(bodyData)
            dispatch(ChangePassword(bodyData,closeModal,setLoading,setbodyData,initialState,passwordResetRequired))
        }
      };
    
      return (
        <Box sx={root}>
        <Modal
        open={show}
        onClose={(e, reason) => {
          if (!passwordResetRequired || (reason !== "backdropClick" && reason !== "escapeKeyDown")) {
            closeModal();
          }
        }}
        aria-labelledby="add-new-task-modal"
        aria-describedby="add-new-task-modal"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
        }}
    
      >
     
        <Box
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderRadius: "10px",
            maxWidth: "90%",
            maxHeight: "90%",
            overflowY: "auto",
            border: "1px solid #c4c4c4",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            padding: "20px",
          }}
        >
   <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: 2,
        width: { xs: "100%", sm: "600px" }, // Adjusted width for responsiveness
        margin: "auto", // Center the form
      }}
    >
      <FormLabel>Change Password</FormLabel>

      {/* <TextField
        required
        label="Current Password "
        name="old_password"
        type="password"
        value={bodyData.old_password}
        onChange={handleChange}
        // multiline
        error={formError["old_password"]}
        helperText={formError["old_password"]}
        fullWidth
        disabled={loading}
      /> */}
          <CustomPasswordField label={"Current Password"} name={"old_password"} bodyData={bodyData} handleChange={handleChange} formError={formError} loading={loading}/>

      {/* <TextField
        required
        label="New Password"
        type="password"
        name="new_password1"
        value={bodyData.new_password1}
        onChange={handleChange}
        // multiline
        error={formError["new_password1"]}
        helperText={formError["new_password1"]}
        fullWidth
        disabled={loading}
      /> */}
          <CustomPasswordField label={"New Password"} name={"new_password1"} bodyData={bodyData} handleChange={handleChange} formError={formError} loading={loading}/>


      {/* <TextField
        required
        label="Confirm New Password"
        name="new_password2"
        type="password"
        value={bodyData.new_password2}
        onChange={handleChange}
        // multiline
        error={formError["new_password2"]}
        helperText={formError["new_password2"]}
        fullWidth
        disabled={loading}
      /> */}

<CustomPasswordField label={"Confirm New Password"} name={"new_password2"} bodyData={bodyData} handleChange={handleChange} formError={formError} loading={loading}/>
 

 
      <Box sx={{ display: "flex", columnGap: 3, justifyContent: "center",  position:"sticky", bottom:"0%",
          left:"50%" }}>
 {
  !passwordResetRequired && <CommonButton
  loading={loading}
  onClickHandler={closeModal}
  title={"Cancel"}
/>
 }

{
  passwordResetRequired && <CommonButton
  loading={loading}
  onClickHandler={()=>{
    closeModal()
    handleConfirmLogout()
  }}
  title={"Logout"}
/>
 }
        {loading ? (
          <Stack sx={{ color: "grey.500",    position: "sticky",
          bottom: "0%",
          left: "50%" }} spacing={2} direction="row">
            <CircularProgress color="success" />{" "}
          </Stack>
        ) : (
          <CommonButton
            onClickHandler={handleSubmit}
            title={"Submit"}
            loading={loading}
          />
        )}
      </Box>
    </FormControl>
    <Close onClick={closeModal} style={{
  fontSize:"0.9rem",
  cursor:"pointer",
  position:"absolute",
  top:"10px",
  right:"10px",
  ...(passwordResetRequired?{display:"none"}:{})
}}/>
        </Box>
     
      </Modal>
          </Box>
      );
    };
    

export default ChangePasswordForm
