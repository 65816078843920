import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import css from '../QuestionRenderer.module.css';

const SliderComp = props => {

    const [options, setOptions] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(null);

    const markStyle = val => <span style = {{fontSize: '15px', fontFamily: 'Lato'}}>{options[val].value}</span>;

    useEffect(() => {
        setOptions(props.question.options);

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && selectedIdx == null){
            let _op_idx = props.question.options.findIndex(o => String(o.value) == String(props.response));
            if (props.debug) console.log(`Option found: ${_op_idx}`);
            
            if (_op_idx != -1) setSelectedIdx(_op_idx);
        }

    }, [props.question, props.response])

    const clickHandler = (e, index) => {
        setSelectedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value);
     }

    return(
            <div className = {css.scale_cont}>
               {options.map((op,index) => (
                   <button key = {index} onClick={(e) => {clickHandler(e, index)}}>{op.value}</button>
               ))}
            </div>
        
    );
}

export default SliderComp;