import React, { Fragment } from 'react';
import {useHistory} from 'react-router-dom';
import css from '../UserSurvey.module.css';
import EmptySurveyGraphic from '../assets/EmptySurveyGraphic.svg';


const EmptySurvey = () => {
    const history = useHistory();
    return(
        <Fragment>
            <div className={css.EmptySurveyContainer}>
                <div className={css.EmptySurveyContent}>
                    <div>404</div>
                    <h2>Error Page</h2>
                    <div className={css.EmptySurveySubContent}>
                        <div className={css.EmptySurveyButton}>
                            <button onClick={history.goBack}>Back to home</button>
                        </div>
                        <div className={css.VerticalLine}></div>
                        <div className={css.NotFoundMsg}>Survey you're looking<br/>for is not available</div>
                    </div>
                </div>
                <div className={css.EmptySurveyImg}>
                    <img src={EmptySurveyGraphic} alt=''></img>
                </div>
            </div> 
            <div className={css.EmptySurveyFooter}>
                ReviewMagic
            </div>
        </Fragment>
    )
}

export default EmptySurvey;
