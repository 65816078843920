import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CommonButton from "../../UI/CommonButton";

function CropImages({ setSelectedFile, image_to_be_cropped, closeCropModal }) {
  const [crop, setCrop] = useState();
  const imageRef = useRef(null);

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL(image_to_be_cropped.filetype);
    setSelectedFile((prev) =>
      prev.map((file) =>
        file.id == image_to_be_cropped.id
          ? { ...file, fileimage: base64Image }
          : file
      )
    );
    closeCropModal();
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop({ unit: "px", width, height });
  };

  return (
    <>
      <ReactCrop
        crop={crop}
        onChange={setCrop}
        style={{ maxWidth: "100%", maxHeight: "600px" }}
      >
        <img
          ref={imageRef}
          onLoad={onImageLoad}
          style={{ maxWidth: "100%", maxHeight: "600px" }}
          src={image_to_be_cropped.fileimage}
        />
      </ReactCrop>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <CommonButton
          title={"Cancel"}
          onClickHandler={closeCropModal}
          sx={{color: "white"}}
        />
        <CommonButton
          title={"Crop & Save"}
          onClickHandler={cropImageNow}
          sx={{color: "white"}}
        />
      </div>
    </>
  );
}

export default CropImages;
