import React, { useState, Fragment, useEffect } from 'react';
import family from '../../assets/family.png';
import Slider from './QuestionType/Slider';
import Checkbox from './QuestionType/Checkbox';
import RadioBtn from './QuestionType/RadioBtn';
import TripType from './QuestionType/TripType';
import Button from './QuestionType/Button';
import TextArea from './QuestionType/TextArea';
import Scale from './QuestionType/Scale';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import css from './QuestionRenderer.module.css'
import { Typography } from '@mui/material';


const QuestionRenderer = props => {

    const [question, setQuestion] = useState({});
    const [response, setResponse] = useState({});

    useEffect(() => {
        setQuestion(props.question || {});
        setResponse(props.response || {});
    }, [props.question, props.response])

    const saveResponse = (resp_val) => {
        let _resp = {'answer_txt': resp_val, 'question': question.id };
        console.log("response1", resp_val)
        props.saveResponse(_resp);
    }

    const inputTypeHandler = (type) => {
        console.log(`question type: ${type}`);
        switch(type){
            case 'Slider':
                return <Slider 
                            saveResponse={saveResponse} 
                            response={response.answer_txt} 
                            question = {question}
                            debug={true}
                        />

            case 'Checkbox':
                return <Checkbox 
                            saveResponse={saveResponse} 
                            response={response.answer_txt} 
                            question = {question}
                        /> 

            case 'TripType':
                return <TripType saveResponse={saveResponse} 
                                    response={response.answer_txt} 
                                    question = {question}  />

            case 'Radio Button':
                return <RadioBtn saveResponse={saveResponse} 
                                 response={response.answer_txt} 
                                 question = {question}
                                 debug = {false}
                                 />

            case 'Buttons':
                return <Button saveResponse={saveResponse} 
                                response={response.answer_txt} 
                                question = {question}
                        />

            case 'Text Box':
                return <TextArea saveResponse={saveResponse} 
                                    response={response.answer_txt} 
                                    question = {question}/>
            
            case 'Scale':
                return <Scale saveResponse={saveResponse} 
                                    response={response.answer_txt} 
                                    question = {question} />
        }
    } 


    return(
        <Fragment>
<Card
    sx={{
        width: '100%',
        margin: '20px 0',
        minHeight: '170px',
        borderRadius: '12px',
        padding: '2% 5%',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
    }}
>
    <CardContent sx={{ position: 'relative', padding: '16px' }}>
        {/* Question Index */}
        <span
            className={css.questionIndex}
            style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                // color: '#3f51b5',
                color:"black",
                position: 'absolute',
                top: '16px',
                left: '16px',
                backgroundColor:"#E4E6E7"
            }}
        >
            {props.index}
        </span>

        {/* Question Text */}
        <Typography
            variant="h6"
            sx={{
                marginTop: '16px',
                fontWeight: 500,
                fontSize: '1.2rem',
                color: '#333',
                textAlign: 'left',
            }}
            className={css.Question}
        >
            {question.question_txt + ' ?'}
        </Typography>

        {/* Input Type Container */}
        <div
            className={css.input_type_cont}
            style={{
                marginTop: '24px',
                textAlign: 'left',
            }}
        >
            {inputTypeHandler(question.display)}
        </div>
    </CardContent>
</Card>

        </Fragment>
    );
}

export default QuestionRenderer;