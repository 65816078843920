import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    height: props => props.height,
    width: props => props.width,
    color: "#7ea63f"
  },
});

const muiTheme = createTheme({
    overrides:{
      MuiSlider: {
        thumb:{
        color: "#7ea63f",
        },
        track: {
          color: '#7ea63f'
        },
        rail: {
          color: 'black'
        },
        mark: {
            color: '#7ea63f'
        }
      }
  }
  });

function valuetext(value) {
  return `${value}°C`;
}

export default function DiscreteSlider(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
    <ThemeProvider theme={muiTheme}>
      <Slider
        onChangeCommitted={() => props.onChange(props.ariaLabelledBy)}
        orientation={props.orientation}
        defaultValue={props.min}
        getAriaValueText={valuetext}
        aria-labelledby={props.ariaLabelledBy}
        step={props.step}
        marks={true}
        min={props.min}
        max={props.max}
        valueLabelDisplay="auto"
      />
    </ThemeProvider>
    </div>
  );
}

DiscreteSlider.defaultProps = {
  height: 10,
  width: 20 
}
