import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import profileImg from "./assets/profile_image.svg";
import pencil from "./assets/pencil.svg";
import myprofile from "./assets/myprofile.png";
import subscription from "./assets/subscription.svg";
import logout from "./assets/logout.svg";
import { url } from "../Constants/Constants";
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LoginActionCreators from '../../actions/loginActions';
import { add_auth_header, check_auth_error } from "../Utility/Utility"
import Backdrop from '../Backdrop/Backdrop';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import changePass from './assets/Group 927.png'

class Profile extends Component {

  constructor(props){
    super(props);
    this.state = {
      redirect:null,
      backdropShow: false
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout = (e) => {
    const {LoginActions: {setToInitial} } = this.props;
    e.preventDefault();
    setToInitial();

    this.setState({'redirect': '/login'});
  }

  show = () => {
    const {head} = this.props;
    if(head === 'My dashboard'){
      document.getElementById("dropdown-1")
      .classList.toggle("dropdown_profile_show");
      document.getElementById("dropdown-1")
      .classList.toggle("dropdown_profile_show_2");
      console.log(document.getElementById("dropdown-1")
      .classList.contains("dropdown_profile_show"));
    }
    document
      .getElementById("profile_dropdown")
      .classList.toggle("dropdown_show");
    this.setState((prevState) => ({
      backdropShow: !prevState.backdropShow
    }));
  }

  render() {

    const {redirect} = this.state
    if (redirect){
      return <Redirect to={redirect}/>
    } else {
      return (
        <React.Fragment>
          <img style={{marginTop:"20px"}} src={this.props.user.profile && this.props.user.profile.photo || profileImg} alt="" className="dashboard_profile" onClick={this.show}/>
          &nbsp;&nbsp;
          <span style={{color:"#747474",marginTop:"6px"}}>
            {this.props.user.first_name  + ' ' + this.props.user.last_name}
          </span>
          <span style={{color:"#747474"}}></span>
          <Backdrop show = {this.state.backdropShow} onClick = {this.show}/>
          <div className="dropdown_profile_container" id="profile_dropdown">
            <div className="dropdown_profile_show uparrow" id="dropdown-1">
              <div className="dropdown_profile_details">
                <Link to="/my-profile">
                  <div>
                    <img
                      src={this.props.user.profile && this.props.user.profile.photo || profileImg}
                      alt=""
                      className="dashboard_profile_dropdown_image"
                    />
                    <img src={pencil} alt="" className="dashboard_profile_edit" />
                  </div>
                </Link>
                <div className="dropdown_email_name">
                  <p>{this.props.user.first_name} {this.props.user.last_name}</p>
                  <p>{ this.props.user.email }</p>
                </div>
                <br />
                <br />
              </div>
              <div className="dropdown_profile_subscription">
              <Link
                  to="/my-profile"
                  style={{
                    textDecoration: "none",
                    color: "#324047",
                    margin: "0px",
                  }}>
                  <p>
                    <img src={myprofile} alt="" className="image_dropdown" /> My profile
                  </p>
                </Link>
                <Link
                  to={`/account-management?tab=subscription`}
                  style={{
                    textDecoration: "none",
                    color: "#324047",
                    margin: "0px",
                  }}
                >
                  <p className="subscriptions_dropdown">
                    <img src={subscription} alt="" className="image_dropdown" />{" "}
                    Subscriptions
                  </p>
                </Link>
                <Link
                  to="/faq"
                  style={{
                    textDecoration: "none",
                    color: "#324047",
                    margin: "0px",
                  }}
                >
                  <p className="support_dropdown">
                    <FontAwesomeIcon icon = {faHeadset} className = 'support_dropdown_icon'/>
                    Support
                  </p>
                </Link>
                <Link
                  to="/change-password"
                  style={{
                    textDecoration: "none",
                    color: "#324047",
                    margin: "0px",
                  }}
                >
                  <p className="support_dropdown">
                    <img src = {changePass} style = {{width:"18px",marginRight:"10px"}}/>
                    Change Password
                  </p>
                </Link>
              </div>
              <div className="dropdown_logout">
               
                <p onClick={this.handleLogout}>
                  <img src={logout} alt="" className="image_dropdown" />
                  Log out
                </p>
               
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapDispatchToState = (state, ownProps) => {
  return {
    user: state.auth.user || {}
  }
}

const mapDispatchToProps = dispatch => {
  return{
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      LoginActions: bindActionCreators(LoginActionCreators, dispatch),
      };
  };
  
  
  export default connect(mapDispatchToState,mapDispatchToProps)(Profile);
    