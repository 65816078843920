import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { StepLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    label: {
        fontFamily: 'Nunito  !important',
        fontstyle: 'normal',
        fontweight: '400  !important',
        fontSize: '16px !important',
        lineHeight: '28px  !important',
        letterSpacing: '0.0025em  !important',
        color: '#283953  !important'
    }
})

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export default function HorizontalStepperWithStepTitle(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const classes = useStyles()

  const totalSteps = () => {
    return props.steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          props.steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%', padding: '0', margin: '25px 0px' }}>
      <Stepper activeStep={props.activeStep}>
        {props.steps.map((label, index) => {
          return (
            <Step key={label} {...{completed: index < props.activeStep}}>
              <StepLabel classes={classes.label} >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}