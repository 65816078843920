import React from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Typography, 
  Box, 
  Checkbox, 
  FormControlLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ResponsiveDialog(props) {
  const {
    open, 
    setOpen,
    handleClose: propHandleClose,
    handleButton1,
    handleButton2,
    dialogTitle = 'Confirm',
    button1 = 'Confirm',
    button2 = 'Cancel',
    component,
    text,
    hideButton1 = false,
    hideButton2 = false,
    dont_ask_checkbox = false,
    crossHandler
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [checkbox, setCheckbox] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    propHandleClose?.();
  };

  const handleAgree = () => {
    if (dont_ask_checkbox) {
      handleButton2?.(checkbox);
    } else {
      handleButton2?.();
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      aria-labelledby="responsive-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '40%',
          margin: '0 auto',
          maxWidth: 'none'
        }
      }}
    >
      <DialogTitle 
        id="responsive-dialog-title"
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          position: 'relative' 
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {dialogTitle}
        </Typography>
        <IconButton
          onClick={crossHandler || handleClose}
          sx={{ 
            position: 'absolute', 
            right: 0, 
            top: '50%', 
            transform: 'translateY(-50%)' 
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent 
        sx={{ 
          minWidth: '25vw',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        {component}
        {text && (
          <DialogContentText>
            {text}
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: dont_ask_checkbox ? 'space-between' : 'flex-end',
          alignItems: 'center',
          padding: '16px 24px'
        }}
      >
        {dont_ask_checkbox && (
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                color="primary"
                onChange={(e) => {
                  e.stopPropagation();
                  setCheckbox(e.target.checked);
                }}
              />
            }
            label="Don't ask again"
            sx={{
              color: 'text.secondary'
            }}
          />
        )}

        <Box>
          {!hideButton1 && (
            <Button 
              onClick={handleButton1} 
              color="primary"
            >
              {button1}
            </Button>
          )}
          
          {!hideButton2 && (
            <Button 
              onClick={handleAgree} 
              color="primary" 
              autoFocus
            >
              {button2}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

// Default props can be set externally or using defaultProps
ResponsiveDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  dialogTitle: 'Confirm',
  button1: 'Confirm',
  button2: 'Cancel',
  hideButton1: false,
  hideButton2: false,
  dont_ask_checkbox: false
};