import { Tooltip } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

const InfoComponent = ({ title = "Info",additonalCss={marginLeft: "5px"}, FontSize=null }) => {
  
  return (
    <Tooltip
      title={
      title
      }
      arrow
    placement="top"
    >
      <InfoIcon sx={{ ...additonalCss, cursor: "pointer" }}  style={{
      ...(FontSize?{fontSize:FontSize}:{})
      }} />
    </Tooltip>
  );
};

export default InfoComponent;
