import React, {useState, useEffect} from 'react';
import { Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: props => `${props.minWidth}px`,
      maxWidth: props => `${props.maxWidth}px`,
      transform: 'translateY(-40%)'
    }
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckboxDropdown = (props) => {
    const classes = useStyles(props);
    const [allObjects,setAllObjects] = useState([]);
    const [selectedObjects,setSelectedObjects] = useState([]);
    const {label} = props;

    const handleChange = (e) => {
        const selected_obj_ids = e.target.value;

        if (props.debug)
          console.log(`selected_obj_ids: ${JSON.stringify(selected_obj_ids)}`);
        
          const newSelectedObjects = allObjects.filter(obj => selected_obj_ids.includes(obj.id))

        if(props.onChange){

          if (props.debug)
            console.log(`calling onChange: ${JSON.stringify(newSelectedObjects)}`);
          props.onChange(newSelectedObjects);
        }

        setSelectedObjects(newSelectedObjects);
    }


    useEffect(() => {
      
      // The objects must have label field and id field
      setAllObjects(props.allObjects);
      setSelectedObjects(props.selectedObjects);

      if(props.debug){
        console.log(`useEffect: selectedObjects: ${JSON.stringify(props.selectedObjects)}. allObjects: ${JSON.stringify(props.allObjects)}`)
      }
    }, [props.allObjects, props.selectedObjects])

    return(
      <div>
        <FormControl> 
          {/* className={classes.formControl} */}
        {selectedObjects.length === 0 &&
        <InputLabel id="demo-mutiple-checkbox-label" disabled={true}>{props.displayLabel?props.displayLabel:label}</InputLabel>}
          <Controller
            control={props.control}
            name={label}
            defaultValue={null}
            rules={{required: true}}
            render={() => (
                <Select
                  multiple
                  onChange = {handleChange}
                  value={selectedObjects.map(obj => obj.id)}
                  renderValue = {(selected) => {let r = allObjects.filter(obj => selected.includes(obj.id)).map(sel_obj => sel_obj.label).join(", "); return r;}}
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  input={<Input />}
                  MenuProps={MenuProps}
                  >
                  {allObjects.map((object) => (
                  <MenuItem key={object.id} value={object.id}>
                      <Checkbox checked={selectedObjects.some(obj => obj.id==object.id)} checkedIcon={<CheckBoxOutlinedIcon style={{color: "#7ea63f"}}/>}/>
                          <ListItemText primary={object.label} />
                  </MenuItem>
                  ))}
                </Select>
            )}
          />
          <span style={{color:'red',fontSize:'0.8em'}}>
            {props.errors?.hotel_ids?.type === 'required' && "Atleast one selection is required"}
          </span>
          </FormControl>
          </div>
    );
}

CheckboxDropdown.defaultProps = {
  minWidth: 220,
  maxWidth: 300
}
export default CheckboxDropdown;