import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadImageButton({handleChange,sx={}}) { return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
     sx={{
      // width:"10%",
      marginBottom:'10px',
      // border:"0.1px solid"
      // backgroundColor:"success"
      minWidth:"120px",
      marginTop:"10px",
      ...sx
     }}
    >
    {"Upload Photo"}
<VisuallyHiddenInput name='photo' onChange={handleChange} type="file" />
    </Button>

  );
}