import React, { useEffect, useState } from 'react';
import { Slider, Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  '& .MuiSlider-track': {
    height: 8,
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-rail': {
    height: 8,
    backgroundColor: theme.palette.grey[300],
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    '&:before': { display: 'none' },
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

const SliderInput = styled(TextField)({
  '& .MuiInputBase-input': {
    textAlign: 'center',
    padding: '8px',
    width: '80px',
  },
});

const SliderComp = ({ question, debug, updateInParent }) => {
  const [options, setOptions] = useState([
    { type: "Text", value: 'Terrible', sort_order: 1 },
    { type: "Text", value: 'Bad', sort_order: 2 },
    { type: "Text", value: 'Average', sort_order: 3 },
    { type: "Text", value: 'Good', sort_order: 4 },
    { type: "Text", value: 'Excellent', sort_order: 5 }
  ]);
  const [sliderValue, setSliderValue] = useState(null);

  const getTextColor = (index) => {
    const percent = (index / (options.length - 1)) * 100;
    if (percent <= 20) return '#d32f2f'; // Error red
    if (percent < 75) return '#ed6c02'; // Warning orange
    return '#2e7d32'; // Success green
  };

  const handleOptionChange = (value, index) => {
    if (debug) {
      console.log(`Updating value: Index: ${index} Old value: ${options[index].value} New value: ${value}`);
    }

    const newOptions = options.map((option, i) => 
      i === index ? { ...option, value } : option
    );

    setOptions(newOptions);
    if (updateInParent) {
      updateInParent(newOptions, 'options');
    }
  };

  const marks = options.map((option, index) => ({
    value: index,
    label: (
      <SliderInput
        variant="outlined"
        size="small"
        value={option.value}
        onChange={(e) => handleOptionChange(e.target.value, index)}
        sx={{ 
          '& .MuiInputBase-input': { 
            color: getTextColor(index),
            fontWeight: 'medium',
          },
            marginTop:"10px"
        }}
      />
    )
  }));

  useEffect(() => {
    if (question?.options) {
      setOptions(question.options);
    } else if (updateInParent) {
      updateInParent(options, 'options');
    }
  }, [question]);

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ marginBottom: 4 }}
      >
        {question?.title || 'Please rate your experience'}
      </Typography>
      
      <Box sx={{ px: 2, mt: 3 }}>
        <StyledSlider
          min={0}
          max={options.length - 1}
          step={1}
          marks={marks}
          value={sliderValue}
          onChange={(_, value) => setSliderValue(value)}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => options[value]?.value}
        />
      </Box>
    </Box>
  );
};

export default SliderComp;