import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  TextField, 
  Stack 
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Textbox = (props) => {
  const [question, setQuestion] = useState({});

  useEffect(() => {
    setQuestion(props.question);

    if (props.debug)
      console.log(`Question received: ${JSON.stringify(props.question)}`);
  }, [props.question]);

  return (
    <Stack spacing={2} padding={2}>
      <Typography 
        variant="body1" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: 'text.secondary'
        }}
      >
        <InfoOutlinedIcon fontSize="small" />
        The user will enter their answer in the textbox
      </Typography>

      <TextField
        fullWidth
        disabled
        variant="outlined"
        placeholder="answer will appear here"
        InputProps={{
          sx: {
            backgroundColor: 'background.default'
          }
        }}
      />
    </Stack>
  );
};

export default Textbox;