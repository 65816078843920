import React, {useState,Fragment,useEffect} from 'react';
import { Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: props => `${props.minWidth}px`,
      maxWidth: props => `${props.maxWidth}px`,
      marginLeft: '5%',
      transform: 'translateY(-40%)'
    }
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CheckboxDropdown = (props) => {

    const [selectedVal, setSelectedVal] = useState("");
    const [allObjects, setAllObjects] = useState([]);
    const [selectedObject,setSelectedObject] = useState({});

    const classes = useStyles(props);
    const {label} = props;


    const handleChange = (event) => {
        const val = event.target.value
        if(props.debug) console.log(`handleChange: ${JSON.stringify(val)}`);
        const new_selected_obj = allObjects.find(obj => obj.id == val);

        if(!new_selected_obj) new_selected_obj={};
        
        setSelectedObject(new_selected_obj);

        if(props.onChange){
          if (props.debug) console.log(`calling onChange: ${JSON.stringify(new_selected_obj)}`);
          props.onChange(new_selected_obj);
        }
    };

    useEffect(() => {

      setAllObjects(props.allObjects);
      setSelectedObject(props.selectedObject);

    },[props.allObjects, props.selectedObject])

    return(
        <Fragment>
        <FormControl className={classes.formControl}>
        {(selectedVal && selectedVal.length>0) &&
          <InputLabel id="single-checkbox-label" disabled={true}>{props.displayLabel?props.displayLabel:label}</InputLabel>
        }
          <Controller
            control={props.control}
            name={label}
            rules={{required: true}}
            render={() => (
                <Select
                  value = {selectedObject.id}
                  onChange = {handleChange}
                  renderValue = {(selected_obj_id) => {return selectedObject.label}}
                  labelId="single-checkbox-label"
                  id="single-checkbox"
                  input={<Input />}
                  MenuProps={MenuProps}
                  >
                  {allObjects.map((obj) => (
                  <MenuItem key={obj.id} value={obj.id}>
                      <Checkbox checked={selectedObject.id == obj.id} checkedIcon={<CheckBoxOutlinedIcon style={{color: "#7ea63f"}}/>}/>
                          <ListItemText primary={obj.label} />
                  </MenuItem>
                  ))}
                </Select>
            )}
          />
          {props.errors &&
          <span style={{color:'red',fontSize:'0.8em'}}>
            {props.errors.status?.type === 'required' && "Status is required"}
            {props.errors.language?.type === 'required' && "Language is required"}
          </span>}
          </FormControl>
          </Fragment>
    );
}

CheckboxDropdown.defaultProps = {
  minWidth: 220,
  maxWidth: 300,
  selectedVal: ''
}

export default CheckboxDropdown;