import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setCurrDate } from "../actions/actions";
import { Box, useMediaQuery } from "@mui/material";

function DateRangeFilter() {
  const dispatch = useDispatch();
  const match = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [dates, setDates] = useState([null, null]);
  const selectedCurrDate = useSelector(
    (state) => state.filter.selectedCurrDate || {}
  );

  const handleChange = (dates,e) => {
  if(Array.isArray(dates))
  for(let d of dates){
    if (d==="Invalid Date")
    return
  }
    let obj
   if(dates)
     obj  = Ranges.filter(e=>e.value[0] == dates[0]&& e.value[1] == dates[1])
   
    const startDate = dates ? new Date(dates[0]) : null;
    const endDate = dates ? new Date(dates[1]) : null;
    setDates([startDate, endDate]);
    startDate &&
      endDate &&
      dispatch(setCurrDate({ option: "Custom Date", startDate, endDate,label:obj && obj.length>0?obj[0].label:null }));
  };

  useEffect(() => {
    setDates([
      new Date(selectedCurrDate.startDate),
      new Date(selectedCurrDate.endDate),
    ]);

   

  }, [selectedCurrDate]);

  useEffect(() => {
    if (selectedCurrDate && Object.keys(selectedCurrDate).length > 0) {
      let obj = Ranges.filter(e => e.label === selectedCurrDate.label)
      if (obj.length > 0) {
        let startDate = obj[0].value[0]
        let endDate = obj[0].value[1]
        dispatch(setCurrDate({ option: "Custom Date", startDate, endDate, label:  obj[0].label}));
      }
      console.log("check",obj,selectedCurrDate);
    }
  }, []);

  const Ranges = [
    {
      placement: "left",
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "yesterday",
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1)),
      ],
    },
    {
      placement: "left",
      label: "Last 7 days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "This week",
      value: [startOfWeek(new Date()), new Date()],
    },
    {
      placement: "left",
      label: "Last week",
      value: [
        startOfWeek(subDays(startOfWeek(new Date()), 1)),
        subDays(startOfWeek(new Date()), 1),
      ],
    },
    {
      placement: "left",
      label: "This month",
      value: [startOfMonth(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "Last month",
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
    },
    {
      placement: "left",
      label: "This quarter",
      value: [startOfQuarter(new Date()), endOfDay(new Date())],
    },
    {
      placement: "left",
      label: "Last quarter",
      value: [
        startOfQuarter(subMonths(startOfQuarter(new Date()), 1)),
        endOfQuarter(subMonths(startOfQuarter(new Date()), 1)),
      ],
    },
    {
      placement: "left",
      label: "This year",
      value: [
       startOfYear(new Date()),
       endOfDay(new Date()),
      ],
    },
  ];

  return (
    <Box>
      <label
        style={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#181A1B",
        }}
        htmlFor="date-range"
      >
        Date
      </label>
      <DateRangePicker
        id="date-range"
        block={true}
        showOneCalendar={match ? true : false}
        placeholder="Select a range"
        format="yyyy-MM-dd"
        placement="autoVerticalEnd"
        value={dates[0] && dates[1] && dates}
        ranges={Ranges}
        character=" - "
        onChange={(dates,label)=>handleChange(dates,label)}
        shouldDisableDate={(date) => isAfter(date, new Date())}
        style={{paddingTop:"0px"}}
      />
    </Box>
  );
}

export default DateRangeFilter;
