import React from 'react';

import {
  Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export,
} from 'devextreme-react/chart';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    chart:{
        height: '100%'
    }
})

const grossProductData = [{
    state: 'Room Service',
    year2015: 813,
    year2016: 803,
    year2017: 823,
    year2018: 863,
  }, {
    state: 'Club Service',
    year2015: 813,
    year2016: 316,
    year2017: 332,
    year2018: 332,
  }, {
    state: 'Gym Service',
    year2015: 813,
    year2016: 452,
    year2017: 459,
    year2018: 470,
  }, {
    state: 'Dining Service',
    year2015: 813,
    year2016: 452,
    year2017: 459,
    year2018: 470,
  }];  

const SideBySideBarChart = () =>  {
      const classes  = useStyles();
      const onPointClick = (e) => {
        e.target.select();
      }
    return (
      <Chart className={classes.chart}
        dataSource={grossProductData}
        onPointClick={onPointClick}
      >
        <CommonSeriesSettings
          argumentField="state"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          {/* <Label visible={true}>
            <Format type="fixedPoint" precision={0} />
          </Label> */}
        </CommonSeriesSettings>
        <Series
          argumentField="state"
          valueField="year2018"
          name="2018"
        />
        <Series
          valueField="year2017"
          name="2017"
        />
        <Series
          valueField="year2016"
          name="2016"
        />
        <Series
          valueField="year2015"
          name="2015"
        />
        <Legend position="top" verticalAlignment="bottom" horizontalAlignment="center"></Legend>
        {/* <Export enabled={true} /> this can enable a dropdoen menu to print the chart  */}
      </Chart>
    )
}

export default SideBySideBarChart;
