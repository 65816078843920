import React, { Fragment } from 'react';
import OverallExperience from './ChartComponents/OverallGuestSatisfaction/OverallExperience' ;
import ValueForMoney from './ChartComponents/OverallGuestSatisfaction/ValueForMoney';
import Welcomed from './ChartComponents/HowGuestsFelt/Welcomed';
import ArrivalExperience from './ChartComponents/GuestStay/ArrivalExperience';
import HotelPerfBySurvey from './ChartComponents/HotelPerfBySurvey';
import MostUsedServiceByGuests from './ChartComponents/MostUsedServiceByGuests';
import SurveyStats from './ChartComponents/SurveyStats';
import ProblemsOccured from './ChartComponents/ProblemResolutionDuringStay/ProblemsOccured';
import ProblemsByDepartment from './ChartComponents/ProblemResolutionDuringStay/ProblemsByDept';
import ProblemResolution from './ChartComponents/ProblemResolutionDuringStay/ProblemResolution';
import Carousel from '../../components/Carousel/Carousel';
import { Line } from 'rc-progress';
import Suggestions from './ChartComponents/KeywordsFrom/Suggestions';
import SuggestionsDrilldown from './ChartComponents/KeywordsFrom/SuggestionsDrilldown';
import GuestResponse from './ChartComponents/GuestResponse';
import EnviromentalQuestions from './ChartComponents/EnviromentalQuestions';
import ManuallyEnteredQues from './ChartComponents/ManuallyEnteredQues';
import CarouselControls from '../../../../components/CarouselControls/CarouselControls';
import CardWithTitle from '../../../../components/UI/cardWithTitle';
import ActiveSurvey from '../ManageSurvey/ActiveSurvey';
import css from './SurveyAnalytics.module.css';
import PieChart from '../../../../components/UI/pieChart';
import BarChart from '../../../../components/UI/BarChart';
import SideBySideBarChart from '../../../../components/UI/SideBySideBarChart'
import BulletChart from '../../../../components/UI/BulletChart';
import { Doughnut, Chart } from "react-chartjs-2";

const SurveyAnalytics = props => {

    const activeSurveyData =[
        {title: 'Total Questions', value: "10", color:"#6B81BE", icon:"list_icon"},
        {title: 'Completed Survey', value: "28", color:"#70AD47", icon:"check_icon"},
        {title: 'Conpletion Rate', value: "70%", color:"#FF6161", icon:"meter_icon"},
        {title: 'Survey Viewd', value: "30", color:"#FFC000", icon:"eye_icon"},
        {title: 'Attempted Survey', value: "16", color:"#028B9E", icon:"done_icon"},
        {title: 'DropOut Rate', value: "1%", color:"#737880", icon:"chart_icon"}
    ]

    const pieChartData = [
        { platform: 'Email', area: 45 },
        { platform: 'Website', area: 30 },
        { platform: 'Direct', area: 10 },
        { platform: 'Social Media', area: 15 }
      ];

    const Pie = () => <PieChart width="100%" height={300} legendPosition="right" chartData={pieChartData} valueField="area" argumentField="platform" />
    //     return (<BaseChart
    //         chart={<PieChart legendPosition="bottom" chartData={pieChartData} valueField="area" argumentField="platform" />}
    //         data_url = {`${url}/`}
    //         setAPIData = {setLineChatData}
    //         api_dependency = {[lineChatData]}/>
    //     );
    // }
    const api_data = {
        "total_cases": 31,
        "total_resolved_cases": 3,
        "total_not_resolved_cases": 3,
        "total_in_process_cases": 20,
        "total_not_closed_on_time": 2,
        "percentage_completed_on_time": 60
    }

    const getChartDataFromResp = (resp_data) => {
        if (Object.keys(resp_data).length > 0){
          return {
            labels: ["Not Completed on Time", "Completed On Time"],
            datasets: [
              {
                data: [resp_data.total_not_closed_on_time, resp_data.total_resolved_cases],
                backgroundColor: ["#A4A1FB", "#EDECFE"],
                borderWidth: 0,
              },
            ],
          }
        } else {
          return {}
        }
      }

    return(

        <>
            <ActiveSurvey
             height={400}
             width={'99%'}
             data={activeSurveyData}
             />
            <CardWithTitle 
                height={260} 
                width={'99%'} 
                textAlignHeader='left' 
                title='1. Guest Arrival Experience' 
                description='This section shows the response number of the votes in respect to time' 
                component={<BulletChart />}
            />
            <CardWithTitle 
                height={260} 
                width={'99%'} 
                textAlignHeader='left' 
                title='2. Guest Arrival Experience' 
                description='This section shows the response number of the guests in respect to time' 
                component={<BulletChart />}
            />
            <CardWithTitle 
                height={400} 
                width={'99%'} 
                textAlignHeader='left' 
                title='3. Guest Overall Experience' 
                description='This section shows the response number of the guests in respect to time' 
                component={<Pie/>}
            />
            <CardWithTitle 
                height={"max-content"} 
                width={'99%'} 
                textAlignHeader='left' 
                title='4. Rate our Services' 
                description='This section shows the response number of the guests in respect to time' 
                component={<SideBySideBarChart />}
            />
            <CardWithTitle 
                height={400} 
                width={'99%'} 
                textAlignHeader='left' 
                title='5. Where can we improve?' 
                description='This section shows the response number of the guests in respect to time' 
                component={<div>
                    <Doughnut
                        data={getChartDataFromResp(api_data)}
                        height={5}
                        width={25}
                        options={{
                            responsive: true,
                            plugins: {
                            labels: false
                            },
                            rotation: (-2.5 * Math.PI) / 4,
                            cutoutPercentage: 85,
                        }}
                    />
                    </div>
                }
            />
        </>

        // <Fragment>
        //     <div className = {css.overall_guest_satisfaction_cont}>
        //         <h4 className = {css.section_head}>Overall Guest Satisfaction</h4>
        //         <CarouselControls containerName = "overall_guest_satisfaction_graph"/>
        //         <div className = {css.overall_guest_satisfaction}>
        //             <div className = "overall_guest_satisfaction_graph">
        //                 <OverallExperience/>
        //             </div>
        //             <div className = {css.vertical_line}></div>
        //             <div className = {`${css.recommend_scale_cont} overall_guest_satisfaction_graph`}>
        //                 <p>likely to recommend hotel to others ( scale: 1 to 10)</p>
        //                 <div className =  {css.scale_btn_cont}>
        //                     {[1,2,3,4,5,6,7,8,9,10].map(el => (
        //                         <button key = {el}>{el}</button>
        //                     ))}
        //                 </div>
        //             </div>
        //             <div className = {css.vertical_line}></div>
        //             <div className = "overall_guest_satisfaction_graph">
        //                 <ValueForMoney/>
        //             </div>
        //         </div>  
        //     </div>

        //     <div className = {css.guest_stay_cont}>
        //         <div className = {css.guest_stay_left}>
        //             <h4 className = {css.section_head}>Guest stay</h4>
        //             <div className = {css.guest_stay_graphs_cont}>
        //                 <ArrivalExperience title = "Arrival experience"/>
        //                 <ArrivalExperience title = "Departure experience"/>
        //                 <ArrivalExperience title = "Arrival experience"/>
        //                 <ArrivalExperience title = "Arrival experience"/>
        //             </div>
        //         </div> 
        //         <div className = {css.guest_stay_right}>
        //             <h4 className = {css.section_head}>Hotel performance by survey</h4>
        //             <div className = {css.hotel_perf_cont}>
        //                 <HotelPerfBySurvey/>
        //             </div>
        //         </div> 
        //     </div>

        //     <div className = {css.most_used_service_by_guest_cont}>
        //         <div className = {css.most_used_service_by_guest_left}>
        //             <h4 className = {css.section_head}>Most used Service by guests</h4>
        //             <MostUsedServiceByGuests/>
        //         </div>
        //         <div className = {css.most_used_service_by_guest_right}>
        //             <h4 className = {css.section_head}>Survey stats</h4>
        //             <SurveyStats/>
        //         </div>
        //     </div>

        //     <h1 className = {css.heading}>Survey - Problems & Suggestions</h1>

        //     <div className = {css.guest_stay_cont}>
        //         <div className = {css.problem_and_sugg_left}>
        //             <div className = {css.overall_guest_satisfaction_cont}>
        //                 <h4 className = {css.section_head}>Problem resolution during stay</h4>
        //                 <div className = {css.overall_guest_satisfaction}>
        //                     <ProblemsOccured />
        //                     <div className = {css.vertical_line}></div>
        //                     <ProblemsByDepartment/>
        //                     <div className = {css.vertical_line}></div>
        //                     <div>
        //                         <p className = "chart_title_font">Problems occured</p>
        //                         <Carousel>
        //                             <ProblemResolution/>
        //                             <ProblemsOccured/>
        //                         </Carousel>
        //                     </div>
        //                 </div>  
        //             </div>
        //             <div className = {css.problem_and_sugg_bottom}>
        //                 <div className = {css.guest_response_cont}>
        //                     <h4 className = {css.section_head}>Guest response</h4>
        //                     <GuestResponse/>
        //                 </div>
        //                 <div className = {css.guest_response_cont}>
        //                     <h4 className = {css.section_head}>Environmental questions</h4>
        //                     <EnviromentalQuestions/>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className = {css.problem_and_sugg_right}>
        //             <h4 className = {css.section_head}>Keywords from</h4>
        //             <Carousel>
        //                 <Suggestions/>
        //                 <Suggestions/>
        //             </Carousel>
        //             <SuggestionsDrilldown/>
        //         </div>
        //     </div>

        //     <h1 className = {css.heading}>Survey - manually entered questions</h1>

        //     <div className = {css.manually_entered_quest_cont}>
        //         <h4 className = {css.section_head}>Manually entered questions</h4>
        //         <ManuallyEnteredQues/>
        //     </div>

        // </Fragment>
    );
}

export default SurveyAnalytics;