import React, { useState,useEffect, Fragment } from 'react';
import './HotelList.css';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { setSelectedHotel } from '../../../commons/components/Filter/actions/actions';
import {get_hotel_obj, get_query_param} from '../../Utility/Utility';
import hotelImg from '../../Header/assets/hotel_img.png'; 
import HotelGroups from './HotelGroups';
import {add_auth_header} from '../../Utility/Utility';

const HotelList = props => {

    const [hotel_groups_data, setHotelGroupsData] = useState([]);
    const {hotels, selectedHotel, setSelectedHotel} = props

    const onSelectionChange = (event) => {
        let hotel = get_hotel_obj(event.target.value, hotels, hotel_groups_data);
        setSelectedHotel(hotel); 
    }

    useEffect(() => {
      if (hotels.length > 0 && !Object.keys(selectedHotel).length > 0){
            setSelectedHotel(hotels[0]);
      }
    }, [hotels]);


    return (
      <div className="hotellist">
        <img className = 'HotelImage' src = {hotelImg} alt = 'hotel image'/>
        {hotels.length > 0 
          ? (
            <select
              value={selectedHotel.name}
              onChange={onSelectionChange}
              className="dropdown-list">
                <optgroup style = {{fontStyle: 'normal'}} label = "Hotels">
                  {hotels.map((hotel, index) => {
                    return (
                      <option value={hotel.name} key={index}>
                        {hotel.name}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup style = {{fontStyle: 'normal'}} label = "Groups">
                  <HotelGroups
                    setHotelGroupsData={setHotelGroupsData}
                  />
                </optgroup>
                
            </select>)
      : ("")
      }
      </div>
    );
}

const mapStateToProps = state => {
  return{
    hotels: state.filter?.hotels || [],
    selectedHotel: state.filter?.selectedHotel || {}
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return{
      setSelectedHotel: (hotel) => dispatch(setSelectedHotel(hotel)),
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(HotelList);