import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import CommonButton from "../../UI/CommonButton";
import CommonModalForForms from "../../Modals/CommonModalForForms";
const AddFormButton = ({title,FormUi,imageUploadModal}) => {
    const [show, setShow] = useState(false);

    const toggleNewTaskModal = () => {
      setShow((prev) => !prev);
    };
  
    return (
      <Box sx={{ alignSelf: "flex-start" }}>
        <CommonButton
          title={title}
          sx={{ color: "#000000", marginBottom: 0 }}
          onClickHandler={toggleNewTaskModal}
        />
        {/* <Modal
          open={show}
          onClose={toggleNewTaskModal}
          aria-labelledby="add-new-task-modal"
          aria-describedby="add-new-task-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "10px",
              minWidth: { xs: "400px", sm: "500px" },
              border: "1px solid #c4c4c4",
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              maxHeight:"80vh",
              overflowY:"scroll",
              scrollBehavior:"smooth",
              // minHeight:"60vh"
            }}
          >
           {FormUi &&
        React.cloneElement(FormUi, {
            closeModal:toggleNewTaskModal
        })}
          </Box>
        </Modal> */}
        <CommonModalForForms show={show} toggleNewTaskModal={toggleNewTaskModal} FormUi={   React.cloneElement(FormUi, {
            closeModal:toggleNewTaskModal,
            imageUploadModal:imageUploadModal
        })}/>
      </Box>
    );
  };
export default AddFormButton
