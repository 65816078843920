import React, { useEffect, useState } from "react";
import "./SideNav.css";

import { connect } from 'react-redux';
import BrandContainer from './BrandContainer';
// import { getSidenavLinks } from '../../actions/sidenavActions';
import { fetchSideNavLinks } from "../../commons/components/Navbar/actions/actions";
import { fetchUserDetail } from "../../actions/loginActions";
import { fetchHotels } from "../../commons/components/Filter/actions/actions";
import { add_auth_header } from "../Utility/Utility";
import { Fragment } from "react";
import { Redirect } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Header from '../Header2/header2.js'
import { makeStyles,createTheme } from '@material-ui/core/styles';
import manage_account from "./assets/manage-account.svg"
import logout from "./assets/logout.svg"
import NavItem from "./navItem";
import Feedback from "./Feedback";
import Logout from "./Logout";
import * as filterActions from '../../commons/components/Filter/actions/actions';
import {setToInitial} from '../../actions/loginActions';
import { bindActionCreators } from "redux";


const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  header:{
    position: 'absolute !important',
    width: 'calc(100% - 250px) !important',
    boxShadow: 'none !important',
    background: 'transparent !important',
    zIndex:'1 !important',

    [theme.breakpoints.down(480)]: {
      width: '100% !important',
      },
  },
  navBar:{
    background: '#283953',
    
    paddingBottom: '5rem',
    zIndex: 0
  },
  title:{
    font: '24px/28px Arial',
    color: '#FFFFFF'
  },
  selected:{
    width: 241, 
    height: 48, 
    background: 'rgba(255, 255, 255, 0.9)', 
    borderRadius: '0px 8px 8px 0px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  navLinks:{
    width: 241,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem'
  }
}))

const SideNav = props => {

  const [showReload,setShowReload] = useState(false)
  const [reqHeader,setReqHeader] = useState({})
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const errCallback = (status) => {
    if(status){
      setShowReload(false)
    }else{
      setShowReload(true)
    }
  }

  const getTheSortedArr = (arr) => {

    if(!arr || arr.length == 0){
      return [];
    }

    let updtArr = [...arr];
    
    updtArr.sort((a,b) => a.sort_order > b.sort_order ? 1 : -1)

    return updtArr;
  }

  useEffect(()=>{
    if(Object.keys(props.selectedHotel).length>0) props.fetchFilters();
  }, [props.selectedHotel])

  useEffect(() => {
    setReqHeader(props.add_auth_header({}));
    props.getHotels();
    // props.getSidenavLinks(props.add_auth_header({}),errCallback);
    props.getSidenavLinks();

    props.getUserDetail();
    
  },[])

  const handleLogout = (e) => {
    e.preventDefault();
    props.setToInitial();
    setRedirectUrl('/login');
  }

  const drawer = (
    <div className={classes.navBar}>
      {redirectUrl && <Redirect to={redirectUrl} />}
      <BrandContainer />
      <Divider />
      {showReload ?    
          <div className="review_reload">
            <p onClick={() => props.getSidenavLinks(reqHeader,errCallback)} className="reload-text">Reload</p>
          </div>  
      :<List>   
        {getTheSortedArr(props.sideNavLinks).map((obj, idx) => (
          <NavItem 
            key={idx}
            contClass={props.selectedMenu == obj.name ? classes.selected : classes.navLinks} 
            navItem={obj}
            selectedMenu={props.selectedMenu}
            imgSrc={obj.icon}
          />
          ))}
          <NavItem 
            contClass={props.selectedMenu == 'Manage Account' ? classes.selected : classes.navLinks} 
            navItem={{frontend_route: '/account-management', name: 'Manage Account'}}
            selectedMenu={props.selectedMenu}
            imgSrc={manage_account}
          />
      </List>}
      <Divider />
        <div style={{height: '3rem'}} ></div>
        <Feedback />
        <Logout logout={handleLogout} imgSrc={logout}/>
    </div>
  );

  return (
    <Fragment>
    <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <AppBar className={classes.header}>
            <Header 
              head={props.head}
              setShowTour = {props.setShowTour}
              showTour={ props.showTour}
              hide_hotel_list={props.hide_hotel_list}
              menuButtonClick={handleDrawerToggle}
              // FOR FILTERS
              hide_filters={props.hide_filters}
              sentiment={props.sentiment}
              show_filter_topics={props.show_filter_topics}
          />
        </AppBar>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowX: 'hidden'},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowX: 'hidden', zIndex: 10},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchFilters: () => dispatch(filterActions.fetchFilters()),
    setToInitial: () => dispatch(setToInitial()),
    add_auth_header: bindActionCreators(add_auth_header, dispatch),
    getSidenavLinks: () => dispatch(fetchSideNavLinks()),
    getUserDetail: () => dispatch(fetchUserDetail()) ,
    getHotels: () => dispatch(fetchHotels())
  }
}

const mapStateToProps = (state, ownProps) => {
  return{
    brandColor: state.gen.brandColor,
    brandPhoto: state.gen.brandPhoto,
    brandLogo: state.gen.brandLogo,
    sideNavLinks : state.nav.sideNavLinks,
    brandBgColor:state.gen.brandBgColor,
    selectedHotel: state.filter?.selectedHotel || {}
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
