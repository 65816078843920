import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import InfoComponent from "./InfoComponent";

function CommonButton({ onClickHandler, title, flag, loading, sx = {}, type,isReq=false,isInfo=false, infoCom=null }) {
  return (
    <Button
      title={title}
      type={type || "button"}
      sx={{
        padding: { xs: "4px", lg: "8px 12px" },
        borderRadius: "8px",
        border: 2,
        borderColor: "#E4E6E7",
        bgcolor: "transparent",
        textAlign: "center",
        fontSize: { xs: "12px", lg: "16px" },
        // marginBottom: "15px",
        color: "#313336",
        textTransform: "none",
        ...sx,
      }}
      onClick={onClickHandler}
      disabled={loading}
    >
      {title}
      {flag != undefined && (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: flag ? "#24b304" : "#E4E6E7",
            marginLeft: "10px",
          }}
        />
      )}
      {
      (loading &&  isReq)  ? <CircularProgress color="success" size={20} sx={{
        margin:"0 10px"
       }} /> :
       
        isInfo ? <InfoComponent title={infoCom}/> :<></>
       
      }
    </Button>
  );
}

export default CommonButton;
