import React, { Component } from 'react'
import notification from './assets/icon_notification@2x.png';
import Backdrop from '../Backdrop/Backdrop';

export default class Notifications extends Component {

    state = {
        backdropShow: false
    };

    showNotification = () => {
        const {head} = this.props;
        if(head === 'My dashboard'){
        document.getElementById("dropdown-2")
        .classList.toggle("dropdown_notification_show");
        document.getElementById("dropdown-2")
        .classList.toggle("dropdown_notification_show_2");
        }
        document
        .getElementById("notification")
        .classList.toggle("dropdown_show");
    
        this.setState((prevState) => ({
          backdropShow: !prevState.backdropShow
        }));
        
      }

    notificationHandler = () => {
        window.location.assign('/notifications');
    }

    render() {
        return (
            <React.Fragment>
                <img src={notification} alt="" className="dashboard_notification" onClick={this.showNotification} />
                <Backdrop show = {this.state.backdropShow} onClick = {this.showNotification}/>
                <div className="dropdown_profile_container" id="notification" >
                    <div className="dropdown_notification_show notification_uparrow" id="dropdown-2" onClick = {this.notificationHandler}>
                        <div className="notifications_head">
                            <p>Notifications <span className="notification_status" >0 new</span></p>
                        </div>
                        {/* <div className="notifications_content">
                            <div className="dropdown_notification" >
                                <p className="notifications_title"> 23 new reviews added</p>
                                <p className="notifications_subcontent">3 hours ago</p>
                            </div>
                            <hr className="hr_dropdown" />
                            <div className="dropdown_notification" >
                                <p className="notifications_title">15 new users signed up</p>
                                <p className="notifications_subcontent">3 hours ago</p>
                            </div>
                            <hr className="hr_dropdown" />
                            <div className="dropdown_notification" >
                                <p className="notifications_title"> 3 issues solved</p>
                                <p className="notifications_subcontent">3 hours ago</p>
                            </div>

                        </div> */}
                        <div className="dropdown_notification" >
                            <p className="notifications_title"> Notifications not found</p>
                            <p className="notifications_subcontent">3 hours ago</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
