import React from 'react';
import feedback from './assets/feedback.svg'
import SecondaryButton from '../UI/buttons/secondaryButton';
import { makeStyles,createTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyle = makeStyles({
    container:{
        display: 'grid', 
        justifyItems: 'center',
        height: '10rem'
    },
    img:{

    },
    texts:{
        fontFamily: 'Nunito',
        fontSize: '14px',
        color: '#fff'
    }
})

const Feedback = () => {
    const classes = useStyle()
    return (
        <div className={classes.container}>
            <img src={feedback}/>
            <h5 className={classes.texts} >Your feedback matters to us</h5>
            <Link
                  to="/faq"
                  style={{
                    textDecoration: "none",
                    height: 'max-content'
                  }}
                >
            <SecondaryButton 
                buttonTitle="Feedback" 
                height={32} fontSize={14} 
                clickHandler={() => {}}
                backgroundColor= 'transparent'
                hoverBackgroundColor = 'white'
                hoverColor = "#283953"
                color="#ffffffe6"/>
            </Link>
        </div>
    );
};

export default Feedback;