import React, { useState,useEffect, Fragment } from 'react';
import { url } from '../../Constants/Constants';
import BaseAPIComponent from '../BaseAPIComponent/BaseAPIComponent'
import './HotelList.css';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actionTypes from '../../../actions/actionTypes';
import * as HotelActionCreators from '../../../actions/loginActions';
import {get_hotel_obj, get_query_param} from '../../Utility/Utility';
import hotelImg from '../../Header/assets/hotel_img.png'; 

const HotelList = props => {

    const [api_data, setAPIData] = useState([]);
    const {HotelActions: {updateSelectedHotel} } = props;

    useEffect(() => {
        if (props.setHotelGroupsData){
            props.setHotelGroupsData(api_data.map((group, index) => {return getGroupedHotel(group)}));
        }

    }, [api_data])

    const getGroupedHotel = (group) => {
        let group_obj = {
            name: group.name,
            hotel_code: group.hotels.map((hotel, index) => {return hotel.hotel_code}).join(","),
            hotels: group.hotels
        }
        
        return group_obj;
    }

    const getGroupedHotelCode = (group) => {
        let code = `${group.hotels.map((hotel, index) => {return hotel.hotel_code}).join(",")}`
        return code;
    }


    const component = (
                <Fragment>
                    {api_data.map((group, index) => {
                        return <option value={`${group.name}`} key={index}>{group.name}</option>
                           })}

                    {/* {api_data.map((group, index) => {
                        return <optgroup label={group.name} style = {{fontStyle: 'normal'}} key={index}>
                            <option value={`${getGroupedHotel(group)}`} key={0}>All</option>
                            {group.hotels.map((hotel, index) => {
                                return ( 
                                    <option value={hotel} key={index+1}>
                                        {hotel.name}
                                    </option>
                                );})}
                        </optgroup>})}     */}
                </Fragment>
                );

    return (
        <BaseAPIComponent
            name={"HotelGroups"}
            component = {component}
            data_url = {`${url}/services/hotels/groups/`}
            setAPIData = {setAPIData}
            api_dependency = {[true]}
        />
    );
}

const mapStateToProps = state => {
  return{
    hotel: state.gen.selectedHotel
  };
};

const mapDispatchToProps = dispatch => {
  return{
      HotelActions: bindActionCreators(HotelActionCreators, dispatch),
      // add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(HotelList);