import React, {useEffect, useState, Fragment} from 'react';
import EditableField from '../../../../../components/UI/editableField';
import { add_auth_header } from "../../../../../components/Utility/Utility";
import ItemAddButton from '../../../../../components/UI/buttons/itemAddButton';
import ConfirmationDialog from '../../../../../components/UI/confirmationDialog';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {Delete} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { setCreateSurvey, getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../../../actions/surveyActions';
import QuestionComponent from './QuestionComponent';
import NewQuestion from './NewQuestion';
import css from './QuestionRenderer.module.css';
import DialogBox from '../../../../../commons/components/PopUp/DialogBox';

const useStyles = makeStyles({
    root: {
        '&:focus': {
            outline: "none"
        },
    }
});

const SectionRenderer = props => {

    const [surveyId, setSurveyId] = useState(null);
    const [section, setSection] = useState({});
    const [questions, setQuestions] = useState([]);
    const [index, setIndex] = useState(null);
    const [sectionDelPopup,setSectionDelPopup] = useState({show:false, id:null});
    const classes = useStyles();

    useEffect(() => {
        
        setSurveyId(props.surveyId);
        setSection(props.section);
        setQuestions(props.section.questions);
        setIndex(props.index);
    }, [props.key, props.surveyId, props.section])


    const deleteSectionHandler = (id) => {
        if(!id){
            return;
        }

        props.deleteSection(props.add_auth_header({}),surveyId,id,(status) => {
            if(status){
                setSectionDelPopup({show:false,id:null});
                if(props.handleSectionDeleteFromSurvey) props.handleSectionDeleteFromSurvey(id)
                // setSurvey([]);
                // setSurveySections([]);
            }
        })
    };

    const handleQuestionDeleteFromSection = (id) => {
        let _questions = questions.filter(q => q.id != id);
        console.log(questions,_questions,id);      
        setQuestions(_questions);
    }

    const handleQuestionAddToSection = (question_obj) => {

        console.log(`handleQuestionAddToSection: ${JSON.stringify(question_obj)}`);

        let _questions = [...questions];
        _questions.push(question_obj);
        _questions.sort( (q1, q2) => q1.sort_order < q2.sort_order);
        setQuestions(_questions);
    }
    
    return <Fragment>
                <div className = {css.EditTemplatesSection}  style={props.EditTemplatesSection || {}}>
                <div className = {css.EditTemplatesSectionHead}   style={props.EditTemplatesSectionHead || {}}>
                    {index}. 
                    &nbsp;
                    <EditableField className={css.SectionHeadNameInput}
                                    value={section.name ? section.name : `Section ${index+1}`}
                                    placeholder="Section Name"
                                    iconColor=""
                                    inputWidth="60%"
                                    onChange={(value) => props.editSectionName(add_auth_header({}),surveyId, section.id,value, () => {})}/>
                    <div className={css.SectionHeadButtons}>
                        <IconButton className={classes.root} size="small" onClick = {() => setSectionDelPopup({show:true,id:section.id})}>
                            <Delete style={{fontSize:"1.3em"}}/>
                        </IconButton>
                        {/* &nbsp;&nbsp; */}
                        {/*Section Copy button */}
                        {/* <NewSection
                            renderButton={false} 
                            renderIcon={true}
                            surveyId={surveyId}
                        /> */}
                    </div>
                </div>
                {questions.map((question,index) => {
                    return(<QuestionComponent 
                                            debug={false}
                                            key={index+1}
                                            index={index+1}
                                            surveyId = {surveyId}
                                            sectionId = {section.id}
                                            question = {question}
                                            handleQuestionDeleteFromSection = {handleQuestionDeleteFromSection}

                                        />)
                })}
                <NewQuestion 
                    index={questions.length+1}
                    surveyId={surveyId}
                    sectionId={section.id}
                    handleQuestionDeleteFromSection = {handleQuestionDeleteFromSection}
                    updateMainQuestionData={setQuestions}
                />
            </div>

            {/* {sectionDelPopup.show && 
            <ConfirmationDialog dialogTitle="Warning !" handleButton1={() => deleteSectionHandler(sectionDelPopup.id)} 
            crossHandler={() => setSectionDelPopup({show:false,id:null})} handleButton2={() => setSectionDelPopup({show:false,id:null})} open={true} 
            text={`Are you sure you want to delete this section? This process cannot be undone`}/>} */}

<DialogBox open={sectionDelPopup.show} setOpen={() => setSectionDelPopup({show:false,id:null})} handleAgree={() => deleteSectionHandler(sectionDelPopup.id)} agreeButtonText={"Yes"} content={"Are you sure you want to delete this section? This process cannot be undone"} topHeading={"Confirm"} closeText={"No"}/>

        </Fragment>

}

const mapStateToProps = state => {
    return{
    }
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        editSurvey: bindActionCreators(editSurvey, dispatch),
        editSectionName: bindActionCreators(editSectionName, dispatch),
        deleteSection: bindActionCreators(deleteSection, dispatch)
        };
    };

export default connect(mapStateToProps,mapDispatchToProps)(SectionRenderer);