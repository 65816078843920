import React, { useEffect } from 'react';
import family from '../../../assets/family.png';
import couple from '../../../assets/couple.png';
import Individual from '../../../assets/individual.png';
import friends from '../../../assets/friends.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

import css from '../QuestionRenderer.module.css';

const TripType = props => {


    const tripTypeHandler = (e,value) => {
        if(e) e.preventDefault();
        activeBtnHandler(value);
        props.setResponse(value);
    }

    const activeBtnHandler = (value) => {
        const active = document.getElementsByClassName(css.ActiveTripType)[0];
        active.classList.remove(css.ActiveTripType);
        document.getElementById(value).classList.add(css.ActiveTripType);
    }

    useEffect(() => {

        const userResponse = props.userResponse[props.currentSec][props.currentQues];

        if(userResponse){
            activeBtnHandler(userResponse);
        }
    },[]);


    return(
        <div className = {`${css.TripTypeContainer} `}>
            <button id = "business" className = {`${css.TripType} ${css.ActiveTripType}`} onClick = {(e) => tripTypeHandler(e,'business')}>
                <FontAwesomeIcon icon = {faUserTie} style = {{color: '#000000'}} className = {css.TripIcon} />
                <p>Business</p>
            </button>
            <button id = "family" className = {css.TripType} onClick = {(e) => tripTypeHandler(e,'family')}>
                <img style = {{width: '40px'}} src = {family}/>
                <p>Family</p>
            </button>
            <button id = "friends" className = {css.TripType} onClick = {(e) => tripTypeHandler(e,'friends')}>
                <img style = {{width: '40px'}} src = {friends}/>
                <p>Friends</p>
            </button>
            <button id = "couple" className = {css.TripType} onClick = {(e) => tripTypeHandler(e,'couple')}>
                <img style = {{width: '40px'}} src = {couple}/>
                <p>Couple</p>
            </button>
            <button id = "individual" className = {css.TripType} onClick = {(e) => tripTypeHandler(e,'individual')}>
                <img style = {{width: '40px'}} src = {Individual}/>
                <p>Individual</p>
            </button>
        </div>
    );
}

export default TripType;