import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Review2 from "../../../apps/Reviews/components/Review2";

const AdditionalInfoForChatInput = ({text,setText, clearUrlParams,data=null,setNull=null}) => {
  const dispatch = useDispatch()
  const CLoading = useSelector((state=>state.teamCom?.channels?.loading))
  const DMLoading = useSelector((state=>state.teamCom?.directMessage?.loading))
    const handleClose = ()=>{
        clearUrlParams()
        dispatch(setText(null))
    }
  return (
(!CLoading && !DMLoading ) &&  <Box sx={{
  maxWidth: '90%',
   margin: "auto",
   minWidth: "580px",
   minHeight: "10vh",
   display: "flex", alignItems: "center", justifyContent: "center",
   padding: {xs:'4%', sm:"4%",md:"1%"},

   borderRadius: '16px',

   WebkitBackdropFilter: 'blur(6.4px)',
   border: '1px solid lightgrey',
   marginBottom:"4px",
   color: '#000000a3',
   position:"relative",
   cursor:"pointer"
 }}
 >
  <CloseIcon sx={{position:"absolute",right:"1%",top:"5%",opacity:"0.8"}} style={{
      fontSize:"14px"
  }} onClick={(event)=>handleClose(event)}/>
 {/* <Tooltip title={text} sx={{cursor:"pointer"}}>
 <Typography  sx={{
     textAlign: "center", display: '-webkit-box',
     WebkitBoxOrient: 'vertical',
     overflow: 'hidden',
  //    WebkitLineClamp: 2,
     fontSize:{xs:"8px",sm:"10px",md:"12px",lg:"13px"}
   }}>
     {text}
   </Typography>
 </Tooltip> */}
{
data &&                    <Review2
review={data}
forMessage={true}
randomId={Math.random().toString()}
/>
}
 </Box>
  )
}

export default AdditionalInfoForChatInput
