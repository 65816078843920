import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  Box,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../UI/CommonButton";
import { AddNewScrapUrl } from "../actions/settingsActions";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const AddUrlForm = ({ closeModal,isEdit=false,data }) => {
    const initialState = {
     url:data?.url || ""
      };
      const [bodyData, setbodyData] = useState(initialState);
      const [loading, setLoading] = useState(false);
      const [formError, setFormError] = useState({
     url: ""
      });
      const dispatch = useDispatch();
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (formError[name] && `${value}`.trim())
        setFormError((prev) => ({ ...prev, [name]: "" }));
        setbodyData({ ...bodyData, [name]: value });
      };
    
      const isValid = () => {
        let valid = true;
        Object.keys(bodyData).forEach((key) => {
          if (bodyData[key].length == 0) {
            valid = false;
            setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
          }
        });
        return valid;
      };
    
      const handleSubmit = () => {
     
        if (isValid()) {
          setLoading(true);
          if(isEdit)
          dispatch()
          else
          dispatch(AddNewScrapUrl(bodyData, closeModal, setLoading));
        }
      };
    
      return (
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
            width: { xs: "100%", sm: "600px" },  // Adjusted width for responsiveness
            margin: "auto", // Center the form
            // minHeight:"250px"
          }}
        >
          <FormLabel>{isEdit?"Edit":"Add"} URL</FormLabel>
    
          <TextField
            required
            label="URL"
            name="url"
            value={bodyData.url}
            onChange={handleChange}
            multiline
            error={formError["url"]}
            helperText={formError["url"]}
            fullWidth
            disabled={loading}
          />
              <Box sx={{ display: "flex", columnGap: 3, justifyContent: "center" }}>
            <CommonButton
              loading={loading}
              onClickHandler={closeModal}
              title={"Cancel"}
            />
            {loading ? (
              <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="success" />{" "}
              </Stack>
            ) : (
              <CommonButton
                onClickHandler={handleSubmit}
                title={"Submit"}
                loading={loading}
              />
            )}
          </Box>
        </FormControl>
      );
    };
    

export default AddUrlForm
