import React, { useState } from 'react';
import CreditsFooter from '../../../../../components/UI/CreditsFooter';

// MUI imports
import { Button, Typography, TextField } from '@mui/material';
import SelectDropdown from '../../../../../components/UI/dropdown/selectDropdown';

function GuestInfoForm(props) {
  const [formData, setFormData] = useState({});

  const handleFormData = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const styles = {
    root: {
    //   background: "#f3f7ff",
    // background: "linear-gradient(to right, #f9f9f9, #e3eefa)",
    backgroundColor:"#F9FAFA",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    container: {
      maxWidth: "800px",
      width: "100%",
      borderRadius: "16px",
      boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#ffffff",
      padding: "30px 40px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    header: {
      marginBottom: "10px",
    },
    title: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: "28px",
      fontWeight: "600",
      color: "#344767",
      margin: 0,
    },
    description: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      color: "#778798",
      margin: 0,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
    button: {
    //   backgroundColor: "#4472C4",
    backgroundColor:"#E4E6E7",
      borderRadius: "8px",
      fontFamily: "'Nunito', sans-serif",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "22px",
      color: "black",
      textTransform: "none",
      padding: "10px 20px",
      transition: "all 0.3s ease",
      cursor: "pointer",
      border: "none",
      "&:hover": {
        backgroundColor: "#355a9e",
      },
      "&:disabled": {
        opacity: 0.6,
        cursor: "not-allowed",
      },
    },
    footer: {
      marginTop: "20px",
      textAlign: "center",
      fontSize: "12px",
      color: "#778798",
    left:"0px"
    },
  };

  return (
    <div style={styles.root}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Typography style={styles.title}>Personal Information</Typography>
          <Typography style={styles.description}>
            Please provide your personal information before filling the survey.
          </Typography>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center"
        }}>
          <SelectDropdown
            hideIcon={true}
            selectedOption={props.selectedLang}
            handleChange={(event) => props.setSelectedLang(event.target.value)}
            menuOptions={props.surveyLanguages}
          />
        </div>
        <form style={styles.form}>
          <TextField
            onChange={handleFormData}
            name="guest_name"
            value={formData.guest_name || ""}
            label="Full Name"
            placeholder="Enter full name"
            type="text"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              style: { color: "#344767" },
            }}
            inputProps={{
              style: { color: "#344767" },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0',
                },
                '&:hover fieldset': {
                  borderColor: '#4472C4',
                },
              },
            }}
          />
          <TextField
            onChange={handleFormData}
            name="phone"
            value={formData.phone || ""}
            label="Contact Details"
            placeholder="+1 413 370 4371"
            type="tel"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              style: { color: "#344767" },
            }}
            inputProps={{
              style: { color: "#344767" },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0',
                },
                '&:hover fieldset': {
                  borderColor: '#4472C4',
                },
              },
            }}
          />
          <TextField
            onChange={handleFormData}
            name="age"
            value={formData.age || ""}
            label="Age"
            placeholder="Enter age"
            type="number"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              style: { color: "#344767" },
            }}
            inputProps={{
              style: { color: "#344767" },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0',
                },
                '&:hover fieldset': {
                  borderColor: '#4472C4',
                },
              },
            }}
          />
          <TextField
            onChange={handleFormData}
            name="guest_email"
            value={formData.guest_email || ""}
            label="Email Address"
            placeholder="Enter email address"
            type="email"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              style: { color: "#344767" },
            }}
            inputProps={{
              style: { color: "#344767" },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0',
                },
                '&:hover fieldset': {
                  borderColor: '#4472C4',
                },
              },
            }}
          />
          <Button
            style={styles.button}
            disabled={props.guestInfoSubmitting}
            onClick={() => props.handleSubmit(formData)}
          >
            Start Survey
          </Button>
        </form>
        {/* <CreditsFooter
          style={styles.footer}
          message="Survey created by ReviewMagic team. Check out our Privacy Policy"
        /> */}
      </div>
    </div>
  );
}

export default GuestInfoForm;
