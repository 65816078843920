import React from 'react';
import loader from '../Dashboard/assets/Loadinggif.gif';
import css from './Spinner.module.css';

const Spinner = () => (
    <div className={css.skcircle}>
        <div className={`${css.skcircle1} ${css.skchild}`}></div>
        <div className={`${css.skcircle2} ${css.skchild}`}></div>
        <div className={`${css.skcircle3} ${css.skchild}`}></div>
        <div className={`${css.skcircle4} ${css.skchild}`}></div>
        <div className={`${css.skcircle5} ${css.skchild}`}></div>
        <div className={`${css.skcircle6} ${css.skchild}`}></div>
        <div className={`${css.skcircle7} ${css.skchild}`}></div>
        <div className={`${css.skcircle8} ${css.skchild}`}></div>
        <div className={`${css.skcircle9} ${css.skchild}`}></div>
        <div className={`${css.skcircle10} ${css.skchild}`}></div>
        <div className={`${css.skcircle11} ${css.skchild}`}></div>
        <div className={`${css.skcircle12} ${css.skchild}`}></div>
    </div>
);

export default Spinner;
