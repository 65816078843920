import React, { useState, Fragment, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import {Delete,Menu,Edit, Cancel, Save} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import QuestionTypes from './QuestionTypes/questionTypes';
import SliderComp from './QuestionTypes/Slider';
import MultiOptions from './QuestionTypes/multiOptions';
import TextBox from './QuestionTypes/TextBox';
// import RadioBtn from './QuestionTypes/RadioBtn';
// import TripType from './QuestionTypes/TripType';
// import Button from './QuestionTypes/Button';
import Scale from './QuestionTypes/Scale';
import ConfirmationDialog from '../../../../../components/UI/confirmationDialog';
import { add_auth_header, deepCopy } from "../../../../../components/Utility/Utility";
import { createNewQuestion, updateQuestion, deleteQuestion, getQuestion, createNewDependentQuestion } from '../../../../../actions/surveyActions';
import downArrow from "../../../assets/arrow.png"
import maincss from './QuestionRenderer.module.css'
import DialogBox from '../../../../../commons/components/PopUp/DialogBox';
import { TextField } from '@mui/material';

const useStyles = makeStyles({
    root: {
        '&:focus': {
            outline: "none"
        }
    }
  });

const QuestionComponent = props => {

    const [surveyId, setSurveyId] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [question, setQuestion] = useState({});
    const [__question, __setQuestion] = useState({});
    const [index, setIndex] = useState(null);
    const [editable, setEditable] = useState(false);
    const [showDelPopup, setShowDelPopup] = useState();
    const [isNewQuestion, setIsNewQuestion] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        console.log(props);
        
        setSurveyId(props.surveyId);
        setSectionId(props.sectionId);
        setQuestion(deepCopy(props.question));
        __setQuestion(props.question);
        setIndex(props.index);

        if (props.question && !props.question.id) {setEditable(true); setIsNewQuestion(true)};
    }, [props.surveyId, props.sectionId, props.question]);

    const cancelEdit = () => {
        setQuestion(__question);

        if (isNewQuestion && props.cancelAddingNewQuestion) {props.cancelAddingNewQuestion()};
        if (editable) setEditable(false);
        
    }

   const saveQuestion = () => {
    if(!question?.options)
        question.options = []
       if (!question.id) {
            props.createNewQuestion(props.add_auth_header({}), surveyId, sectionId, question, (isSuccess, data) => {
                if(isSuccess) {
                    setQuestion(data);
                    __setQuestion(data);
                    setEditable(false);
                    setIsNewQuestion(false);
                    if (props.updateMainQuestionData) {props.updateMainQuestionData((p)=>([...p,data]));

                        props.cancelAddingNewQuestion()
                    }
                }
            });
            
        }    
        else {
            props.updateQuestion(props.add_auth_header({}), surveyId, sectionId, question.id, question, (isSuccess, data) => {
                if(isSuccess) {
                    setQuestion(data);
                    __setQuestion(data);
                    setEditable(false);
                }
            });
        }       
   }

   const questionDelHandler = () => {

        const q_id = question.id;
        props.deleteQuestion(props.add_auth_header({}),surveyId,sectionId,q_id,(status) => {
            if(status){
                setShowDelPopup(false);
                console.log(props);
                
                if (props.handleQuestionDeleteFromSection) props.handleQuestionDeleteFromSection(q_id);
            }
        });
    }

    const updateQuestionData = (value,key) => {
        const _quest = {...question}
        Object.keys(_quest).map(_key => {
            if(_key === key){
                _quest[_key] = value;
            }
        })

        if (props.debug) console.log(`updating question locally: ${JSON.stringify(_quest)}`);
        setQuestion(_quest);
    }

    const onQuestTypeSelection = (new_quest) => {
        let _quest = {...question}; // update fields
        _quest['display'] = new_quest['display'];

        if (props.debug || true) console.log(`Display type changed to: ${JSON.stringify(_quest)}`, new_quest, _quest);
        setQuestion(_quest);
    }

    const inputTypeHandler = (type) => {
        console.log(`inputTypeHandler: ${type}`);

        switch(type){
            case 'Slider':
                return <SliderComp
                            updateInParent = {updateQuestionData} 
                            question = {question}
                            debug = {true}
                        />

            case 'Checkbox':
                return <MultiOptions 
                            input="checkbox"
                            editable={true}
                            question = {question}
                            updateInParent = {updateQuestionData}
                        /> 
            
            case 'Radio Button':
                return <MultiOptions 
                            input="radio"
                            editable={true}
                            question = {question}
                            updateInParent = {updateQuestionData}
                        />
            
            case 'Buttons':
                return <MultiOptions 
                            input="buttons"
                            editable={true}
                            question = {question}
                            updateInParent = {updateQuestionData}
                        />
            
            case 'Text Box':
                return <TextBox 
                        editable={true}
                        question = {question}
                        updateInParent = {updateQuestionData}    
                    />
            
            case 'Scale':
                return <Scale 
                            editable={true}
                            question = {question}
                            updateInParent = {updateQuestionData}
                     />
            
            default:
                return <QuestionTypes
                            onQuestTypeSelection={onQuestTypeSelection} />

            // case 'TripType':
            //     return <TripType 
            //                         // saveResponse={props.saveResponse} 
            //                         // response={props.response} 
            //                         question = {props.question}  />
        }
    } 


    return(
        <Fragment>
                <div className = {maincss.EditTemplateQuestion} style={{paddingLeft:"0",paddingRight:"0"}}>
                    <div style={{
                        display:"flex",
                        justifyContent:"start",
                        alignItems:"center"
                    }}>
                        {editable ?      <TextField
                        // size='smalll'
            // name={props.name}
            // placeholder={props.placeholder}
            value={question.question_txt} onChange={(e) => updateQuestionData(e.target.value,"question_txt")}
            // error={formError["name"]}
            // helperText={formError["name"]}
            fullWidth
            // disabled={loading}
            multiline
            // minRows={3}
            // maxRows={5}
            sx={{
                // padding:"10.5px 14px"
            }}
          />
                        
                        // <input type = 'text' value={question.question_txt} onChange={(e) => updateQuestionData(e.target.value,"question_txt")} className = {maincss.NewQuestionInput}/>
                        :<p style={{margin:"0",fontWeight:"600",fontSize:"1em", textAlign:"start"}}>{index}. {question.question_txt}?</p>}
                    </div>
                    <div className = {maincss.ActionIconCont}>
                        {/* <IconButton className={classes.root} size="small" onClick={() => {setShowDetails(prev => !prev); setEditable(false);}}>
                            <Menu />
                        </IconButton>
                        &nbsp;&nbsp; */}
                        {editable && <IconButton className={classes.root} size="small" onClick={(e) => cancelEdit()}>
                            <Cancel />
                        </IconButton>}
                        &nbsp;&nbsp;
                        <IconButton className={classes.root} size="small">
                            {editable ? <Save onClick={saveQuestion} /> : <Edit onClick={() => {setEditable(true);}}/>}
                        </IconButton>
                        &nbsp;&nbsp;
                        {!editable && <IconButton className={classes.root} size="small" onClick={() => setShowDelPopup(true)}>
                            <Delete />
                        </IconButton>}
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: "0px 25px",position:"relative"}} >
                    {editable && <div className = {maincss.input_type_cont} style={{
                        width:"100%"
                    }}>
                        {inputTypeHandler(question.display)}
                    </div>}

                    {editable && <div style={{
                        position:"absolute",
                        right:"0"
                    }}>
                        <select style={{backgroundImage: `url(${downArrow})`,filter: "grayscale(1)"}} className={maincss.optionType} value={question.display} onChange={(e) => onQuestTypeSelection({"display": e.target.value})} >
                            {['Slider','Checkbox','Buttons','Radio Button','Scale','Text Box'].map(itr => <option value={itr} >{itr}</option>)}
                        </select>
                    </div>}
                </div>

                {/* {showDelPopup &&
                    <ConfirmationDialog dialogTitle="Warning !" handleButton1={questionDelHandler} 
                        crossHandler={() => setShowDelPopup(false)} handleButton2={() => setShowDelPopup(false)} open={true} 
                        text={`Are you sure you want to delete this question? This process cannot be undone`}/>
                        } */}
        <DialogBox open={showDelPopup} setOpen={() => setShowDelPopup(false)} handleAgree={questionDelHandler} agreeButtonText={"Yes"} content={"Are you sure you want to delete this question? This process cannot be undone"} topHeading={"Confirm"} closeText={"No"}/>
        </Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        createNewQuestion: bindActionCreators(createNewQuestion, dispatch),
        updateQuestion: bindActionCreators(updateQuestion, dispatch),
        deleteQuestion: bindActionCreators(deleteQuestion, dispatch)
        };
    };

export default connect(null, mapDispatchToProps)(QuestionComponent);