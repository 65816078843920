import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Avatar} from "@mui/material"
import Divider from '@mui/material/Divider';
import { stringAvatar } from '../../../commons/utility';

export default function StaffList({handleClick}) {
  const channels = useSelector((state)=>state.teamCom?.channels?.data || [])
  const directMessage = useSelector((state)=>state.teamCom?.directMessage?.data || [])
  return (
<>
<List
      sx={{ width: '100%', minWidth:300,  maxWidth: 360, maxHeight:"30vh", bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader sx={{textAlign:"center"}} component="div" id="nested-list-subheader">
         Channels
        </ListSubheader>
      }
    >
         <Divider variant="middle" component="li" />
{channels.map((i)=>(
      <ListItemButton key={i.conversation_id} onClick={(e)=>{handleClick(e,i)}}>
        <ListItemIcon>
        <Avatar
                      {...stringAvatar(i.name)}
                      src={""}
                    />
        </ListItemIcon>
         <ListItemText  primary={i.name} />
      </ListItemButton>
    ))}
    </List>
    <List
      sx={{ width: '100%', minWidth:300,  maxWidth: 360, maxHeight:"30vh", bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader sx={{textAlign:"center"}} component="div" id="nested-list-subheader">
         Direct Messages
        </ListSubheader>
      }
    >
         <Divider variant="middle" component="li" />
{directMessage.map((i)=>(
      <ListItemButton key={i.id} onClick={(e)=>{handleClick(e,i)}}>
        <ListItemIcon>
        <Avatar
                      {...stringAvatar(`${i.first_name? i.first_name+" ":""}${i.last_name? i.last_name:""}`)}
                      src={i.profile?.photo}
                    />
        </ListItemIcon>
         <ListItemText  primary={`${i.first_name? i.first_name+" ":""}${i.last_name? i.last_name:""}`} />
      </ListItemButton>
    ))}
    </List>
    </>
  );
}
