import React from 'react';
import { makeStyles } from '@material-ui/core';
import filter_icon  from '../assests/bi_filter.png'
import downArrow from "../assests/arrow.png"

const useStyles = makeStyles({
    select:{
        background: '#FFFFFF',
        border: '1px solid #EAF0F4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        width: 'max-content',
        height: '40px',
        padding: '0rem 30px',
        marginRight: '2rem',
        '-moz-appearance': 'none',
        '-webkit-appearance': 'none', /* Will check to style the dropdown arrow */
        appearance: 'none',
        backgroundImage: `url(${downArrow})`,
        fontFamily: 'Nunito',
        fontSize: '18px',
        lineHeight: '25px',
        backgroundPosition: '100%',
        backgroundRepeat: 'no-repeat',
        cursor: "pointer"
    }
})

const SelectDropdown = (props) => {
    const classes = useStyles();
    return (
        <div>
            {!props.hideIcon && <img src={filter_icon} style={{margin: '0rem 1rem'}} />}
            <select className={classes.select} onChange={props.handleChange} value={props.selectedOption} >
                {
                    props.menuOptions.map((option) => <option value={option}>{option}</option>)
                }
            </select>

        </div>
    );
};

export default SelectDropdown;