import React from 'react';

import Bullet, { Tooltip } from 'devextreme-react/bullet';
import css from './UI.module.css';

const chartData = [{
        value: 8,
        target: 20,
        color: '#39D188',
    },{
        value: 10,
        target: 24,
        color: '#4472C4',
    }, {
        value: 5,
        target: 26,
        color: '#FFCD4B',
    }, {
        value: 3,
        target: 24,
        color: '#FF9090',
    }, {
        value: 2,
        target: 26,
        color: '#BFBFBF',
    }]

const BulletChart = () => {
  const customizeTooltip = (arg) => {
    return {
      text: `Current t&#176: ${arg.value} &#176C<br>Average t&#176:${arg.target}&#176C`,
    };
  }

  var bulletOptions = {
    showZeroLevel: false,
    tooltip: {
      enabled: false
  },
};
    return (
      <div id={css.bulletChart}>
            {chartData.map((data, j) => 
                <div style={{display: 'flex', width: '100%'}}>
                  <div style={{background: '#EAF0F4', width: '60%'}} >
                    <Bullet
                      className={css.bullet}
                      startScaleValue={0}
                      endScaleValue={15}
                      value={data.value}
                      showZeroLevel={false}
                      showTarget={false}
                      tooltip={{enabled: false}}
                    //   target={data.target}
                      color={data.color}
                    />
                  </div>
                  <span className={css.votes} >{data.value} Votes</span>
                </div>
            )}
      </div>
    );
}

export default BulletChart;
