import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    container:{
        color: props => props.color
    },
    icon:{
        width: '2rem',
        marginRight: '1rem'
    },
    title:{
        fontSize: '13px',
        lineHeight: '10px',
        textAlign: 'center'
    },
    value:{
        fontWeight: '600',
        fontSize: '45px',
        lineHeight: '65px'
    }
})

const Analytics = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.container}>
            <div style={{display: 'flex'}} >
                <img className={classes.icon} src={props.icon}/>
                <h4 className={classes.value} >{props.value}</h4>
            </div>
            <p className={classes.title}>{props.title}</p>
        </div>
    );
};

export default Analytics;