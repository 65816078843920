import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const MobileMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const handleDropdownClick = (event) => {
    console.log("ddddddddddddddddddd",event)
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // handle side bar open
  const handleSideBar = (setState) => {
    setState(true);
    handleDropdownClose();
  };

  return props.hasSideBar ? (
    <>
      <IconButton
        id="sidebar-button"
        aria-controls={openDropdown ? "sidebar" : undefined}
        aria-haspopup="true"
        aria-expanded={openDropdown ? "true" : undefined}
        onClick={handleDropdownClick}
        title="nav"
        sx={{
          display: { xs: "flex", sm: "none" },
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="sidebar"
        anchorEl={anchorEl}
        open={openDropdown}
        onClose={handleDropdownClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        sx={{ width: 320, maxWidth: "100%" }}
      >
        <MenuItem
          sx={{ padding: "0 12px" }}
          onClick={() => handleSideBar(props.setNavBarOpen)}
        >
          <ListItemText>Navbar</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ padding: "0 12px" }}
          onClick={() => handleSideBar(props.setSideBarOpen)}
        >
          <ListItemText>{props.pageSideBarName}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <IconButton
      color="inherit"
      edge="start"
      onClick={() => props.setNavBarOpen(true)}
      sx={{
        display: { xs: "flex", sm: "none" },
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default MobileMenu;
