import { setGlobalMessage } from "../../../actions/dashboardActions";
import { modifyData } from "../../../commons/utility";
import axiosClient from "../../../httpClient";
import * as actionTypes from "./SurveyActionType";

export const fetchSurveyFailure = (data) => {
    return {
      type: actionTypes.FETCH_SURVEY_DATA_FAILURE,
      payload: data,
    };
  };

  export const fetchSurveySuccess = (data) => {
    return {
      type: actionTypes.FETCH_SURVEY_DATA_SUCCESS,
      payload: data,
    };
  };


  export const updateSurveyDataByKey = (key,data) => {
    return {
      type: actionTypes.SET_SURVEY_DATA_BY_KEY,
      payload: data,
      key
    };
  };


export const fetchSurvey = (page = 0,params = {}) => {
    return (dispatch, getState) => {
      const {
        filter: { selectedHotel },
        
      } = getState();
  
      let str = ""
      for (let i in params){
          str+=`&${i}=${params[i]}`
      }
  
      axiosClient
        .get(`/survey/?hotel=${selectedHotel.hotel_code}${str}`)
        .then((res) => {
          const data = res.data;
  
          dispatch(fetchSurveySuccess(data))
          
        })
        .catch((error) => {
          console.log(error);
          dispatch(fetchSurveyFailure(error.message))
          //check_auth_error(error);
          dispatch(setGlobalMessage({
            mess: error.message,
            type: 'error'
          }))
        });
    };
  };
  
  
  export const AddUpdateAndDeleteSurvey = (
    body,
    isEdit = false,
    closeModel,
    setLoading,
    reqType = "post",
    navigate
  ) => {
    return (dispatch, getState) => {
      const {
        filter: { selectedHotel },
        surveyStore: { Survey }
      } = getState();
  
      const isDelete = reqType === "delete";
      const url = `/survey/${isEdit || isDelete ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
  
      console.log(reqType, isEdit, url, body);
  
      axiosClient[reqType](url, body)
        .then((res) => {
          dispatch(
            setGlobalMessage({
              mess: isDelete ? "Survey is successfully deleted." : "Details are successfully saved.",
              type: "success",
            })
          );
          const data  = modifyData(Survey.data,reqType,res?.data,body?.id)
          // console.log("data",data,GuestReqType,res.data,reqType,body);
          
          dispatch(fetchSurveySuccess(data))
          if(navigate && reqType==="post")   navigate.push(`/survey/edit/${reqType,res?.data?.id}`)
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          dispatch(
            setGlobalMessage({
              mess: error.message || "An error occurred.",
              type: "error",
            })
          );
        })
        .finally(() => {
          if(closeModel)
          closeModel();
        if(setLoading)
          setLoading(false);
        });
    };
  };