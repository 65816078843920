import React, { useState,useEffect, Fragment } from 'react';
import axios from 'axios';
import { check_auth_error, add_auth_header } from "../../Utility/Utility";
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import './BaseAPIComponent.css'

const BaseAPIComponentNoLoading = props => {

  const {add_auth_header} = props;
    const [api_error, setAPIError] = useState(false);
    let api_dependency = props.api_dependency != null ? props.api_dependency : [];
    
    const load_data = () => {
        setAPIError(false);
        axios
        .get(
            props.data_url,
            {
              headers: add_auth_header({}),
            }
        )
        .then((res) => {
            props.setAPIData(res.data);
            setAPIError(false);
        })
        .catch((error) => {
            setAPIError(true);
            console.log(error);
            check_auth_error(error);
        });
    }

    const not_null = (v) => {
      return v instanceof Array ? v.length>0 : v instanceof Object ? Object.keys(v).length>0: v != null;
    }

    useEffect(() => {
        if (api_dependency.length > 0 && api_dependency.every(not_null)){
            load_data();
        }
    }, api_dependency);

    return (
        <Fragment>
            {api_error ? (
              <div className="review_reload">
                <p onClick={load_data} className="reload-text">Reload</p>
              </div>
            ) : !props.dataLoaded || (props.dataLoaded && props.dataLoaded()) ? (
              <Fragment> 
                {props.component}
              </Fragment>
            ): ("")}
        </Fragment>
    )
}


const mapDispatchToProps = dispatch => {
  return{
      // saveTokenHandler: value => dispatch({type: actionTypes.AUTH_TOKEN, value: value}),
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
  };
  
  
  export default connect(null,mapDispatchToProps)(BaseAPIComponentNoLoading);
      