import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { url } from '../../../../components/Constants/Constants';
import axios from 'axios';
import { setCreateSurvey, getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../../actions/surveyActions';
import { connect, useSelector } from 'react-redux'
import { add_auth_header } from "../../../../components/Utility/Utility";
import Container from '../../../../components/UI/baseContainer';
// import SingleCheckboxDropdown from '../../../../components/UI/singleCheckboxDropdown';
// import TertiaryButton from '../../../../components/UI/buttons/tertiaryButton';
// import PrimaryButton from '../../../../components/UI/buttons/primaryButton';
import SurveyCover from '../../assets/DefaultSurveyCover.jpg';
import { useForm } from 'react-hook-form';
import EditableField from '../../../../components/UI/editableField';
import SecondaryButton from '../../../../components/UI/buttons/secondaryButton';
import ImageUploading from 'react-images-uploading';
// import ShareSurvey from './ShareSurvey';
// import Sidenav from '../../../../components/SideNav/SideNav';
import HotelList from '../../components/HotelList';
import LanguageList from '../../components/LanguageList';
import SectionRenderer from './components/SectionRenderer';
import NewSection from './components/NewSection';
// import survey_icon from "../../assets/download.png"
// import eye_icon from "../../assets/eye.png"
import css from './CreateSurvey.module.css';
import { Link } from 'react-router-dom';
import BasePage from '../../../../commons/components/BasePage/BasePage';
import { Suspense } from 'react';
import FullScreenLoader from '../../../../commons/components/UI/FullScreenLoader';
import { Box } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Share } from '@material-ui/icons';
import CommonModalForForms from '../../../../commons/components/Modals/CommonModalForForms';
import ShareSurveyForm from '../../../../commons/components/Settings/components/ShareSurveyForm';
import NoDataState from '../../../../commons/components/UI/NoDataState';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';



const root = {
    boxShadow: "none",
    border: "none",
    display: "flex",
    gap: "32px",
    flexDirection: "column",
    height: "100%",
    padding: "20px 0",
    flexGrow: 1,
  };

  const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    ...theme.typography.body2,
    textAlign: 'center',
  }));


const CreateNewSurvey = props => {

    const history = useHistory();
    const base_path = useLocation().pathname;

    const surveyId = props.match.params.surveyId;
    const [languageOptions,setLanguageOptions] = useState([]);
    const [hotelOptions,setHotelOptions] = useState([]);
    const { control,setValue,register,formState:{errors},getValues } = useForm();
    const [survey,setSurvey] = useState({});
    const [sections,setSections] = useState([]);
    const [showSharePopup,setShowSharePopup] = useState(false);
    const [coverImg, setCoverImg] = useState();
    const [loading,setLoading] = useState(true)
    
    const selectedLang = useRef("");

    const navObj = useSelector((state) => state.nav.selectedNav || {});
    const selectedHotel = useSelector(
        (state) => state.filter.selectedHotel || {}
      );
    
    const imageHandler = (imageList) => {
        let image = imageList[imageList.length-1].data_url
        setCoverImg(image);
        editSurvey('cover_photo',image);
      }

    const onHotelsChangedForSurvey = (hotels) => {
        props.editSurvey(props.add_auth_header({}),
                         surveyId, {'hotel_ids': hotels.map(h => h.id)},
                         ()=>{})
    }
    
    const handleSectionDeleteFromSurvey = (id) => {
        let _sections = sections.filter(s => s.id != id);
        setSections(_sections);
    }

    const handleSectionAddToSurvey = (section_obj) => {
        let _sections = [...sections];
        _sections.push(section_obj);
        _sections.sort( (s1, s2) => s1.sort_order < s2.sort_order);
        setSections(_sections);
    }

    const editSurvey = (fieldName,value) => {
        // "headers": {...props.add_auth_header({}), "Content-Type": "image/jpeg"   }   

        axios.patch(`${url}/survey/${surveyId}/`,{[fieldName]:value},{
            "headers": props.add_auth_header({})      
        })
        .then(response => {
            setSurvey(response.data);
        })
        .catch(({ response = {} }) => {
            console.log("edit survey err response",response)
        })
    }

    
    
    useEffect(() => {
        if(Object.keys(survey).length===0){
            if(!loading)setLoading(true)
            axios.get(`${url}/survey/${surveyId}/?hotel=${selectedHotel.hotel_code}`,{
                "headers": props.add_auth_header({})
            })
            .then(response =>{
                for(const language of languageOptions){
                    if(language.code === response.data.language){
                        selectedLang.current = language.name;
                    }
                }
                setCoverImg(response.data.photo?response.data.photo:SurveyCover);
                response.data.sections.sort((a, b) => (a.id > b.id) ? 1 : -1);
                setSections(response.data.sections);
                setSurvey(response.data);
            }
            )
            .catch(error => console.log(error.response))
            .finally(()=>setLoading(false))
        }
    },[survey,languageOptions])

    return(
    //     <div className = {css.MainContainer} style = {{backgroundColor: props.brandBgColor}}>
                
    //             <Sidenav 
    //               hide_filters={true}
    //               head = "Edit Survey"
    //             //   setHotelAPIData={this.setHotelAPIData}
    //             //   setSelectedHotel={this.setSelectedHotel}
    //               selectedMenu='Survey'
    //             />

       
    // </div>

    <BasePage title={"Create Survey"} show_side_filter={false}>
    <Suspense fallback={<FullScreenLoader height="100%" />}>
      <Box sx={root}>
   
    {
        Object.keys(survey).length>0?
        <div className={css.ContentContainer}>
        <Container second_div_css={{margin:0}}   extraHeaderContents={
            <div style={{marginRight:"10px"}}>
            <Link to={`/user-survey?surveyId=${surveyId}&preview=1`} style={{textDecoration:'none'}}        target="_blank" 
      rel="noopener noreferrer" 
 >
                {/* <TertiaryButton buttonTitle={<div><img style={{marginRight: '0.6rem'}} src={eye_icon} /><text>Preview</text></div>} fontSize={15} height={40} width={127} backgroundColor="transparent" /> */}
                <LoadingButton
        loading={0}
        loadingPosition="end"
        startIcon={<VisibilityIcon/>}
        variant="contained"
        sx={{
            backgroundColor: "gray",
            "&:hover": {
              backgroundColor: "darkgray",
            },
          }}
      >

<span>
Preview
       </span>
      </LoadingButton>
            </Link>
       
                {/* <PrimaryButton buttonTitle="Share" fontSize={15} height={40} width={127} clickHandler={() => setShowSharePopup(true)}/> */}
         <span style={{marginLeft:"10px"}}>   
        <LoadingButton
        loading={0}
        loadingPosition="end"
        endIcon={<Share/>}
        variant="contained"
        sx={{
            backgroundColor: "gray",
            "&:hover": {
              backgroundColor: "darkgray",
            },
          }}
          onClick={(e)=>{
            e.stopPropagation()
            setShowSharePopup(true)
          }}
      >
       <span>
       Share
       </span>
      </LoadingButton>

  </span> 
            </div>

        } backBtn={() => history.goBack()} component={''} />
       <DemoPaper square={false}  variant="elevation" elevation={2}>
       <div >
        {coverImg &&
            <div  style={{
                position:"relative"
            }}>
                <div className={css.ChangeCoverButton}>
                    <ImageUploading multiple={true} maxNumber={10000} value = {coverImg} onImageUpload={(image) => {console.log("image*****", image)}} onChange = {(image) => imageHandler(image)} dataURLKey = 'data_url' onError={(error,files) => console.log("error",error, files)}>
                    {({
                    imageList,
                    onImageUpload,
                    }) => (
                        <SecondaryButton buttonTitle="Change cover" inheritsWidth={true} width={130} height={35} 
                        backgroundColor="transparent" fontSize={15} color="white" clickHandler={onImageUpload} hoverBackgroundColor={"gray"}/>
                        )}
                    </ImageUploading>
                </div>
                <img className={css.SurveyCover} src={coverImg} alt='Not Found'/>
            </div>}
            <div>
                <div className={css.SurveyInfoRow1}>
                    <div style={{display:"flex", justifyContent:"flex-start", borderBottom: '1px solid #D8D8D8', height: 'max-content', width: '300px'}}>
                        {Object.keys(survey).length!==0&&
                        <EditableField name="name" iconColor={"gray"}  value={survey.name} onChange={(value) => editSurvey("name",value)}/>}
                    </div>
                    
                    <HotelList 
                            surveyId={survey.id}
                            surveyHotels={survey.hotels}
                            onSelectionChange={onHotelsChangedForSurvey}
                            />

                </div>
            </div>
                <div className={css.SurveyInfoRow2}>
                <div style={{justifyContent:"flex-start", borderBottom: '1px solid #D8D8D8', height: 'max-content', width: '300px', textAlign:"start"}}>
                        Description {survey.description}
                    </div>
                    <LanguageList surveyId={survey.id} surveyLanguages={survey.group ? survey.group.survey_languages : []} />
                </div>
           
                <div className = {css.EditTemplatesCont}>
                    {sections.map((section,index) => {
                        
                        return <SectionRenderer  EditTemplatesSectionHead={{
                             backgroundColor:"#E4E6E7", color:"#333"
                        }} key={index} index={index+1} surveyId={survey.id} section={section}
                        handleSectionDeleteFromSurvey={handleSectionDeleteFromSurvey}
                        />
                    })}
                </div>

                <NewSection surveyId={survey.id}
                            renderButton={true} 
                            renderIcon={false}
                            handleSectionAddToSurvey={handleSectionAddToSurvey}
                />
        </div>

       </DemoPaper>
            {/* {showSharePopup && <ShareSurvey setShowSharePopup={setShowSharePopup}/>} */}
            <CommonModalForForms
          show={showSharePopup}
          toggleNewTaskModal={()=>setShowSharePopup((p)=>!p)}
          FormUi={
            <ShareSurveyForm
              closeModal={()=>setShowSharePopup((p)=>!p)}
              isEdit={true}
              data={{"group":{
                id: survey.group?.id
              }}}
            />
          }
        />
        </div>
        :
     !loading ? <NoDataState/>:<></>
    }


      </Box>

      </Suspense>
    </BasePage>

    );
}


const mapStateToProps = state => {
    return{
        createSurvey: state.gen.createSurvey ? state.gen.createSurvey : {},
        sections: state.gen.createSurvey.sections ? state.gen.createSurvey.sections : [],
        token: state.auth.token,
        brandBgColor: state.gen.brandBgColor
    }
}

export default connect(mapStateToProps,{
    setCreateSurvey,
    add_auth_header,
    getSurvey,
    editSurvey,
    createNewSection,
    createNewSurvey,
    deleteSection,
    editSectionName,
    editSurvey
})(CreateNewSurvey);