import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import css from './ComponentTabs.module.css';
import {get_query_param, append_or_replace_query_params} from '../Utility/Utility'

const ComponentTabs = props => {

    const DEFAULT_COLOR = '#000';
    const history = useHistory();
    const base_path = useLocation().pathname;
    const base_path2 = useLocation().pathname.split('/').slice(0,-1).join('/');

    const getTabId = (tabLabel) => {
        return tabLabel.replace(/\s/g, '')
    }

    const getTabId2 = (tabLabel) => {
        return tabLabel.toLowerCase().replace(/\s/g, '-')
    }

    const isActiveTab = (tabLabel) => {
        let tabId = getTabId(tabLabel);
        let qmTab = get_query_param('tab')

        if(qmTab){
            return tabId == qmTab;
        } else if (props.tabs[1]){
            return getTabId(props.tabs[1]) == tabId;  
        }
        
    }

    const pathTabHandler = (e,tab) => {
        const tabId = getTabId2(tab);
        const new_path = append_or_replace_query_params(base_path);
        
        history.push(new_path);
    }

    const selectedTabHandler = (e,tab) => {
        props.tabChangeHandler(getTabId(tab));
        const tabId = getTabId(tab);
        const new_path = append_or_replace_query_params(base_path, {'tab': tabId});
        history.push(new_path);
     }

    return(
        <div className = {css.TabContainer} id = 'TabsContainer' style = {{boxShadow: props.shadow}}>
            {props.tabs.map((el, index) => (
                <p className={isActiveTab(el) && css.selectedTab} onClick = {(e) => props.usePathTabHandler ? pathTabHandler(e,el) : selectedTabHandler(e,el)} key={index} /*style={{color: isActiveTab(el) ? props.brandColor : DEFAULT_COLOR}}*/ >{el}</p>
            ))}
        </div>
    );
}

const mapStateToProps = state => {
    return{
        brandColor: state.gen.brandColor
    }
  }

export default connect(mapStateToProps)(ComponentTabs);