import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, MenuItem, useMediaQuery } from "@mui/material";
import {
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import { convert_filter_obj_to_query_param } from "../../../components/Utility/Utility";
import { resetFilters, setSelectedFilters, setSelectedReviewId, unselectSelectedFilters } from "./actions/actions";
import HotelList from "./HotelList";
import DateRangeFilter from "./Date/DateRangeFilter";
import { clearUrlParams } from "../../utility";
import CommonDateFilter from "./Date/CommonDateFilter";

const drawerWidth = { xs: 220, lg: 280 };
const allowedRoutes =  ["/reviews","/guest-requests","/chat-support","/settings?tab=staff","/settings?tab=trip planner"]

const SideFilter = (props) => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const selecteReviewId = useSelector(s=>s.filter.selectedReviewId)
  let website = queryParameters.get("website");
  let review_id = queryParameters.get("review_id");

  const filters = useSelector((state) => state.filter.filters.data || []);

  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );

  const [tab, setTab] = useState( queryParameters.get("tab"));
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const getKey = ()=>{
    if(navObj.frontend_route == "/settings")
      return `${navObj.frontend_route}-${tab}`
    return navObj.frontend_route
  }

  const selectedFilters = useSelector(
    (state) => state.filter?.selectedFilters[getKey()] || []
  );
  const [filterCount, setFilterCount] = useState(0);
  const [filterFlag, setFilterFlag] = useState(false);
  const [dateRangeException, setDateRangeException] = useState(false);
  
  const variant = useMediaQuery((theme) => theme.breakpoints.down("sm"))
    ? "temporary"
    : "persistent";

  const [open, setOpen] = useState(false);
  // const [initialRenderFlag, setinitialRenderFlag] = useState(false);

  const handleOpen = (value) => {
    if (props.handleOpen) props.handleOpen(value);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);


  // useEffect(() => {
  //  console.log("selectedFilters==========>",selectedFilters,getKey())
  // }, [selectedFilters]);

  useEffect(() => {
    if (filters.length == 0) return;
    if (website) {
      let option_value;
      const code_filter = filters.find((_f) => {
        const val = _f.options.find((value) => value.query_value == website);
        option_value = val;
        return val;
      });
      if (option_value) {
        let newValue = { ...option_value, query_key: code_filter.query_key };
        let key = getKey()
        dispatch(setSelectedFilters(newValue,key));
      }
    }
    //  else {
    //   const code_filter = filters.find((_f) =>
    //     _f.query_key.includes("website")
    //   );
    //   if (code_filter)
    //     dispatch(
    //       setSelectedFilters({
    //         ...code_filter["options"][0],
    //         query_key: code_filter.query_key,
    //       })
    //     );
    // }
  
    dispatch(setSelectedReviewId(review_id));
  }, [website, review_id, filters]);


  useEffect(() => {
    let t = queryParameters.get("tab")
    setTab(t);
    console.log(queryParameters.get("tab"));
    console.log(`${navObj.frontend_route}?tab=${t || ""}`)
    const flag = allowedRoutes.includes(
      navObj.frontend_route == "/settings"?`${navObj.frontend_route}?tab=${t || ""}`:navObj.frontend_route
    );
   const  dateRangeExcp = ['/analytics',"/reviews","/dashboard","/review-management"].includes(navObj.frontend_route)
    // navObj.frontend_route == "/reviews" || navObj.frontend_route==="/review-management";
    setFilterFlag(flag);
    setDateRangeException(dateRangeExcp)
  }, [window.location.search]);

  //   function getIcon(filterName) {
  //     switch (filterName) {
  //       case "Trip Type":
  //         return iconTrip;
  //       case "Language":
  //         return iconLang;
  //       case "Guest Origin":
  //         return iconOrigin;
  //       case "Source":
  //         return iconSource;
  //       default:
  //         return iconOrigin;
  //     }
  //   }

  const onSelectionChange = (event, newValue, query_key) => {
    // add query_key property to filter object
    var _newVal = { ...newValue, query_key: query_key };
  if(newValue)
    dispatch(setSelectedFilters(_newVal,getKey()));
    else  
    dispatch(unselectSelectedFilters({query_key,isAll:false},getKey()))
  };



  // useEffect(() => {
  //   console.log(filters,selectedFilters,navObj,"filte chanfggggggggggggggggggg",selectedFilters,"ss;eeeeeeeeeeeeeee");
    
  //   if (filters?.length > 0 && selectedFilters?.length == 0 && allowedRoutes.includes(
  //     navObj.frontend_route == "/settings"?`${navObj.frontend_route}?tab=${tab}`:navObj.frontend_route
  //   )) {
  //       dispatch(resetFilters(filters,getKey()))
  //   }
  // }, [filters]);

  // useEffect(() => {
  //   if (filters?.length > 0 && initialRenderFlag && allowedRoutes.includes(
  //     navObj.frontend_route == "/settings"?`${navObj.frontend_route}?tab=${tab}`:navObj.frontend_route
  //   )) {
  //     dispatch(resetFilters(filters,getKey(),{
  //       selectedHotelChange:true
  //     }))
  //   }
  //   if(!initialRenderFlag)
  //     setinitialRenderFlag(true)
  // }, [selectedHotel]);

  const clearSelectectReviewId = ()=>{
    dispatch(setSelectedReviewId(null))
    clearUrlParams()
  }

  const [dateRanges, setDateRanges] = useState({});

  useEffect(() => {
    const initialDateRanges = {};
    selectedFilters.forEach((filter) => {
      if (filter.filterType === 'dateRange' && filter.query_value && filter.query_value.startDate && filter.query_value.endDate) {
        initialDateRanges[filter.query_key] = {
          startDate: filter.query_value.startDate,
          endDate: filter.query_value.endDate
        };
      }
    });
    setDateRanges(initialDateRanges);
  }, []);


  const handleDateChange = (obj, { startDate, endDate }) => {
    // Check if the dates are cleared (empty)
    if (!startDate && !endDate) {
      setDateRanges(prev => ({
        ...prev,
        [obj.query_key]: null // Set the date range to null or an empty object
      }));
      dispatch(unselectSelectedFilters({...obj,isAll:false},getKey()))
    } else {
      // Otherwise, update the state with the selected date range
      setDateRanges(prev => ({
        ...prev,
        [obj.query_key]: { startDate, endDate }
      }));
      onSelectionChange({}, {
        filterType: "dateRange",
        display_value: obj.display_value,
        query_value: { startDate, endDate }
      }, obj.query_key);

    }
  };


  return (
    <>
      <Box
        sx={{
          // width: open ? drawerWidth : "1px",
          zIndex: 1002,
          display: { xs: open ? "flex" : "none", sm: "flex" },
        }}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          open={open}
          variant={variant}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
          disableBackdropTransition
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: { xs: "15px", sm: "24px" },
              columnGap: "8px",
              height: "80px",
            }}
          >
            <IconButton
              color="inherit"
              edge="start"
              onClick={(e) => handleOpen(false)}
              sx={{
                display: {
                  xs: "flex",
                  // lg: filterFlag && navObj.frontend_route != "/reviews" && "none",
                },
                justifyContent: "center",
                alignItems: "center",
                padding: "6px",
              }}
            >
              <ChevronRight />
            </IconButton>

            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              Filters
            </Typography>
            {/* <Box
              sx={{
                background: "#E4E5E7",
                height: "25px",
                width: "25px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                {filterCount}
              </Typography>
            </Box> */}
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "24px 16px",
              gap: "16px",
            }}
          >
            <HotelList />
            {(dateRangeException) && <DateRangeFilter />}
            {/* <DateFilter /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {filterFlag &&
                selectedFilters?.length > 0 &&
                filters.map((obj, idx) => {
                  if(obj.type === "date_range"){
                    return (
                        // <Grid key={obj.query_key} item sm={6} xs={6}>
                            <CommonDateFilter
                                selectedDates={dateRanges[obj.query_key]} 
                                onDateChange={(dates) => handleDateChange(obj, dates)} 
                                obj={obj}
                            />
                        // </Grid>
                    );
            
                }
                else
                  return (
                    <Box key={`hotel_reply_${idx}`}>
                      <label
                        htmlFor={`filter-${obj.name}`}
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#181A1B",
                        }}
                      >
                        {obj.name}
                      </label>
                      <Autocomplete
                        key={`filter-${obj.name}`}
                        id={`filter-${obj.name}`}
                        options={obj.options}
                        sx={{
                          bgcolor: "#FFFFFF",
                        }}
                        value={
                          selectedFilters?.filter(
                            (_f) => _f.query_key == obj.query_key
                          )[0]
                        }
                        onChange={(event, newValue) =>
                          onSelectionChange(event, newValue, obj.query_key)
                        }
                        isOptionEqualToValue={(option, value) => {
                          // console.log(obj, "ddddddd", value)
                          return obj?.query_key == value?.query_key;
                        }}
                        getOptionLabel={(option) =>
                          option.display_value ? option.display_value : ""
                        }
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              padding: 0,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...params.inputProps.style,
                                padding: 0,
                              },
                              sx: {
                                ...params.inputProps.sx,
                                boxShadow: "none",
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  );
                })}
            </Box>
  {
    selecteReviewId &&   <Box>
    <Box sx={
      {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
      }
    }>
    <label
                            htmlFor={`reviewId`}
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "20px",
                              color: "#181A1B",
                            }}
                          >
                           Review Id
                          </label>
                      <CloseIcon sx={
                        {
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                        onClick={clearSelectectReviewId}
                        />
    </Box>
                      <TextField name="reviewId" id="reviewId" size="small" placeholder="review id" value={selecteReviewId}/>
        </Box>
  }
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default SideFilter;
