import { Box, Typography } from "@mui/material";
import React from "react";

function Notification(props) {
  return (
    <Box
      sx={{
        width: "24px",
        height: "22px",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: props.bgcolor || "#E24536",
        margin:"0px 5px"
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "18px",
          color: props.color || "#F9FAFA",
        }}
      >
        {props.count}
      </Typography>
    </Box>
  );
}

export default Notification;
