import React, { useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const InputWithAvatar = ({handleChange,avatar,disable=false, name="photo"}) => {

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setAvatar(e.target.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };
  return (
  <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        onChange={handleChange}
        name={name}
        disabled={disable}
      />
      <label htmlFor="icon-button-file">
        <IconButton component="span" >
          <Avatar
            src={avatar}
            sx={{ width: 70, height: 70,marginTop:"-2px"}}
          >
            {!avatar && <PhotoCamera />}
          </Avatar>
        </IconButton>
      </label>
      </>
  )
}

export default InputWithAvatar




