import * as actionTypes from '../actions/actionTypes';
import axiosClient from '../httpClient';
import { setGlobalMessage } from './dashboardActions';

export function updateBrandColor(color) {
    return {
      type: actionTypes.BRAND_COLOR_UPDATE,
      payload: color,
    };
  }
  

export const updateBackgroundColor = (bgColor) => {
    return{
        type: actionTypes.UPDATE_BRAND_BG,
        payload: bgColor
    }
}

export const updateLogo = (logo) => {
  return{
      type: actionTypes.UPDATE_BRAND_LOGO,
      payload: logo
  }
}

export const updatePhoto = (photo) => {
  return{
      type: actionTypes.UPDATE_BRAND_PHOTO,
      payload: photo
  }
}

export const updateBrandId = (id) => {
  return{
      type: actionTypes.UPDATE_BRAND_ID,
      payload: id
  }
}

export const updateBrandName = (id) => {
  return{
      type: actionTypes.UPDATE_BRAND_NAME,
      payload: id
  }
}

export const fetchBrandData = () => {
  return (dispatch) => {
    axiosClient 
      .get(
        `/services/brands/`)
      .then((response) => {
        console.log(response)
        updateBrandColor(response.data.color);
        updateBackgroundColor(response.data.bg_color);
        updateLogo(response.data.logo);
        updatePhoto(response.data.photo);
        updateBrandId(response.data.id);
        updateBrandName(response.data.name);
      })
      .catch((error) => {
        dispatch(setGlobalMessage({
          mess:error.message,
          type:'error'
         }))
      });
  };
};