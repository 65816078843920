import React, { useState,useEffect, Fragment } from 'react';
import reload from '../../assets/reload.svg';
import loading from '../../assets/Loadinggif.gif';
import axios from 'axios';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { check_auth_error, add_auth_header } from "../../../Utility/Utility";
import Spinner from '../../../Spinner/Spinner';
import datanotfound from '../../assets/datanotfound.png';
import './BaseChart.css';

const BaseChart = props => {
    const {add_auth_header} = props;
    const [api_error, setAPIError] = useState(false);
    const [http_resp_code, setHttpRespCode] = useState(200);
    const [api_loading, setAPILoading] = useState(true);
    let api_dependency = props.api_dependency != null ? props.api_dependency : [];

    const load_data = () => {
        setAPILoading(true);  
        setAPIError(false);
        axios
        .get(
            props.data_url,
            {
              headers: add_auth_header({}),
            }
        )
        .then((res) => {
            props.setAPIData(res.data);
            setHttpRespCode(res.status);
            setAPILoading(false);
            setAPIError(false);
        })
        .catch((error) => {
            setAPIError(true);
            setAPILoading(false);
            console.log(error);
            check_auth_error(error);
        });
    }

    const not_null = (v) => {
      // if(props.name=='CategoryAnalysis')
        // console.log(`v is ${v} instance of ${typeof(v)} returning ${v instanceof Array ? v.length>0 : v instanceof Object ? Object.keys(v).length>0: v != null }`)
      return v instanceof Array ? v.length>0 : v instanceof Object ? Object.keys(v).length>0: v != null;
    }

    useEffect(() => {
        if (api_dependency.length > 0 && api_dependency.every(not_null)){
            load_data();
        } else {
          setAPILoading(true); 
        }
    }, api_dependency);

    return (
        <Fragment>
            {api_error ? (
              <div className="review_reload">
                <img
                  src={reload}
                  alt=""
                  className="loading_image cursor"
                  onClick={load_data}
                />
                <p className="loading_text ">Reload</p>
              </div>
            ) : api_loading ? (
              <div className="review_loading">
                <Spinner/>
                <p className="loading_text">Loading, please wait</p>
              </div>
            ) : http_resp_code == 204 ? (<div className="data-not-found">
                <img src={datanotfound} alt="Data Not Found"/>
                <p className="loading_text msg1">Oops! No data found</p>
                <p className="loading_text">Please try changing hotels/filters</p>
              </div>)
          : !props.dataLoaded || (props.dataLoaded && props.dataLoaded()) ? (
              <Fragment>    
                {props.chart}
              </Fragment>
            )
            :("")}
        </Fragment>
    )
}


const mapDispatchToProps = dispatch => {
  return{
      // saveTokenHandler: value => dispatch({type: actionTypes.AUTH_TOKEN, value: value}),
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
  };
  
  
  export default connect(null,mapDispatchToProps)(BaseChart);
    