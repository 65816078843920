import React, { useEffect, useState } from 'react';
import { faCheck, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { faSquare,faSmile, faFrown, faMeh, faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from '../../../../components/UI/baseContainer';
import css from './CreateSurvey.module.css';

// From there new UI imports
import { makeStyles } from '@material-ui/core';
import CardWithTitle from '../../../../components/UI/cardWithTitle';
import Analytics from './analytics.js'
import list_icn from '../../assets/list.svg';
import check_icn from '../../assets/check.svg';
import meter_icn from '../../assets/meter.svg';
import eye_icn from '../../assets/eye.svg';
import done_icn from '../../assets/done.svg';
import chart_icn from '../../assets/chart.svg'
import axios from 'axios';
import { add_auth_header, check_auth_error } from '../../../../components/Utility/Utility';
import { url } from '../../../../components/Constants/Constants';
import Spinner from '../../../../components/Spinner/Spinner';
import datanotfound from '../../../../components/Dashboard/assets/datanotfound.png';
import reload from '../../../../components/Dashboard/assets/reload.svg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const list_icon = list_icn
const check_icon = check_icn
const meter_icon = meter_icn
const eye_icon = eye_icn
const done_icon = done_icn
const chart_icon = chart_icn

const useStyles = makeStyles({
    analyticsContainer:{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        justifyItems: 'center',
        height: '20rem',
        alignItems: 'center'
    }
})

const ActiveSurvey = (props) => {
    const classes = useStyles()
    const [api_error, setAPIError] = useState(false);
    const [http_resp_code, setHttpRespCode] = useState(200);
    const [api_loading, setAPILoading] = useState(true);
    const [api_data, setAPIData] = useState([]);
    const [activeSurveyData, setActiveSurveyData] = useState([]);

    const load_data = () => {
        console.log("load_data called")
        axios
        .get(
            `${url}/survey/response-analytics/stats/`,
            {
              headers: props.add_auth_header({}),
            }
        )
        .then((res) => {
            setAPIData(res.data);
            setHttpRespCode(res.status);
            setAPILoading(false);
            setAPIError(false);
        })
        .catch((error) => {
            setAPIError(true);
            setAPILoading(false);
            console.log(error);
            check_auth_error(error);
        });
    }

    useEffect(() => {
        console.log("activesurvey mounted")
        load_data()
    },[])

    useEffect(() => {
        setActiveSurveyData([
            {title: 'Total Questions', value: api_data?.response_metric?.total, color:"#6B81BE", icon:"list_icon"},
            {title: 'Completed Survey', value: api_data?.response_metric?.completed, color:"#70AD47", icon:"check_icon"},
            {title: 'Conpletion Rate', value: `${api_data?.response_metric?.completed_percent}%`, color:"#FF6161", icon:"meter_icon"},
            {title: 'Survey Viewd', value: api_data?.response_metric?.viewed, color:"#FFC000", icon:"eye_icon"},
            {title: 'Attempted Survey', value: api_data?.response_metric?.attempted, color:"#028B9E", icon:"done_icon"},
            {title: 'DropOut Rate', value: `${api_data?.response_metric?.dropout_percent}%`, color:"#737880", icon:"chart_icon"}
        ])
    }, [api_data])

    return(
            <CardWithTitle 
                height={props.height} 
                width={props.width} 
                textAlignHeader='left' 
                title='Currently active survey' 
                showFilters={false}
                description='This section shows the response number of the guests in respect to time' 
                component={
                    <>
                        {api_error ? (
                        <div className="review_reload">
                            <img
                            src={reload}
                            alt=""
                            className="loading_image cursor"
                            onClick={load_data}
                            />
                            <p className="loading_text ">Reload</p>
                        </div>
                        ) : api_loading ? (
                        <div className="review_loading">
                            <Spinner/>
                            <p className="loading_text">Loading, please wait</p>
                        </div>
                        ) : http_resp_code == 204 ? (<div className="data-not-found">
                            <img src={datanotfound} alt="Data Not Found"/>
                            <p className="loading_text msg1">Oops! No data found</p>
                            <p className="loading_text">Please try changing hotels/filters</p>
                        </div>)
                    : activeSurveyData.length > 1 ? (
                    <div className={classes.analyticsContainer} >
                        {
                            activeSurveyData.map(data => {
                                return <Analytics icon={eval(data.icon)} value={data.value} title={data.title} color={data.color}/>
                            })
                        }
                    </div>
                     )
                     :("")}
                 </>
                } />
    );
}

const mapDispatchToProps = dispatch => {
    return{
        // saveTokenHandler: value => dispatch({type: actionTypes.AUTH_TOKEN, value: value}),
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        };
    };

export default connect(null,mapDispatchToProps)(ActiveSurvey);

// This is the old UI
{/* 
    <div className = {css.CreateSurveyHeader}>
        <div className = {css.CurrentlyActiveCont}>
            <div className = {css.IconContainer}>
                <p>
                    <FontAwesomeIcon icon = {faSmile} className = {`${css.SurveySmileIcon} ${css.SurveyIcon}`}/>
                    <FontAwesomeIcon icon = {faCheck} className = {css.SurveyIcon}/>
                    <FontAwesomeIcon icon = {faSortAmountDown} className = {css.SurveyIcon}/>
                </p>
                <p>
                    <FontAwesomeIcon icon = {faMeh} className = {`${css.SurveySmileIcon} ${css.SurveyIcon}`}/>
                    <FontAwesomeIcon icon = {faSquare} className = {css.SurveyIcon}/>
                    <FontAwesomeIcon icon = {faSortAmountDown} className = {css.SurveyIcon}/>
                </p>
                <p>
                    <FontAwesomeIcon icon = {faFrown} className = {`${css.SurveySmileIcon} ${css.SurveyIcon}`}/>
                    <FontAwesomeIcon icon = {faSquare} className = {css.SurveyIcon}/>
                    <FontAwesomeIcon icon = {faSortAmountDown} className = {css.SurveyIcon}/>
                </p>
                <p>The park hotel standard <FontAwesomeIcon className = {css.SurveyIcon} icon = {faEdit}/></p>
            </div>
            <div className = {css.QuestionNumber}>
            <p>20</p>
            <p>number of questions</p>
            </div>
            <div className = {css.QuestionNumber}>
            <p>566</p>
            <p> guests that took this survey</p>
            </div>
        </div>
    </div>
*/}