export default {
  control: {
    backgroundColor: "#F9FAFA",
    fontSize: 14,
    fontWeight: "normal",
    // maxHeight:120
    // width:"85%"
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 30,
      maxHeight: 120,
      // overflowY:"scroll",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.4375em",
      color: "rgba(0, 0, 0, 0.87)",
      boxSizing: "border-box",
      cursor: "text",
      borderRadius: "4px",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      maxHeight: 120,
    },
    input: {
      padding: 9,
      border: "1px solid silver",
      overflow: "auto",
      maxHeight: 120,
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 10,
      border: "2px inset",
      // border:"1px solid gray"
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid #E4E6E7",
      fontSize: 14,
      maxHeight: "35vh",
      overflowY: "scroll",
      borderRadius: 5,
      minWidth: "150px",
      maxWidth: "200px",
      boxShadow: "2px 2px #F9FAFA",
    },
    item: {
      padding: "5px 10px",
      // color: "b",
      fontSize: "14px",
      // backgroundColor: "#F9FAFA",
      borderBottom: "1px solid #E4E6E7",
      "&focused": {
        backgroundColor: "#F9FAFA",
      },
    },
  },
};
