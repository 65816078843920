import React, {useEffect, useState, Fragment} from 'react';
import { add_auth_header } from "../../../../../components/Utility/Utility";
import ItemAddButton from '../../../../../components/UI/buttons/itemAddButton';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import ConfirmationDialog from '../../../../../components/UI/confirmationDialog';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Delete,FileCopyRounded} from '@material-ui/icons';
import { getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../../../actions/surveyActions';
import { useForm } from 'react-hook-form';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import Cross from '../../../../../components/UI/buttons/crossButton';
import TertiaryButton from '../../../../../components/UI/buttons/tertiaryButton';
import HotelList from '../../../components/HotelList';
import LanguageList from '../../../components/LanguageList';

import Ellipse1 from '../../../assets/Ellipse1.png';
import Ellipse2 from '../../../assets/Ellipse2.png';
import css from '../CreateSurvey.module.css';


const CreateEditSurveyPopup = props => {

    const [hotels, setHotels] = useState([]);
    const [addingLanguage,setAddingLanguage] = useState(false);
    const [createSurveyLngs,setCreateSurveyLngs] = useState([]);
    const [selectedComp, setSelectedComp] = useState('');

    //For edit survey
    const [surveyId,setSurveyId] = useState();
    const [survey,setSurvey] = useState(null);


    const { control, register, setValue ,formState:{errors}, handleSubmit } = useForm({defaultValues:{'name':addingLanguage?survey.name:''}});

    useEffect(() => {
        register('hotel_ids');
        register('language_code');
    }, [])

    useEffect(() => {
        setHotels(props.hotels);
        setSurvey(props.survey);

    }, [props.hotels, props.survey])
    
    const onSubmit = (data) => {

        console.log(`onSubmit: ${JSON.stringify(data)}`);

        // if(addingLanguage)
        //     data['name'] = survey.name;
        //     data['group_id'] = survey.group.id
        props.setShowCreateSurveyPopup(false);
        setAddingLanguage(false);
        setSurvey(null);
        createNewSurveyHandler(data);
    }

    const createNewSurveyHandler = (data) => {
        props.createNewSurvey(props.add_auth_header({}),data,(status,data) => {
            if(status){
                console.log("create survey response", data);
                setSurvey(data);
                props.setCreatingSurvey(true);
                props.setCreateSurvey(data)
                setSelectedComp("create");
            }
        })
    }

    return (<Fragment>
            <Backdrop show={true} />
            <div className={css.create_new_survey_popup}>
                <div style={{display:"flex"}}>
                    <div style={{marginRight:"auto",borderRadius:"12px"}}>
                        <img src={Ellipse1} alt=''/>
                    </div>
                    <div style={{marginTop:"10px",marginLeft:"auto",transform:"translateX(-10px)"}}>
                        <Cross clickHandler={() => {props.setShowCreateSurveyPopup(false);setAddingLanguage(false)}}/>
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div className={css.createSurveyContent}>
                        <h4>{addingLanguage ? "Add Language" : "Create Survey"}</h4>
                        <div className={css.create_new_survey_popup_fields}>
                            <label>Survey name:</label>
                            <div>
                                {addingLanguage ? survey.name:
                                <input name="name" className={css.surveyInput} ref={register}/>}
                                <div className={css.inputLine}>
                                </div>
                                <div style={{color:"red",fontSize:"0.8em"}}>
                                {errors.name?.type === 'required' && "Survey name is required"}
                                </div>
                            </div>
                        </div>
                        <div className={css.create_new_survey_popup_fields}>
                            <label>Hotel names:</label>
                            <HotelList  hotels={hotels} onSelectionChange={(selected_hotels)=> {let h_ids=selected_hotels.map(h => h.id); setValue('hotel_ids', h_ids); return h_ids;}}/>
                        </div>
                        <div className={css.create_new_survey_popup_fields}>
                            <label>Survey language:</label>
                            <LanguageList  isSingleSelect={true} surveyLanguages={[]} onSelectionChange={(selected_language)=> {setValue('language_code', selected_language.id)}}/>
                        </div>
                        <div className={css.create_new_survey_popup_fields}>
                            <TertiaryButton buttonTitle="Create Survey" fontSize={16} height={40} clickHandler={handleSubmit(onSubmit)}/>
                        </div>
                        </div>
                        <div style={{marginLeft:"auto"}}>
                            <img src={Ellipse2} className={css.createSurveyImg} alt=''/>
                        </div>
                    </div>
                </div>
        </Fragment>)

}

const mapStateToProps = state => {return{}}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        editSurvey: bindActionCreators(editSurvey, dispatch),
        createNewSection: bindActionCreators(createNewSection, dispatch),
        createNewSurvey: bindActionCreators(createNewSurvey, dispatch)
        };
    };

export default connect(mapStateToProps,mapDispatchToProps)(CreateEditSurveyPopup);