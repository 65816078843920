import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const makeString = (number) => {
  return number.toString() + "px";
};

const useStyles = makeStyles((props) => ({
  root: {
    height: (props) => makeString(props.height),
    width: (props) => makeString(props.width),
    background: "#E24536",
    textTransform: "none",
    "&:hover": {
      background: "#E24536",
    },
    "&:focus": {
      outline: "none",
    },
    "@media (max-width:768px)": {
      width: (props) => makeString(0.75 * props.width),
    },
    "@media (max-width:450px)": {
      width: (props) => makeString(0.6 * props.width),
    },
  },
}));

function CrossButton(props) {
  const classes = useStyles(props);
  return props.disabled ? (
    <IconButton className={classes.root} disabled>
      <CloseSharpIcon style={{ color: "grey", fontSize: props.fontSize }} />
    </IconButton>
  ) : (
    <IconButton
      size="small"
      className={classes.root}
      onClick={props.clickHandler}
    >
      <CloseSharpIcon style={{ color: "white", fontSize: props.fontSize }} />
    </IconButton>
  );
}

CrossButton.defaultProps = {
  height: 20,
  width: 20,
  fontSize: "14px",
  disabled: false,
};

export default CrossButton;
