import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  PieSeries,
  Title,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core';
import { Palette } from '@devexpress/dx-react-chart';


const legendStyles = () => ({
  root: {
    display: 'flex',
    left:'0',
    paddingTop: '20px'
  },
});
const legendLabelStyles = () => ({
  label: {
    fontSize:'12px',
    whiteSpace: 'nowrap'
  },
});
const legendItemStyles = () => ({
  item: {
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingRight: '8px'
  },
});
const legendMarkerStyles = () => ({
  marker: {
    alignSelf:'baseline',
    marginLeft: '10px'
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const legendMarkerBase = ({ classes, ...restProps }) => (
  <Legend.Marker className={classes.marker} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel'})(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
const Marker = withStyles(legendMarkerStyles, { name: 'LegendItem' })(legendMarkerBase);

const PieChart = (props) => {
    return (
        <Chart
          data={props.chartData}
          height={props.height}
          width={props.width}
        >{console.log('props.legendPosition',props.legendPosition)}
          <PieSeries
            valueField={props.valueField}
            argumentField={props.argumentField}
          />
          <Palette scheme={['#855CF8', "#E289F2", "E289F2", "#ACB9FF"]} />
          <Animation />
          <Legend position={props.legendPosition} rootComponent={Root} itemComponent={Item} labelComponent={Label} markerComponent={Marker} />
        </Chart>
    )
}

export default PieChart;