import React from 'react';
import { makeStyles } from '@material-ui/core';
import filter_icon from './assests/filter_icon.svg'
import date_picker from './assests/date_picker.svg'

const useStyles = makeStyles({
    card:{
        height: props => props.height,
        width: props => props.width,
        background: '#fff',
        borderRadius: '10px',
        border: '1px solid #D8D8D8',
        padding: '1rem',
        marginLeft: '0.5rem',
        marginTop: '1rem'
    },
    header:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    title:{
        fontFamily: 'Nunito',
        fontSize: '20px',
        lineHeight: '25px',
        textAlign: props => props.textAlignHeader,
        width: props => props.titleWidth,
        margin: 0
    },
    filters:{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '35%'
    },
    filterOptions:{
        border: '0.397321px solid #D8D8D8',
        height: '42px',
        minWidth: '6rem',
        padding: '0rem 0.7rem',
        borderRadius: '5px',
        fontSize: '13.125px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    description:{
        fontFamily: 'Nunito',
        fontSize: '12px',
        lineHeight: '18px',
        texAlign: props => props.textAlignHeader,
        margin: 0
    },
    img:{
        marginLeft: '5px'
    }
})

const CardWithTitle = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.card} >
            <div className={classes.header}>
                <h5 className={classes.title} >{props.title}</h5>
                { props.showFilters && 
                    <div className={classes.filters} >
                        <div>Filters <img className={classes.img} src={filter_icon} /></div>
                        <div className={classes.filterOptions} >Monthly</div>
                        <div className={classes.filterOptions} ><span style={{width: '35%'}}>Custom date</span> <input style={{width: "66%"}} type='date' /></div>
                    </div>
                }
            </div>
            <p className={classes.description} >{props.description}</p>
            <div>{props.component}</div>
        </div>
    );
};

export default CardWithTitle;