import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./DateWidget.css";
import DatePopup from './DatePopup';
import { convert_date_to_str } from "../../Utility/Utility";
import Backdrop from '../../Backdrop/Backdrop';
import * as filterActions from '../../../commons/components/Filter/actions/actions';

const DateWidget = props => {

  const [showPopup,setShowPopup] = useState(false);

  const showPopupHandler = () => {
    setShowPopup(prev => !prev);
  }

  const setSelectedDates = (selectedStatOption, selectedStatStartDate, selectedStatEndDate, 
    selectedCompOption, selectedCompStartDate, selectedCompEndDate) => {

    let params = ''

    props.setStatOption(selectedStatOption);
    if (selectedStatOption == 'Custom Date'){
      props.setStatStartOption(new Date(selectedStatStartDate));
      props.setStatEndOption(new Date(selectedStatEndDate));
    }
    
    props.setCompOption(selectedCompOption);
    if (selectedCompOption == 'Custom Date'){
      props.setCompStartOption(new Date(selectedCompStartDate))
      props.setCompEndOption(new Date(selectedCompEndDate));
    }
  }

  useEffect(() => {
    if(props.selectedDate){
      setSelectedDates(props.selectedDate.selectedStatOption, props.selectedDate.selectedStatStartDate, props.selectedDate.selectedStatEndDate,
        props.selectedDate.selectedCompOption, props.selectedDate.selectedCompStartDate, props.selectedDate.selectedCompEndDate)
    }
  }, [])

  return(
    <div className = 'SideNavDateWidgetContainer'>
      {/* <span>Date Range</span> */}
      <div className="Date_range" style = {{ color: 'black'}} onClick = {showPopupHandler}>
        <p className="Range">{convert_date_to_str(props.selectedDate.selectedStatOption, props.selectedDate.selectedStatStartDate, props.selectedDate.selectedStatEndDate)}</p>
        <p className="Date" >compared to</p>
        <p className="Range" >{convert_date_to_str(props.selectedDate.selectedCompOption, props.selectedDate.selectedCompStartDate, props.selectedDate.selectedCompEndDate)}</p>
      </div>
      <Backdrop show={showPopup} onClick={showPopupHandler}/>
      {showPopup
       ? (<DatePopup
        showPopupHandler={showPopupHandler}
      />)
      : ("")
      }
      
    </div>
  )
}

const mapStateToProps = state => {
  return{
    brandColor: state.gen.brandColor,
    selectedDate:  {
      setStatOption: (option) => console.log('Set Stat Option:', option),
      setCompOption: (option) => console.log('Set Comp Option:', option),
      setStatStartOption: (date) => console.log('Set Stat Start Date:', date),
      setStatEndOption: (date) => console.log('Set Stat End Date:', date),
      setCompStartOption: (date) => console.log('Set Comp Start Date:', date),
      setCompEndOption: (date) => console.log('Set Comp End Date:', date),
    }|| state.filter.selectedDate
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setStatOption: (payload) => dispatch(filterActions.setStatOption(payload)),
    setStatStartOption: (payload) => dispatch(filterActions.setStatStartOption(payload)),
    setStatEndOption: (payload) => dispatch(filterActions.setStatEndOption(payload)),
    setCompOption: (payload) => dispatch(filterActions.setCompOption(payload)),
    setCompStartOption: (payload => dispatch(filterActions.setCompStartOption(payload))),
    setCompEndOption: (payload) => dispatch(filterActions.setCompEndOption(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateWidget);
