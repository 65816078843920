import React from 'react';
import css from './UI.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const BaseContainer = (props) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className={css.flex_div}   style={props.second_div_css || {}}>
                {props.backBtn !== '' &&
                <div className = {css.circle}>
                    <FontAwesomeIcon onClick = {(event) => props.backBtn('')} className = {css.BackBtn} icon = {faChevronLeft}/>
                </div>
                }
                <h4>{props.pageTitle}</h4>
                <div className={css.extraHeaderContents} >{props.extraHeaderContents}</div>
            </div>
            {props.component !== '' &&
                <div className={css.container} >
                    {props.component}
                </div>
            }   
        </div>
    );
};

export default BaseContainer;