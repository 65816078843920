import React, { useState, useEffect } from 'react';
import { Bar, Chart, Radar } from 'react-chartjs-2';
import { url } from '../../../../../../components/Constants/Constants';
import BaseChart from '../../../../../../components/Dashboard/ChartComponents/BaseChart/BaseChart';

import css from '../../SurveyAnalytics.module.css';

Chart.defaults.global.legend.display = false;

const ArrivalExperience = props => {

  const [api_data,setAPIData] = useState([]);

  // const barColorHandler = (data) => {
  //   const colorArr = [];
  //   Object.values(data).map(el => {
  //     if(el < 2.5){
  //       colorArr.push('#FF6161');
  //     }else if(el === 2.5){
  //       colorArr.push('#FEF55A');
  //     }else{
  //       colorArr.push('#38B738');
  //     }
  //   });
  //   return colorArr;
  // }

  const getChartDataFromResp = (resp_data) => {
    if (resp_data.length >= 0){
      return {
        labels: ["Excellent ", "Good", "Average", "Bad","Terrible"],
          datasets: [
            {
              data: [2,3,4,5,0],
              backgroundColor: '#92B860',
              borderColor: '#B2B2B2',
              borderWidth: 1,
              pointRadius: 0,
            },
          ],
      }
    } else {
      return {}
    }
  }


  const chart = (
                <div className = {css.carousel_chart_container}>
                    <div>
                    <Radar
                       data={getChartDataFromResp(api_data)}
                       width = {10}
                       height={8}
                       options={{
                          
                           title:{
                               display:true,
                               text: props.title,
                              
                               
                           },
                            plugins: {
                                labels: {
                                render: () => {},
                                },
                           
                       },
                       scale: {
                           angleLines:{
                                display:false,
                           },
                           gridLines:{
                               lineWidth:2

                           },
                            ticks:{
                                display: false,
                                min: 0,
                                max: 5,
                                fontFamily: "AvenirNext"
                            }
                       },
                      
                      
                       }}
                    />
                    </div>
                    {/* <Bar
                      data={getChartDataFromResp(api_data)}
                      height={155}
                      options={{
                      plugins: {
                          labels: {
                          render: () => {},
                          },
                      },
                      scales:{
                        yAxes:[{
                          ticks:{
                            beginAtZero: true,
                            max: 5,
                            maxTicksLimit: 6,
                            fontSize: 14,
                            fontFamily: 'Lato'
                          }
                        }]
                      }
                      }}
                    /> */}
                    <p className = {css.guest_stay_chart_link}>View areas of improvement</p>
              </div>
  );
    

    return (<BaseChart 
      chart={chart}
    //   data_url = {`${url}/analytics/key-reviews/?hotel=${props.selectedHotel.hotel_code}&${props.date_params}&${props.filter_params}`}
    //   setAPIData = {setAPIData}
    //   api_dependency = {[props.selectedHotel, props.date_params, props.filter_params]}  
    />);

}

export default ArrivalExperience;