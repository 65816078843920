import React, { useState, useMemo } from "react";
import { url } from "../../../../components/Constants/Constants";
import BaseChart from '../../../../components/Dashboard/ChartComponents/BaseChart/BaseChart';
import Line from '../../../../components/UI/lineChart';

const Chart = () => {
    const [lineChatData, setLineChatData] = useState({})    

    const dataLoaded = () => {
        return Object.keys(lineChatData).length > 0
    }
    const LineChart = <Line name="" argumentFieldLabel="Dates" valueFieldLabel="Guest Responded" valueList={Object.keys(lineChatData).length > 0 && lineChatData.map(obj => obj[1])} argumentList={Object.keys(lineChatData).length > 0 && lineChatData.map(obj => obj[0])}/>

    return (<>
        {useMemo(() => <BaseChart
        chart={LineChart}
        data_url = {`${url}/survey/response-analytics/trend/`}
        setAPIData = {setLineChatData}
        dataLoaded={dataLoaded} 
        api_dependency = {[lineChatData]}/>, [Object.keys(lineChatData).length > 0 ? null : lineChatData])}
        {console.log("lineChatData",lineChatData)}
    </>);
}

export default Chart;