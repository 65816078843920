import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import ReportIcon from '@mui/icons-material/Report';

import LineLoader from "../../../commons/components/Loader/LineLoader";
import {
  generateReply,
  convertReply,
  cacheGeneratedReviewReply,
  clearAReply,
  sendReviewReply,
  sendReviewReplySuccess,
  MarkReview,
} from "../actions/actions";
import AIReplyIcon from "../assets/Icon.svg";
import { ReplayOutlined } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { Done } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import DialogBox from "../../../commons/components/PopUp/DialogBox";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReplyIcon from '@mui/icons-material/Reply';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { pushGAEvent } from "../../../commons/utility";
const formControl = {
  minWidth: 120,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove border from the select button
    },
    "&:hover fieldset": {
      border: "none", // Remove border from the select button on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove border from the select button when focused
    },
  },
};

const BootstrapButton = styled(LoadingButton)({
  boxShadow: "none",
  color: "black",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid #EAEBEC",
  lineHeight: 1.5,
  "&:hover": {
    boxShadow: "none",
    border: "1px solid #EAEBEC",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {},
});

const secondary2 = {
  fontWeight: 800,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#60666C",
};
const secondary3 = {
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#484D51",
};

function removePosNegTags(raw_review_txt) {
  return raw_review_txt.replace("[Positive]:", "").replace("[Negative]:");
}

const AIReply = (props) => {
  const dispatch = useDispatch();

  const { setReply_for_parent,loading, setLoading } = props

  const [isEditing, setIsEditing] = useState(false);
  const [isReplyGenAllowed, setIsReplyGenAllowed] = useState(
    !props.review.hotel_reply &&
      props.review.raw_content &&
      props.review.raw_content.length > 5
  );
  const inputRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [notConnectedPopUp, setNotConnectedPopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const history = useHistory();
  const sourceData = useSelector((state)=>state.settings.sources.data || [])
  const [sendReplyPopUp, setSendReplyPopUp] = useState(null);
  // const [markReviewPopUp, setMarkReviewPopUp] = useState(null);
  const dont_ask = useSelector((state) => state?.auth?.user?.profile?.settings?.preferences?.review_reply_post_dont_ask_again || false);

  useEffect(()=>{
const {state} = history.location
if(state && state.scrollY)
setTimeout(()=>{
  window.scrollTo({
    top: state.scrollY,
    behavior: 'smooth'
  });
},3000)
  },[])


  const userAccountType = useSelector(
    (state) => state.auth?.user?.profile?.account_type || ""
  );
  

  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );

  const [selectedLanguage, setSelectedLanguage] = useState({
    code: "en",
    name: "English",
  });
  const languageOptions = useSelector(
    (state) => state.review.langOptions || []
  );
  const generated_replies = useSelector(
    (state) => state.review.replies.data || {}
  );
  const reply_sent = useSelector(
    (state) => state.review.replies.send_success || ""
  );
  const [reply, setReply] = useState(props.review.hotel_reply || "");
  const [error, setError] = useState(null);
  const timeoutRef = useRef();

  const handleEditClick = () => {
    if (timeoutRef) clearTimeout(timeoutRef.current);
    setIsEditing(true);
    timeoutRef.current = setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const handleBlur = () => {
    setIsEditing(false);
    const curr_key = `${selectedHotel.hotel_code}_${props.review.review_id}_${selectedLanguage.code}`;
    if (generated_replies[curr_key] != reply) {
      languageOptions.forEach((lang) => {
        lang.code != selectedLanguage.code &&
          dispatch(
            clearAReply(
              `${selectedHotel.hotel_code}_${props.review.review_id}_${lang?.code}`
            )
          );
      });
      dispatch(cacheGeneratedReviewReply(curr_key, reply));
    }
  };

  const languageSelectHandler = (e) => {
    e.preventDefault();
    var lang_code = e.target.value;
    console.log(`lang selected: ${lang_code}`);
    const lang = languageOptions.find((l) => l?.code == lang_code);
    if (lang) setSelectedLanguage(lang);
    console.log(`languageSelectHandler sending generateOrConvert`);
    setError(null);
    // generateOrConvert(lang_code);
  };

  const generateOrConvert = (lang_code) => {
    if (loading == "generating_reply") return;

    const lang = languageOptions.find((l) => l?.code == lang_code);
    const en_key = `${selectedHotel.hotel_code}_${props.review.review_id}_en`;
    const key = `${selectedHotel.hotel_code}_${props.review.review_id}_${lang_code}`;

    console.log(
      `lang code recieved: ${lang_code}, lang found: ${JSON.stringify(lang)}`
    );

    if (generated_replies[key]) setReply(generated_replies[key]);
    else if (generated_replies[en_key]) {
      dispatch(
        convertReply(
          generated_replies[en_key],
          selectedHotel.hotel_code,
          props.review.review_id,
          lang["code"],
          lang["name"],
          setLoading,
          setError,
          loading
        )
      );
    } else if (reply) {
      dispatch(
        convertReply(
          reply,
          selectedHotel.hotel_code,
          props.review.review_id,
          lang["code"],
          lang["name"],
          setLoading,
          setError,
          loading
        )
      );
    } else if (isReplyGenAllowed)
      dispatch(
        generateReply(
          removePosNegTags(props.review.raw_content),
          props.review.reviewer_name,
          selectedHotel.name,
          selectedHotel.hotel_code,
          props.review.review_id,
          props.review.rating_review_score,
          props.review.out_of,
          props.review.source.name,
          // lang["code"],
          // lang["name"],
          lang?.code,
          lang?.name,
          setLoading,
          setError,
          loading
        )
      );
  };

  useEffect(() => {
    if (reply.length > 0) {
      setError(null);
      setLoading("");
    }
    setReply_for_parent(reply)
  }, [reply]);

  // useEffect(() => {
  //   if (!isReplyGenAllowed) {
  //     console.log("not generating or converting as reply not editable");
  //     return;
  //   }

  //   const en_key = `${selectedHotel.hotel_code}_${props.review.review_id}_en`;
  //   if (!generated_replies[en_key])
  //     dispatch(
  //       generateReply(
  //         removePosNegTags(props.review.raw_content),
  //         props.review.reviewer_name,
  //         selectedHotel.name,
  //         selectedHotel.hotel_code,
  //         props.review.review_id,
  //         props.review.rating_review_score,
  //         props.review.out_of,
  //         props.review.source.name,
  //         "en",
  //         "English",
  //         setLoading,
  //         setError,
  //         loading
  //       )
  //     );
  // }, []);

  useEffect(() => {
    if (selectedLanguage["code"]) {
      const key = `${selectedHotel.hotel_code}_${props.review.review_id}_${selectedLanguage["code"]}`;
      if (generated_replies[key]) {
        setReply(generated_replies[key]);
      } else if (isReplyGenAllowed) {
        generateOrConvert(selectedLanguage["code"]);
      }
    }
  }, [generated_replies, selectedLanguage]);

  useEffect(() => {
    const key = `${selectedHotel.hotel_code}_${props.review.review_id}_${"en"}`;
    if (generated_replies[key] && !flag) {
      setFlag(true);
      const lang = languageOptions.find(
        (l) => l?.code == props.review.source_language
      );
      if (lang) {
        setSelectedLanguage(lang);
      }
    }
  }, [languageOptions, props.review, generated_replies]);

  const reloadReplyOnError = () => {
    if (selectedLanguage["code"]) generateOrConvert(selectedLanguage["code"]);
  };

const handleReplyPopup = (e)=>{
  e?.stopPropagation()
  if(dont_ask)
    sendReply()
  else
    {
      setSendReplyPopUp(`This will post your reply to ${props.review?.source?.display_name}`)
    }
}


  //send reply to the review
  const sendReply = (checkbox=null) => {
    if (reply.length > 0 && !loading) {
      const data = {
        code: props.review.source.code,
        source_name:props.review?.source?.name,
        // review_id: props.review.review_id,
        review_id: props.review.source_review_id,
        reply,
      };
      if(checkbox) {
        data.review_reply_post_dont_ask_again = true
      }
      if(sendReplyPopUp)
        setSendReplyPopUp(null)
      dispatch(sendReviewReply(setLoading, data,window.scrollY,history,setNotConnectedPopUp,setPopUpMessage));
    }
  };

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (reply_sent == props.review.review_id) {
      timeoutRef.current = setTimeout(() => {
        dispatch(sendReviewReplySuccess(""));
      }, [3000]);
    }
  }, [reply_sent]);

  const handleOnConnect = ()=>{
    setNotConnectedPopUp(false)
    history.push('/settings?tab=connect', { scrollY:window.scrollY,next:'/reviews' });
  }

// const handleMarkReview =()=>{
// setMarkReviewPopUp(`Has this reply been posted on ${props.review?.source?.name}? Would you like to mark this review as replied?`)
// copyToClipBoard()
// }

const copyToClipBoard = (message =`Reply copied to clipboard`,e )=>{
  navigator.clipboard
  .writeText(reply)
  .then(() => {
let data = {
  event:"BTN_REVIEW_COPY",
  source_name:props.review?.source?.name,
}

pushGAEvent(data)


    dispatch(
      setGlobalMessage({
        mess: message,
        type: "success",
      })
    );
    if(props?.handleRedirect)
      props.handleRedirect()
  })
  .catch((err) => {
    dispatch(
      setGlobalMessage({
        mess: `Unable to copy to clipboard: ${err}`,
        type: "error",
      })
    );
  });
}

  // const handleCopy = async () => {
  //   if (!reply){
  //     dispatch(
  //       setGlobalMessage({
  //         mess: `No text to copy`,
  //         type: "error",
  //       })
  //     );
  //   }
  //   else{
  //     if (!loading) {
  //       const data = {
  //         code: props.review.source.code,
  //         review_id: props.review.source_review_id,
  //         reply,
  //       };
  //    dispatch(MarkReview(data,copyToClipBoard,setMarkReviewPopUp))
  //     }  

  //   }}



  const checkInteration = (code)=>{
    let obj = sourceData.find((e)=>e.code===code)
    if(obj && obj.connection_type!== null)
    return true
    return false
  }

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EAEBEC",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          boxShadow: "0px 1px 3px -1px #181A1B1F",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: props.review.hotel_reply ? "16px" : "0 8px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
       {   !props.review.hotel_reply &&  <Box component="img" src={AIReplyIcon} />}
            <Typography sx={secondary2}>
              {props.review.hotel_reply ? "Hotel Response" : "AI Suggested Reply"}
            </Typography>
{
  props.review.status === "Waiting for approval" &&             <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    // backgroundColor: "#FFEEEE",  
    // padding: "5px 12px",
    // borderRadius: "6px",
    // border: "1px solid #FFCCCC",
  }}
>
 {/* <Tooltip  title={`This review is replied by ${props.review.staff_name}`}> */}
 <ReportIcon sx={{ color: "#D9534F" }} /> 
 {/* </Tooltip> */}
  <Typography sx={{ ...secondary2, color: "#D9534F", }}>
   {/* {props.review.status} */}
   Awaiting confirmation from {props.review?.source?.name}, Replied by {props.review?.staff_name}.



  </Typography>
</Box>
}
          </Box>

          {isReplyGenAllowed && (
            <FormControl
              sx={{
                flexDirection: "row",
                alignItems: "baseline",
                ...formControl,
              }}
            >
              <Typography component="span" display="inline" sx={secondary3}>
                Viewing in
              </Typography>
              <Select
                value={selectedLanguage.code}
                onChange={languageSelectHandler}
                disabled={loading}
              >
                {languageOptions.map((language, idx) => (
                  <MenuItem
                    key={idx}
                    value={language.code}
                    sx={{
                      minHeight: "20px",
                    }}
                  >
                    <Typography
                      component="span"
                      display="inline"
                      sx={secondary3}
                    >
                      {language.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <Box sx={{ padding: "8px 16px 16px 16px" }}>
          {loading == "generating_reply" ? (
            <LineLoader />
          ) : error ? (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>{error}</Typography>
              <IconButton
                color="inherit"
                onClick={reloadReplyOnError}
                sx={{ padding: 0, maxHeight: 24 }}
              >
                <ReplayOutlined />
              </IconButton>
            </Box>
          ) : (
            <TextField
              variant="standard"
              multiline
              inputRef={inputRef}
              value={reply ? reply : ""}
              onChange={(e) => setReply(e.target.value)}
              onBlur={handleBlur}
              placeholder={
                isReplyGenAllowed
                  ? "InnSpire.AI is generating reply for you, please wait..."
                  : "Write your reply here"
              }
              fullWidth
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#6D7378",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "-0.44px",
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  border: "none",
                  color: "#484D51",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "20px",
                  letterSpacing: "-0.44px",
                },
              }}
            />
          )}
        </Box>
        {loading != "generating_reply" && !error && (
          <Box sx={{ display: "flex" }}>
         { !checkInteration(props?.review?.source?.code)?  ( <BootstrapButton
              sx={{ width: "50%" }}
              disabled={props.review.status === "Waiting for approval"}
              startIcon={
              //  !props.review.hotel_reply?<BookmarkIcon/> :<ContentCopyIcon/>
              <ContentCopyIcon/>
              }
              onClick={(e)=>{
              e.stopPropagation()
                // if(!props.review.hotel_reply)
                //   handleMarkReview(e)
                // else
                copyToClipBoard()
              }}
            >
          {/* { !props.review.hotel_reply? "Mark":"Copy"} */}
          Copy
            </BootstrapButton>):(
                 <BootstrapButton
                 loading={loading == "sending_reply"}
                 loadingPosition="start"
                 sx={{ width: "50%" }}
                 disabled={!isReplyGenAllowed || userAccountType==="STAFF"}
                 startIcon={
                   reply_sent == props.review.review_id ? <Done /> : <SendIcon />
                 }
                 onClick={handleReplyPopup}
               >
                 Send
               </BootstrapButton>
            )}
            <BootstrapButton
              loading={loading == "editing_reply"}
              loadingPosition="start"
              sx={{ width: "50%" }}
              disabled={props.review.hotel_reply || userAccountType==="STAFF" || isEditing}
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              Edit
            </BootstrapButton>
          </Box>
        )}
      </Box>
      <DialogBox open={notConnectedPopUp} setOpen={setNotConnectedPopUp} handleAgree={handleOnConnect} agreeButtonText={"Connect"} content={popUpMessage || "Souce is not connected. Please connect source first."}/>
      <DialogBox open={sendReplyPopUp} setOpen={setSendReplyPopUp} handleAgree={sendReply} agreeButtonText={"Submit"} content={sendReplyPopUp} dont_ask_checkbox={true}/>
      {/* <DialogBox open={markReviewPopUp} setOpen={setMarkReviewPopUp} handleAgree={handleCopy} agreeButtonText={"Yes"} content={markReviewPopUp} topHeading={"Confirm"} closeText={"No"}/> */}
       </>


  );
};

export default AIReply;
