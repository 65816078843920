import React, { useState,useEffect, Fragment } from 'react';
import { url } from '../../../components/Constants/Constants';
import BaseAPIComponentNoLoader from '../../../components/APIComponents/BaseAPIComponent/BaseAPIComponentNoLoading';
import MultipleCheckboxDropdown from '../../../components/UI/dropdown/multipleCheckboxDropdown';
import SingleCheckboxDropdown from '../../../components/UI/singleCheckboxDropdown';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import {add_auth_header} from '../../../components/Utility/Utility';
import { useForm } from 'react-hook-form';
import { setCreateSurvey, getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../actions/surveyActions';


const LanguageList = props => {

    const [api_data, setAPIData] = useState([]);
    const [surveyLanguages, setSurveyLanguages] = useState([]);
    const [surveyId, setSurveyId] = useState(null);
    const [isSingleSelect, setIsSingleSelect] = useState(false);

    const { control,setValue,register,formState:{errors},getValues } = useForm();

    useEffect(() => {
        setSurveyId(props.surveyId);
        setSurveyLanguages(props.surveyLanguages || []);
        setIsSingleSelect(props.isSingleSelect);
    }, [props.surveyLanguages, props.surveyId, props.isSingleSelect])


    const component = (
        !isSingleSelect
        ? <MultipleCheckboxDropdown displayLabel="Languages"
                                    label={"lang_id"}
                                    control={control} 
                                    setValue={setValue}
                                    allObjects={api_data.map(lang => { return {'label':lang.name, 'id': lang.code}})}//update this with api result when add popup to create neww survey language  //{api_data.map(lang => { return {'label':lang.name, 'id': lang.code}})}
                                    selectedObjects={surveyLanguages.map(lang => { return {'label':lang.label, 'id': lang.code}})}
                                    errors={errors}
                                    onChange={props.onSelectionChange}//{(langs) => {}}//{props.editSurvey(props.add_auth_header({}), surveyId, {'hotel_ids': langs.map(h => h.id)}, ()=>{})}}
                                    minWidth={180}
                                    maxWidth={20}
                                    debug={false}
                                />
        : <SingleCheckboxDropdown displayLabel="Language"
                                  label="language_code"
                                  control={control}
                                  errors={errors}
                                  setValue={setValue}
                                  allObjects={api_data.map(lang => { return {'label':lang.name, 'id': lang.code}})}
                                  selectedObject={{}}
                                  formField="code"
                                  displayField="name"
                                  onChange={props.onSelectionChange}
                                  debug={true}
                                />
        )

    return (
        <div style={{marginLeft:"auto"}}>
            <BaseAPIComponentNoLoader
                component = {component}
                data_url={`${url}/survey/languageOptions/`}
                setAPIData = {setAPIData}
                api_dependency  = {[true]}
                not_found_msg = {'Languages not found.'}
            />
        </div>
    );
}

const mapStateToProps = state => {
  return{
    hotel: state.gen.selectedHotel
  };
};

const mapDispatchToProps = dispatch => {
  return{
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      editSurvey: bindActionCreators(editSurvey, dispatch)
      };
  };
  
  export default connect(mapStateToProps,mapDispatchToProps)(LanguageList);