import React from 'react'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles({
    root: {
        width: '100%',
        background: '#4472C4',
        height: '45px',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '10px',
        position: 'fixed',
        bottom: '0'
    }
})

function CreditsFooter(props) {
    const classes = useStyles()
  return (
    <div className={classes.root} >{props.message}</div>
  )
}

export default CreditsFooter