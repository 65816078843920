import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import DateWidget from './Date/DateWidget';

import * as filterActions from '../../commons/components/Filter/actions/actions';


const Filters = props => {

    const [selections, setSelections] = useState(props.selectedFilters);

    const onSelectionChange = (event, query_key) => {
        let _selections = {...selections};
        _selections[query_key] = event.target.value;
        setSelections(_selections);
    }

    useEffect(()=> {
        if(props.applySelection == 'apply-start') {
            props.setSelectedFilters(selections);
            props.setApplySelection('apply-done');
        }
    }, [props.applySelection])

    useEffect(()=>{
        const _default_selections = {}
        if(Object.keys(props.selectedFilters).length == 0){
            props.filters.map((obj)=>{_default_selections[obj.query_key]='All'})
            setSelections(_default_selections)
            props.setSelectedFilters(_default_selections)
        }
    },[props.filters])

    return (<>
            <DateWidget/>
          {props.filters.map((obj, index) => {
              return (index < (window.innerWidth <=1024 ? 1 :  3) || props.showFilters) && (
               <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                <span key={"s"+index} className="filter-saperater" ></span>
                <div key={index} className = 'SidenavFiltersContainer'>
                <span>{obj.name}</span>
                <select
                    id={obj.query_key}
                    query_key={obj.query_key} 
                    value={selections[obj.query_key]}
                    onChange={(e) => onSelectionChange(e, obj.query_key)}>

                    {obj['options'] instanceof Array 
                    ? (obj['options'].map((option, v_index) => {
                        return (
                            <option value={option.query_value} key={`${obj.query_key}_${v_index}`}>
                            {option.display_value}
                            </option>
                        );
                        }))
                    : (obj['options'][Object.keys(obj['options'])[0]].map((option, v_index) => {
                        return (
                            <option value={option.query_value} key={`${obj.query_key}_${v_index}`}>
                            {option.display_value}
                            </option>
                        );}
                    ))}
                </select>
            </div>
            </div>)
          })}
        </>
    );

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSelectedFilters: (selection) => dispatch(filterActions.setSelectedFilters(selection))
    }
}

const mapStateToProps = state => {
    return{
        brandColor: state.gen.brandColor,
        selectedHotel: state.gen.selectedHotel || {},
        filters: state.filter.filters.data || [],
        selectedFilters: state.filter.selectedFilters[window.location.pathname] || []
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);