import React from 'react';
import CreditsFooter from '../../../../../components/UI/CreditsFooter';
import success from "../assets/mirage-message-sent.png"

// MUI imports
import { makeStyles, Typography } from '@material-ui/core';
import { Card } from '@mui/material';

const useStyles = makeStyles({
    root: { backgroundColor:"#F9FAFA", height: "100%", width: "100%",display:"flex", justifyContent:"center", alignItems:"center"},
    container: {display: 'grid', justifyItems: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center'},
})

function SurveySuccess(props) {
    const classes = useStyles()
    
  return (
    <div className={classes.root} >
        <div className={classes.container} >
            <Card sx={{width: '1000px', height: '440px', display: 'grid', justifyItems: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}} >
                <img style={{marginBottom: '20px'}} src={success} />
                <Typography variant='h5' >{props.surveyAlreadySubmitted ? "This survey has already been submitted" : "Survey submitted successfully"}</Typography>
                <Typography variant='body1' style={{marginTop: '20px'}} >Thanks for participating in the survey and sharing your valuable feebacks</Typography>
            </Card>
        </div>
        {/* <CreditsFooter message="Survey created by ReviewMagic team.  Check out our Privacy Policy" /> */}
    </div>
  )
}

export default SurveySuccess