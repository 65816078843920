import React, { useState } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  Box,
  Grid,
  MenuItem,
  Checkbox
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../UI/CommonButton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { commonFetchData } from "../../../utility";
import { AddUpdateAndDeleteSurvey } from "../../../../apps/Survey/action/SurveyAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SurveyForm = ({ closeModal,isEdit=false,data }) => {
    const initialState = {
     name:data?.name || "",
     hotel_ids:data?.hotels?.length>0 ?data.hotels.map(e=>e.id):[],
     language_code: data?.language?.code || "",
     sections:[]
      };
      const [bodyData, setbodyData] = useState(initialState);
      const [loading, setLoading] = useState(false);
      const [language,setLanguage] = useState([])
      const [formError, setFormError] = useState({
        name:"",
        hotel_ids:"",
        language_code: "",
      });
      const hotels = useSelector((state) => state.filter.hotels.data);
      const dispatch = useDispatch();
      const navigate = useHistory()


      useEffect(() => {
        (async () => {
          const Data = await commonFetchData("/survey/languageOptions/",{req:"get",onErrorData:[]});
                    setLanguage(Data)
        })();
      }, []);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (formError[name] && `${value}`.trim())
        setFormError((prev) => ({ ...prev, [name]: "" }));
        setbodyData({ ...bodyData, [name]: value });
      };
    
      const isValid = () => {
        let valid = true;
        Object.keys(bodyData).forEach((key) => {
          if (key !== "sections" && bodyData[key].length == 0) {
            valid = false;
            setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
          }
        });
        return valid;
      };
    
      const handleSubmit = () => {
        if (isValid()) {
          setLoading(true);
          if(isEdit)
           dispatch(AddUpdateAndDeleteSurvey({id:data.id,...bodyData},true, closeModal, setLoading,"patch",navigate));
          else
          dispatch(AddUpdateAndDeleteSurvey(bodyData,false, closeModal, setLoading,"post",navigate));
        }
      };
    
      return (
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
            width: { xs: "100%", sm: "600px" },  // Adjusted width for responsiveness
            margin: "auto", // Center the form
            // minHeight:"250px"
          }}
        >
          <FormLabel>{isEdit?"Edit":"Add"} Department</FormLabel>
    
  <Grid container spacing={2}>
<Grid item sm={12} xs={12}>
          <TextField
            required
            label="Survey Name"
            name="name"
            value={bodyData.name}
            onChange={handleChange}
            error={formError["name"]}
            helperText={formError["name"]}
            fullWidth
            disabled={loading}
            multiline
            // minRows={3}
            // maxRows={5}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
      <TextField
        required
        label="Hotels"
        name="hotel_ids"
        value={bodyData?.hotel_ids}
        onChange={handleChange}
        error={formError["hotel_ids"]}
        helperText={formError["hotel_ids"]}
        fullWidth
        disabled={loading}
        select
        SelectProps={{ multiple: true }}
      >
        {hotels &&
          hotels.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              <Checkbox checked={bodyData?.hotel_ids.includes(i.id)} />
              {i.name}
            </MenuItem>
          ))}
      </TextField>
      </Grid>

      <Grid item sm={12} xs={12}>
      <TextField
        required
        label="Survey Language"
        name="language_code"
        value={bodyData?.language_code}
        onChange={handleChange}
        error={formError["language_code"]}
        helperText={formError["language_code"]}
        fullWidth
        disabled={loading}
        select
        SelectProps={{ multiple: false }}
      >
        {language &&
          language.map((i) => (
            <MenuItem key={i.code} value={i.code}>
              <Checkbox checked={bodyData?.language_code == i.code} />
              {i.name}
            </MenuItem>
          ))}
      </TextField>
      </Grid>
  </Grid>
              <Box sx={{ display: "flex", columnGap: 3, justifyContent: "center" }}>
            <CommonButton
              loading={loading}
              onClickHandler={closeModal}
              title={"Cancel"}
            />
            {loading ? (
              <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="success" />{" "}
              </Stack>
            ) : (
              <CommonButton
                onClickHandler={handleSubmit}
                title={"Submit"}
                loading={loading}
              />
            )}
          </Box>
        </FormControl>
      );
    };
    

export default SurveyForm
