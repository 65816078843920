import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper, 
  Stack,
  Container,
  Grid 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Inbox as InboxIcon,
} from '@mui/icons-material';

// Styled components
const DashedBorderPaper = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[300]}`,
  backgroundColor: 'transparent'
}));

const NoDataState = ({ variant = 'default', message = 'No data available' }) => {
  const variants = {
    default: (
      <Box sx={{ 
        // p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        // bgcolor: 'grey.50', 
        height:"100%",
        justifyContent:"center"
      }}>
        <InboxIcon sx={{ width: 48, height: 48, color: 'grey.400', mb: 1.5 }} />
        <Typography variant="body1" color="text.secondary" fontWeight={500}>
          {message}
        </Typography>
        <Typography variant="body2" color="text.disabled" sx={{ mt: 0.5 }}>
          Try refresh page to see data
        </Typography>
      </Box>
    ),

    minimal: (
      <DashedBorderPaper sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Typography color="text.secondary">
          {message}
        </Typography>
      </DashedBorderPaper>
    ),

    illustrated: (
      <Paper sx={{ 
        p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        bgcolor: 'grey.50' 
      }}>
        <Box sx={{ position: 'relative', mb: 2 }}>
          <Box sx={{ 
            width: 64, 
            height: 64, 
            bgcolor: 'grey.200', 
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Box sx={{ width: 32, height: 4, bgcolor: 'grey.300', borderRadius: 1 }} />
          </Box>
          <Box sx={{ 
            position: 'absolute',
            top: -8,
            right: -8,
            width: 24,
            height: 24,
            bgcolor: 'grey.300',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Box sx={{ width: 12, height: 12, bgcolor: 'grey.400', borderRadius: '50%' }} />
          </Box>
        </Box>
        <Typography variant="h6" color="text.primary" gutterBottom>
          {message}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ maxWidth: 'sm' }}>
          No entries found. Try changing your search parameters or clear filters to see more results.
        </Typography>
      </Paper>
    ),

    actionable: (
      <Paper sx={{ 
        p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        bgcolor: 'grey.50' 
      }}>
        <InboxIcon sx={{ width: 48, height: 48, color: 'grey.400', mb: 1.5 }} />
        <Typography variant="body1" color="text.primary" fontWeight={500} gutterBottom>
          {message}
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
          No data matches your current criteria
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button 
            variant="outlined" 
            color="inherit"
            size="small"
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            size="small"
          >
            Add New Entry
          </Button>
        </Stack>
      </Paper>
    ),

    compact: (
      <Paper sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1.5,
        bgcolor: 'grey.50' 
      }}>
        <InboxIcon sx={{ width: 20, height: 20, color: 'grey.400' }} />
        <Typography color="text.secondary">
          {message}
        </Typography>
      </Paper>
    )
  };

  return variants[variant] || variants.default;
};



export default NoDataState;