import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  Box,
  Checkbox,
  Grid,
  FormControlLabel,
} from "@mui/material";
import UploadImageButton from "./UploadImageButton";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../UI/CommonButton";
import { AddNewStaff } from "../actions/settingsActions";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { deepClone, fetchStaffData } from "../../../utility";
import InputWithAvatar from "./InputWithAvatar";
import CustomPasswordField from "./inputCommponets/CustomPasswordField";


const AddStaffForm = ({ closeModal,data={},isEdit=false , allowedFields = {
  first_name: true,
  last_name: true,
  email: true,
  password1: true,
  password2: true,
  hotels: true,
  department_id: true,
  account_type: true,
  designation: true,
  photo:true,
  change_password:true,
  hotel_system_user_id:true
}, readOnly = {},updateUser=false, title="Add Staff" }) => {
  const initialState = {
    // "username": "",
    first_name: data?.first_name ||"",
    last_name: data?.last_name || "",
    email: data?.email || "",
    password1: data?.password1 || "",
    password2: data?.password2 || "",
    legal:data.legal !== undefined?data.legal :  true,
    profile: data.profile ? 
    {
      designation: data.profile?.designation || "",
      account_type: data.profile?.account_type || "",
      department_id: data.profile?.department_id || "",
      hotels: data.profile?.hotels.map((e)=>e.hotel_code) || [],
      ...(allowedFields.hotel_system_user_id?{hotel_system_user_id:data?.profile?.hotel_system_user_id || ""}:{}),
    }
    :   {
      designation:"",
      account_type:"",
      department_id:"",
      hotels:[],
    },
    ...(allowedFields.change_password?{change_password:data?.change_password || false}:{}),
  };
  const names = useSelector((state) => state.filter.hotels.data);
  const [bodyData, setbodyData] = useState(initialState);
  let a = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [optionData, setOptionData] = useState(null);
  const hodel_code = useSelector(
    (s) => s?.filter?.selectedHotel?.hotel_code || ""
  );
  const [formError, setFormError] = useState({
    // "username": "",
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
    hotels: "",
    // department:"",
    // designation:"",
    // photo:"",
    account_type:""
  });
  const [avatar, setAvatar] = useState(data?.profile?.photo || null);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const data = await fetchStaffData(hodel_code);
      if (Object.keys(data).length > 0) setOptionData(data);
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value,checked } = e.target;
    if (formError[name] && `${value}`.trim())
      if (name.includes("password")) {
        setFormError((prev) => ({ ...prev, ["password1"]: "" }));
        setFormError((prev) => ({ ...prev, ["password2"]: "" }));
      } else setFormError((prev) => ({ ...prev, [name]: "" }));
      if(name === "photo"){
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setbodyData((p)=>({ ...p,profile:{ ...p.profile, [name]: reader.result} }));
            setAvatar(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }else if (["designation","account_type","department_id","hotels","hotel_system_user_id"].includes(name)) setbodyData((p)=>({ ...p,profile:{ ...p.profile, [name]: value} }));
      else setbodyData({ ...bodyData, [name] : ["change_password"].includes(name)?checked: value });

      console.log(name,value,bodyData)
  };

  const isValid = (filter) => {
    let valid = true;
    const emailRegex = /^\S+@\S+\.\S{2,}$/;
    Object.keys(bodyData).forEach((key) => {
      if (!filter.includes(key) && bodyData[key].length == 0) {
        valid = false;
        setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
      }
    });
    if (!emailRegex.test(bodyData.email)) {
      valid = false;
      setFormError((prev) => ({ ...prev, ["email"]: "enter valid email" }));
    }
    if (bodyData.password1 != bodyData.password2) {
      valid = false;
      setFormError((prev) => ({
        ...prev,
        ["password1"]: "password should match",
      }));
      setFormError((prev) => ({
        ...prev,
        ["password2"]: "password should match",
      }));
    }
    if(bodyData.profile.hotels.length=== 0)
    {
      setFormError((prev) => ({ ...prev, ["hotels"]: "This field is requiredd" }));
      valid = false
    }

    if(bodyData.profile.account_type.length== 0)
      {
        setFormError((prev) => ({ ...prev, ["account_type"]: "This field is requiredd" }));
        valid = false
      }

    return valid;
  };

  const handleSubmit = () => {
    let filter = ["photo",'designation',"department_id","hotels","account_type","hotel_system_user_id"]
    if(isEdit)
      filter.push("password1","password2")
    if (isValid(filter)) {
      setLoading(true);
      let d  = deepClone(isEdit?{id:data.id,...bodyData}:{...bodyData})
      dispatch(AddNewStaff(d, isEdit,closeModal, setLoading,updateUser));

    }
  };

  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: 2,
        width: { xs: "100%", sm: "600px" },  // Adjusted width for responsiveness
        margin: "auto", // Center the form
      }}
    >
      <FormLabel>{title}</FormLabel>
      <Grid container spacing={2}>
  
   {
    allowedFields.photo &&      <Grid item sm={2} xs={12}  sx={{
      display:"flex"
    }}>
    <Grid container spacing={2} >
    <Grid item sm={2} xs={12} display={"flex"} alignItems={"center"} justifyContent={"start"}>
    <InputWithAvatar handleChange={handleChange} avatar={avatar}/>
  </Grid>
    </Grid>
    </Grid>
   }
        <Grid item sm={10} xs={12}>
<Grid container spacing={2}>
{
    allowedFields.first_name && 
<Grid item sm={12} xs={12}>
      <TextField
        required
        label="First Name"
        name="first_name"
        value={bodyData.first_name}
        onChange={handleChange}
        multiline
        error={formError["first_name"]}
        helperText={formError["first_name"]}
        fullWidth
        disabled={loading}
      />
      </Grid>
}

{
    allowedFields.last_name && 

      <Grid item sm={12} xs={12}>
      <TextField
        required
        label="Last Name"
        name="last_name"
        value={bodyData.last_name}
        onChange={handleChange}
        multiline
        error={formError["last_name"]}
        helperText={formError["last_name"]}
        fullWidth
        disabled={loading}
      />
      </Grid>
}


</Grid>
        </Grid>

        {
    allowedFields.email && 
      <Grid item sm={12} xs={12}>
      <TextField
        required
        label="Email"
        name="email"
        type="email"
        value={bodyData.email}
        onChange={handleChange}
        multiline
        error={formError["email"]}
        helperText={formError["email"]}
        fullWidth
        disabled={loading || isEdit}
      />
      </Grid>
    
}
{
  !isEdit && <>

{
    allowedFields.password1 && 
    <Grid item sm={6} xs={12}>
    {/* <TextField
      required
      label="Password"
      type="password"
      name="password1"
      value={bodyData.password1}
      onChange={handleChange}
      // multiline
      error={formError["password1"]}
      helperText={formError["password1"]}
      fullWidth
      disabled={loading}
    /> */}
    <CustomPasswordField label={"Password"} name={"password1"} bodyData={bodyData} handleChange={handleChange} formError={formError} loading={loading}/>
    </Grid>
}
     
{
    allowedFields.password2 && 
      <Grid item sm={6} xs={12}>
      {/* <TextField
        required
        label="Confirm Password"
        name="password2"
        type="password"
        value={bodyData.password2}
        onChange={handleChange}
        // multiline
        error={formError["password2"]}
        helperText={formError["password2"]}
        fullWidth
        disabled={loading}
      /> */}
          <CustomPasswordField label={"Confirm Password"} name={"password2"} bodyData={bodyData} handleChange={handleChange} formError={formError} loading={loading}/>
      </Grid>
    
}

  </>
}

{
    allowedFields.hotels && 
    
      <Grid item sm={12} xs={12}>
      <TextField
        required
        label="Hotels"
        name="hotels"
        value={bodyData?.profile?.hotels}
        onChange={handleChange}
        error={formError["hotels"]}
        helperText={formError["hotels"]}
        fullWidth
        disabled={loading || readOnly.hotels}
        select
        SelectProps={{ multiple: true }}
      >
        {names &&
          names.map((i) => (
            <MenuItem key={i.id} value={i.hotel_code}>
              <Checkbox checked={bodyData?.profile?.hotels?.includes(i.hotel_code)} />
              {i.name}
            </MenuItem>
          ))}
      </TextField>
      </Grid>
}

{
    allowedFields.department_id && 
      <Grid item sm={6} xs={12}>
      <TextField
        label="Departments"
        name="department_id"
        value={bodyData?.profile?.department_id}
        onChange={handleChange}
        // error={formError["department"]}
        // helperText={formError["department"]}
        fullWidth
        disabled={loading}
        select
        SelectProps={{ multiple: false }}
      >
        {optionData?.profile?.department ?
         ( optionData?.profile?.department.map((i) => (
            <MenuItem key={i.name} value={i.id}>
              {i.name}
            </MenuItem>
          ))):   <MenuItem disabled={true} value={""}>
          No Data
        </MenuItem>}
      </TextField>
      </Grid>
}

{
    allowedFields.account_type && 
      <Grid item sm={6} xs={12}>
      <TextField
      required
        label="Account Type"
        name="account_type"
        value={bodyData.profile?.account_type}
        onChange={handleChange}
        multiline
        error={formError["account_type"]}
        helperText={formError["account_type"]}
        fullWidth
        disabled={loading || readOnly.account_type}
        select
        SelectProps={{ multiple: false }}
      >
        {optionData?.profile?.account_type ?
         ( optionData?.profile?.account_type.map((i) => (
            <MenuItem key={i.display} value={i.value}>
              {i.display}
            </MenuItem>
          ))):   <MenuItem disabled={true} value={""}>
          No Data
        </MenuItem>}
      </TextField>
      </Grid>   
}

{
    allowedFields.designation && 
    
      <Grid item sm={12} xs={12}>
      <TextField
        label="Designations"
        name="designation"
        value={bodyData.profile?.designation}
        onChange={handleChange}
        multiline
        // error={formError["designation"]}
        // helperText={formError["designation"]}
        fullWidth
        disabled={loading}
      />
            </Grid>
}

{
    allowedFields.hotel_system_user_id && 
    
      <Grid item sm={12} xs={12}>
      <TextField
        label="Internal Ticket system ID"
        name="hotel_system_user_id"
        value={bodyData?.profile?.hotel_system_user_id}
        onChange={handleChange}
        multiline
        // error={formError["hotel_system_user_id"]}
        // helperText={formError["hotel_system_user_id"]}
        fullWidth
        disabled={loading}
      />
            </Grid>
}

{
    allowedFields.change_password && 
    
      <Grid item sm={12} xs={12}>
             <FormControlLabel
               control={
                 <Checkbox
                   name="change_password"
                   onChange={handleChange}
                   value={bodyData.change_password}
                   color="success"
                   defaultChecked={bodyData?.change_password}
                 />
               }
               label="Reset Password On Login"
             />
            </Grid>
}
            {/* <Grid item sm={6} xs={12} justifyContent={"center"} alignItems={"center"}>
  <UploadImageButton handleChange={handleChange} sx={{ marginTop:"10px",}}/>
  </Grid> */}
  </Grid>
      <Box sx={{ display: "flex", columnGap: 3, justifyContent: "center"}}>
      {/* position:"sticky", bottom:"0%",
          left:"50%"  */}
        <CommonButton
          loading={loading}
          onClickHandler={closeModal}
          title={"Cancel"}
        />
        {loading ? (
          <Stack sx={{ color: "grey.500",    position: "sticky",
          bottom: "0%",
          left: "50%" }} spacing={2} direction="row">
            <CircularProgress color="success" />{" "}
          </Stack>
        ) : (
          <CommonButton
            onClickHandler={handleSubmit}
            title={"Submit"}
            loading={loading}
          />
        )}
      </Box>
    </FormControl>
  );
};

export default AddStaffForm;
