import React, { useState, Fragment } from 'react';
import { url } from '../Constants/Constants';
import BaseAPICompnenetWithLoader from '../APIComponents/BaseAPIComponent/BaseAPICompnenetWithLoader';
import backdrop from "../Backdrop/Backdrop";
import background from './assets/background.jpg';
import logo from "./assets/logo.png";
import camera from "./assets/camera.svg";
import { Divider } from '@mui/material';
import { makeStyles,createTheme } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

const useStyle = makeStyles( () => ({
    container:{
        backgroundImage: props => `url(${props.brandPhoto})`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        minHeight: '8.5rem',
        paddingTop: '8rem',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end'
    },
    subContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '3rem',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.6)',
        padding: '0.4rem 1rem 0.4rem 0rem'
    },
    hotelInfoContainer:{
        borderLeft: '2px solid #283953',
        marginLeft: '1rem',
        paddingLeft: '1rem',
        height: '50%',
        width: '80%',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem'
    },
    hotelName:{
        fontFamily: 'Nunito',
        fontWeight: 'bolder',
        fontSize: 14,
        color: '#283953'
    },
    hotelAddress:{
        fontFamily: 'Nunito',
        fontWeight: 500,
        fontSize: 10,
        color: '#283953',
        margin: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    cameraContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        background: 'rgba(255, 255, 255, 0.8)',
        border: '0.5px solid #9AB3DA',
        borderRadius: '50px',
        margin: '0rem 0rem 1rem 0rem'
    }
}))

const BrandContainer = props => {
    const classes = useStyle(props)
    console.log('props.brandPhoto',props.brandName)
    return (
        <div className='SidenavHeadingContainer'>
                <Fragment>
                    <div style={{ display: 'none' }} className='SidenavImageBackdrop'></div>
                    <div className='SidenavHeadingContent'>
                        {props.brandName && <h2 className="SideNav_header">{props.brandName}</h2>}
                        <Divider />
                        {/* <div className="logo_sidenav_container">
                            <img src={logo} alt="" className="SideNav_logo" />
                        </div> */}
                        <div className={classes.container} >
                            <div className={classes.subContainer} >
                                <div className={classes.hotelInfoContainer}>
                                    <h5 className={classes.hotelName}>{props.selectedHotel && props.selectedHotel.name}</h5>
                                    <p className={classes.hotelAddress}>{props.selectedHotel && props.selectedHotel.address}</p>
                                </div> 
                                <span className={classes.cameraContainer}>
                                    <NavLink
                                        to= '/account-management?tab=personalization'
                                        style={{margin: 0}}
                                        >  
                                        <img src={camera}/>
                                        
                                    </NavLink>
                                </span>
                            </div>
                        </div>
                    </div>
                </Fragment>
        </div>
    );
}

const mapStateToProps = state => {
    return{
        brandColor: state.gen.brandColor,
        bgColor:state.gen.brandBgColor,
        brandPhoto: state.gen.brandPhoto,
        brandLogo: state.gen.brandLogo,
        brandName: state.gen.brandName,
        brandId: state.gen.brandId,
        selectedHotel: state.filter?.selectedHotel || {}
    };
}

export default connect(mapStateToProps, {})(BrandContainer);