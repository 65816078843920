import React from 'react'
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import './swipe.css';

import { Navigation } from 'swiper/modules';

const SwipeImageForTable = (props) => {

    return (
        <>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper1">
          {props.list}
          </Swiper>
        </>
      );
}

export default SwipeImageForTable
