import React, { useEffect, useState } from 'react';
import { Button as MuiButton, Box } from '@mui/material';
// import css from '../QuestionRenderer.module.css';

const Button = (props) => {
    const [options, setOptions] = useState([]);
    const [checkedIdx, setCheckedIdx] = useState(null);

    useEffect(() => {
        setOptions(props.question.options);

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && checkedIdx == null) {
            let _op_idx = props.question.options.findIndex(
                (o) => String(o.value) === String(props.response)
            );
            if (props.debug) console.log(`Option found: ${_op_idx}`);

            if (_op_idx !== -1) setCheckedIdx(_op_idx);
        }
    }, [props.question, props.response]);

    const clickHandler = (e, index) => {
        setCheckedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
                gap: 2,
                marginTop: 2,
            }}
        >
            {options.map((option, i) => (
                <MuiButton
                    key={i}
                    variant={checkedIdx === i ? 'contained' : 'outlined'}
                    color={checkedIdx === i ? 'primary' : 'inherit'}
                    onClick={(e) => clickHandler(e, i)}
                    sx={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontWeight: checkedIdx === i ? 'bold' : 'normal',
                        border: checkedIdx === i ? '2px solid green' : '1px solid',
                    }}
                >
                    {option.value}
                </MuiButton>
            ))}
        </Box>
    );
};

export default Button;
