import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
// import css from '../QuestionRenderer.module.css';

const TextArea = (props) => {
    const [txt, setTxt] = useState('');

    useEffect(() => {
        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && txt === '') {
            setTxt(props.response);
        }
    }, [props.question, props.response]);

    const onChangeHandler = (e) => {
        setTxt(e.target.value);
        if (props.saveResponse) props.saveResponse(e.target.value);
    };

    return (
        <Box sx={{ margin: '20px 0' }}>
            <TextField
                multiline
                minRows={4}
                maxRows={6}
                fullWidth
                value={txt}
                onChange={onChangeHandler}
                placeholder="Type your response here..."
                variant="outlined"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#f9f9f9',
                    },
                }}
            />
        </Box>
    );
};

export default TextArea;
