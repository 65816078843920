import { Box, Button, Modal, Typography } from "@mui/material";

function CustomModal({ title, show, handleClose, handleClick, loading }) {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="custom-confirm-modal"
      aria-describedby="custom-confirm-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "400px" },
          backgroundColor: "white",
          borderRadius: "10px",
          border: "1px solid #c4c4c4",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px",
          padding: "10px",
        }}
      >
        <Typography sx={{ fontSize: "large" }}>{title}</Typography>
        <Box sx={{ alignSelf: "flex-end", display: "flex", gap: "10px" }}>
          <Button
            onClick={handleClick}
            variant="contained"
            color="success"
            disabled={loading}
          >
            Confirm
          </Button>
          <Button onClick={handleClose} variant="outlined" color="error">
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomModal;
