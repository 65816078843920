import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Stack, 
  Typography 
} from '@mui/material';

const Scale = (props) => {
  const [question, setQuestion] = useState({});
  const [options, setOptions] = useState([
    {type:"Number", value: 1, sort_order:1},
    {type:"Number", value: 2, sort_order:2},
    {type:"Number", value: 3, sort_order:3},
    {type:"Number", value: 4, sort_order:4},
    {type:"Number", value: 5, sort_order:5},
    {type:"Number", value: 6, sort_order:6},
    {type:"Number", value: 7, sort_order:7},
    {type:"Number", value: 8, sort_order:8},
    {type:"Number", value: 9, sort_order:9},
    {type:"Number", value: 10, sort_order:10}
  ]);

  useEffect(() => {
    console.log(props);
    setQuestion(props.question);

    // Check if options exist in the question prop and set them if not initialized
    if (props.question.options) {
      setOptions(JSON.parse(JSON.stringify(props.question.options)));
    }

    if (props.debug) {
      console.log(`Question received: ${JSON.stringify(props.question)}`);
    }
  }, [props.question]);

  const handleOptionSelect = (obj) => {
    if (props.updateInParent) {
      props.updateInParent([obj], "options");
    }
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Stack 
        direction="row" 
        spacing={1} 
        sx={{ 
          width: '100%', 
          justifyContent: 'start', 
          alignItems: 'center' 
        }}
        paddingY={2}
      >
        {options?.map((obj) => (
          <Button
            key={obj.sort_order}
            variant="outlined"
            color="primary"
            onClick={() => handleOptionSelect(obj)}
            sx={{ 
              minWidth: '40px', 
              height: '40px', 
              borderRadius: '50%' 
            }}
          >
            {obj.value}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default Scale;