import React, { Fragment, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import SliderComp from './Slider';
import MultiOptions from './multiOptions';
import Textbox from './TextBox';
import AnswerTypeOptions from './questionTypeOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DependentQuesSection from './dependentQuesSection';

import css from './QuestionType.module.css';

import Scale from './Scale';

const AnswerTypes = props => {

    const [answerType, setAnswerType] = useState(null);
    

    const answerTypeHandler = (type) => {
        // props.setAnswerType(type);
        let _quest = {'display': type};
        if (props.onQuestTypeSelection) props.onQuestTypeSelection(_quest);
        // setAnswerType(type);

    }

    const cancelAddingDepdtQues = () => {
        props.setConditionalQues(false);
        props.setOptionToDepend(null);
        props.setDependentQues(null);
        props.setDependentQuesDisplay(null)
        props.setDependent_Ques_Options([]);
    }

    const answerTypeCompHandler = (type,setOptions,conditionalQues=false) => {
        switch(type){
            case 'Slider':
                return <SliderComp setOptions = {setOptions} conditionalQues={conditionalQues}/>;
            case 'Checkbox':
                return <MultiOptions editable={true} input="checkbox" setOptions = {setOptions} conditionalQues={conditionalQues}/>;
            case 'Radio Button':
                return <MultiOptions editable={true} input="radio" setOptions = {setOptions} conditionalQues={conditionalQues}/>;
            case 'Text Box':
                return <Textbox  />;
            case 'Buttons':
                return <MultiOptions editable={true} setOptions = {setOptions} conditionalQues={conditionalQues}/>;
            case 'Scale':
                return <Scale setOptions = {setOptions} />;
        }
    }



    return(
        <Fragment>
            {answerType ? (<Fragment>
                {answerTypeCompHandler(answerType,props.setOptions,props.conditionalQues)}
                {!props.conditionalQues ? 
                <Fragment>
               
                    {/* <div className={css.dependent_btn_cont} onClick = {() => {props.setConditionalQues(true);}}>
                        <FontAwesomeIcon icon = {faPlus}/>
                        <label>Add a dependent question on selection of a particular choice</label>
                    </div> */}
                </Fragment>  
                
                : <DependentQuesSection cancelAddingDepdtQues = {cancelAddingDepdtQues} setDependentQues = {props.setDependentQues}
                answerTypeCompHandler = {answerTypeCompHandler} options = {props.options} 
                setDependentQuesDisplay = {props.setDependentQuesDisplay} 
                setOptionToDepend = {props.setOptionToDepend} dependentQuesDisplay = {props.dependentQuesDisplay}
                setDependent_Ques_Options = {props.setDependent_Ques_Options}/>}
                </Fragment>)
            :
      
             <AnswerTypeOptions answerTypeHandler={answerTypeHandler}/>
            }
        </Fragment>
    );
}

export default AnswerTypes;