import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import { createTheme } from '@mui/material';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import './header2.css';
import HotelList from '../APIComponents/HotelList/HotelList'
import Profile from '../Header/Profile';
import Notifications from "../Header/Notifications";
import tourIcon from '../Header/assets/tour_icon.png';
import MenuIcon from '@mui/icons-material/Menu';
import FilterComponent from '../UI/FilterComponent';
import { useSelector, useDispatch } from 'react-redux';
import profileImg from "../Header/assets/profile_image.svg";
import { setToInitial } from '../../actions/loginActions';
import { Link, Redirect } from "react-router-dom";
import myprofile from "../Header/assets/myprofile.png";
import subscription from "../Header/assets/subscription.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import changePass from '../Header/assets/Group 927.png';
import logout from "../Header/assets/logout.svg";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '50px',
    backgroundColor: '#fff',
    marginBottom: '25px',
    width: '100%',
    zIndex: '4',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuIcon: {
    color: '#000',
    [theme.breakpoints.up(900)]: {
      display: 'none !important',
    },
  },
}));

export default function PrimaryAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setToInitial());
    setRedirect("/login");
  };
 

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <div></div>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem >
      <Link
            to="/my-profile"
            style={{
              textDecoration: "none",
              color: "#324047",
              margin: "0px",
            }}
          >
            <p>
              <img src={myprofile} alt="" className="image_dropdown" /> My profile
            </p>
          </Link>
      </MenuItem>
      <MenuItem>
      <Link
            to={`/account-management?tab=subscription`}
            style={{
              textDecoration: "none",
              color: "#324047",
              margin: "0px",
            }}
          >
            <p className="subscriptions_dropdown">
              <img src={subscription} alt="" className="image_dropdown" />{" "}
              Subscriptions
            </p>
          </Link>
      </MenuItem>
      <MenuItem>
      <Link
            to="/faq"
            style={{
              textDecoration: "none",
              color: "#324047",
              margin: "0px",
            }}
          >
            <p className="support_dropdown">
              <FontAwesomeIcon icon={faHeadset} className="support_dropdown_icon" />
              Support
            </p>
          </Link>
      </MenuItem>
      <MenuItem>
      <Link
            to="/change-password"
            style={{
              textDecoration: "none",
              color: "#324047",
              margin: "0px",
            }}
          >
            <p className="support_dropdown">
              <img src={changePass} style={{ width: "18px", marginRight: "10px" }} />
              Change Password
            </p>
          </Link>
      </MenuItem>
      <MenuItem>
      <div className="dropdown_logout">
          <p onClick={handleLogout}>
            <img src={logout} alt="" className="image_dropdown" />
            Log out
          </p>
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      {redirect && <Redirect to={redirect} />}
      <AppBar className={classes.root} position="relative">
        <Toolbar>
          <MenuIcon className={classes.menuIcon} onClick={props.menuButtonClick} />
          {!props.hide_hotel_list && (
            <span className="hotel_list">
              <HotelList />
            </span>
          )}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <span style={{ marginTop: '5px', marginRight: '15px' }}>
              {props.showTour && (
                <img
                  src={tourIcon}
                  style={{ height: '30px', width: '30px' }}
                  className="tour_icon"
                  onClick={() => props.setShowTour(true)}
                  alt=""
                />
              )}
            </span>
            {/* <Notifications head={props.head} /> */}
            {/* <Profile setShowTour={props.setShowTour} head={props.head} /> */}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="header_title" style={{ color: `${props.color}` }}>
          {props.head}
        </span>
        {!props.hide_filters ? (
          <FilterComponent
            sentiment={props.sentiment}
            show_filter_topics={props.show_filter_topics}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}