import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { logout_unauthorized_user } from '../../../components/Utility/Utility';
import { useDispatch } from 'react-redux';
import { setToInitial } from '../../../actions/loginActions';

export const ShowLogoutWarning = () => {
  const [open, setOpen] = useState(true);
  const [autoLogoutTimer, setAutoLogoutTimer] = useState(null);
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
        dispatch(setToInitial());
    //   logout_unauthorized_user();
    }, 5000);
    setAutoLogoutTimer(timer);

    return () => clearTimeout(timer); 
  }, []);

  const handleClose = () => {
    setOpen(false);
    clearTimeout(autoLogoutTimer);
    dispatch(setToInitial());
    // logout_unauthorized_user();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{"Logged Out"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
        <Typography>
        It seems you have been logged out. You are being redirected to the login screen now.
</Typography> 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
