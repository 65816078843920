import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import { EditGuestReqSuccess } from "../../../apps/Chat/actions/chatActions";

const CustomAlert = ({ type, message,position={ vertical: "top", horizontal: "center"},style={},autoclose=true }) => {
  const [open, setOpen] = useState(false);
const dispatch = useDispatch()
  useEffect(() => {
    if (
      message &&
      message.length > 0 &&
      message != "--" &&
      message != "No chat messages found"
    )
      setOpen(true);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    dispatch(setGlobalMessage({
      mess:"",
      type:""
    }))
   dispatch( EditGuestReqSuccess(""))
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoclose?5000:null}
      anchorOrigin={position}
      onClose={handleClose}
    >
 {
    message &&  <Alert onClose={handleClose} severity={type}  variant="filled" sx={style}>
    {message}
  </Alert>
 }
    </Snackbar>
  );
};

export default CustomAlert;
