import React, { Component } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import Sidenav from '../../components/SideNav/SideNav';
import ComponentTabs from '../../components/ComponentTabs/ComponentTabs';
import SurveyAnalytics from './pages/SurveyAnalytics/SurveyAnalytics';
import ManageSurvey from './pages/ManageSurvey/ManageSurvey';
import { connect } from 'react-redux';

import css from './Survey.module.css';
import { get_query_param } from '../../components/Utility/Utility';

class Survey extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: get_query_param('tab') || 'Managesurvey',
            date_params: null,
            filter_params: null,
            hotels: [],
            selectedHotel: {},
            filters: [],
            creatingSurvey: false
        }
        
        this.setHotelAPIData = this.setHotelAPIData.bind(this);
        this.setSelectedHotel = this.setSelectedHotel.bind(this);
        this.setDatesAsQueryParams = this.setDatesAsQueryParams.bind(this);
        this.setFiltersAsQueryParams = this.setFiltersAsQueryParams.bind(this);
        this.setSelectedTab = this.setSelectedTab.bind(this);
        this.setCreatingSurvey = this.setCreatingSurvey.bind(this);

    }

     setHotelAPIData = (data) => {
        this.setState({hotels: data})
      }
    
      setSelectedHotel = (hotel) => {
        this.setState({selectedHotel: hotel})
      }
    
      setDatesAsQueryParams = (params) => {
       this.setState({date_params: params});
     }
    
     setFiltersAsQueryParams = (params) => {
       this.setState({filter_params: params});
     }

     setCreatingSurvey = (creating) => {
      this.setState({creatingSurvey: creating});
    }

     setSelectedTab = (value) => {
         this.setState({selectedTab: value});
        //  this.props.history.push(`/survey/${value}`)
     }

    render() {
        return(
            <div className = {css.MainContainer} style = {{backgroundColor:this.props.brandBgColor}}>
                {/* <Hamburger /> */}
                <Sidenav 
                  hide_filters={true}
                  head = "Survey - Guest Experience"
                  setHotelAPIData={this.setHotelAPIData}
                  setSelectedHotel={this.setSelectedHotel}
                  selectedMenu='Survey'
                />
                <div className = {css.ContentContainer} >
                  {/* <div className={css.header}>
                    <Header 
                        head = "Survey - Guest Experience"
                        setHotelAPIData={this.setHotelAPIData}
                        setSelectedHotel={this.setSelectedHotel}
                    />
                    </div> */}
                    {!this.state.creatingSurvey &&
                    <ComponentTabs tabs = {['Manage survey', 'Analytics',]} tabChangeHandler={this.setSelectedTab} usePathTabHandler={false} />}
                    {this.state.selectedTab === 'Analytics' ? <SurveyAnalytics selectedHotel={this.state.selectedHotel}/>
                    :this.state.selectedTab === 'Managesurvey' ? <ManageSurvey setCreatingSurvey={(creating) => this.setCreatingSurvey(creating)} selectedHotel={this.state.selectedHotel}/>: ''}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
      token: state.auth.token,
      brandColor: state.gen.brandColor,
      runTour: state.gen.startTour,
      brandBgColor:state.gen.brandBgColor
    };
  };

export default connect(mapStateToProps)(withRouter(Survey));