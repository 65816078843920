import React, { useState,useEffect, Fragment } from 'react';
import { url } from '../../../components/Constants/Constants';
import BaseAPIComponentNoLoader from '../../../components/APIComponents/BaseAPIComponent/BaseAPIComponentNoLoading';
import MultipleCheckboxDropdown from '../../../components/UI/dropdown/multipleCheckboxDropdown';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import {add_auth_header, deepCopy} from '../../../components/Utility/Utility';
import { useForm } from 'react-hook-form';
import { setCreateSurvey, getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../actions/surveyActions';


const HotelList = props => {

    const [api_data, setAPIData] = useState([]);
    const [surveyHotels, setSurveyHotels] = useState([]);

    const { control,setValue,register,formState:{errors},getValues } = useForm();

    useEffect(() => {
            
        // Subscribed hotels. When this is tru api_data is not used.
       if(props.hotels)
        setAPIData(deepCopy(props.hotels) || []);
        // This is hotels attached to the survey. Keep it empty for create new survey.
        setSurveyHotels(props.surveyHotels || []);
    }, [props.hotels, props.surveyHotels])


    const component = (
        api_data.length > 0 &&
        <MultipleCheckboxDropdown displayLabel="Hotels"
                                    label="hotel_ids" 
                                    control={control} 
                                    setValue={setValue}
                                    allObjects={api_data.map(hotel => { return {'label':hotel.name, 'id': hotel.id}})}
                                    selectedObjects={surveyHotels.map(hotel => { return {'label':hotel.name, 'id': hotel.id}})}
                                    errors={errors}
                                    onChange={props.onSelectionChange}
                                    minWidth={180}
                                    maxWidth={20}
                                    debug={false}
                                />)

    return (
        <div style={{marginLeft:"auto"}}>
            {!props.hotels 
            ? <BaseAPIComponentNoLoader
                component = {component}
                data_url={`${url}/services/hotels/?subscription=true&competitor=false`}
                setAPIData = {setAPIData}
                api_dependency  = {[true]}
                not_found_msg = {'Hotels not found.'}
            />
            : component
        }
        </div>
    );
}

const mapDispatchToProps = dispatch => {
  return{
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      editSurvey: bindActionCreators(editSurvey, dispatch)
      };
  };
  
  export default connect(null,mapDispatchToProps)(HotelList);