import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import css from '../QuestionRenderer.module.css';

const SliderComp = props => {

    const [selectedIdx, setSelectedIdx] = useState(null);
    const [options, setOptions] = useState([]);
    const [max, setMax] = useState(null);

    const markStyle = val => <span style = {{top: '10px', left: "0px", position: 'absolute', fontSize: '15px', fontFamily: 'Lato'}}>{options[val].value}</span>;

    useEffect(() => {
        setMax(props.question.options.length-1)
        setOptions(props.question.options)

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && selectedIdx == null){
            let _op_idx = props.question.options.findIndex(o => String(o.value) == String(props.response));
            if (props.debug) console.log(`Option found: ${_op_idx}`);
            
            if (_op_idx != -1) setSelectedIdx(_op_idx);
        }

    }, [props.question, props.response])

    const getMarkStyle = () => {
        let m = {}
        for(let i=0; i<options.length; i++){
            m[i] = markStyle(i);
        }
        return m;
    }

    const sliderHandler = (index) => {
        setSelectedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value)
     }

    return(
        <div className={css.NormalSlider}>
        <Slider
            min={0}
            max={max}
            step={1}
            trackStyle={[
                {
                    height: '6px',
                    borderRadius: '3px',
                    background: '#E4E6E7',
                },
            ]}
            railStyle={{
                height: '6px',
                borderRadius: '3px',
                background: '#e0e0e0',
            }}
            marks={getMarkStyle()}
            dotStyle={{
                display: 'none',
            }}
            value={selectedIdx}
            onChange={sliderHandler}
            handleStyle={[
                {
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    border: '2px solid #4472C4',
                    background: '#fff',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    transform: 'translateY(-50%)',
                    top:"12px"
                },
            ]}
        />
    </div>
    
    );
}

export default SliderComp;