import React, { useEffect, useState } from 'react';

import css from '../QuestionRenderer.module.css';

const Checkbox = props => {

    const [options, setOptions] = useState([]);
    const [checkedIdxs, setCheckedIdxs] = useState([]);
    const [showOtherInput,setShowOtherInput] = useState(false);

    const checkboxHandler = (e,index) => {
        
        let contains = checkedIdxs.includes(index)
        
        let newIdxs = [...checkedIdxs];
        if (contains ==true){
            newIdxs = newIdxs.filter(i => i != index);
        } else {
            newIdxs.push(index);
        }
        setCheckedIdxs(newIdxs);
        console.log("props.question.options",options, newIdxs)
        newIdxs.map(i => console.log("options[i]",options[i]))
        if (props.saveResponse) props.saveResponse(newIdxs.map(i => options[i].value).join(","));
    }

    useEffect(() => {
        setOptions(props.question.options);

        console.log("props.question.options",props.question.options)

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && Object.keys(props.response).length > 0 && props.question && checkedIdxs.length == 0){
            const resp_values = props.response.split(",");
            let _selectedIdxs = []
            for (let i=0; i<props.question.options.length; i++){
                if (resp_values.includes(String(props.question.options[i].value)))
                    _selectedIdxs.push(i);
            }
            if (props.debug) console.log(`Options found: ${JSON.stringify(_selectedIdxs)}`);
            
            if (_selectedIdxs.length > 0) setCheckedIdxs(_selectedIdxs);
        }
        
    },[props.question, props.response]);


    return(
        <div className = {css.CheckboxContainer}>
            {options.map((option, i) => (
                <div key = {i} className = {css.Checkboxes}>
                     <input type = 'checkbox' name = {option.value} checked = {checkedIdxs.includes(i)}
                    onChange = {(e) => checkboxHandler(e, i)} />
                    <label htmlFor = {option.value}>{option.value}</label>
                </div>
            ))}
            {showOtherInput && <div className = {css.OtherInputContainer}>
                <input type = "text" placeholder = "Other: how did you hear about us?"/>
                <button>Done</button>
            </div>}
        </div>
    );
}

export default Checkbox;