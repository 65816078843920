import React from 'react'
import {
    Box,
    Modal
  } from "@mui/material";
import { Close } from '@material-ui/icons';

const CommonModalForForms = ({show,toggleNewTaskModal,FormUi}) => {
  return (
    <Modal
    open={show}
    onClose={toggleNewTaskModal}
    aria-labelledby="add-modal"
    aria-describedby="add-modal"
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: 'center',
    }}
  >
   
    <Box
      sx={{
        position: "relative",
        backgroundColor: "white",
        borderRadius: "10px",
        maxWidth: "90%",
        maxHeight: "90%",
        overflowY: "auto",
        border: "1px solid #c4c4c4",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        padding: "20px",
      }}
    >
  
        {FormUi &&
        React.cloneElement(FormUi, {})}
            <Close onClick={toggleNewTaskModal} style={{
  fontSize:"0.9rem",
  cursor:"pointer",
  position:"absolute",
  top:"10px",
  right:"10px",
  zIndex:"999999",
  color:"gray",
}}/>
    </Box>
  </Modal>
  )
}

export default CommonModalForForms
