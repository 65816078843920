import React, { useEffect } from "react";

const FilePreview = (props) => {
  const { id, filename, fileimage, caption } =
    props.data;

  const handleCaptionChange = (e) => {
    const caption = e.target.value;
    props.setSelectedFile((prev) =>
      prev.map((file) => (file.id == id ? { ...file, caption } : file))
    );
  };

  return (
    <div className="file-atc-box" key={id}>
      {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
        <div className="file-image">
          {" "}
          <img src={fileimage} alt="" />
        </div>
      ) : (
        <div className="file-image">
          <i className="far fa-file-alt"></i>
        </div>
      )}
      <div className="file-detail">
        <h6>{filename}</h6>
{
 props.showCaption && <>
          <label htmlFor="caption">Caption: </label>
        <input
          id="caption"
          className="caption_input"
          placeholder="Enter caption..."
          value={caption}
          onChange={(e) => handleCaptionChange(e)}
        />
  </>
}
        <div className="file-actions"
        >
          <button
            type="button"
            className="file-action-btn"
            onClick={() => props.deleteSelectedFile(id)}
          >
            Delete
          </button>
          <button
            type="button"
            className="file-action-btn"
            onClick={() => props.setCropImage(props.data)}
          >
            Crop Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
