import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {Box
  } from "@mui/material";
import StaffList from './StaffList';
import { useHistory } from "react-router-dom";
const StaffListPopup = ({reviewId}) => {
    const history = useHistory()
    const selectedHotel = useSelector(
        (state) => state.filter.selectedHotel || {}
      );
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const handleOperation = (e, data) => {
        e.stopPropagation();
        if(!data.id && data.conversation_id){
            history.push(`/team-communication?conversation_id=${data.conversation_id}&review_id=${reviewId}&hotel=${selectedHotel.hotel_code}`)
        }else if(data.id){
            history.push(`/team-communication?id=${data.id}&review_id=${reviewId}&hotel=${selectedHotel.hotel_code}`)
        }
        handleClose()
    };

  return (
   <Box>
   
   <Button  aria-describedby={id} onClick={handleClick} sx={{padding:0,color:"black",minWidth:"25px",marginBottom:"2px"
   }} >
   <PersonAddAltIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
      >
        <StaffList  handleClick={handleOperation}/>
      </Popover>
   </Box>
  )
}

export default StaffListPopup
