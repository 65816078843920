import React, {useEffect, useState, Fragment} from 'react';
import { add_auth_header } from "../../../../../components/Utility/Utility";
import ItemAddButton from '../../../../../components/UI/buttons/itemAddButton';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import ConfirmationDialog from '../../../../../components/UI/confirmationDialog';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AddCircle, Delete,FileCopyRounded} from '@material-ui/icons';
import { getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../../../actions/surveyActions';

import css from './QuestionRenderer.module.css';
import { LoadingButton } from '@mui/lab';
import CommonModalForForms from '../../../../../commons/components/Modals/CommonModalForForms';
import GenericForm from '../../../../../commons/components/UI/GenericForm';


const useStyles = makeStyles({
    root: {
        '&:focus': {
            outline: "none"
        }
    }
});

const initialState = {
    name: "",
  };

  const fields = [
    { name: "name", label: "Section Name", required: true,type:"text" },
  ];

const NewSection = props => {

    const [surveyId, setSurveyId] = useState(null);
    const [currentSections, setCurrentSections] = useState([]);
    const [addSectionPopup,setAddSectionPopup] = useState({show:false,name:"",questions:[]});

    const classes = useStyles();

    // Render Icon or Button
    const [renderIcon, setRenderIcon] = useState(false);
    const [renderButton, setRenderButton] = useState(false);

    useEffect(() => {
        setSurveyId(props.surveyId);
        setCurrentSections(props.currentSections);
        setRenderIcon(props.renderIcon);
        setRenderButton(props.renderButton);
    }, [props.key, props.surveyId, props.renderButton, props.renderIcon])
    
    const addNewSection = (addSectionPopup,setLoading) => {
        const name=addSectionPopup.name,questions=addSectionPopup.questions || [];
        
        props.createNewSection(props.add_auth_header({}),surveyId,name,questions,(status, data) => {
            if(status){
                if (props.handleSectionAddToSurvey)
                    props.handleSectionAddToSurvey(data);
                else
                    console.warn('No handleSectionAddToSurvey prop is provided, so unable to re render the UI.')
            setAddSectionPopup({show:false,name:"",questions:[]})
            } else if(props.debug){
                console.log(`Error while creating section. ${data}. status: ${status}`)
            }
            if(setLoading)setLoading(false)
        })
    };
    
    return (<Fragment>
            {renderButton 
            
                ? <Fragment>
                        <div className={css.addNewSecCont}>
                            {/* <ItemAddButton clickHandler = {() => setAddSectionPopup({show:true,name:"",questions:[]})} width={220} height={40} fontSize={18} buttonTitle="Add new section"/> */}
                            <LoadingButton
        loading={0}
        loadingPosition="end"
        startIcon={<AddCircle/>}
        variant="contained"
        sx={{
            backgroundColor: "gray",
            "&:hover": {
              backgroundColor: "darkgray",
            },
            width:"220px",
                 padding : "12px 16px"
          }}
          onClick={(e) =>{
                e.stopPropagation()
                setAddSectionPopup({show:true,name:"",questions:[]})
          } }
      >
       <span>
       Add new section
       </span>
      </LoadingButton>
                        </div>

                        <CommonModalForForms
          show={addSectionPopup.show}
          toggleNewTaskModal={() => setAddSectionPopup({show:false,name:"",questions:[]})}
          FormUi={
            <GenericForm
              closeModal={() => setAddSectionPopup({show:false,name:"",questions:[]})}
              isEdit={false}
              onSubmit={addNewSection}
              title={"Add New Section"}
              initialState={initialState}
              fields={fields}
            />
          }
        />

                        {/* {addSectionPopup.show &&
                        <ConfirmationDialog dialogTitle="Add new Section" handleButton1={addNewSection} button1="Add" 
                        crossHandler={() => setAddSectionPopup({show:false,name:"",questions:[]})} handleButton2={() => setAddSectionPopup({show:false,name:"",questions:[]})} open={true} 
                        component={
                            <div style={{width:'50%'}}>
                                <input className={css.AddSectionInput} placeholder="Section Name" onChange={(e) => setAddSectionPopup({show:true,name:e.target.value,questions:addSectionPopup.questions})}/>
                            </div>}/>
                        } */}
                    </Fragment>
                : renderIcon ? <Fragment>
                    {/* This is for Section copy button. Not being used now, better shift it to another componenet later and re-implement */}
                    <IconButton className={classes.root} size="small" onClick={() => setAddSectionPopup({show:true,name: "",questions:[]})}>
                            <FileCopyRounded style={{color:"white",fontSize:"1.3em"}}/>
                        </IconButton>
                </Fragment>
                : <div></div>
            }
            
        </Fragment>)

}

const mapStateToProps = state => {return{}}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        editSurvey: bindActionCreators(editSurvey, dispatch),
        createNewSection: bindActionCreators(createNewSection, dispatch)
        };
    };

export default connect(mapStateToProps,mapDispatchToProps)(NewSection);