import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import {
  Typography,
  Button,
  MenuItem,
  Menu,
  IconButton,
  useMediaQuery,
  Box
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Add, ChevronLeft } from "@material-ui/icons";
import { Logout, Settings } from "@mui/icons-material";

import { setSelectedSideNav, updateUserData } from "./actions/actions";
import { logout } from "../../../actions/loginActions";

import logo from "../../assets/innspire_logo_one.svg";
import defaultNavLogo from "./assets/iconNav.svg";
import Notification from "../UI/Notification";
import tvIcon from "./assets/IconTV.svg";
import mobIcon from "./assets/IconMobile.svg";
import CustomModal from "../Modals/CustomModal";
import { stringAvatar } from "../../utility";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import overviewIcon from "./assets/iconOverview.svg";
import DialogBox from "../PopUp/DialogBox";
import SyncLockIcon from '@mui/icons-material/SyncLock';
import ChangePasswordForm from "./components/ChangePasswordForm";
import CommonModalForForms from "../Modals/CommonModalForForms";
import AddStaffForm from "../Settings/components/AddStaffForm";
import EditIcon from '@mui/icons-material/Edit';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import GenericForm from "../UI/GenericForm";
// import { version } from "../../../../package.json";

const drawerWidth = { xs: 240, lg: 300 }; //280;

const useStyles = makeStyles({
  menuroot: {
    "&.MuiList-padding": {
      padding: 0,
    },
  },
});

const fields = [
  { name: "receive_notifications", label: "Receive notifications", required: false,type:"switch" },
  { name: "send_msg_on_enter_key_press", label: "Send message on enter key press", required: false,type:"switch" },
  { name: "review_reply_post_dont_ask_again", label: "Don't ask for confirmation, while posting review reply", required: false,type:"switch" },
];


const allowedFields = {
  first_name: true,
  last_name: true,
  email: true,
  password1: true,
  password2: true,
  hotels: true,
  department_id: true,
  account_type: true,
  designation: true,
  photo:true
}

const SideNavBar = (props) => {
  const variant = useMediaQuery((theme) => theme.breakpoints.down("sm"))
    ? "temporary"
    : "permanent";
  const dispatch = useDispatch();
  const location = useLocation();
  const LinkStyle = { color: 'inherit', textDecoration:'none'}
  // const version = process.env.REACT_APP_VERSION_INFO;

  const navs = useSelector((state) => state.nav.sideNavLinks || []);
  const user = useSelector((state) => state.auth.user || {});
  const selectedNav = useSelector((state) => state.nav.selectedNav || {});
  const passwordResetRequired = useSelector((state) => state?.auth?.user?.change_password || false);
  const new_user_notification = useSelector(
    (state) => state.chat.new_user_notification
  );
  const initialState = {
    ...(user?.profile?.settings?.preferences || {})
  }
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [userPreference, setUserPreference] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showEditProfileForm, setShowEditProfileForm] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const togglePasswordForm = () => {
    handleClose()
    setShowPasswordForm((prev) => !prev);
  };

  const toggleEditProfileForm = () => {
    handleClose()
    setShowEditProfileForm((prev) => !prev);
  };
  useEffect(() => {
    setDrawerOpen(props.open);
  }, [props.open]);

  // handle drawer open
  const handleDrawerToggle = (value) => {
    if (props.handleOpen) props.handleOpen(value);
  };

  const handleNavClick = (e, navObj) => {
    dispatch(setSelectedSideNav(navObj));
    //TODO: When the new sidenav is used in all screens remove below line
    // setRedirectTo(navObj.frontend_route);
  };

  const classes = useStyles();


  const   submitUserPref = (data,setLoading,closeModal)=>{
    let body = {
      "profile":{
        "settings_to_update":{
          "preferences":data
        }
      }
    }
    dispatch(updateUserData(body,setLoading,closeModal))
  }

  useEffect(() => {
    if (
      navs.length > 0 &&
      (!selectedNav ||
        selectedNav.frontend_route != location.pathname.toLowerCase())
    ) {
      var nav_obj = navs.find(
        (_n) => _n.frontend_route == location.pathname.toLowerCase()
      );
      dispatch(setSelectedSideNav(nav_obj));
    }
  }, [navs]);

  const additionalNavs = [
    {
      id: 1,
      name: "TV-system",
      frontend_route: "/tv-system",
      sort_order: 1,
      icon: tvIcon,
    },
    {
      id: 2,
      name: "Hotel App",
      frontend_route: "/hotel-app",
      sort_order: 2,
      icon: mobIcon,
    },
    {
      id: 3,
      name: "CMS",
      frontend_route: "",
      sort_order: 2,
      icon: overviewIcon,
    },
  ];

  useEffect(()=>{
    if(passwordResetRequired)
    setShowPasswordForm(true)
  },[passwordResetRequired])

  //handle logout
  const handleToggleLogout = () => {
    setLogoutModal((prev) => !prev);
  };

  const handleConfirmLogout = () => {
    handleToggleLogout();
    dispatch(logout());
  };

  //TODO: When the new sidenav is used in all screens uncomment below lines
  // useEffect(() => {
  //   console.log(
  //     `pathname: ${location.pathname} navroute: ${selectedNav.frontend_route}`
  //   );
  //   if (
  //     Object.keys(selectedNav).length > 0 &&
  //     selectedNav.frontend_route != location.pathname
  //   )
  //     setRedirectTo(selectedNav.frontend_route);
  // }, [selectedNav]);

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <Box
        sx={{
          // width: drawerOpen ? drawerWidth : "1px",
          zIndex: 1002,
          display: { xs: drawerOpen ? "flex" : "none", sm: "flex" },
        }}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "#F9FAFA",
              borderRight: "1px solid #E4E6E7",
            },
            position: "relative",
          }}
          open={drawerOpen}
          variant={variant}
          anchor="left"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "32px 24px",
            }}
          >
            <Box component="img" sx={{ maxWidth: { xs:"100px",md:"120px",lg:"150px"} }} src={logo} />
            <Button
              id="menu-button"
              aria-controls={open ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ padding: 0 }}
              style={{ minWidth: "40px", borderRadius: "15px" }}
            >
              <Avatar
                src={user.profile?.photo}
                {...stringAvatar(
                  user.first_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.last_name || ""
                )}
              />
            </Button>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "menu-button",
              }}
              sx={{ width: 320, maxWidth: "100%" }}
            >
              {user.first_name && (
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    cursor: "default",
                  }}
                >
<ListItem sx={{
  display:"flex",
  flexDirection:"column"
}}>          
                  <ListItemText>{`${user.first_name} ${user.last_name}`}</ListItemText>
             
             {
              user.email &&      <Typography
              sx={{ fontWeight: 500, fontSize: "11px", color: "#767676" }}>
                {user.email}
              </Typography>
             }
                 
                 </ListItem>
                </MenuItem>
              )}
                <Link to={"/settings"} style={LinkStyle}>
              <MenuItem
                onClick={(e) =>
                  handleNavClick(e, {
                    id: 0,
                    name: "Settings",
                    frontend_route: "/settings",
                  })
                }
                sx={{ padding: "8px 12px" }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <Settings fontSize="small" />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </MenuItem>
              </Link>

              {/* <Link to={"/user-preferences"} style={LinkStyle}> */}
              <MenuItem
                onClick={(e) =>{
                e.stopPropagation()
                setUserPreference(true)
                handleClose()
                
                 } }
                sx={{ padding: "8px 12px" }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <SettingsAccessibilityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>User Preferences</ListItemText>
              </MenuItem>
              {/* </Link> */}

              {/* <Link to={"/settings"} style={LinkStyle}> */}
              <MenuItem
                onClick={togglePasswordForm}
                sx={{ padding: "8px 12px" }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                 <SyncLockIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={toggleEditProfileForm}
                sx={{ padding: "8px 12px" }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                 <EditIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>Edit profile</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleToggleLogout}
                sx={{ padding: "8px 12px" }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
              {/* </Link> */}
            </Menu>
            <IconButton
              color="inherit"
              edge="end"
              onClick={(e) => handleDrawerToggle(false)}
              sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                },
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Box>

          <List sx={{ padding: "16px 24px 32px 24px" }}>
            {navs.map((obj, idx) => {
              let count = obj.name == "Chats" ? new_user_notification:obj.notif_count
              return (
                <Link to={obj.frontend_route} style={LinkStyle}>
                <ListItem key={`${idx}_${obj.name}`} sx={{ padding: 0 }}>
                  <ListItemButton
                    onClick={(e) => handleNavClick(e, obj)}
                    selected={selectedNav.name == obj.name}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <img width={18} height={18} src={obj.icon || defaultNavLogo} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="sidenav">{obj.name}</Typography>
                      }
                    />

                    {count > 0 && selectedNav.name != obj.name && (
                      <Notification count={count} />
                    )}
                  </ListItemButton>
                </ListItem>
                 </Link>
              );
            })}
          </List>

          <Divider />
          {/* <Box sx={{ padding: "16px 24px 48px 24px" }}>
            <Typography
              sx={{
                padding: "8px",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "18px",
                color: "#484D51",
              }}
            >
              Add additional services
            </Typography>
            <List>
              {additionalNavs.map((obj, idx) => (
                    // <Link to={obj.frontend_route} style={LinkStyle}>
                <ListItem key={`${idx}_${obj.name}`} sx={{ padding: 0 }}>
                  <ListItemButton
                    //   onClick={(e) => handleNavClick(e, obj)}
                    selected={selectedNav.name == obj.name}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "&:hover": { borderRadius: "8px" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <img src={obj.icon || defaultNavLogo} alt="Nav" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="sidenav">{obj.name}</Typography>
                        }
                      />
                    </Box>
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                      }}
                    >
                      <Add
                        sx={{ width: "12px", height: "12px" }}
                        htmlColor="black"
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                    //  </Link>
              ))}
            </List>
          </Box> */}
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Typography
              sx={{
                alignSelf: "end",
                textAlign: "center",
                fontSize: 14,
                color: "#404040",
                width: "100%",
              }}
            >
              {process.env.REACT_APP_Current_Version || "v-.-.--"}
            </Typography>
          </Box>
        </Drawer>
      </Box>
      {/* <CustomModal
        show={logoutModal}
        handleClose={handleToggleLogout}
        handleClick={handleConfirmLogout}
        title={"Please confirm before logout"}
      /> */}
            <DialogBox open={logoutModal} setOpen={setLogoutModal} handleAgree={handleConfirmLogout} agreeButtonText={"Logout"} content={"Please confirm before logout"}/>
            <ChangePasswordForm show={showPasswordForm} closeModal={togglePasswordForm} passwordResetRequired={passwordResetRequired} handleConfirmLogout={()=>dispatch(logout())}/>
            <CommonModalForForms
  show={showEditProfileForm} 
  toggleNewTaskModal={toggleEditProfileForm} 
  FormUi={
    <AddStaffForm 
    closeModal={toggleEditProfileForm}
    isEdit={true}
    data={user}
    allowedFields={allowedFields}
    readOnly={{
      hotels:true,
      account_type:true
    }}
    updateUser={true}
    title={"Edit Profile"}
    />
  }
    />

<CommonModalForForms
          show={userPreference}
          toggleNewTaskModal={() => setUserPreference(false)}
          FormUi={
            <GenericForm
              closeModal={() => setUserPreference(false)}
              isEdit={false}
              onSubmit={submitUserPref}
              title={"User Preferences"}
              initialState={initialState}
              fields={fields}
              submitButtonText={"Save"}
              itemStyle={{
                paddingTop:"0px",
              }}
              parrentStyle={
                {
                  paddingTop:"16px"
                }
              }
            />
          }
        />

    </>
  );
};

export default SideNavBar;
