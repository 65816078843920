import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faShareSquare, faEye} from '@fortawesome/free-regular-svg-icons';
import { faLink, faLanguage } from '@fortawesome/free-solid-svg-icons'; 
import addIcon from '../../assets/addIcon.svg';
import ActiveSurvey from './ActiveSurvey';
import CreateNewSurvey from './CreateNewSurvey';
import { url } from '../../../../components/Constants/Constants'
import {  createNewSurvey, deleteSurvey } from '../../../../actions/surveyActions';
import { connect } from 'react-redux'

import BaseAPICompnenetWithLoader from '../../../../components/APIComponents/BaseAPIComponent/BaseAPICompnenetWithLoader';
import BaseAPIComponent from '../../../../components/APIComponents/BaseAPIComponent/BaseAPIComponent';
import BaseAPIComponentNoLoader from '../../../../components/APIComponents/BaseAPIComponent/BaseAPIComponentNoLoading';
import {add_auth_header, filterSurveyWithLanguage} from '../../../../components/Utility/Utility';
import Container from '../../../../components/UI/baseContainer';
import ItemAddButton from '../../../../components/UI/buttons/itemAddButton';
import Table from '../../../../components/UI/styledTable2'; 
import CreateSurveyPopup from './components/CreateEditSurveyPopup';


import MultipleCheckboxDropdown from '../../../../components/UI/dropdown/multipleCheckboxDropdown';
import { useForm } from 'react-hook-form';
import ShareSurvey from './ShareSurvey';
import css from './CreateSurvey.module.css';
import { useHistory } from "react-router-dom";
import CardWithTitle from '../../../../components/UI/cardWithTitle';

import PieChart from '../../../../components/UI/pieChart';
import SelectDropdown from '../../../../components/UI/dropdown/selectDropdown';
import Chart from "./Chart.js";

const ManageSurvey = props => {

    const [hotels, setHotels] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState({});
    const [api_data,setApidata] = useState([]);
    const [surveyId,setSurveyId] = useState();
    const [surveyGroupId,setSurveyGroupId] = useState();
    const [selectedComp, setSelectedComp] = useState('');
    const [showCreateSurveyPopup,setShowCreateSurveyPopup] = useState(false);
    const [showSharePopup,setShowSharePopup] = useState(false);
    const [selectedSurvey,setSelectedSurvey] = useState(null);
    const [addingLanguage,setAddingLanguage] = useState(false);
    const [delSurveyObj,setDelSurveyObj] = useState({
        show:false,
        id:null
    })
    const [createSurveyLngs,setCreateSurveyLngs] = useState([]);
    const { control,setValue,formState:{errors}, register, handleSubmit } = useForm({defaultValues:{'name':addingLanguage?selectedSurvey.name:''}});
    const history = useHistory();
    const [selectedFilter, setSelectedFilter] = useState("");

    useEffect(() => {
        setHotels(props.hotels);
        setSelectedHotel(props.selectedHotel);
    }, [props.hotels, props.selectedHotel])

    const editSurveyHandler = (object) => {
        console.log("create survey response edit", object);
        setSurveyId(object.id);
        setSurveyGroupId(object.group.id);
        props.setCreatingSurvey(true);
        // setSelectedComp('create');
        history.push(`/survey/edit/${object.id}`)
    }

    const confirmDelSurvey = (id) => {

        let updtData;

        if(!id){
            return;
        }

        props.deleteSurvey(props.add_auth_header({}),id,(status) => {
            setDelSurveyObj({show:false,id:null});
            updtData = [...api_data].filter(el => el.id !== id);
            setApidata(updtData);
        })
    }

    const renderer = (object,field) => {
        if(field === 'primary'){
            if(object.is_primary === true)
                return "Yes";
            return "No";
        }
        else if(field === 'language'){
            for(const lang of createSurveyLngs)
                if(lang.code === object.language)
                    return lang.name;
        }
        return object[field];
    }

    const language_processor = (langs) => {
        return langs.label;
    }

    const surveyTemplateList = (
        <Table 
            objectName="Survey"
            data={selectedFilter && selectedFilter != "Filter by survey language ( All )" ? filterSurveyWithLanguage(api_data, selectedFilter) : api_data}
            columns={['Name','Hotels','Language', 'Last updated','Primary','']}
            value_processors={{'group.survey_languages': language_processor}} 
            fieldsTypes={[{"name":"primary"},{"hotels":"list"},{"group.survey_languages":"list"},
                            {"updated_at":"date"},{"primary":"text"},{"":"dots"}]}
            editHandler={editSurveyHandler}
            deleteHandler={confirmDelSurvey}
            emptyButtonHandler={() => setShowCreateSurveyPopup(true)}
            renderer={renderer}
            extraMenuItems={[
                {
                    name:"Share",
                    clickHandler:(object) => {setShowSharePopup(true);console.log("selected survey",object);setSelectedSurvey(object)},
                    icon:faShareSquare
                },
                {
                    name:"Preview",
                    clickHandler:(object) => history.push(`user-survey?surveyId=${object.id}`),
                    icon:faEye
                },
                {
                    name:"Add language",
                    clickHandler: (object) => {setSelectedSurvey(object);setShowCreateSurveyPopup(true);setAddingLanguage(true)},
                    icon:faLanguage}]}
            maxHeight={550}
        />)
    

    const dataLoaded = () => {
        return api_data.length > 0;
    }


    const pieChartData = [
        { platform: 'Email', area: 45 },
        { platform: 'Website', area: 30 },
        { platform: 'Direct', area: 10 },
        { platform: 'Social Media', area: 15 }
      ];

    const Pie = useMemo(() => () => <PieChart  width={300} height={300} legendPosition="bottom" chartData={pieChartData} valueField="area" argumentField="platform" />, [])
    //     return (<BaseChart
    //         chart={}
    //         data_url = {`${url}/`}
    //         setAPIData = {setLineChatData}
    //         api_dependency = {[lineChatData]}/>
    //     );
    // }

    return (

        selectedComp === 'create' ? <CreateNewSurvey
                                        surveyId = {surveyId}
                                        surveyGroupId = {surveyGroupId} 
                                        backBtn = {setSelectedComp}
                                        selectedHotel= {props.selectedHotel}
                                        setCreatingSurvey={props.setCreatingSurvey}
              />
        
        :<Fragment>

            <CardWithTitle 
                height={600} 
                width={'97%'} 
                textAlignHeader='left' 
                title='Response Trend' 
                showFilters={true}
                description='This section shows the response number of the guests in respect to time' 
                component={<div style={{position: "relative", marginTop: "3rem"}}><Chart/></div>}
            />

            <div style={{display: 'flex'}} >
                <ActiveSurvey
                    height={500}
                    width={'59.4%'}
                />
                
                <CardWithTitle 
                    height={500} 
                    width={'37%'} 
                    textAlignHeader='center' 
                    showFilters={false}
                    titleWidth='100%'
                    title='Popular Source' 
                    description='This section shows on what platforms your survey is performing' 
                    component={<Pie />}
                />
            </div>
                
            <Container backBtn="" pageTitle="Surveys" 
            extraHeaderContents={
                api_data.length!==0 &&
                <>
                    <SelectDropdown selectedOption={selectedFilter} handleChange={(event) => setSelectedFilter(event.target.value)} menuOptions={["Filter by survey language ( All )", ...(createSurveyLngs.map(lang => lang.name))]}  />
                    <div className = {css.AddNewCont}>
                        <ItemAddButton 
                            buttonTitle="Create New" 
                            backgroundColor="transparent" 
                            fontSize={16} 
                            height={40} 
                            clickHandler={() => setShowCreateSurveyPopup(true)}/>
                    </div>
                </>} component=""/>
            <div style={{width:"97%",marginLeft:"10px",marginTop:"15px"}}>
                <BaseAPIComponentNoLoader
                component = {""}
                data_url={`${url}/survey/languageOptions/`}
                setAPIData = {setCreateSurveyLngs}
                api_dependency  = {[true]}
                not_found_msg = {'Survey languages not found.'}
                />
                <BaseAPIComponent 
                component = {surveyTemplateList}
                data_url={`${url}/survey/`}
                setAPIData = {setApidata}
                api_dependency  = {[true]}
                not_found_msg = {'Surveys not found.'}
                />
            
            
            {showCreateSurveyPopup && 
                <CreateSurveyPopup
                    hotels={hotels}
                    setShowCreateSurveyPopup={setShowCreateSurveyPopup}
                    setCreateSurvey={editSurveyHandler}
                    setCreatingSurvey={props.setCreatingSurvey}

                />
            }
            {showSharePopup && <ShareSurvey guestName="" surveyId={selectedSurvey.group.id} setShowSharePopup={setShowSharePopup}/>}
            </div>
        </Fragment>
    );

}

export default connect(null,{
    createNewSurvey,
    add_auth_header,
    deleteSurvey
})(ManageSurvey);
