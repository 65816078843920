import React, { useState, useRef } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import './passwordField.css';

const CustomPasswordField = ({ bodyData, handleChange, formError, loading, name, label }) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  const handleClickShowPassword = (e) => {
    e.stopPropagation();


    const input = inputRef.current;
    const { selectionStart, selectionEnd } = input;

    setShowPassword((prevState) => !prevState);
    setTimeout(() => {
      if (input) {
        input.selectionStart = selectionStart;
        input.selectionEnd = selectionEnd;
        input.focus();
      }
    }, 0); 
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      required
      label={label}
      type={showPassword ? "text" : "password"}
      name={name}
      value={bodyData[name]}
      onChange={handleChange}
      error={formError[name]}
      helperText={formError[name]}
      fullWidth
      disabled={loading}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        className: 'passwordField',
        inputRef: inputRef 
      }}
    />
  );
};

export default CustomPasswordField;
