import React, { useState,Fragment, useEffect } from 'react';
import {Edit,CheckCircle} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import css from './UI.module.css';
import { IconButton, TextField } from '@mui/material';

const useStyles = makeStyles({
    root: {
        transform: 'translateY(-2px)',
        '&:focus': {
            outline: "none"
        }
    }
  });

const EditableField = (props) => {
    const classes = useStyles();
    const [editing,setEditing] = useState(false);
    const [value,setValue] = useState(props.value);

    useEffect(()=>{
setValue(props.value)

    },[[props.value]])

    return (
        <Fragment>
     {editing ?
         <TextField
          size="small"
            name={props.name}
            placeholder={props.placeholder}
            value={value} 
            onChange={(e) => setValue(e.target.value)}
            // error={formError["name"]}
            // helperText={formError["name"]}
            fullWidth
            // disabled={loading}
            multiline
            // minRows={3}
            // maxRows={5}
            sx={{
                padding:"4px",
                width:props.inputWidth || "100%"
            }}
          />
        //    <input className={`${css.EditableFieldInput} + ${props.className}`} placeholder={props.placeholder} value={value} 
        // ref={props.register} name={props.name} onChange={(e) => setValue(e.target.value)}/>
        :
        <span>
            {value}
        </span>
        } 
        &nbsp;&nbsp;
        <IconButton className={classes.root} size="small" onClick={() => {
            if(editing && props.onChange)
                props.onChange(value);
            setEditing(prevState => !prevState)}}>
            {editing ? <CheckCircle style={{color:props.iconColor}}/> : <Edit style={{color:props.iconColor}}/>}
        </IconButton> 
        </Fragment>
    );
}

EditableField.defaultProps = {
    iconColor: "#7ea63f"
}

export default EditableField;