import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {
    color: props => props.color,
  },
}));

export default function Circle(props) {
  const classes = useStyles(props);
  return (
    <div style={{position:"relative"}}>
    <span style={{width:"100%",position: 'absolute',marginTop:"10px",marginLeft:"20px",top: '20%',color:"black",fontSize:props.size/6,fontWeight:"600"}}>{props.value}%</span>
    <CircularProgress className={classes.root} thickness={props.thickness} variant="determinate" value={props.value} size={props.size}/>
    </div>
  );
}
