import React from 'react'
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';



const makeString = (number) => {
    return number.toString() + "px";
}

const useStyles = makeStyles(props => ({
    root: {
        height : props => makeString(props.height),
        width : props => (props.inheritsWidth?props.width:makeString(props.width)),
        background : '#7ea63f',
        color : 'white',
        fontSize : props => makeString(props.fontSize),
        textTransform : 'none',
        '&:hover': {
            background: '#7ea63f'
        },
        '&:focus': {
            outline: "none"
        },
        '@media (max-width:768px)': {
            width : props => (props.inheritsWidth?props.width:makeString(0.75 * props.width)),
            fontSize : props => makeString(props.fontSize-1)
          },
        '@media (max-width:450px)': {
            width : props => (props.inheritsWidth?props.width:makeString(0.60 * props.width)),
            fontSize : props => makeString(props.fontSize-2)
        }
    }
}));
  
function PrimaryButton(props){
    const classes = useStyles(props);
    return(
        props.disabled ?
        <Button className = {classes.root} variant = "contained" disabled >
        {props.disabledTitle ? props.disabledTitle : props.buttonTitle}
        </Button> 
        :
        <Button type={props.type} className = {classes.root} variant = "contained" onClick = {props.clickHandler}>
        {props.buttonTitle}
        </Button>
    );
}

PrimaryButton.defaultProps = {
    height : 50,
    width : 140,
    fontSize : 10,
    disabled : false,
    inheritsWidth : false
}

export default PrimaryButton;

