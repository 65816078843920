import React, { useState } from 'react';
import ConfirmationDialog from '../../../../components/UI/confirmationDialog';
import TertiaryButton from '../../../../components/UI/buttons/tertiaryButton';
import PrimaryButton from '../../../../components/UI/buttons/primaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { url } from '../../../../components/Constants/Constants';
import {add_auth_header } from '../../../../components/Utility/Utility';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import css from './CreateSurvey.module.css';


const ShareSurvey = (props) => {

    const [guestName, setGuestName] = useState("")

    const generateLinkHandler = () => {
        axios.post(`${url}/survey/${props.surveyId}/generate-link/`,{guest_name : guestName},{
            "headers": props.add_auth_header({})
          })
        .then(response => setLink(response.data['url']))
        .catch(error => console.log(error));
    }

    const [link,setLink] = useState('');

    return(
        <ConfirmationDialog dialogTitle={props.name} hideButton1={true} hideButton2={true}
                    crossHandler={() => props.setShowSharePopup(false)} open={true} component={
                        <div>
                            <div>
                                <form className={css.SharePopupGuestForm}>
                                    <label>Guest Name:</label>
                                    <input onChange={(e) => setGuestName(e.target.value)} value={guestName} type="text"></input>
                                </form>
                            </div>
                            <div className={css.shareByEmail}>
                                Share by Email:
                            </div>
                            <div className={css.emailInput}>
                                <input placeholder="Enter Emails, separated by commas"/>
                                <div className={css.shareButton}>
                                    <TertiaryButton buttonTitle="Share" fontSize={15} width={110} height={40}/>
                                </div>
                            </div>
                            <div className={css.surveyLinkHeading} onClick={generateLinkHandler}>
                                Generate Link &nbsp;<FontAwesomeIcon icon={faPlusCircle}/>
                            </div>
                            <div className={css.surveyLinkContainer}>
                                <input disabled={true} value={link}/>
                                <div className={css.shareButton}>
                                    <PrimaryButton buttonTitle="Copy" fontSize={15} height={40} width={110} clickHandler={() => navigator.clipboard.writeText(link)}/>
                                </div>
                            </div>
                        </div>
                    }/>
    )
}


const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        };
    };

export default connect(null,mapDispatchToProps)(ShareSurvey);