import React, { useEffect, useState } from 'react';

import css from '../QuestionRenderer.module.css';

const RadioBtn = props => {

    const [options, setOptions] = useState([]);
    const [checkedIdx, setCheckedIdx] = useState(null);

    useEffect(() => {
        setOptions(props.question.options);

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && checkedIdx == null){
            let _op_idx = props.question.options.findIndex(o => String(o.value) == String(props.response));
            if (props.debug) console.log(`Option found: ${_op_idx}`);
            
            if (_op_idx != -1) setCheckedIdx(_op_idx);
        }
    },[props.question, props.response])


    const checkHandler = (e,index) => {
        setCheckedIdx(index);

        if (props.saveResponse) props.saveResponse(options[index].value)
    }


    return(
        <div className = {css.RadioContainer}>
            {options.map((option, i) => (
            <div key = {i} className = {css.RadioBtn}>
                    <input type = 'radio' name = {option.value} value = {option.value} checked={checkedIdx==i} onChange={e => checkHandler(e,i)}/>
                    <label>{option.value}</label>
                </div>
            ))}
        </div>
    );
}

export default RadioBtn;