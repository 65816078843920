import React, { useState,useEffect, Fragment } from 'react';
import axios from 'axios';
import { check_auth_error, add_auth_header } from "../../Utility/Utility";
import './BaseAPIComponent.css';
import datanotfound from '../assets/datanotfound.png';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

const BaseAPIComponent = props => {
    const {add_auth_header} = props; 

    const [api_error, setAPIError] = useState(false);
    const [http_resp_code, setHttpRespCode] = useState(200);
    const [api_loading, setAPILoading] = useState(true);
    let api_dependency = props.api_dependency != null ? props.api_dependency : [];
    
    const load_data = () => {
        const {add_auth_header} = props;
        let req_header = add_auth_header({})
        setAPILoading(true);  
        setAPIError(false);
        axios
        .get(
            props.data_url,
            {
              headers: req_header,
            }
        )
        .then((res) => {
          if (res.status != 204){
            props.setAPIData(res.data);
          }
          setHttpRespCode(res.status);
          setAPILoading(false);
          setAPIError(false);

        })
        .catch((error) => {
            setAPIError(true);
            setAPILoading(false);
            check_auth_error(error);
        });
    }

    const not_null = (v) => {
      return v instanceof Array ? v.length>0 : v instanceof Object ? Object.keys(v).length>0: v != null;
    }

    useEffect(() => {
        if (api_dependency.length > 0 && api_dependency.every(not_null)){
            load_data();
        }
    }, api_dependency);
    
    useEffect(() => {
      if(props.setApiExecuted){
        props.setApiExecuted(!api_loading);
      }
    }, [api_loading]);

    return (
        <Fragment>
            {api_error ? (
              <div className="review_reload">
                <p onClick={load_data} className="reload-text">Reload</p>
              </div>
            ) : api_loading ? (
              props.loading_css?
              <div className={props.loading_css} style={{textAlign:"center"}}>
                <p className="loading_text" style={{marginTop:"10%",transform:"translateY(10px)"}}>Loading, please wait</p>
              </div>:
              <div className="review_loading">
                <p className="loading_text">Loading, please wait</p>
              </div>
            ) : http_resp_code === 204 ? (<div className="review_loading">
                  {!props.hide_not_found_img &&<img src={datanotfound} alt="Data Not Found"/>}

                  { props.not_found_msg === "Staff not found."  || props.not_found_msg === "Cases not found."
                  ? <Fragment> 
                      {props.component} { /* This part is only to handle staff and case component */ }
                    </Fragment>

                  : props.not_found_msg ? <p className="loading_text">{props.not_found_msg}</p>
                   : (
                    <Fragment>
                      <p className="loading_text">Data Not found.</p>
                      <p className="loading_text">Please try changing the hotels/filters settings.</p>
                    </Fragment>
                   )}
                </div>) 
            : !props.dataLoaded || (props.dataLoaded && props.dataLoaded()) ? (
              <Fragment> 
                {props.component}
              </Fragment>
            ): ("")}
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
  return{
      // saveTokenHandler: value => dispatch({type: actionTypes.AUTH_TOKEN, value: value}),
      add_auth_header: bindActionCreators(add_auth_header, dispatch),
      };
  };
  
  
  export default connect(null,mapDispatchToProps)(BaseAPIComponent);
      