import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Rating,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AIReviewReply from "./AIReviewReply";
import Comments from "../../../commons/components/Comments/Comments";
import Mark from "mark.js";
import styles from "../Reviews.module.css";
import ToolTip from "../../../commons/components/UI/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import LanguageIcon from '@mui/icons-material/Language';
import globe from "../assets/globe.svg"
import StaffListPopup from "./StaffListPopup";
import { MarkReview, UnMarkReview } from "../actions/actions";
import DialogBox from "../../../commons/components/PopUp/DialogBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(2),
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    padding: 2,
  },
  box: {
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
}));

const NameAndSourceStyle = {
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "20px",
};
const secondary = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#60666C",
};
const secondary2 = {
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#60666C",
};
const reviewStyle = {
  fontStyle: "normal",
  fontWeight: 500,
  fontSzie: "16px",
  lineHeight: "24px",
  color: "#484D51",
};

const Review2 = ({ review,forMessage=false,randomId="" }, ref) => {
  const tooltip = useRef();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const bound = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch()
  const [viewOriginal, setViewOriginal] = useState(false);
  const [unmarkPopup, setUnmarkPopup] = useState(false);
  const sourceData = useSelector((state)=>state.settings.sources.data || [])
  const [markReviewPopUp, setMarkReviewPopUp] = useState(null);
const [reply_for_parent, setReply_for_parent] = useState("")
const [loading, setLoading] = useState("");


  const checkInteration = (code)=>{
    let obj = sourceData.find((e)=>e.code===code)
    if(obj && obj.connection_type!== null)
    return true
    return false
  }



  const handleClick = (event) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleClickUnmark = (e) => {
    e?.stopPropagation()
    setUnmarkPopup(true)
    handleCloseMenu()
  };

  const handleRedirect = (e) => {
   if(review.direct_review_url || review.review_url)
   window.open(review.direct_review_url || review.review_url, '_blank');
  else
  dispatch(setGlobalMessage({
mess:"Source not found",
type:"info"}))
  };

  useEffect(() => {
    let reviewId = !forMessage?review.review_id : review.review_id+randomId
    if(viewOriginal===true) return;
    let markInstance = new Mark(document.getElementById(reviewId));
    review?.analytics?.forEach((data) => {
      data.highlight_indices.forEach((range) => {
        markInstance.markRanges(
          [{ start: range[0], length: range[1] - range[0] + 1 }],
          {
            element: "span",
            className: styles[range[2].toLowerCase()],
            each: (element) => {
              // add eventListener to change tooltip position.
              element.addEventListener("mousemove", changePosition, false);
              bound.current = element.getBoundingClientRect();

              function changePosition(e) {
                if (bound.current.right - e.clientX < 30)
                  tooltip.current.style.left =
                    e.clientX - tooltip.current.offsetWidth + "px";
                else tooltip.current.style.left = e.clientX + "px";
                tooltip.current.style.top =
                  window.scrollY + e.clientY - 45 + "px";
              }

              // set topic and display tooltip on mouse enter over the highlighted phrase
           if(!forMessage){
            element.onmouseenter = (e) => {
              tooltip.current.innerText = data.topic;
              tooltip.current.style.display = "inline-block";
            };

            // Hide tooltip on mouse leave.
            element.onmouseleave = (e) => {
              tooltip.current.style.display = "none";
            };
           }
            },
          }
        );
      });
    });
    let content  =  document.getElementById(reviewId).querySelector('p').innerHTML
  removeOriginalReviewFromTranslated(content)

    return () => markInstance.unmark();
  }, [review,viewOriginal]);

  const removeOriginalReviewFromTranslated = (inputStr) => {
    let reviewId = !forMessage?review.review_id : review.review_id+randomId

    const parts = inputStr.split(/\(Original\): |\(Translated\): /);
    const result = {};
  
    for (let i = 1; i < parts.length; i += 2) {
      result[`line${Math.ceil((i + 1) / 2)}`] = parts[i].trim();
    }
    if(Object.keys(result).length>0){
      document.getElementById(reviewId).querySelector('p').innerHTML = `${result?.line1}<br/>${result?.line2}`
    }
  };

  const handleUnmark = (e)=>{
e?.stopPropagation()
setUnmarkPopup(false)
dispatch(UnMarkReview({
  review_id:review.source_review_id,
  code: review.source.code
},handleCloseMenu))
  }

  const handleMarkReview =()=>{
    setMarkReviewPopUp(`Has this reply been posted on ${review?.source?.name}? Would you like to mark this review as replied?`)
    // copyToClipBoard()
    }

    const handleCopy = async () => {
      if (!reply_for_parent){
        dispatch(
          setGlobalMessage({
            mess: `No text to copy`,
            type: "error",
          })
        );
      }
      else{
        if (!loading) {
          const data = {
            code: review?.source.code,
            review_id: review?.source_review_id,
            reply:reply_for_parent,
          };
       dispatch(MarkReview(data,null,setMarkReviewPopUp))
        }
        else {
          dispatch(
            setGlobalMessage({
              mess: `Please wait and try again reply is loading`,
              type: "warning",
            })
          )
        }  
  
      }}
    

  return (
    <>
      <Box
        ref={ref}
        className={classes.root}
        sx={{
          columnGap: {
            xs: "0 !important",
            sm: `${theme.spacing(2)} !important`,
          },
        }}
      >
        <Avatar
          sx={{ display: { xs: "none", sm: "flex" } }}
          src={review.source.icon || ""}
          alt={review.reviewer_name || ""}
        />
        <Box className={classes.box}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {isMobile ? (
              <Box className={classes.root}>
                <Avatar src={review.source.icon} alt={review.reviewer_name} />
                <Box>
                  <Typography
                    display="inline"
                    component="span"
                    sx={NameAndSourceStyle}
                  >
                    {review.reviewer_name}{" "}
                  </Typography>
                  <Typography display="inline" componenet="span" sx={secondary}>
                    wrote a review at{" "}
                  </Typography>
                  <Typography
                    display="inline"
                    componenet="span"
                    sx={NameAndSourceStyle}
                  >
                    {review.source.name}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography
                  display="inline"
                  component="span"
                  sx={NameAndSourceStyle}
                >
                  {review.reviewer_name}{" "}
                </Typography>
                <Typography display="inline" componenet="span" sx={secondary}>
                  wrote a review at{" "}
                </Typography>
                <Typography
                  display="inline"
                  componenet="span"
                  sx={NameAndSourceStyle}
                >
                  {review.source.name}
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", gap: 0.5 }}>
         {
          !forMessage && <>
               <Box>
               <StaffListPopup reviewId={review.review_id}/>
              </Box>
              {/* <Box>
                <BookmarkBorderIcon />
              </Box> */}
              <Box>
                <MoreHorizIcon   onClick={(e)=>{handleClick(e) }}  />
              </Box>
          </>
         }
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "8px",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Rating
              name="rating"
              value={review.rating_review_score}
              max={review.out_of}
              readOnly
            />
            <Typography component="span" display="inline" sx={secondary2}>
              {" "}
              {review.date}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              paddingTop: "16px",
            }}
          >
    {
      review.source_language !== "en" && (
        <Box display={"flex"} alignItems={"center"} sx={{padding:'2px'}} >
       {
    <>
    {/* <LanguageIcon style={{
      margin: "0px 5px 0px 0px",
      color: "grey"
}}/> */}
<img src={globe} alt="globe" style={{  margin: "0px 5px 0px 0px"}} />
<Typography style={{
    fontSize: "12px",
    fontFamily:'Inter',
    margin: "0px 15px 0px 0px",
    color: '#60666C',
    lineHeight:"18px",
    letterSpacing:"-0.4px",
    fontWeight:"500"
}}>Transalated from {review.source_language_name}</Typography></>
       }
       <li style={{fontSize: "12px",
            margin: "0px 15px 0px 0px",
            color: '#60666C',
            lineHeight:"18px",
            letterSpacing:"-0.4px",
            cursor:"pointer"}} ><span style={{color:"#0080ff"}} onClick={()=>setViewOriginal((p)=>!p)}>{viewOriginal?"View translated":"View original"}</span></li>
       </Box>
      )
    }
            <Box id={!forMessage?review.review_id : review.review_id+randomId
} sx={{
              overflowWrap:"anywhere"
            }}>
         
              {<Typography variant="body1" sx={reviewStyle}>{!viewOriginal?review.content:review.raw_content}</Typography>}
            </Box>
          {
            !forMessage &&   <Box marginTop={3}>
            <AIReviewReply review={review}  handleRedirect={handleRedirect} setReply_for_parent={setReply_for_parent} loading={loading} setLoading={setLoading} />
          </Box>
          }
          </Box>

     {
      !forMessage &&      <Box sx={{ marginTop: "16px" }}>
      <Comments review={review} />
    </Box>
     }
        </Box>
      </Box>
      <ToolTip
        ref={tooltip}
        styles={{ backgroundColor: "#f9fafa", zIndex: 1000 }}
      />
            <Menu
          id="menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
        > 
          <MenuItem onClick={handleRedirect} >View Source</MenuItem>
          {
            !checkInteration(review?.source?.code) && !review?.hotel_reply  && <MenuItem onClick={(e)=>handleMarkReview(e)} >Mark as Replied</MenuItem>
          }
        {  review.status === "Waiting for approval" &&   <MenuItem onClick={handleClickUnmark} >Unmark Reply</MenuItem> }
        </Menu>
        <DialogBox open={unmarkPopup} setOpen={setUnmarkPopup} handleAgree={handleUnmark} agreeButtonText={"Yes"} content={"Are you sure you want to unmark this review?"} topHeading={"Confirm"} closeText={"No"}/>
        <DialogBox open={markReviewPopUp} setOpen={setMarkReviewPopUp} handleAgree={handleCopy} agreeButtonText={"Yes"} content={markReviewPopUp} topHeading={"Confirm"} closeText={"No"}/>
    </>
  );
};

const ForwardedReviewRef = React.forwardRef(Review2);

export default ForwardedReviewRef;
