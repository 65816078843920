import React, { useEffect, useState } from 'react';

import Filters from '../SideNav/Filters';
import FiltersTopic from '../SideNav/FiltersTopic';
import expand_icon from  '../SideNav/assets/plus-square-Regular.png';
import minimize_icon from '../SideNav/assets/minus-square-Regular.png'
import { border } from '@material-ui/system';

const FilterComponent = (props) => {

    const [showFilters, setShowFilters] = useState(false);   
    const [applySelection, setApplySelection] = useState(null); // 'apply-start', 'apply-done'

    const applyFilters = (e) => {
        setApplySelection('apply-start');
    }

    useEffect(() => {
        if(applySelection == 'apply-done') setApplySelection(null);
    }, [applySelection])

    return (
        <div className="filters">
            <div className="filter-container" >
                <Filters 
                    showFilters={showFilters}
                    applySelection={applySelection}
                    setApplySelection={setApplySelection}
                />
                {props.show_filter_topics && //&& (window.innerWidth <=1024 || showFilters) 
                <FiltersTopic
                showFilters={showFilters}
                applySelection={applySelection}
                setApplySelection={setApplySelection}
                />
                }
            </div>
            <div className='expand-btn' >
                <img style={{cursor: "pointer"}} onClick={() => setShowFilters(!showFilters)} src={showFilters ? minimize_icon : expand_icon} />
                <span>{showFilters ? "Minimize filters" : "Expand filters"}</span>
                <span onClick={applyFilters}>
  <button
    style={{
      backgroundColor: "white",
      color: "black",
      padding: "10px",
      border: "none",
      borderRadius: "1px",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
    }}
    onMouseEnter={(e) => {
      e.target.style.backgroundColor = "white";
      e.target.style.color = "black";
      e.target.style.transform = "scale(1.1)";
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundColor = "white";
      e.target.style.color = "black";
      e.target.style.transform = "scale(1)";
    }}
  >
    Apply Filter
  </button>
</span>

            </div>
        </div>
    );
};

export default FilterComponent;