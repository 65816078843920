import React, { useEffect, useState } from "react";

import "./DateWidget.css";
import DateSelector from './DateSelector';
import * as dateActions from '../../../commons/components/Filter/actions/actions';
import { useSelector, useDispatch } from "react-redux";

const DatePopup = props => {

  const dispatch = useDispatch();
  const [applySelection, setApplySelection] = useState(false);
  const [dateSelectionDone, setDateSelectionDone] = useState(false);
  const [compDateSelectionDone, setCompDateSelectionDone] = useState(false);

  const dispatchStatOption = (val) => dispatch(dateActions.setStatOption(val))
  const dispatchStatStartDate = (val) => dispatch(dateActions.setStatStartOption(val))
  const dispatchStatEndDate  = (val) => dispatch(dateActions.setStatEndOption(val))
  const statOption =  useSelector(state => state.filter.selectedCurrDate.option)
  const statStartDate = useSelector(state => state.filter.selectedCurrDate.startDate)
  const statEndDate = useSelector(state => state.filter.selectedCurrDate.endDate)

  const dispatchCompOption = (val) => dispatch(dateActions.setCompOption(val))
  const dispatchCompStartDate = (val) => dispatch(dateActions.setCompStartOption(val))
  const dispatchCompEndDate  = (val) => dispatch(dateActions.setCompEndOption(val))
  const compOption = useSelector(state => state.filter.selectedCompDate.option)
  const compStartDate = useSelector(state => state.filter.selectedCompDate.startDate)
  const compEndDate = useSelector(state => state.filter.selectedCompDate.endDate)

  const stat_date_range = [
    {label: 'Default', values: ['Year To Date']},
    {label: 'Day', values: ['Today', 'Yesterday']},
    {label: 'Week', values: ['This Week', 'Last Week']},
    {label: 'Month', values: ['This Month', 'Last Month']},
    {label: 'Quarter', values: ['This Quarter', 'Last Quarter']},
    {label: 'Custom', values: ['Custom Date']},
  ]

  const comp_date_range = [
    {label: 'Year', values: ['Same Period Last Year']},
    {label: 'Custom', values: ['Custom Date']},
  ]

  const startApplyingSelection = () => {
    setApplySelection(true);
  }

  useEffect(() => {
    if(dateSelectionDone && compDateSelectionDone) props.showPopupHandler();
  }, [dateSelectionDone, compDateSelectionDone]);

  return(
    <div className="date_widget_container">
        <div>
          <p className="date_range_head" >Date range</p>
        </div>
        <div className="date_range_widget">
          <div className="period">
            <p className="period_header">Select Date</p>
            {console.log(`dat ${statOption} ${statStartDate} ${statEndDate}`)}
            <DateSelector
              name='date' 
              options={stat_date_range}
              selectedOption={statOption}
              dispatchOption={dispatchStatOption}
              dispatchStartDate={dispatchStatStartDate}
              dispatchEndDate={dispatchStatEndDate}
              startDate={statStartDate}
              endDate={statEndDate}
              applySelection={applySelection}
              setSelectionDone={setDateSelectionDone}
            />

          </div>
          <div><p className="vertical_line" >|</p></div>
          <div className="period">
            <p className="period_header">Compare with</p>
            {console.log(`comp ${compOption} ${compStartDate} ${compEndDate}`)}
            <DateSelector
              name='comp-date'
              options={comp_date_range}
              selectedOption={compOption}
              dispatchOption={dispatchCompOption}
              dispatchStartDate={dispatchCompStartDate}
              dispatchEndDate={dispatchCompEndDate}
              startDate={compStartDate}
              endDate={compEndDate}
              applySelection={applySelection}
              setSelectionDone={setCompDateSelectionDone}
            />
            
          </div>
        </div>
        <div className='input_date_error'>{props.errorMessage}</div>
        <button className="comparision_btn" 
        onClick={startApplyingSelection}>
        Show Comparison
        </button>
      </div>
  )
}

export default DatePopup;
