import * as actionTypes from './action/SurveyActionType';

const initialState = {
    Survey:{
        data: [],
        count: 0,
        next: null,
        previous: null,
        loading: false,
        page:0,
        error:""
      }
}

const SurveyReducer = (state = initialState, action) => {
  switch(action.type){

    case actionTypes.FETCH_SURVEY_DATA_SUCCESS:
      
    return {
      ...state,
      Survey: {
        data: action?.payload || [],
        count: action.payload.length,
        next: action?.next || null,
        previous: action?.previous || null,
        loading:false,
        error:""
      },
    };


    case actionTypes.FETCH_SURVEY_DATA_FAILURE:   
    return {
      ...state,
      Survey: {
        data:  [],
        count: 0,
        next:  null,
        previous:  null,
        loading:false,
        error: action.payload
      },
    };

    case actionTypes.SET_SURVEY_DATA_BY_KEY:   
    return {
      ...state,
      Survey: {
        ...state.Survey,
        [action.key]:action.payload
      },
    };
        
    case actionTypes.SET_TO_INITIAL:
      return initialState

    default:
      return state;
  }
}

export default SurveyReducer;