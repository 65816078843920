import React from 'react';
import CreditsFooter from '../../../../../components/UI/CreditsFooter';
import success from "../assets/section-success.png"

// MUI imports
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Card } from '@mui/material';

const useStyles = makeStyles({
    root: { backgroundColor:"#F9FAFA", height: "100%", width: "100%", display:"flex", justifyContent:"center", alignItems:"center"},
    container: {display: 'grid', justifyItems: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center'},
})

function SectionSuccess(props) {
    const classes = useStyles()
  return (
    <div className={classes.root} >
        <div className={classes.container} >
            <Card sx={{width: '1000px', height: '620px', display: 'grid', justifyItems: 'center', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}} >
                <img style={{marginBottom: '20px'}} src={success} />
                <Typography variant='h5' >{props.completedSectionName} Completed</Typography>
                <Typography variant='body1' style={{marginTop: '20px'}} >Let’s move to {props.nextSection}, click on next button to proceed</Typography>
                <div style={{display: 'flex', justifyContent: 'end', width: '210%', marginBottom: '-10rem'}} >
                    {/* <Button style={{ backgroundColor: "#E4E6E7", color: "black", width: "110px"}} onClick={props.back} variant='contained' >Back</Button> */}
                    <Button style={{backgroundColor: "#E4E6E7", color: "black", width: "110px"}} onClick={props.next} variant='contained' >Next</Button>
                </div>
            </Card>
        </div>
        {/* <CreditsFooter message="Survey created by ReviewMagic team.  Check out our Privacy Policy" /> */}
    </div>
  )
}

export default SectionSuccess