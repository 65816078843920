import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedHotel } from "./actions/actions";

import { Box, Typography, Autocomplete, TextField } from "@mui/material";

import iconOrigin from "./assets/IconOrigin.svg";

const HotelList = (props) => {
  const dispatch = useDispatch();
  const [hotel_groups_data, setHotelGroupsData] = useState([]);
  const hotels = useSelector((state) => state.filter.hotels.data || []);
  const queryParameters = new URLSearchParams(window.location.search);
  const hotel = queryParameters.get("hotel");
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
  const setting_hotelCode = useSelector((s)=>s?.auth?.user?.profile?.settings?.selected_hotel_code || "")

  const onSelectionChange = (event, newValue) => {
      event.stopPropagation()
    if(newValue)
      dispatch(setSelectedHotel(newValue));
  };

  useEffect(() => {
    if (hotel) {
      const _hotel = hotels.find((_h) => _h.hotel_code == hotel);
      dispatch(setSelectedHotel(_hotel));
    }
    // else dispatch(setSelectedHotel(hotels[0]));
  }, [hotel]);

  useEffect(() => {
    // console.log("called otellsit",hotels,selectedHotel,setting_hotelCode)
    if (hotels.length > 0 && !Object.keys(selectedHotel).length > 0) {
      let _h = hotels[0]
      if(setting_hotelCode)
      {
        const hotel = hotels.find(e=>e.hotel_code == setting_hotelCode)
        if(hotel)
          _h = hotel
      }      
      dispatch(setSelectedHotel(_h));
    }
  }, [hotels]);

  return (
    <Box>
      <label
        style={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#181A1B",
        }}
        htmlFor="data-range"
      >
        Hotel
      </label>
      <Autocomplete
        id={`filter-Hotels`}
        options={hotels}
        value={selectedHotel}
        onChange={onSelectionChange}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              style: {
                ...params.inputProps.style,
                padding: 0,
              },
              sx: {
                ...params.inputProps.sx,
                boxShadow: "none",
              },
            }}
          />
        )}
        sx={{ bgcolor: "#FFFFFF" }}
        disableClearable={true}
      />
    </Box>
  );

  // return (
  //   <div className="hotellist">
  //     <img className = 'HotelImage' src = {hotelImg} alt = 'hotel image'/>
  //     {hotels.length > 0
  //       ? (
  //         <select
  //           value={selectedHotel.name}
  //           onChange={onSelectionChange}
  //           className="dropdown-list">
  //             <optgroup style = {{fontStyle: 'normal'}} label = "Hotels">
  //               {hotels.map((hotel, index) => {
  //                 return (
  //                   <option value={hotel.name} key={index}>
  //                     {hotel.name}
  //                   </option>
  //                 );
  //               })}
  //             </optgroup>
  //             <optgroup style = {{fontStyle: 'normal'}} label = "Groups">
  //               <HotelGroups
  //                 setHotelGroupsData={setHotelGroupsData}
  //               />
  //             </optgroup>

  //         </select>)
  //   : ("")
  //   }
  //   </div>
  // );
};

export default HotelList;
