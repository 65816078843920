import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFilterTopics, setSelectedFilterTopics } from '../../commons/components/Filter/actions/actions';

const FiltersTopic = props => {

    const [selections, setSelections] = useState(props.selectedFilterTopics);
    
    const onSelectionChange = (event, query_key) => {
        console.log(`selectionChange ${query_key} ${event.target.value} selection: ${JSON.stringify(selections)}`);
        let _selections = {...selections}

        _selections[query_key] = event.target.value;
        if(query_key=='category'){
            _selections['phrase'] = 'All';
        }
    
        setSelections(_selections);
        console.log(`selectionChange ${query_key} ${event.target.value} selection: ${JSON.stringify(selections)}`);
        
    }

    useEffect(()=> {
        props.fetchFilterTopics(false); // do not include sentiment options in response

    }, [])

    useEffect(()=> {
        if(props.applySelection == 'apply-start') {
            props.setSelectedFilterTopics(selections);
        }
    }, [props.applySelection])

    
    return (
      <>
          { props.showFilters && props.filterTopics.map((obj, index) => {
              return (
                <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                    <span key={"s"+index} className="filter-saperater" ></span>
                    <div key={index} className = 'SidenavFiltersContainer'>
                        <span>{obj.name}</span>
                        <select
                            id={obj.query_key}
                            query_key={obj.query_key} 
                            value={selections[obj.query_key]}
                            onChange={(e) => onSelectionChange(e, obj.query_key)}>
                            
                            {obj['options'] instanceof Array 
                            ? (obj['options'].map((option, v_index) => {
                                return (
                                    <option value={option.query_value} key={`${obj.query_key}_${v_index}`}>
                                    {option.display_value}
                                    </option>
                                );
                                }))
                            : selections != null && selections['category'] && selections['category'] != 'All'
                            ? (obj['options'][selections['category']] && obj['options'][selections['category']].map((option, v_index) => {
                                return (
                                    <option value={option.query_value} key={`${obj.query_key}_${v_index}`}>
                                    {option.display_value}
                                    </option>
                                );}
                            ))
                            : ( <option value={'All'} key={`${'sub-topic'}_${1}`}>
                                    {'All'}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                </div>)
          })}
      </>
    );

}

const mapDispatchtoProps = (dispatch, ownProps) => {
    return {
        fetchFilterTopics: (includeSentiment) => {dispatch(fetchFilterTopics(includeSentiment))},
        setSelectedFilterTopics: (selection) => {dispatch(setSelectedFilterTopics(selection))}
    }
}

const mapStateToProps = state => {
    return{
        brandColor: state.gen.brandColor,
        filterTopics: state.filter.filterTopics.data || [],
        selectedFilterTopics: state.filter.selectedFilterTopics || {}
    };
}

export default connect(mapStateToProps, mapDispatchtoProps)(FiltersTopic);