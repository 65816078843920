import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const makeString = (number) => {
    return number.toString() + "px";
}

const useStyles = makeStyles(props => ({
    root: {
        height : props => makeString(props.height),
        width : props => (props.inheritsWidth?props.width:makeString(props.width)),
        fontSize : props.fontSize,
        background : props => props.backgroundColor,
        color : '#7ea63f',
        border : '1px solid',
        textTransform : 'none',
        '&:hover': {
            background: props => props.backgroundColor
        },
        '&:focus': {
            outline: "none"
        },
        '@media (max-width:768px)': {
            width : props => (props.inheritsWidth?props.width:makeString(0.75 * props.width)),
            fontSize : props.fontSize-1
          },
        '@media (max-width:450px)': {
            width : props => (props.inheritsWidth?props.width:makeString(0.60 * props.width)),
            fontSize : props.fontSize-2
        }
    }
}));
  
function ItemAddButton(props){
    const classes = useStyles(props);
    return(
        props.disabled ?
        <Button startIcon = {<AddIcon />} className = {classes.root} disabled >
        {props.disabledTitle}
        </Button> 
        :
        <Button startIcon = {<AddIcon />} className = {classes.root} onClick = {props.clickHandler}>
        {props.buttonTitle}
        </Button>
    );
}

ItemAddButton.defaultProps = {
    height : 50,
    width : 140,
    fontSize : 10,
    disabled : false,
    inheritsWidth : false,
    backgroundColor : 'white'
}

export default ItemAddButton;

