import React from 'react'
import { stringAvatar } from '../../utility'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Avatar } from '@mui/material';

const AvatarWithON_OFF = ({badge=false,showOn=false,user={},style={},broadCast=false}) => {
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
        //  #44b700
          backgroundColor:  `${showOn?'#66FF00':"lightgray"}` ,
          color: `${showOn?'#66FF00':"lightgray"}`,
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            // animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }));

  return (
  <>
 { badge ?      <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
    >
              <Avatar
                src={user?.photo}
                {...stringAvatar(
                  broadCast?user.name:user?.first_name
                    ? `${user?.first_name} ${user?.last_name}`
                    : user?.last_name
                ,style)}
              />
                    </StyledBadge>:              <Avatar
                      src={user?.photo}
                      {...stringAvatar(
                        broadCast?user.name: user?.first_name
                          ? `${user?.first_name} ${user?.last_name}`
                          : user?.last_name,style
                      )}
                    />
}
  
  </>
  )
}

export default AvatarWithON_OFF
