import React, { Fragment, useState } from 'react';
// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// import css from '../../CreateSurvey.module.css';
// import Scale from './Scale';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Checkbox,
    Radio,
    TextField,
    Button,
    Slider,
    RadioGroup,
    FormControlLabel,
    useTheme,
    IconButton,
    Tooltip
  } from '@mui/material';
  import {
    LinearScale,
    CheckBox as CheckBoxIcon,
    RadioButtonChecked,
    TextFields,
    ThumbUp,
    Timeline
  } from '@mui/icons-material';


const AnswerTypeOptions = props => {
    const theme = useTheme();

    const answerTypes = [
      {
        id: 'Slider',
        title: 'Slider',
        description: 'Let users select a value within a range',
        icon: <Timeline />,
        preview: (
          <Box sx={{ px: 2, width: '100%' }}      
          >
            <Slider
              defaultValue={50}
              sx={{
                '& .MuiSlider-thumb': {
                  backgroundColor: theme.palette.primary.main,
                },
                '& .MuiSlider-track': {
                  backgroundColor: theme.palette.primary.main,
                }
              }}
            />
          </Box>
        )
      },
      {
        id: 'Checkbox',
        title: 'Multiple Choice',
        description: 'Allow multiple selections from options',
        icon: <CheckBoxIcon />,
        preview: (
          <Box>
            <FormControlLabel
              control={<Checkbox />}
              label="Option 1"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Option 2"
            />
          </Box>
        )
      },
      {
        id: 'Radio Button',
        title: 'Single Choice',
        description: 'Allow one selection from options',
        icon: <RadioButtonChecked />,
        preview: (
          <RadioGroup defaultValue="1">
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Option 1"
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Option 2"
            />
          </RadioGroup>
        )
      },
      {
        id: 'Text Box',
        title: 'Text Input',
        description: 'Collect written responses',
        icon: <TextFields />,
        preview: (
          <TextField
            placeholder="Enter your answer here..."
            variant="outlined"
            fullWidth
            size="small"
          />
        )
      },
      {
        id: 'Buttons',
        title: 'Yes/No',
        description: 'Simple binary choice',
        icon: <ThumbUp />,
        preview: (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="outlined" size="small">Yes</Button>
            <Button variant="outlined" size="small">No</Button>
          </Box>
        )
      },
      {
        id: 'Scale',
        title: 'Rating Scale',
        description: 'Numerical rating from 1-10',
        icon: <LinearScale />,
        preview: (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            width: '100%',
            px: 1 
          }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
              <IconButton
                key={num}
                size="small"
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: '0.875rem',
                }}
              >
                {num}
              </IconButton>
            ))}
          </Box>
        )
      }
    ];
  
    return (
      <Box sx={{ 
        maxWidth: 'lg', 
        mx: 'auto', 
        p: 4,
        bgcolor: 'background.default' 
      }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Select Answer Type
        </Typography>
        {/* <Typography variant="body1" color="text.secondary" paragraph>
          Choose how you want respondents to answer this question
        </Typography> */}
        
        <Grid container spacing={3}>
          {answerTypes.map((type) => (
            <Grid
            item xs={12} md={6} lg={4} key={type.id}>
              <Tooltip title={`Select ${type.title}`}>
                <Paper
                //   elevation={selected === type.id ? 3 : 1}
                  // onClick={() => handleSelect(type.id)}
                  onClick={() => props.answerTypeHandler(type.id)}
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    height: '100%',
                    transition: 'all 0.2s ease-in-out',
                    // border: selected === type.id 
                    //   ? `2px solid ${theme.palette.primary.main}`
                    //   : '2px solid transparent',
                    '&:hover': {
                      elevation: 3,
                      transform: 'translateY(-2px)',
                      bgcolor: 'action.hover'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1
                  }}>
                    <Box sx={{ 
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      {type.icon}
                    </Box>
                    <Typography variant="h6">
                      {type.title}
                    </Typography>
                  </Box>
                  
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {type.description}
                  </Typography>
  
                  <Box sx={{ 
                    minHeight: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    {type.preview}
                  </Box>
                </Paper>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
}

export default AnswerTypeOptions;