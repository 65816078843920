import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as filterActions from "../Filter/actions/actions";
import * as navActions from "../Navbar/actions/actions";
import { setToInitial } from "../../../actions/loginActions";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Stack,
  ListItem,
  List,
  ListItemText,
  Dialog,
  DialogTitle,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import SideNavBar from "../Navbar/SideNavBar";
import SideFilterNavBar from "../Filter/SideFilter";
import IconEmoji from "../../assets/IconEmoji.svg";
import IconImage from "../../assets/IconImage.svg";
import SendIcon from '@mui/icons-material/Send';
import { WS_URL, url } from "../../../components/Constants/Constants";
import {
  fetchGuests,
  fetchUsers,
  setSelectedChannel,
  setSelectedUser,
  updateOrAddChatUserAndReshuffle,
  updateSuggestedReply,
  updateUserMessage,
} from "../../../apps/Chat/actions/chatActions";
import FileUpload from "../FileUpload/FileUpload";
import { connectingError } from "../Settings/actions/settingsActions";
import ToolTip from "../UI/Tooltip";
import MobileMenu from "./subComponents/MobileMenu";
import {  clearUrlParams, commonFetchData, fetchMessageTemplate, getMessageForWhatsappChannel, saveStaffToken, stringAvatar, trimReview } from "../../utility";
import CustomAlert from "../../../commons/components/UI/CustomAlert"
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import NotificationPopup from "./NotificationPopup";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setInfoForChatInp, updateStaffUnreadMsgCount, updateUnreadMsgCount } from "../../../apps/TeamCommunication/actions/teamComActions";
import { setGlobalMessage } from "../../../actions/dashboardActions";
import filterIcon from "../../assets/filter.svg";
import { isValidJSON } from "../../../components/Utility/Utility";
import { UPDATE_FIREBASE_CRED } from "../../../actions/actionTypes";
import CommonMenu from "../Menus/CommonMenu";
import { SET_NOTIFICATION_UNREAD_COUNT } from "../Navbar/actions/actionTypes";
import AdditionalInfoForChatInput from "./AdditionalInfoForChatInput";
import { debounce } from "lodash";
import { ShowLogoutWarning } from "../PopUp/ShowLogoutWarning";
// import { debounce } from "lodash";


const BasePage = (props) => {
  // Props
  // - pageSideBar -- remove it used in Team management screen. Make it similar to chat
  // - show_bottom_bar -- show hide bottom app bar with input box
  // - inputHandler: handler for the input box in the bottombar
  // - show_side_filter: display/hide side filter bar
  // - handle_open_close_side_filter
  // - pageRightSideBar



  const match = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const send_msg_on_enter_key_press = useSelector((state) => state?.auth?.user?.profile?.settings?.preferences?.send_msg_on_enter_key_press || false);
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
  const isAIEnable = selectedHotel?.hotel_config?.enable_ai || false
  const hotels = useSelector((state) => state.filter.hotels.data || []);
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const activeStaffList = useSelector((state) => state.nav.activeStaff);

  const [sideFilterOpen, setSideFilterOpen] = useState(false);
  const [sideNavBarOpen, setSideNavBarOpen] = useState(false);
  const [pageSideBarOpen, setPageSideBarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const socket = useRef(null);
  const selectedUsers = useSelector((state) => state.chat.selectedUsers || []);
  const selectedBroadCast = useSelector((state) => state.chat.selectedBroadCast || []);
  let key = selectedBroadCast[0]?"selectedGroup_channels":"selectedUser_channels"
  const [whatsappMessageOptions, setWhatsappMessageOptions] = useState({});
  const [MessageTemplate, setMessageTemplate] = useState([]);
  const [selectedMenuForSmallSC, setSelectedMenuForSmallSC] = useState("");
  const [selectedMenuForSmallSCOptions, setSelectedMenuForSmallSCOptions] = useState("");
  const infoForChatInp = useSelector(
    (state) => state.teamCom?.infoForChatInp || null
  );
  const msgInpRef = useRef()
  const prevSelectedIdForChat = useRef({})

  const channels = useSelector(
    (state) => state.chat[key] || []
  );
  const channel = useSelector((state) => state.chat.selected_channel || "");
  const selected_channel_data = useSelector((state) => state.chat?.selected_channel_data || {});
  const [selectedWhatsappMessageOption, setselectedWhatsappMessageOption] = useState("");
  const [selectedMessageTemplate, setselectedMessageTemplate] = useState("");
  const selectedChannels = useSelector(
    (state) => state.teamCom.channels.selectedChannels || []
  );
  const selectedDirectMessageUsers = useSelector(
    (state) => state.teamCom.directMessage.selectedUsers || []
  );
  const showLogoutWarningFlag = useSelector((state) => state?.common?.showLogoutWarning || false);
  const history = useHistory()
  const setting_hotelCode = useSelector((s)=>s?.auth?.user?.profile?.settings?.selected_hotel_code || "")

  // const errors = useSelector(
  //   (state) =>
  //     state.chat.error ||
  //     state.nav.error ||
  //     state.review.reviews.error ||
  //     state.guestReq.error ||
  //     state.teamCom.other_error ||
  //     state.teamCom.channels.error ||
  //     state.teamCom.directMessage.error ||
  //     state.settings.sources.error ||
  //     ""
  // );

  const chatVarMap = {
    selectedChannels,
    selectedDirectMessageUsers,
    selectedUsers,
    selectedBroadCast,
  };

  const checkIfPrevAndCurrUserSame = (prevSelectedIdForChat, selectedVariables) => {
    const { varName, id } = prevSelectedIdForChat.current;
    const selectedArray = selectedVariables[varName];
    // Check if the array exists and contains the id
    if (selectedArray && Array.isArray(selectedArray) && selectedArray.length>0) {
      return selectedArray[0].user_id === id
    }
  
    return false; // Return false if not found or if array doesn't exist
  };

  const globalMessage = useSelector(
    (state) =>
      state.common?.globalMessage?.mess ? state.common.globalMessage : ""
  );

  const humumInteractionUpdateMsg = useSelector((state)=>state.chat.successMessage)

  const [open, setOpen] = useState(false);
  const [copiedImage, setCopiedImage] = useState(null);
  const user = useRef({});
  const [_, setDecoy] = useState(Date.now());

  const {
    selectedSource = {},
    error = "",
    connecting = "",
  } = useSelector((state) => state.settings.sources);
  const toolTip = useRef();
  const hotelRef = useRef();
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const suggestedReply= useSelector((state) => state.chat?.suggestedReply || []);






  useEffect(()=>{
    if(navObj.frontend_route == "/chat-support" && selectedUsers.length> 0){
(async()=>{
  let data = await fetchMessageTemplate(selectedHotel.hotel_code,{
    "fill-template":true,
    "guest-user-id":selectedUsers[0].user_id,
    active:true
    })
    if(data)
      setMessageTemplate(data)
})()
    }

  },[selectedUsers])

  useEffect(() => {
    const messageListener = async(message) => {
 
      try {
        if(!isValidJSON(message?.data))
          return

        const data = JSON.parse(message?.data)
        console.log(data)
        if(data.type ==="native_app_data" && data.fcm_token && data?.device_id){
        console.log("message Listner Called.............")
      console.log(message.data,selectedHotel); 
      let resp = await saveStaffToken(data.fcm_token,data?.device_id,selectedHotel?.hotel_code)
      if(resp)
        dispatch({
          type:UPDATE_FIREBASE_CRED,
          payload:data
        })
      let msg = resp?"Staff fcm data successfully saved":"Staff fcm data not saved"
            console.log(msg)
        }
      } catch (error) {
        console.log(error)
      }
    };
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [selectedHotel]);

  useEffect(() => {
    user.current = selectedUsers[0] || {};
    setDecoy(Date.now());
    setselectedWhatsappMessageOption("")
    setselectedMessageTemplate("")
  }, [selectedUsers]);

  useEffect(() => {
    const flag = !match &&  ["/reviews","/guest-requests","/settings","/chat-support"].includes(navObj.frontend_route);
    setSideFilterOpen(flag);
  }, [navObj.frontend_route, match]);

  useEffect(() => {
   dispatch(navActions.fetchNotification())
   dispatch(navActions.fetchNotificationStat())
  }, [navObj?.name]);

  useEffect(() => {     
    if (channels.length > 0 && !channel){
      dispatch(setSelectedChannel(channels[0].code,key));
    } 
  }, [channels,channel]);

  useEffect(() => {
    if (Object.keys(selectedHotel).length > 0) {
      window.dataLayer.push({
        'event': 'hotel_code',
        'hotel_code': selectedHotel.hotel_code,
      });
      if (socket.current?.readyState === 1) socket.current.close();
      hotelRef.current = selectedHotel;
      connectWebSocket();
      // dispatch(filterActions.fetchFilters(navObj.frontend_route));
      dispatch(navActions.fetchActiveStaffMembers(navObj.id));
      dispatch(navActions.fetchSideNavLinks())
    }
  }, [selectedHotel]);

 

  const checkHotelExists = (code) => {
    const hotel = hotels.filter((hotel) => hotel.hotel_code == code);
    return hotel?.length > 0;
  };

  useEffect(() => {
    // console.log("called basePage",hotels,selectedHotel,setting_hotelCode)
    if (
      hotels.length > 0 &&
      Object.keys(selectedHotel).length == 0
    )
   {
    let _h = hotels[0]
    if(setting_hotelCode && checkHotelExists(setting_hotelCode))
      {
        const hotel = hotels.find(e=>e.hotel_code == setting_hotelCode)
        if(hotel)
          _h = hotel
      }
    dispatch(filterActions.setSelectedHotel(_h));
   }
    
  }, [hotels]);

  useEffect(() => {
    if (token == null) {
      dispatch(setToInitial());
      window.location = "/login";
    } else if (navObj?.name != "Settings") {
      console.log("filter called");
      // dispatch(navActions.fetchSideNavLinks());
      dispatch(filterActions.fetchHotels());
    }
  }, [token]);

  // const handleChangeMessage = async (e) => {
  //   e.stopPropagation()
  //   setMessage((p)=>e.target.value);
  // };

  // const debouncedHandleChangeMessage = useCallback(
  //   debounce((value) => setMessage(value), 5),
  //   []
  // );

  // const handleChangeMessage = (event) => {
  //   debouncedHandleChangeMessage(event.target.value);
  // };

  const handleChangeMessage = useCallback(() => {
    // const message = msgInpRef.current.value;
    // console.log("Message changed:", message);
  }, []);

  const handleSuggestedMesg = (element)=>{
    msgInpRef.current.value = element.text;
    // setMessage(element.text)
    dispatch(   updateSuggestedReply({
      type:"delete",
      ...element
    }))
  }

  const handleSuggestedMesgDelete = (event,element)=>{
    event.stopPropagation();
    // console.log(element)
    dispatch(   updateSuggestedReply({
      type:"delete",
      ...element
    }))
  }

  const handleSendMessage = (e,fromButton=false) => {
    e?.stopPropagation()
    if ((e.key === "Enter" && !e.shiftKey) || fromButton) {
      if (props.inputHandler && msgInpRef.current?.value.length > 0) {
        const data = { msg: msgInpRef.current?.value };
        if(selectedWhatsappMessageOption){
          data.msg_type ="TEXT"
          data.template_name_id = selectedWhatsappMessageOption
        }
        if(infoForChatInp?.review_id){ 
          data.review_id = infoForChatInp?.review_id
          dispatch(setInfoForChatInp(null))
          clearUrlParams()
        }
          props.inputHandler(data, channel);
        
      }
      // setMessage("");
      msgInpRef.current.value = '';
      setselectedWhatsappMessageOption("")
      setselectedMessageTemplate("")


      if(suggestedReply.length>0){
        dispatch(    updateSuggestedReply({
          type:"bulk_delete",
          user:user?.current?.user_id
        }))
      }
     
    }
  };



  const handleChangeChannel = async(e) => {
    e?.stopPropagation()
    dispatch(setSelectedChannel(e.target.value,key));
    setselectedWhatsappMessageOption("")
    setselectedMessageTemplate("")
    if(e.target.value=="WHATSAPP" && selectedUsers.length>0){
    //   if(selectedUsers.length<0)
    //     return dispatch(
    //       dispatch(setGlobalMessage({
    //         mess:"User is not selected",
    //         type:'error'
    //        })
    //   )
    // )
      let resp = await getMessageForWhatsappChannel(selectedUsers[0].user_id,selectedHotel.hotel_code)
      if(!resp.status)
        return dispatch(
          dispatch(setGlobalMessage({
            mess:resp?.data ||"No data found for whatspaa channel",
            type:'error'
           })
      ))
      setWhatsappMessageOptions(resp.data)
    }
  };

  const handleWhatsappChannel = (e)=>{
    e.stopPropagation()
    setselectedWhatsappMessageOption(e.target.value)
    msgInpRef.current.value = whatsappMessageOptions[e.target.value]|| "";
    // setMessage(whatsappMessageOptions[e.target.value]|| "")

  }

  const handleMessageTemplateChange = (e)=>{
    e?.stopPropagation()
    console.log(e.target.value)
    setselectedMessageTemplate(e.target.value)
    msgInpRef.current.value = e.target.value.content;
    // setMessage(e.target.value.content)

  }

  const connectWebSocket = () => {
    console.log(
      `opening new socket connection in basepage for userslist with hotel`,
      hotelRef.current?.hotel_code
    );
    socket.current = new WebSocket(
      `${WS_URL}/innspire-ai/ws/staff/notifications/?token=${token}&hotel=${hotelRef.current?.hotel_code}`
    );
    socket.current.onopen = (event) => {
      console.log(
        `socket opened for userslist and hotel:`,
        hotelRef.current?.hotel_code
      );
      
    //  if(navObj.name !== "Chats")
    //   dispatch(fetchGuests());

    };

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("userlist: msg received");
      if (data.type == "staff_notification") {
        console.log(`userlist: payload received: ${JSON.stringify(data)}`);
        const _data = data.payload;
        if (_data.type == "feedback") {
          if (_data.data == "DISLIKE")
            dispatch(
              updateOrAddChatUserAndReshuffle("feedback_neg", _data.user)
            );
          dispatch(updateUserMessage(_data.msg));
        } else if (_data.type == "new_user" || _data.type == "user_update") {
          if (user.current?.user_id == _data.user.user_id) {
            prevSelectedIdForChat.current = {
              varName:"selectedUsers",
              id: user.current?.user_id 
            }
            dispatch(setSelectedUser(_data.user));
          }
          dispatch(
            updateOrAddChatUserAndReshuffle("user_new_or_update", _data.user)
          );
        } else if (_data.type == "page_viewer_add_staff") {
          // console.log()
          dispatch(
            navActions.updateActiveStaffList(_data.staff, true, _data.page_id)
          );
        } else if (_data.type == "page_viewer_remove_staff") {
          dispatch(
            navActions.updateActiveStaffList(_data.staff, false, _data.page_id)
          );
        }
        else if (_data.type == "notification_msg") {
          dispatch(
            navActions.appendNewNotification(_data.data)
          )
        }
        else if (_data.type == "staff_staff_unread_msg_count") {
          dispatch(
            updateUnreadMsgCount(_data.data,"Team Communication")
          )
          if(_data?.per_conversation_count && _data.per_conversation_count.length > 0)
          dispatch(updateStaffUnreadMsgCount(_data.per_conversation_count))
        }
        else if (_data.type == "notification_stat")
        {
          dispatch( {
            type: SET_NOTIFICATION_UNREAD_COUNT,
            payload:_data.data,
          })
        }
      }
    };

    socket.current.onclose = (event) => {
      if (document.visibilityState == "visible" && event.code != 1000) {
        setTimeout(() => {
          connectWebSocket();
        }, [5000]);
      }
      console.log(
        `userlist: socket closed for hotel`,
        selectedHotel?.hotel_code
      );
    };

    socket.current.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      socket.current?.close();
    };
  };

  const onPageVisiblityChange = () => {
    if (document.hidden) {
      console.log(
        `page is now hidde userslist and socket: ${socket.current?.readyState}`
      );
    } else {
      if (socket.current?.readyState == 3) connectWebSocket();
      console.log(
        `page is now visible (state: ${document.visibilityState}) for userslist and socket: ${socket.current?.readyState}`
      );
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onPageVisiblityChange);

    return () => {
      socket.current?.close();
      document.removeEventListener("visibilitychange", onPageVisiblityChange);
    };
  }, []);

  //open image upload modal
  const handleModalToggle = () => {
    setOpen((prev) => !prev);
  };

  //for disabling message box if no chat selected
  const checkForSelectedChat = () => {
    return (
      (selectedChannels.length === 0 &&
      selectedDirectMessageUsers.length === 0 &&
      selectedUsers.length === 0 && selectedBroadCast.length===0)
    );
  };

  useEffect(()=>{
   let flag = checkIfPrevAndCurrUserSame(prevSelectedIdForChat,chatVarMap) 
  if( msgInpRef?.current?.value && !flag )
    msgInpRef.current.value = '';
  if(Object.keys(prevSelectedIdForChat.current).length>0)
  prevSelectedIdForChat.current= {}
  },[selectedChannels,selectedDirectMessageUsers,selectedUsers,selectedBroadCast])

  // show active users name
  //error icon handling
  const handleAvatarEnter = (e, name) => {
    toolTip.current.innerText = name;
    toolTip.current.style.display = "inline-block";
    toolTip.current.style.left =
      e.clientX - toolTip.current?.offsetWidth + "px";
    toolTip.current.style.top = e.clientY + "px";
  };

  const handleAvatarLeave = (e) => {
    toolTip.current.style.display = "none";
    toolTip.current.innerText = "";
  };

  const handlePaste = (event) => {
    // event.preventDefault();
    if (event.clipboardData.files.length > 0 && !checkForSelectedChat()) {
      setCopiedImage(event.clipboardData.files)
      handleModalToggle()
    }
  };


  const getOptionForMenu =()=>{
let op = [{
  label:"Channels"
}]

if(channel!=="WHATSAPP" && MessageTemplate.length>0 && selectedUsers.length>0){
  op.push({
    label:"Select Message Template"
  })
}

if(channel==="WHATSAPP" && selectedUsers.length>0){
  op.push({
    label:"Select WhatsApp Message Template"
  })
}
    return op
  }

  const handleOptionClick = (val)=>{
    setSelectedMenuForSmallSC(val)
  }

  const createOptionsForSelectedMenu = ()=>{
    let jsx = []
    switch (selectedMenuForSmallSC) {
      case "Channels":
        if(channels.length>0){
          jsx = channels?.map((channel) => {
            return (
                                  <MenuItem
                                  key={channel.code}
                                  value={channel.code}
                                  onClick={(e)=>{
                                    handleChangeChannel({
                                      target:{
                                        value:channel.code
                                      },
                                      stopPropagation:e.stopPropagation
                                    })
                                    setSelectedMenuForSmallSC("")
                                  }}
                                >
                               
                  
                                <List dense={true}>
              
                <ListItem>
                  <ListItemText
                    primary={channel.display}
                    sx={{
                      maxWidth:"400px",
                      textWrap:"wrap"
                    }}
                  />
                </ListItem>
              
            </List>
                              
                                </MenuItem>
            );
          })
        }
        break;
        case "Select Message Template":
          if(MessageTemplate.length>0){
            jsx =  MessageTemplate?.map((k) => {
               return (
                 <MenuItem
                   key={k.id}
                   value={k}
                   onClick={(e)=>{
                    handleMessageTemplateChange({
                      target:{
                        value:k
                      },
                      stopPropagation:e.stopPropagation
                    })
                    setSelectedMenuForSmallSC("")
                  }}
                 >
                 <List dense={true}>
   
   <ListItem>
   <ListItemText
     primary={k.name}
     secondary={k.content.slice(0, 100) + (k.content.length > 100 ? '...' : '')}
     sx={{
       maxWidth:"400px",
       textWrap:"wrap"
     }}
   />
   </ListItem>
   
   </List>
               
                 </MenuItem>
               );
             })}
          break;
    

          case "Select WhatsApp Message Template":
            if(whatsappMessageOptions && Object.keys(whatsappMessageOptions).length>0){
              jsx =  Object.keys(whatsappMessageOptions)?.map((k) => {
                 return (
                   <MenuItem
                   key={k}
                   value={k}
                     onClick={(e)=>{
                      handleWhatsappChannel({
                        target:{
                          value:k
                        },
                        stopPropagation:e.stopPropagation
                      })
                      setSelectedMenuForSmallSC("")
                    }}
                   >
                   <List dense={true}>
     
     <ListItem>
     <ListItemText
       secondary={k.slice(0, 100) + (k.length > 100 ? '...' : '')}
       sx={{
         maxWidth:"400px",
         textWrap:"wrap"
       }}
     />
     </ListItem>
     
     </List>
                 
                   </MenuItem>
                 );
               })}
            break;
      
      default:
        // return jsx
        break;
    }
 if(jsx.length === 0){
  jsx.push(
    <MenuItem
  >
  <List dense={true}>

<ListItem>
<ListItemText
primary={"No Option"}
sx={{
maxWidth:"400px",
textWrap:"wrap"
}}
/>
</ListItem>

</List>

  </MenuItem>
  )
 }
    return jsx
  }

  return (
    <Box
      sx={{
        background: "#ffffffcc",
        width: "100%",
        minHeight: "100%",
        display: "flex",
      }}
    >

<Dialog sx={{
  minWidth:"200px"
}} onClose={()=>{
  setSelectedMenuForSmallSC("")
}} open={selectedMenuForSmallSC}>
<DialogTitle>Select One</DialogTitle>
<Divider/>
<Box   sx={{
  maxHeight:"300px",
  overflowY:"auto"
}}>

{
  createOptionsForSelectedMenu()
}
  </Box>
</Dialog>

      <CustomAlert type={globalMessage.type} message={globalMessage.mess} />
      <CustomAlert type={'success'} message={humumInteractionUpdateMsg} position={{ vertical: "top", horizontal: "center" }} style={{ background: "red", color: "white"}} autoclose={false}/>
      <SideNavBar open={sideNavBarOpen} handleOpen={setSideNavBarOpen} />
      {props.pageSideBar &&
        React.cloneElement(props.pageSideBar, {
          open: pageSideBarOpen,
          handleOpen: setPageSideBarOpen,
        })}

      <Box sx={{ flexBasis: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            // background: "#ffffffcc",
            background:"#fff",
            position: "sticky",
            top: 0,
            zIndex: 1001,
          }}
        >
          <Box
            sx={{
              padding: "22px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <MobileMenu
                hasSideBar={props.pageSideBar ? true : false}
                pageSideBarName={props.pageSideBarName}
                setNavBarOpen={setSideNavBarOpen}
                setSideBarOpen={setPageSideBarOpen}
              />
            <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                {props.title}
              </Typography>

            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
             <NotificationPopup/>
              <AvatarGroup
                sx={{
                  "& .MuiAvatarGroup-avatar": {
                    width: "32px",
                    height: "32px",
                    fontSize: "14px",
                  },
                }}
              >
                {activeStaffList[navObj.id]?.map((staff, idx) => {
                  return (
                    <Avatar
                      key={`${staff.first_name}_${idx}`}
                      {...stringAvatar(
                        staff.first_name
                          ? `${staff.first_name} ${staff.last_name}`
                          : staff.last_name
                      )}
                      src={staff?.profile?.photo}
                      onMouseEnter={(e) =>
                        handleAvatarEnter(
                          e,
                          `${staff.first_name} ${staff.last_name}`
                        )
                      }
                      onMouseLeave={handleAvatarLeave}
                    />
                  );
                })}
                
              </AvatarGroup>
              {!sideFilterOpen && props.show_side_filter && (
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => setSideFilterOpen(true)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
               <img width={18} height={18} src={filterIcon} />
                </IconButton>
              )}
            </Box>
          </Box>

          <Divider />
        </Box>
        <Box
          sx={{
            padding: { xs: "12px 12px 0 12px", sm: "24px 24px 0 24px" },
            width: "100%",
            flexBasis: "100%",
            background: "#ffffffcc",
            // background:"#fff"
          }}
        >
          {props.children}
        </Box>
        {/* Footer Bar with comment box */}
        {props.show_bottom_bar && (
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              // background: "#ffffffcc",
              background: "white",
              zIndex: 1001,
            }}
          >
        {
         suggestedReply && suggestedReply.length > 0 && <Box sx={{ display: "flex", alignItems: "center",overflowX:"scroll" }}>
          {
             suggestedReply?.map((e,i)=>{
             return  <Box key={i} sx={{
               maxWidth: {xs:'90%', sm:"60%",md:"35%"},
               margin: "auto",
               minWidth: "40%",
               minHeight: "10vh",
               border: "2px solid",
               display: "flex", alignItems: "center", justifyContent: "center",
               padding: {xs:'4%', sm:"3%",md:"2%"},
               background: 'rgb(236 231 231 / 9%)',
               borderRadius: '16px',
              //  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              //  backdropFilter: 'blur(80.4px)',
               WebkitBackdropFilter: 'blur(6.4px)',
               border: '1px solid rgba(255, 255, 255, 0.14)',
               color: '#000000a3',
               display:user?.current?.user_id === e.user?"block":"none",
               position:"relative",
               cursor:"pointer"
             }}
             onClick={()=>handleSuggestedMesg(e)}
             >
              <CloseIcon sx={{position:"absolute",right:"1%",top:"5%", fontSize:"0.8rem",opacity:"0.3"}} onClick={(event)=>handleSuggestedMesgDelete(event,e)}/>
             <Tooltip title={e.text} sx={{cursor:"pointer"}}>
             <Typography  sx={{
                 textAlign: "center", display: '-webkit-box',
                 WebkitBoxOrient: 'vertical',
                 overflow: 'hidden',
                 WebkitLineClamp: 2,
                 fontSize:{sm:"1rem",md:"0.8rem"}
               }}>
                 {e.text}
               </Typography>
             </Tooltip>
             </Box>
           })
          }
         </Box>
        }

        {
            infoForChatInp && <AdditionalInfoForChatInput text={infoForChatInp} setText={setInfoForChatInp} clearUrlParams={clearUrlParams} data={infoForChatInp} />
        }
            {Object.keys(user.current).length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  justifyContent: "center",
                  marginBottom:"15px",
                  marginTop: "15px"
                }}
              >
                {!user.current.chat_closed &&
                  props.close_chat_btn &&
                  props.close_chat_btn}
                { user.current.requires_assistance &&
                  props.move_to_ongoing_btn &&
                  props.move_to_ongoing_btn}
                {isAIEnable && props.bot_toggle_btn && props.bot_toggle_btn}
              </Box>
            )}
            <Divider />
            <Box
              sx={{
                minHeight: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: { xs: "8px", sm: "24px" },
                flexDirection: "column",
              }}
            >
              <TextField
                onPaste={handlePaste}
                type="text"
                // value={message}
                inputRef={msgInpRef}
                sx={{
                  maxWidth: { xs: "100%", sm: selectedWhatsappMessageOption!="" || selectedMessageTemplate !="" ?"100%":"566px" },
                  width: { xs: "100%", sm: "-webkit-fill-available" },
                }}
                disabled={checkForSelectedChat() || selectedWhatsappMessageOption!=""}
                onKeyUp={(e)=>{                  
                  if(send_msg_on_enter_key_press)
                    handleSendMessage(e)
                }}
                onKeyDown={(e) =>
                  // e.key === "Enter" && !e.shiftKey && e.preventDefault()
                  e.key === "Enter" && send_msg_on_enter_key_press && !e.shiftKey && e.preventDefault()
                }
                placeholder="Enter text..."
                multiline
                maxRows={4}
                onChange={handleChangeMessage}
                InputProps={{
                  sx: {
                    padding: "12px",
                    borderRadius: "8px",
                    "& textarea": {
                      color: "#6D7378",
                    },
                    "&.css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E4E6E7",
                    },
                  },
                  endAdornment: (
                    <InputAdornment style={{ gap: "5px" }} position="end">
                      {channels?.length > 0 && (
                        <FormControl
                          variant="standard"
                          size="small"
                          sx={{ minWidth: "80px",
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex", 
                            },
                            flexDirection:"row",gap:"5px"
                           }}
                        >
                          <Select

                            value={channel}
                            onChange={handleChangeChannel}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            hidden={channels?.length === 0}
                          >
                            {channels?.map((channel) => {
                              return (
                                <MenuItem
                                  key={channel.code}
                                  value={channel.code}
                                >
                                  {channel.display}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {
                            (channel==="WHATSAPP" && selectedUsers.length>0) &&        <Select
                            value={selectedWhatsappMessageOption}
                            onChange={handleWhatsappChannel}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            hidden={!whatsappMessageOptions|| Object.keys.length === 0}
                          >
                                   <MenuItem
                               
                                  value={""}
                                  selected={true}
                                >
                               Select Message
                                </MenuItem>
                            {whatsappMessageOptions && Object.keys(whatsappMessageOptions)?.map((k) => {
                              return (
                                <MenuItem
                                  key={k}
                                  value={k}
                                >
                                  {k}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          }

                          {
                            (channel!=="WHATSAPP" && MessageTemplate.length>0 && selectedUsers.length>0) &&        <Select
                            value={selectedMessageTemplate}
                            onChange={handleMessageTemplateChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            renderValue={(e)=>{
                              return  e?e.name:"select message"
                            }}
                            // hidden={!MessageTemplate|| !selectedMessageTemplate}
                          >
                      
                            {MessageTemplate && MessageTemplate?.map((k) => {
                              return (
                                <MenuItem
                                  key={k.id}
                                  value={k}
                                >
                                <List dense={true}>
              
                <ListItem>
                  <ListItemText
                    primary={k.name}
                    secondary={k.content.slice(0, 100) + (k.content.length > 100 ? '...' : '')}
                    sx={{
                      maxWidth:"400px",
                      textWrap:"wrap"
                    }}
                  />
                </ListItem>
              
            </List>
                              
                                </MenuItem>
                              );
                            })}
                          </Select>
                          }
                   
                        </FormControl>
                      )}

<CommonMenu
Options={
  getOptionForMenu()
}
additionalCss={{display: {
  xs: "flex",
  sm: "flex",
  md: "none", 
}}}
disabled={checkForSelectedChat()}
handleOptionClick={handleOptionClick}
/>
{
  selected_channel_data?.display && <Typography sx={{
    fontSize:"12px",
    display: {
      xs: "inline",
      sm: "inline",
      md: "none", 
    }
  }}>
  {selected_channel_data.display}
</Typography>
}

                      {props.imageSumbitLoader ? (<Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                        <CircularProgress color="success" />
                      </Stack>) : (channel!=="SMS" && <IconButton
                        edge="end"
                        onClick={handleModalToggle}
                        disabled={checkForSelectedChat()}
                      >
                        <img src={IconImage} />
                      </IconButton>)}
                      {/* <IconButton edge="end" disabled={checkForSelectedChat()}>
                        <img src={IconEmoji} />
                      </IconButton> */}
                      <IconButton edge="end" disabled={checkForSelectedChat()}>
                      <SendIcon sx={{color:"darkgrey"}} onClick={(e)=>handleSendMessage(e,true)}/>
                      </IconButton >
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {props.show_side_filter && sideFilterOpen && (
        <SideFilterNavBar
          open={sideFilterOpen}
          handleOpen={setSideFilterOpen}
        />
      )}
      {props.pageRightSideBar && props.pageRightSideBar}
      {showLogoutWarningFlag && <ShowLogoutWarning />}
      {/* File upload UI */}
      <Modal
        open={open}
        onClose={handleModalToggle}
        aria-labelledby="modal-upload-image"
        aria-describedby="modal-select-or-drag&drop-image"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: "800px" },
          }}
        >
          <IconButton
            onClick={handleModalToggle}
            aria-label="delete"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            size="large">
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <FileUpload
            inputHandler={props.inputHandler}
            closeUploadModal={handleModalToggle}
            copiedImage={copiedImage}
            setCopiedImage={setCopiedImage}
          />
        </Box>
      </Modal>

      {/* custom tooltip to display text on hover */}
      <ToolTip
        ref={toolTip}
        styles={{
          zIndex: 1001,
          backgroundColor: "#575757",
          color: "white",
          fontSize: "12px",
        }}
      />
        
    </Box>
  );
};

export default BasePage;
