import { setGlobalMessage } from "../../../actions/dashboardActions";
import { check_auth_error } from "../../../components/Utility/Utility";
import axiosClient from "../../../httpClient";
import * as teamComActionTypes from "./teamComActionTypes";

export const setSelectedDmu = (dmu,setExact=false) => {
  return {
    type: teamComActionTypes.SET_SELECTED_DMU,
    payload: {dmu,setExact},
  };
};

export const unsetSelectedDmu = (dmu_id) => {
  return {
    type: teamComActionTypes.UNSET_SELECTED_DMU,
    payload: dmu_id,
  };
};

export const setUnReadCount = (id) => {
  return {
    type: teamComActionTypes.SET_UNREAD_COUNT,
    payload: id,
  };
};

export const setSelectedTeamComChannel = (channel) => {
  return {
    type: teamComActionTypes.SET_TEAM_COM_SELECTED_CHANNEL,
    payload: channel,
  };
};

export const unsetSelectedChannel = (conversation_id) => {
  return {
    type: teamComActionTypes.UNSET_SELECTED_CHANNEL,
    payload: conversation_id,
  };
};

export const fetchChannelsRequest = () => {
  return {
    type: teamComActionTypes.FETCH_TEAM_COM_CHANNELS_REQUEST,
  };
};

export const fetchChannelsSuccess = (channels) => {
  return {
    type: teamComActionTypes.FETCH_TEAM_COM_CHANNELS_SUCCESS,
    payload: channels,
  };
};

export const fetchChannelsError = (error) => {
  return {
    type: teamComActionTypes.FETCH_TEAM_COM_CHANNELS_ERROR,
    payload: error,
  };
};

export const fetchTeamComChannels = () => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(fetchChannelsRequest());
    axiosClient
      .get(`/innspire-ai/staff-chat/topics/?hotel=${selectedHotel.hotel_code}`)
      .then((res) => {
        dispatch(fetchChannelsSuccess(res.data));
      })
      .catch((e) => {
        console.log("error in fetching channels", e);
        check_auth_error(e);
        dispatch(fetchChannelsError(e.message));
      });
  };
};

export const addChannelRequest = () => {
  return {
    type: teamComActionTypes.ADD_TEAM_COM_CHANNEL_REQUEST,
  };
};

export const addChannelSuccess = (newChannel) => {
  return {
    type: teamComActionTypes.ADD_TEAM_COM_CHANNEL_SUCCESS,
    payload: newChannel,
  };
};

export const addChannelError = (error) => {
  return {
    type: teamComActionTypes.ADD_TEAM_COM_CHANNEL_ERROR,
    payload: error,
  };
};

export const addTeamComChannel = (channel_name) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    dispatch(addChannelRequest());
    axiosClient
      .post(
        `/innspire-ai/staff-chat/topics/?hotel=${selectedHotel.hotel_code}`,
        {
          name: channel_name,
        }
      )
      .then((res) => {
        dispatch(addChannelSuccess(res.data));
        dispatch(setGlobalMessage({
          mess:"Channel are successfully added..",
          type:'success'
         }))
      })
      .catch((e) => {
        console.log("error in adding new channel", e);
        dispatch(addChannelError(e.message));
        dispatch(setGlobalMessage({
          mess:e.message,
          type:'error'
         }))
      });
  };
};

export const addToChannelMessages = (payload) => {
  return {
    type: teamComActionTypes.ADD_TO_CHANNEL_MESSAGES,
    payload: payload,
  };
};

export const fetchChannelMessagesRequest = () => {
  return {
    type: teamComActionTypes.FETCH_CHANNEL_MESSAGES_REQUEST,
  };
};

export const fetchChannelMessageSuccess = (data) => {
  //data = {messages: msgs, AppendToMsgs: true|false}
  return {
    type: teamComActionTypes.FETCH_CHANNEL_MESSAGES_SUCCESS,
    payload: data,
  };
};

export const prependChannelMessages = (data) => {
  return {
    type: teamComActionTypes.PREPEND_CHANNEL_MESSAGES,
    payload: data,
  };
};

export const unsetChannelMessages = () => {
  return {
    type: teamComActionTypes.UNSET_CHANNEL_MESSAGES,
  };
};

export const fetchChannelMessagesError = (error) => {
  return {
    type: teamComActionTypes.FETCH_CHANNEL_MESSAGES_FAILURE,
    payload: error,
  };
};

export const fetchChannelMessages = (
  conversation_id,
  offset = 0,
  limit = 10
) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    let _url = `/innspire-ai/staff-chat/topics/chat/?conversation=${conversation_id}&hotel=${selectedHotel.hotel_code}&limit=${limit}`;
    if (offset) _url += `&offset=${offset}`;

    dispatch(fetchChannelMessagesRequest());
    axiosClient
      .get(_url)
      .then((res) => {
        const data = res.data?.results || [];
        const sortedMessage = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        // if (data?.length == 0) {
        //   dispatch(
        //     fetchChannelMessagesError(
        //       offset > 0 ? "--" : "No chat messages found"
        //     )
        //   );
        // } else {
        if (offset && offset > 0) dispatch(prependChannelMessages(sortedMessage));
        else dispatch(fetchChannelMessageSuccess(sortedMessage));
        // }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(fetchChannelMessagesError(error.message));
      });
  };
};

export const sendChannelMessage = (msg, conversation_id,clearUrlParams) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth: { user },
    } = getState();
    let url = `/innspire-ai/staff-chat/topics/chat/?conversation=${conversation_id}&hotel=${selectedHotel.hotel_code}`;
    if (msg.id) url += `&feId=${msg.id}`;

    axiosClient.post(url, msg)
    .then((res)=>{
        // if(msg.review_id && clearUrlParams){
        //   clearUrlParams()
        //   dispatch(setInfoForChatInp(null))
        // }
    })
    .catch((error) => {
      let err = !navigator.onLine
        ? "Network error"
        : error.detail ||
          error.message ||
          "Something went wrong, Please try again!";
      const unsent_msg = {
        ...msg,
        error: err,
        staff_user: user,
        speaker: "CURRENT_USER",
        created_at: new Date(),
        id: msg.id ? msg.id : `${Date.now()}`,
        ...(msg.review_id?{review_id:msg.review_id}:{})
      };
      dispatch(updateChannelMessage(unsent_msg, true));
      console.log("Error sending channel message", error);
      dispatch(setGlobalMessage({
        mess:error.message,
        type:'error'
       }))
    });
  };
};

export const updateChannelMessage = (data, add = false) => {
  return {
    type: teamComActionTypes.UPDATE_CHANNEL_MESSAGE,
    payload: { data, add },
  };
};

export const otherFunctionsRequest = () => {
  return {
    type: teamComActionTypes.OTHER_FUNCTIONS_REQUEST,
  };
};

export const updateChannelMessageProps = (msg_id, data) => {
  return {
    type: teamComActionTypes.UPDATE_CHANNEL_MESSAGE_PROPS,
    payload: { msg_id, data },
  };
};

export const otherFunctionsError = (error) => {
  return {
    type: teamComActionTypes.OTHER_FUNCTIONS_ERROR,
    payload: error,
  };
};

export const deleteChannelMessage = (con_id, msg_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    dispatch(otherFunctionsRequest());
    axiosClient
      .delete(
        `/innspire-ai/staff-chat/topics/chat/${msg_id}/?conversation=${con_id}&hotel=${selectedHotel.hotel_code}`
      )
      .then((res) => {
        console.log("channel msg deleted");
      })
      .catch((error) => {
        console.log("error in channel message delete", error);
        dispatch(otherFunctionsError(error.message));
        dispatch(setGlobalMessage({
          mess:error.message,
          type:'error'
         }))
      });
  };
};

export const fetchChannelMsgsOnReconnect = (created_at) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      teamCom: {
        channels: { selectedChannels },
      },
    } = getState();
    const con_id = selectedChannels[0]?.conversation_id;
    axiosClient
      .post(
        `/innspire-ai/staff-chat/${con_id}/push/messages/${created_at}/?hotel=${selectedHotel.hotel_code}`
      )
      .catch((error) => {
        console.log("error on refetching channel msgs on reconnect", error);
        dispatch(setGlobalMessage({
          mess:error.message,
          type:'error'
         }))
      });
  };
};

export const markChannelMsgAsRead = (con_id, msg_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(
        `/innspire-ai/staff-chat/topics/chat/${msg_id}/?conversation=${con_id}&hotel=${selectedHotel.hotel_code}`,
        {
          read: true,
        }
      )
      .catch((error) => {
        console.log("error in read status change", error);
        dispatch(setGlobalMessage({
          mess:error.message,
          type:'error'
         }))
      });
  };
};

// ====staff to staff comms actios=====

export const fetchDmusRequest = () => {
  return {
    type: teamComActionTypes.FETCH_DMU_REQUEST,
  };
};

export const fetchDmusSuccess = (dmus) => {
  return {
    type: teamComActionTypes.FETCH_DMU_SUCCESS,
    payload: dmus,
  };
};

// export const appendToDmusList = (dmus) => {
//   return {
//     type: teamComActionTypes.APPEND_TO_DMUS_LIST,
//     payload: dmus,
//   };
// };

export const fetchDmusError = (error) => {
  return {
    type: teamComActionTypes.FETCH_DMU_ERROR,
    payload: error,
  };
};

export const fetchDmus = () => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth:{ user}
    } = getState();
    dispatch(fetchDmusRequest());
    axiosClient
      .get(`/innspire-ai/staff-users/?hotel=${selectedHotel.hotel_code}&exclude-me=${user.id}`)
      .then((res) => {
        const data = res.data;
        if (data?.length > 0) {
          // if (page <= 1) dispatch(fetchDmusSuccess(data));
          // else dispatch(appendToDmusList(data));
          dispatch(fetchDmusSuccess(data));
        }
      })
      .catch((error) => {
        //check_auth_error(error);
        dispatch(fetchDmusError(error?.message || "Something went wrong!"));
      });
  };
};

export const fetchDirectMessagesRequest = () => {
  return {
    type: teamComActionTypes.FETCH_DMU_MESSAGES_REQUEST,
  };
};

export const fetchDirectMessagesSuccess = (messages) => {
  //payload = {messages: msgs, AppendToMsgs: true|false}
  return {
    type: teamComActionTypes.FETCH_DMU_MESSAGES_SUCCESS,
    payload: messages,
  };
};

export const fetchDirectMessagesError = (error) => {
  return {
    type: teamComActionTypes.FETCH_DMU_MESSAGES_FAILURE,
    payload: error,
  };
};

export const prependDirectMessages = (messages) => {
  return {
    type: teamComActionTypes.PREPEND_DIRECT_MESSAGES,
    payload: messages,
  };
};

export const updateUnreadMsgCount = (count,filter) => {
  return {
    type: teamComActionTypes.UPDATE_UNREAD_MSG_COUNT,
    payload: {count,filter}
  };
};

export const updateStaffUnreadMsgCount = (payload) => {
  return (dispatch, getState) => {
    const { directMessage } = getState().teamCom;
    const staff = directMessage?.data || [];

    const data = staff.map((staffMember) => {
      const matchedPayload = payload.find(
        (p) => p?.conversation_id === staffMember.conversation_id
      );

      if (matchedPayload) {
        return { ...staffMember, unread_msg_count: matchedPayload.unread_count };
      }

      return staffMember;
    });

    dispatch({
      type: teamComActionTypes.UPDATE_STAFF_UNREAD_MSG_COUNT,
      payload: data,
    });
  };
};


export const unsetDirectMessages = () => {
  return {
    type: teamComActionTypes.UNSET_DIRECT_MESSAGES,
  };
};

export const setInfoForChatInp = (d) => {
  return {
    type: teamComActionTypes.SET_INFO_FOR_CHAT_INP,
    payload:d
  };
};

export const fetchDirectMessages = (staff_id, offset = 0, limit = 10) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    let url = `/innspire-ai/staff-chat/staff/chat/?hotel=${selectedHotel.hotel_code}&staff_id=${staff_id}&limit=${limit}`;
    if (offset) url += `&offset=${offset}`;

    dispatch(fetchDirectMessagesRequest());
    axiosClient
      .get(url)
      .then((res) => {
        const data = res.data?.results;
        const sortedMessage  = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        // if (data?.length == 0) {
        //   dispatch(
        //     fetchDirectMessagesError(
        //       offset > 0 ? "--" : "No chat messages found"
        //     )
        //   );
        // } else {
        if (offset && offset > 0) dispatch(prependDirectMessages(sortedMessage));
        else dispatch(fetchDirectMessagesSuccess(sortedMessage));
        // }
      })
      .catch((error) => {
        //check_auth_error(error);
        console.log("error in getting staff msgs", error);
        dispatch(fetchDirectMessagesError(error.message));
      });
  };
};

export const sendDirectMessage = (msg, staff_id,clearUrlParams) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      auth: { user },
    } = getState();

    let url = `/innspire-ai/staff-chat/staff/chat/?hotel=${selectedHotel.hotel_code}&staff_id=${staff_id}`;
    if (msg.id) url += `&feId=${msg.id}`;

    axiosClient.post(url, msg)
    .then((res)=>{
      // if(msg.review_id){
      //   dispatch(setInfoForChatInp(null))
      // }
  })
    .catch((error) => {
      console.log("error in sending deirect message", error);
      let err = !navigator.onLine
        ? "Network error"
        : error.detail ||
          error.message ||
          "Something went wrong, Please try again!";
      const unsent_msg = {
        ...msg,
        error: err,
        staff_user: user,
        speaker: "CURRENT_USER",
        created_at: new Date(),
        id: msg.id ? msg.id : `${Date.now()}`,
        ...(msg.review_id?{review_id:msg.review_id}:{})
      };
      dispatch(updateDirectMessage(unsent_msg, true));
    });
  };
};

export const updateDirectMessage = (data, add = false) => {
  return {
    type: teamComActionTypes.UPDATE_DIRECT_MESSAGE,
    payload: { data, add },
  };
};

export const updateDirectMessageProps = (msg_id, data) => {
  return {
    type: teamComActionTypes.UPDATE_DIRECT_MESSAGE_PROPS,
    payload: { msg_id, data },
  };
};

export const deleteDirectMessage = (staff_id, msg_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();

    let url = `/innspire-ai/staff-chat/staff/chat/${msg_id}/?staff_id=${staff_id}&hotel=${selectedHotel.hotel_code}`;

    dispatch(otherFunctionsRequest());
    axiosClient
      .delete(url)
      .then((res) => {
        console.log("dm deleted");
        dispatch(setGlobalMessage({
          mess:"Message is successfully deleted..",
          type:'success'
         }))
      })
      .catch((error) => {
        console.log("error in deleting direct message", error);
        dispatch(setGlobalMessage({
          mess:"Message not deleted",
          type:'error'
         }))
        dispatch(otherFunctionsError(error.message));
      });
  };
};

export const fetchDirectMsgsOnReconnect = (created_at) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
      teamCom: {
        directMessage: { selectedUsers },
      },
    } = getState();
    const staff_id = selectedUsers[0]?.id;
    axiosClient
      .post(
        `/innspire-ai/staff-chat/staff/push/messages/${created_at}/?hotel=${selectedHotel.hotel_code}&staff_id=${staff_id}`
      )
      .catch((error) => {
        console.log("error on refetching direct msgs on reconnect", error);
      });
  };
};

export const markDirectMessageAsRead = (staff_id, msg_id) => {
  return (dispatch, getState) => {
    const {
      filter: { selectedHotel },
    } = getState();
    axiosClient
      .patch(
        `/innspire-ai/staff-chat/staff/chat/${msg_id}/?staff_id=${staff_id}&hotel=${selectedHotel.hotel_code}`,
        {
          read: true,
        }
      )
      .catch((error) => {
        console.log("error in read status change", error);
        dispatch(setGlobalMessage({
          mess:error.message,
          type:'error'
         }))
      });
  };
};

export const addToDmuMessages = (payload) => {
  return {
    type: teamComActionTypes.ADD_TO_DMU_MESSAGES,
    payload: payload,
  };
};
