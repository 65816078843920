import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  Box,
  Grid,
  MenuItem,
  Checkbox,
  Stack,
  CircularProgress,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import CommonButton from "./CommonButton";

const GenericForm = ({
  title,
  initialState,
  fields = [],
  fetchOptions,
  onSubmit,
  closeModal,
  isEdit = false,
  submitButtonText="Submit",
  itemStyle={},
  parrentStyle={}
}) => {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({});

  // Fetch options dynamically if provided
  useEffect(() => {
    if (fetchOptions) {
      Object.entries(fetchOptions).forEach(async ([key, fetchFn]) => {
        const data = await fetchFn();
        setOptions((prev) => ({ ...prev, [key]: data }));
      });
    }
  }, [fetchOptions]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(type);
    
    setErrors((prev) => ({ ...prev, [name]: "" }));
  
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox state separately
    }));
  };

  // Validate form data
  const isValid = () => {
    let valid = true;
    const newErrors = {};
    fields.forEach(({ name, required }) => {
      if (required && !formData[name]) {
        valid = false;
        newErrors[name] = "This field is required";
      }
    });
    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (isValid()) {
      setLoading(true);
      await onSubmit(formData, setLoading,closeModal);
    }
  };

  const getComp = (field)=>{
    switch (field.type) {
      case "text":
       return <TextField
        required={field.required}
        label={field.label}
        name={field.name}
        value={formData[field.name]}
        onChange={handleChange}
        error={!!errors[field.name]}
        helperText={errors[field.name]}
        fullWidth
        disabled={loading}
        select={field.type === "select"}
        multiline={field.multiline || false}
        SelectProps={{ multiple: field.multiple || false }}
      >
        {field.type === "select" &&
          options[field.name]?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {field.multiple && (
                <Checkbox checked={formData[field.name]?.includes(option.value)} />
              )}
              {option.label}
            </MenuItem>
          ))}
      </TextField>

case "select":
  return <TextField
   required={field.required}
   label={field.label}
   name={field.name}
   value={formData[field.name]}
   onChange={handleChange}
   error={!!errors[field.name]}
   helperText={errors[field.name]}
   fullWidth
   disabled={loading}
   select={field.type === "select"}
   multiline={field.multiline || false}
   SelectProps={{ multiple: field.multiple || false }}
 >
   {field.type === "select" &&
     options[field.name]?.map((option) => (
       <MenuItem key={option.value} value={option.value}>
         {field.multiple && (
           <Checkbox checked={formData[field.name]?.includes(option.value)} />
         )}
         {option.label}
       </MenuItem>
     ))}
 </TextField>
      case "switch":
        return  <Box key={field.name} display="flex" alignItems="center" width="100%">
        {/* Label section */}
        <Box sx={{ width: '80%', flexShrink: 0 }}>
          <FormControlLabel
            control={
              <Switch
                name={field.name}
                checked={formData[field.name]}  // Use 'checked' instead of 'defaultChecked'
                onChange={handleChange}
                color="success"
                sx={{ marginLeft: 'auto' }} // Align switch to the right
              />
            }
            label={field.label}
            labelPlacement="start"
            sx={{ width: '100%', margin: 0 }}  // Ensure label spans 70% width
          />
        </Box>
      </Box>
    
      default:
        break;
    }
  }

  return (
    <FormControl
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        width: { xs: "100%", sm: "600px" },
        margin: "auto",
      }}
    >
      <FormLabel focused={false}  sx={{
      }}>{title}</FormLabel>

      <Grid container spacing={2} style={parrentStyle}>
        {fields?.map((field) => (
          <Grid item sm={field.row || 12} xs={field.row || 12} key={field.name} display={"flex"} justifyConten={"start"} style={itemStyle}>
            {getComp(field)}
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", columnGap: 3, justifyContent: "center" }}>
        <CommonButton loading={loading} onClickHandler={closeModal} title="Cancel" />
        {loading ? (
          <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
            <CircularProgress color="success" />
          </Stack>
        ) : (
          <CommonButton onClickHandler={handleSubmit} title={submitButtonText} loading={loading} />
        )}
      </Box>
    </FormControl>
  );
};

export default GenericForm;
