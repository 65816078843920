import React,{ Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AnswerTypeOptions from './questionTypeOptions';

import css from './QuestionType.module.css';

const DependentQuesSection = props => {
    return(
        <div className = {css.OptionToDependCont}>
            <div className = {css.OptionSelectCont}>
                <label>Select choice:</label>
                {props.options.length > 0 ? 
                
                <select className = {css.OptionSelect} value = {props.optionToDepend} onChange = {(e) => props.setOptionToDepend(e.target.value)} disabled = {!props.editable}>
                    <option value="select">Select option</option>
                    {props.options.map((el,i) => (
                        <option value = {el.id ? el.id : el.value} key = {el + '_' + i} >{el.value}</option>
                    ))}
                </select>
                :<select className = {css.OptionSelect} disabled>
                    
                    <option>none</option>
                </select>}
                {(props.currDepQues && props.currDepQues !== "select") && <FontAwesomeIcon icon={faTimesCircle} onClick = {props.cancelAddingDepdtQues} style={{display:"block",marginLeft:"auto",color:"salmon",cursor:"pointer"}}/>}
            </div>
            {
                (props.currDepQues && props.currDepQues !== "select") && <Fragment>
                    <div className = {css.DependentQuestion}>
                        <input type='text' placeholder = 'Q. Add your question here' onChange = {(e) => props.setDependentQues(e.target.value)}
                        value = {props.dependentQues} disabled={!props.editable}/>
                    </div>
                
                    {!props.dependentQuesDisplay ? <AnswerTypeOptions answerTypeHandler={props.setDependentQuesDisplay}/>
                    : props.answerTypeCompHandler(props.dependentQuesDisplay,props.setDependent_Ques_Options)}
                </Fragment>
            }
        </div>
    );
}

export default DependentQuesSection;