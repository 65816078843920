import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Radio,
  Checkbox,
  Paper,
  InputAdornment,
  Tooltip,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const OptionInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused': {
      '& > fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

const OptionContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const MultiOptions = ({
  question,
  input = 'checkbox',
  editable = true,
  debug = false,
  updateInParent,
  questionList = false,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (question?.options) {
      setOptions(question.options);
      if (debug) {
        console.log('Question received:', question);
      }
    }
  }, [question, debug]);

  const getValueType = (value) => {
    if (!isNaN(value)) return 'Number';
    if (value === 'true' || value === 'false') return 'Boolean';
    return 'Text';
  };

  const handleAddOption = (event) => {
    if (event.key === 'Enter' && event.target.value.trim()) {
      const newOption = {
        type: getValueType(event.target.value),
        value: event.target.value.trim(),
        sort_order: options.length + 1,
      };

      const updatedOptions = [...options, newOption];
      setOptions(updatedOptions);
      updateInParent?.(updatedOptions, 'options');
      event.target.value = '';
    }
  };

  const handleEditOption = (value, sortOrder) => {
    const updatedOptions = options.map((option) =>
      option.sort_order === sortOrder
        ? { ...option, value, type: getValueType(value) }
        : option
    );
    setOptions(updatedOptions);
    updateInParent?.(updatedOptions, 'options');
  };

  const handleRemoveOption = (sortOrder) => {
    if (!sortOrder || !editable) return;

    const updatedOptions = options.filter(
      (option) => option.sort_order !== sortOrder
    );
    setOptions(updatedOptions);
    updateInParent?.(updatedOptions, 'options');
  };

  const getInputLabel = () => {
    switch (input) {
      case 'checkbox':
        return 'Select multiple options';
      case 'radio':
        return 'Select a single option';
      default:
        return 'Options';
    }
  };

  const getIcon = () => {
    return input === 'checkbox' ? (
      <CheckBoxOutlineBlankIcon />
    ) : (
      <RadioButtonUncheckedIcon />
    );
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '42rem',padding:2 }}>
      <Stack spacing={3}>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          {/* {getIcon()} */}
          {getInputLabel()}
        </Typography>

        <OptionContainer elevation={0}>
          <Stack spacing={2} >
            {options.map((option) => (
              <Box
                key={option.sort_order}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                {input === 'checkbox' ? (
                  <Checkbox disabled />
                ) : (
                  <Radio disabled />
                )}
                <OptionInput
                  fullWidth
                  disabled={!editable}
                  value={option.value}
                  onChange={(e) =>
                    handleEditOption(e.target.value, option.sort_order)
                  }
                  InputProps={{
                    endAdornment: editable && (
                      <InputAdornment position="end">
                        <Tooltip title="Remove option">
                          <IconButton
                            edge="end"
                            onClick={() => handleRemoveOption(option.sort_order)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ))}

            {!questionList && editable && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {input === 'checkbox' ? (
                  <Checkbox disabled />
                ) : (
                  <Radio disabled />
                )}
                <OptionInput
                  fullWidth
                  placeholder="Type option and press Enter"
                  onKeyDown={handleAddOption}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AddIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          </Stack>
        </OptionContainer>
      </Stack>
    </Box>
  );
};

export default MultiOptions;