import React, { useState, useEffect } from 'react';
import { Bar, Chart, Radar } from 'react-chartjs-2';
import { url } from '../../../../../components/Constants/Constants';
import BaseChart from '../../../../../components/Dashboard/ChartComponents/BaseChart/BaseChart';

import css from '../SurveyAnalytics.module.css';

Chart.defaults.global.legend.display = false;

const ArrivalExperience = props => {

  const [api_data,setAPIData] = useState([]);
  // const barColorHandler = (data) => {
  //   const colorArr = [];
  //   Object.values(data).map(el => {
  //     if(el < 2.5){
  //       colorArr.push('#FF6161');
  //     }else if(el === 2.5){
  //       colorArr.push('#FEF55A');
  //     }else{
  //       colorArr.push('#38B738');
  //     }
  //   });
  //   return colorArr;
  // }

  const getChartDataFromResp = (resp_data) => {
    if (resp_data.length >= 0){
      return {
        labels: ["Dining ", "Internet", "Water sports", "Pool","Beach","Gym",'Boutique',"Spa",'Boutique',"Spa"],
          datasets: [
            {
              data: [1,5,3,2,8,6,6,5,9,7],
              backgroundColor: '#C8A9E6',
              borderColor: '#D1D7DF5A',
              borderWidth: 1,
              pointRadius: 0,
              barPercentage:0.5
            },
          ],
      }
    } else {
      return {}
    }
  }


  const chart = (
                <div >    
                    <Bar
                      data={getChartDataFromResp(api_data)}
                      height={window.innerWidth > 450 ? 3.5 : 6}
                      width = {10}
                      options={{
                      plugins: {
                          labels: {
                          render: () => {},
                          },
                      },
                      scales:{
                        yAxes:[{
                            gridLines:{
                                drawOnChartArea: false
                            },
                          ticks:{
                            beginAtZero: true,
                            max: 10,
                            maxTicksLimit: 6,
                            fontSize: 14,
                            fontFamily: 'Lato',
                            
                          }
                        }],
                        xAxes:[{
                            gridLines:{
                                drawOnChartArea: false,
                               
                            },
                            ticks:{
                                
                            }
                        }]
                      }
                      }}
                    />
                   
              </div>
  );
    

    return (<BaseChart 
      chart={chart}
    //   data_url = {`${url}/analytics/key-reviews/?hotel=${props.selectedHotel.hotel_code}&${props.date_params}&${props.filter_params}`}
    //   setAPIData = {setAPIData}
    //   api_dependency = {[props.selectedHotel, props.date_params, props.filter_params]}  
    />);

}

export default ArrivalExperience;


