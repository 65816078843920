import React, { useState, useEffect } from "react";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./DateWidget.css";

const DateSelector = props => {

    const [option, setOption] = useState(props.selectedOption)
    const [startDate, setStartDate] = useState(props.startDate)
    const [endDate, setEndDate] = useState(props.endDate)

    // Selects options from drop down
    const onSelectionChange = (event) => {
        setOption(event.target.value);
    }

    const onPeriodSelected = (date, period) =>{
        if (period=='start'){
            setStartDate(date)
            if (endDate && endDate < date) setEndDate(date);    
        }
        else if (period=='end') setEndDate(date)
    }

    useEffect(() => {
        if(props.applySelection==true){
            props.dispatchOption(option);
            props.dispatchStartDate(startDate)
            if (endDate && endDate < startDate) props.dispatchEndDate(startDate);    
            else props.dispatchEndDate(endDate)
            props.setSelectionDone(true);
        }
    },[props.applySelection])

    return (
        <div>
            <select value = {option} className='periodSelector'
                onChange = {onSelectionChange}>
                {props.options.map((obj, index) => {
                    return (<optgroup label = {obj.label} key={index}>
                        {obj.values.map((value, index) => {
                            return (<option value={value} key={index}>{value}</option>)
                        })}
                    </optgroup>)
                })}
            </select>
            {option == 'Custom Date'
                ? (<div className="interval" id='customInputInterval'>
                        <div className="interval_span">
                            {console.log(`${props.name} start date: ${endDate}`)}
                            <Datepicker selected = {startDate} dateFormat = 'dd-MMM-yy'
                                startDate = {''} endDate = {''}
                                onChange = {date => onPeriodSelected(date, 'start')}
                                placeholderText='Start Date' maxDate={new Date()} selectsStart
                            />
                        </div> 
                        <div className="horizontal_line"><p>__</p></div>
                        <div className="interval_span">
                            {console.log(`${props.name} end date: ${endDate}`)}
                            <Datepicker selected = {endDate} dateFormat = 'dd-MMM-yyyy'
                                startDate = {''} endDate = {''}
                                onChange = {date => onPeriodSelected(date, 'end')}
                                placeholderText='End Date' minDate = {startDate} maxDate={new Date()} selectsEnd />
                        </div>   
                    </div>)
                : ("")
            }
        </div>
    );
}

export default DateSelector;