import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableCell,
    tableCellClasses,
    styled,
    TablePagination,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
  import { useDispatch, useSelector } from "react-redux";
  import { ReplayOutlined } from "@mui/icons-material";
  import CustomModal from "../../Modals/CustomModal";
  import LineLoader from "../../Loader/LineLoader";

  
  const StyledTableCell = styled(TableCell)(({ theme, fontWeight }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E4E6E7",
      color: "#6D7378",
      padding: "4px 5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px",
      fontWeight: fontWeight ? fontWeight : 500,
    },
  }));
  
  const CommonTableUI = ({
    columns = [], // Pass columns dynamically
    fetchData, // Function to fetch data
    dataSelector, // Selector to get data from the store
    loadingSelector, // Selector to get loading state
    errorSelector, // Selector to get error state
    isEditPermission = false,
    handleEdit, // Function to handle edit
    handleDelete, // Function to handle delete
    RowComponent,
    count,
    minRow=10,
    page,
    initialFetch=true,
    title = "table",
    maxHeight
  }) => {
    const dispatch = useDispatch();
    const data = useSelector(dataSelector) || [];
    const loading = useSelector(loadingSelector);
    const error = useSelector(errorSelector);
  
    const [currPage, setCurrPage] = useState(0);
    const [confirmModal, setConfirmModal] = useState(null);
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState([]);
    const [visibleRows, setVisibleRows] = useState([]);
    const selectedHotel = useSelector(
        (state) => state.filter.selectedHotel || {}
      );
  
    useEffect(() => {
      if(initialFetch)
        fetchData()
      setVisibleRows(data);
    }, [selectedHotel]);
  
    useEffect(() => {
      console.log("cur",currPage)
      if (data.length > 0) {
        setVisibleRows(data.slice(currPage * minRow, currPage * minRow + minRow));
      } else {
        setVisibleRows([]);
      }
    }, [currPage, data]);
  
    const handleChangePage = (event, newPage) => {
      console.log(newPage, page)
      if (newPage > page) {
       fetchData(newPage)
      }
        setCurrPage(newPage);
    };
  
    const closeConfirmModal = () => {
      setConfirmModal(null);
    };
  
    const confirmChange = () => {
      closeConfirmModal();
    };
  
    return (
      <Box>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography>{title}</Typography>
          <IconButton onClick={() => {fetchData(0)
setCurrPage(0)}}>
            <ReplayOutlined />
          </IconButton>
        </Box>
        <Paper sx={{ width: "100%", overflow: "auto", borderRadius: "8px" }}>
          <TableContainer
            sx={{
              maxHeight:maxHeight ||  250,
              flexGrow: 1,
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <StyledTableCell key={idx}>
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"2px"}
                      >
                        <Typography
                          sx={{ fontSize: { sm: "10px", lg: "14px", paddingLeft: '20px' } }}
                        >
                          {column.name}
                        </Typography>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(open === column.name ? "" : column.name)}
                        >
                          {open === column.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
  
              <TableBody>
                <TableRow>
                  {loading ? (
                    <StyledTableCell colSpan={columns.length}>
                      <LineLoader />
                    </StyledTableCell>
                  ) : error && data.length === 0 ? (
                    <StyledTableCell colSpan={columns.length}>
                      <Typography sx={{ color: "#6D7378" }}>
                        {error}
                        <IconButton onClick={() =>{fetchData(0)
setCurrPage(0)

                        }}>
                          <ReplayOutlined />
                        </IconButton>
                      </Typography>
                    </StyledTableCell>
                  ) : (
                    data.length === 0 && (
                      <StyledTableCell colSpan={columns.length}>
                        <Typography sx={{ textAlign: "center", color: "#6D7378" }}>
                          No data found!
                        </Typography>
                      </StyledTableCell>
                    )
                  )}
                </TableRow>
                {data.length > 0 &&
                  visibleRows.map((row, index) => {
                    return (
                    <RowComponent row={row} key={row.id}/>
                    );
                  })}
                {Array.from({ length: currPage > 0 ? Math.max(0, (currPage + 1) * minRow - count) : 0 }).map((_, idx) => (
                  <TableRow key={idx}>
                    <StyledTableCell colSpan={columns.length} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={currPage}
            onPageChange={handleChangePage}
            rowsPerPage={minRow}
            rowsPerPageOptions={[minRow]}
          />
        </Paper>
        <CustomModal
          show={confirmModal != null}
          handleClose={closeConfirmModal}
          handleClick={confirmChange}
          title={"Please confirm before any change"}
        />
      </Box>
    );
  };
  
  export default CommonTableUI;
  