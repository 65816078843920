import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import LineLoader from "../Loader/LineLoader";
import { MentionsInput, Mention } from "react-mentions";
import {
  editComment,
  editCommentRequest,
  fetchComments,
  postComment,
} from "../../../apps/Reviews/actions/actions";
import Comment from "./components/Comment";
import { ReplayOutlined } from "@mui/icons-material";
import { stringAvatar } from "../../utility";
import defaultStyle from "./components/defaultStyle";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none", // Remove border from the select button
      },
      "&:hover fieldset": {
        border: "none", // Remove border from the select button on hover
      },
      "&.Mui-focused fieldset": {
        border: "none", // Remove border from the select button when focused
      },
    },
  },
}));

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  color: "black",
  textTransform: "none",
  fontSize: 12,
  padding: "6px 12px",
  lineHeight: 1.5,
  "&:hover": {
    boxShadow: "none",
    border: "1px solid #EAEBEC",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {},
});

const secondary = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#181A1B",
};
const secondary2 = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#6D7378",
};

const Comments = ({ review }) => {
  const [comment1, setComment1] = useState("");

  const users = useSelector((state) => state.guestReq?.staff || []).map(
    (user) => ({
      ...user,
      display: `${user.first_name ? user.first_name : ""}${
        user?.first_name && user?.last_name && " "
      }${user?.last_name ? user?.last_name : ""}`.trim(),
    })
  );
  const handleCommentChange = (event, newValue) => {
    setComment1(newValue);
  };

  const classes = useStyles();
  const [comment, setComment] = useState("");
  const user = useSelector((state) => state.auth.user || {});
  const [loading, setLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [error, setError] = useState("");
  const [taggerUserRecord, setTaggerUserRecord] = useState([]);
  const comments = useSelector(
    (state) => state.review.reviews.comments[review.review_id]
  );
  const comment_edit = useSelector(
    (state) => state.review.reviews.editing_comment || {}
  );
  const [_comments, setComments] = useState({
    data: [],
    next: "",
    count: 0,
  });
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getComments = () => {
    const url = `/reviews/comments/?hotel=${review.hotel_code}&review_id=${review.review_id}&source=${review.source.code}`;
    dispatch(fetchComments(url, setLoading, setError, review.review_id));
  };

  const observer = useRef();
  const lastCommentObjRef = useCallback((commentsObj) => {
    console.log(`comment in view`);
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        getComments();
        observer.current.unobserve(commentsObj);
      }
    });
    if (commentsObj) observer.current.observe(commentsObj);
  }, []);

  useEffect(() => {
    if (comments) {
      setComments(comments);
      setLoading(false);
      setComment("");
    }
  }, [comments]);

  // if user edits a comment
  const input_ref = useRef(null);
  useEffect(() => {
    if (
      Object.keys(comment_edit).length > 0 &&
      comment_edit.review_id == review.review_id
    ) {
      setComment(comment_edit.text);
      input_ref.current.focus();
    }
  }, [comment_edit]);

  const handleChange = (e) => {
    const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    setComment(e.target.value.replace(regex, "@$1"));
  };

  const handleSendComment = (e) => {
    Array.prototype.removeDuplicates = function () {
      return [...new Set(this)];
    };
    // e.key === "Enter" && !e.shiftKey &&
    if (comment.length > 0) {
      let removedDup = taggerUserRecord.removeDuplicates();
      const data = {
        hotel_code: review.hotel_code,
        review_id: review.review_id,
        source_code: review.source.code,
        text: comment,
        removedDup,
      };
      if (Object.keys(comment_edit).length > 0)
        dispatch(
          editComment(
            { ...data, comment_id: comment_edit.id },
            setError,
            setPostLoading
            ,setTaggerUserRecord
          )
        );
      else dispatch(postComment(data, setError, setPostLoading,setTaggerUserRecord));
  
    }
  };

  const load_next_page = () => {
    dispatch(
      fetchComments(_comments.next, setLoading, setError, review.review_id)
    );
  };

  const buttonStyle = {
    "&:hover": {
      backgroundColor: "transparent", // Set the hover background color to transparent
    },
  };

  return (
    <Box ref={lastCommentObjRef}>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 2, gap: 2 }}>
        <Avatar
          src={user?.profile?.photo}
          {...stringAvatar(
            user.first_name
              ? `${user.first_name} ${user.last_name}`
              : user.last_name,
            { width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }
          )}
        />
        <Box sx={{ width: "95%", position: "relative" }}>
          <MentionsInput
            value={comment}
            onChange={handleChange}
            placeholder="Leave a comment"
            style={defaultStyle}
            id="comment"
            disabled={loading || postLoading}
            a11ySuggestionsListLabel="Suggested mentions"
            // onKeyUp={handleSendComment}
            allowSpaceInQuery={true}
            inputRef={input_ref}
            onBlur={(e) => {
              if (Object.keys(comment_edit).length > 0 && comment.length == 0) {
                dispatch(editCommentRequest({}));
              }
            }}
            // singleLine={true}
          >
            {/* <TextField
          fullWidth
          size={isMobile ? "small" : "medium"}
          label="Leave a comment"
          id="comment"
          disabled={loading || postLoading}
          value={comment}
          onChange={handleChange}
          onKeyUp={handleSendComment}
          ref={input_ref}
          onBlur={(e) => {
            if (Object.keys(comment_edit).length > 0 && comment.length == 0) {
              dispatch(editCommentRequest({}));
            }
          }}
        /> */}
            <Mention
              trigger="@"
              // markup="@__display__"
              data={users}
              appendSpaceOnAdd={true}
              style={{ fontWeight: "bold", color: "blue !important" }}
              renderSuggestion={(suggestion, search, highlightedDisplay) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      src={suggestion.photo}
                      {...stringAvatar(suggestion.display, {
                        width: { xs: 25, sm: 30 },
                        height: { xs: 25, sm: 30 },
                        fontSize: {xs: "12px", sm: "14px"}
                      })}
                    />
                    <Typography
                      sx={{
                        fontFamily: "sans-serif",
                        fontSize: 16,
                      }}
                    >
                      {highlightedDisplay}
                    </Typography>
                  </Box>
                );
              }}
              displayTransform={(id, display, photo) => {
                if (!taggerUserRecord.includes(`@${display}||${id}`))
                  setTaggerUserRecord((p) => [...p, `@${display}||${id}`]);
                console.log(taggerUserRecord, display, id);
                return `@${display}`;
              }}
            />
          </MentionsInput>
    {
      postLoading?<CircularProgress 
      sx={{
        position: "absolute",
        top: "50%",
        right: "1%",
        translate: "0% -50%",
        cursor: "pointer",
        padding:"0.5vmax"
      }}
      color="success"
      // size={"0.5vmax"}
      />:
      <SendIcon
      sx={{
        position: "absolute",
        top: "50%",
        right: "1%",
        translate: "0% -50%",
        color: "gray",
        cursor: "pointer",
      }}
      onClick={handleSendComment}
    />
    }
        </Box>
        {/* <button onClick={handleSendComment}  size="small" style={{ background:'#1976d2', color:'white',padding:"10px 15px", borderRadius:"20px"}}>
          Post
        </button> */}
        {/* <TextField
          fullWidth
          size={isMobile ? "small" : "medium"}
          label="Leave a comment"
          id="comment"
          disabled={loading || postLoading}
          value={comment}
          onChange={handleChange}
          onKeyUp={handleSendComment}
          ref={input_ref}
          onBlur={(e) => {
            if (Object.keys(comment_edit).length > 0 && comment.length == 0) {
              dispatch(editCommentRequest({}));
            }
          }}
        /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          felxDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <FormControl
          className={classes.formControl}
          sx={{ flexDirection: "row", alignItems: "baseline" }}
        >
          <Select value={"All comments"} onChange={() => {}}>
            {["All comments"].map((op, idx) => (
              <MenuItem key={idx} value={op}>
                <Typography component="span" display="inline" sx={secondary}>
                  {op}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          className={classes.formControl}
          sx={{ flexDirection: "row", alignItems: "baseline" }}
        >
          <Typography sx={secondary2}>sort by</Typography>
          <Select value={"Most popular"} onChange={() => {}}>
            {["Most popular"].map((op, idx) => (
              <MenuItem key={idx} value={op}>
                <Typography component="span" display="inline" sx={secondary}>
                  {op}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

  <Box sx={{
    maxHeight:"40vh",
    overflowY:"auto"
  }}>
  {_comments.data.length > 0
        ? _comments.data.map((comment, index) => {
            return (
              <Comment
                key={`${comment.id}_${index}`}
                review_id={review.review_id}
                comment={comment}
                hotel_code={review.hotel_code}
                source_code={review.source.code}
                taggedData={comment.tags}
              />
            );
          })
        : !loading && (
            <Typography
              sx={{ textAlign: "center", fontSize: "12px", marginTop: "0px" }}
            >
              {error ? (
                <Typography>
                  {error}{" "}
                  <IconButton onClick={getComments}>
                    <ReplayOutlined />
                  </IconButton>
                </Typography>
              ) : (
                "No comments found"
              )}
            </Typography>
          )}
  </Box>
      {loading && <LineLoader></LineLoader>}

      {_comments.next && (
        <Box sx={{ marginTop: 2 }}>
          <BootstrapButton
            sx={{ width: "100%", gap: 1, border: "1px solid #EAEBEC" }}
            onClick={load_next_page}
          >
            View More Comments{" "}
            <span
              style={{
                borderRadius: "50%",
                padding: "2px 5px",
                backgroundColor: "#EAEBEC",
              }}
            >
              {_comments.count - 1}
            </span>
          </BootstrapButton>
        </Box>
      )}
    </Box>
  );
};

export default Comments;
