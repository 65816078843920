import { useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Box, Typography } from "@mui/material";
import { formatDate, stringAvatar } from "../../utility";
import AvatarWithON_OFF from "../Avatar/AvatarWithON_OFF";
import groupImage from "../../assets/group.png"
import { truncateMessage } from "../../../components/Utility/Utility";

export default function NotificationList({ handleClickNotification }) {
  const {
    results = [],
    count = 0,
    next,
    previous,
  } = useSelector((state) => state.nav?.notificationData);



  return (
    <List
      sx={{
        width: "100%",
        minWidth: 250,
        maxWidth: 300,
        maxHeight: "60vh",
        bgcolor: "background.paper",
        border: "1px solid #E4E6E7",
        paddingBottom: "5px",
        overflowY:"auto"
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          sx={{
            textAlign: "left",
            backgroundColor: "#F9FAFA",
            borderBottom: "1px solid #E4E6E7",
            lineHeight: "36px",
            fontSize: "16px",
            fontWeight: "600",
          }}
          component="div"
          id="nested-list-subheader"
        >
          Notifications
        </ListSubheader>
      }
    >
      {results.length>0?   results.map((i) => (
        <ListItemButton
          key={i.id}
          onClick={(e) => handleClickNotification(e, i)}
          sx={{
            lineHeight: "16px",
            padding: "5px 15px",
            borderRadius: 0,
            display:"flex",
            justifyContent:"start",
            alignItems:"center",
            gap:1,
            bgcolor: i.read ? "background.paper" : "#F0F4FF", 
            "&:hover": {
              bgcolor: i.read ? "#F9FAFA" : "#E0E7FF", 
            },
            marginBottom:"2px"
          }}
        >
    <AvatarWithON_OFF  user={i?.from_user?{
      ...i.from_user,photo:i?.from_user?.profile?.photo
    }:{
      photo:null
    }} style={{
              width: "30px",
              height: "30px",
              fontSize: "12px",
            }}/>
          <ListItemText
            // primary={i.message}
            // primaryTypographyProps={{
            //   sx: {
            //     fontSize: 14,
            //     fontWeight: i.read ? 400 : 600,
            //     color: "#6D7378",
            //   },
            // }}
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              position:"relative"
            }}
          >
<Typography 
sx={{
  position:'absolute',
  bottom:"0",
  right:"0",
  fontSize:"8px"
}}>
  {formatDate(i.created_at)}
</Typography>

{
  i.from_user && <Typography 
  sx={{
    position:'absolute',
    top:"0",
    left:"15px",
    fontSize:"8px",
    fontWeight:"bold"
  }}>
{
  `${i.from_user.first_name} ${i.from_user.last_name}`.trim() || ''
}
  </Typography>
}
            <Typography
              sx={{
                fontSize: 12,
                margin: "5px 5px",
                fontWeight: i.read ? 400 : 600,
                color: "#6D7378",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                padding:"10px"
              }}
            >
              {truncateMessage(i.message,50)} 
            </Typography>
          </ListItemText>
        </ListItemButton>
      )):
      <Box  sx={{
        height:"30vh",
        display:'flex',
        alignItems:"center",
        justifyContent:"center"
      }}>
        <Typography sx={{
          fontSize:"12px"
        }} >No Notifications</Typography>
        </Box>}
    </List>
  );
}
