import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import css from './CarouselControls.module.css';

const CarouselControls = props => {

    const [activeElement,setActiveElement] = useState(0);


    const topChartNavigationHandler = (type) =>{
        const maxLength = document.getElementsByClassName(props.containerName).length;
        switch(type){
          case 'left':
              
            for(let i = 0; i< maxLength ;i ++){
              document.getElementsByClassName(props.containerName)[i].
              style.display = 'none';
            }
            if(activeElement === 0){
              document.getElementsByClassName(props.containerName)[maxLength - 1].
              style.display = 'block';
              setActiveElement(maxLength-1);
            }else{
                document.getElementsByClassName(props.containerName)[activeElement - 1].
                style.display = 'block';
                setActiveElement(prevState => prevState - 1);
            }
            
            break;
          case 'right':
            for(let i = 0; i< maxLength ;i ++){
              document.getElementsByClassName(props.containerName)[i].style.display = 'none';
            }
            if(activeElement === maxLength - 1){
              document.getElementsByClassName(props.containerName)[0].style.display = 'block';
              setActiveElement(0);
            }else{
              document.getElementsByClassName(props.containerName)[activeElement + 1].
              style.display = 'block';
              setActiveElement(prevState => prevState + 1);
            }
            
            break;
          default:
            console.log('default');
        }
      }


    return(
        <React.Fragment>
            <div className = {`${css.Container} CarouselControlsContainer`} style = {{display: props.display}}>
                <FontAwesomeIcon style = {{color:props.brandColor}} className = {`CarouselControlsLeft ${css.Left}`} id = 'CorouselControlsLeft'
                icon = {faArrowAltCircleLeft} onClick = {() => topChartNavigationHandler('left')}/>
                <FontAwesomeIcon className = {`CarouselControlsRight ${css.Right}`} style = {{color:props.brandColor}}
                icon = {faArrowAltCircleRight} onClick = {() => topChartNavigationHandler('right')}/>
            </div>         
        </React.Fragment>
    );
}

const mapStateToProps = state => {
  return{
      brandColor: state.gen.brandColor
  }
}

export default connect(mapStateToProps)(CarouselControls);