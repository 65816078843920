import React from 'react';
import CommonTableUI from './CommonTableUI';
import { useState } from 'react';
import CommonSingleRow from './CommonSingleRow';
import { useDispatch, useSelector } from 'react-redux';
import CommonModalForForms from "../../Modals/CommonModalForForms";
import SurveyForm from './SurveyForm';
import { fetchSurvey, AddUpdateAndDeleteSurvey, updateSurveyDataByKey } from '../../../../apps/Survey/action/SurveyAction';
import { formatDate } from '../../../utility';
import { Check, Close } from '@material-ui/icons';
import ShareSurveyForm from './ShareSurveyForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const SurveyTable = () => {
    const [operationData, setOperationData] = useState(null);
    const [show, setShow] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const navigate = useHistory()

  const dataSelector = state => state?.surveyStore?.Survey?.data || [];
  const loadingSelector = state => state?.surveyStore?.Survey?.loading;
  const errorSelector = state => state?.surveyStore?.Survey?.error;

  const count = useSelector(dataSelector).length || 0;
  const minRow = 10;
  const page = Math.ceil(count / minRow);

  const dispatch = useDispatch()

  const columns = [
    { name: "Name" },
    { name: "Hotels" },
    { name: "Language" },
    {name:"Last updated"},
    {name:"Primary"},
    {name : "Action"}
  ];

  const toggleUpdateModal = () => {
    setShow((prev) => {
      if(prev)
      setOperationData(null)
        return !prev
    });
  };


  const toggleShareModal = () => {
    setShowShareModal((prev) => {
      if(prev)
      setOperationData(null)
        return !prev
    });
  };

  const handleDelete = (data)=>{
    
   dispatch(AddUpdateAndDeleteSurvey(data,false, null, null,"delete"));
        }
        const handleEdit = (data)=>{
            // console.log(data)
            // setOperationData(data)
            // toggleUpdateModal()
            navigate.push(`/survey/edit/${data?.id}`)
          }

          const handleShare = (data)=>{
            console.log(data)
            setOperationData(data)
            toggleShareModal()
          }

  const menuItems = [
      { label: 'Edit', onClick: handleEdit },
    { label: 'Delete', onClick: handleDelete },
    { label: 'Share', onClick: handleShare },
  ];

  const fetchData = ()=>{
    dispatch(updateSurveyDataByKey("loading",true))
    dispatch(fetchSurvey())
  }

  const RowComponent = ({row}) => {
    
    let payload = {
        name :row.name,
        hotel: row?.hotels?.length > 0 ? row.hotels[0]?.name: "",
        lang : row?.group?.survey_languages? row?.group?.survey_languages[0]?.label : "",
        last_up:row?.updated_at ? formatDate(row.updated_at):"",
        primary:row?.is_primary? <Check/>: <Close/>
    }
    return (
      <>
   
            <CommonSingleRow
              key={`${row.id}`}
              payload={payload}
              showMenu={true}
              id={row.id}
              firstRowCap={false}
              menuItems={menuItems}
              fullData={row}
             commonCss= {{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '270px', textTransform:"capitalize"}}
            />
      </>
    );
  };


  return (
    <>
    <CommonTableUI
      columns={columns}
      fetchData={fetchData}
      dataSelector={dataSelector}
      loadingSelector={loadingSelector}
      errorSelector={errorSelector}
      isEditPermission={true}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      RowComponent = {RowComponent}
      minRow={minRow}
      count={count}
      page={page}
      title={""}
      maxHeight={1096}
    />
            <CommonModalForForms
          show={show}
          toggleNewTaskModal={toggleUpdateModal}
          FormUi={
            <SurveyForm
              closeModal={toggleUpdateModal}
              isEdit={true}
              data={operationData}
            />
          }
        />

<CommonModalForForms
          show={showShareModal}
          toggleNewTaskModal={toggleShareModal}
          FormUi={
            <ShareSurveyForm
              closeModal={toggleShareModal}
              isEdit={true}
              data={operationData}
            />
          }
        />
    </>
  );
};

export default SurveyTable;
