import React from 'react'
import Popover from '@mui/material/Popover';
import {Typography,Box, Avatar} from '@mui/material';
import { stringAvatar } from '../../../utility';

const HoverTaggedUser = ({word,userData}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
  return (<>
    <span  
       aria-owns={open ? 'mouse-over-popover' : undefined}
       aria-haspopup="true"
       onMouseEnter={handlePopoverOpen}
       onMouseLeave={handlePopoverClose}
    style={{
        color:"blue",
        cursor:"hover"
    }}>
      {word}
    </span>
        <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
       <Box padding={2}>
       <Box sx={{
              display:"flex",
              justifyContent:"between",
              alignItems:"center",
              gap:3,
              borderRadius:"40px",
              minWidth:"20%"
            }}>
      <Avatar
          src={userData?.user?.profile?.photo}
          {...stringAvatar(
            `${userData.user?.first_name ? userData.user?.first_name + " " : ""}${userData.user?.last_name ? userData.user?.last_name : ""}`,
            { width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }
          )}
        />
        <Typography sx={{fontFamily:"sans-serif",fontSize:"0.9rem",}}>{`${userData.user?.first_name ? userData.user?.first_name + " " : ""}${userData.user?.last_name ? userData.user?.last_name : ""}`}</Typography>
            </Box>
       </Box>
      </Popover>
      </>
  )
}

export default HoverTaggedUser
